import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//components:
//containers:
// import Container from "../../Container";
import MenuButton from "../MenuButton/MenuButton";
import ChatMessages from "../chat/chatMessages/ChatMessages";
import ChatEmoji from "../chat/chatEmoji/ChatEmoji";
import ChatRooms from "../chat/chatRooms/ChatRooms";
import ChatFriends from "../chat/chatFriends/ChatFriends";
//redux:
import { connect } from "react-redux";
import { store, storeSet } from "../..";
//classes:
// import { lang } from "../../classes/lang";
import { moveChatPane, chat } from "../../classes/chat";
import lang from "../../classes/lang";
import { setPrivacy, withPerms } from "../../classes/player";
import { go } from "../../classes/routesLazy";
import ImageCached from "../../classes/cache";
import { focusNext } from "../../classes/utills";
//icons:
import { Icon } from "react-icons-kit";
import { close } from "react-icons-kit/fa/close";
import { caretRight } from "react-icons-kit/fa/caretRight";
import { caretLeft } from "react-icons-kit/fa/caretLeft";
// import { ic_message as chatIcon } from "react-icons-kit/md/ic_message";
// import { ic_location_city as roomIcon } from "react-icons-kit/md/ic_location_city";
// import { ic_email as pmIcon } from "react-icons-kit/md/ic_email";
// import { ic_chat } from "react-icons-kit/md/ic_chat";
import { ic_chat_bubble_outline as chatIcon } from "react-icons-kit/md/ic_chat_bubble_outline";
import { ic_mail_outline as pmIcon } from "react-icons-kit/md/ic_mail_outline";
// import { ic_people as friendsIcon } from "react-icons-kit/md/ic_people";
import { ic_people_outline as friendsIcon } from "react-icons-kit/md/ic_people_outline";
import { ic_lightbulb_outline as notificationsIcon } from "react-icons-kit/md/ic_lightbulb_outline";
import { newspaperO as newsIcon } from "react-icons-kit/fa/newspaperO";
import { search } from "react-icons-kit/fa/search";
import IconCa, { iconCaName } from "../../classes/iconCa";
// import { preloadImages } from "../../classes/utills";
// import Select from "../../components/select/Select";
// import { ic_gif as gifIcon } from "react-icons-kit/md/ic_gif";
import { cog as iconSettings } from "react-icons-kit/fa/cog";
import { mask as iconPrivacy } from "react-icons-kit/entypo/mask";

class CChat extends PureComponent {
	constructor(props) {
		super(props);
		// CChat.inst = this;
		this.state = {
			showOverlay1: false,
		};
		// preloadImages(["/assets/twitter32.png"]);

		this.moveChatPaneClick = this.moveChatPaneClick.bind(this);
		this.chatLocations = {
			chat: ChatMessages,
			news: ChatMessages,
			pm: ChatMessages,
			to: ChatMessages,
			notifications: ChatMessages,
			rooms: ChatRooms,
			friends: ChatFriends,
		};
		this.headlines = {
			chat: "chat",
			news: "news",
			pm: "pm",
			to: "pm",
			notifications: "notifications",
			rooms: "rooms",
			friends: "friends",
		};
	}
	static get propTypes() {
		return {
			chatLeft: PropTypes.bool,
			chatOpen: PropTypes.bool,
			chatLocation: PropTypes.string,
			chatRoomSelected: PropTypes.object,
			chatUnreadMessagesCount: PropTypes.number.isRequired,
			newsUnreadMessagesCount: PropTypes.number.isRequired,
			notificationsUnreadMessagesCount: PropTypes.number.isRequired,
			pmUnreadMessagesCount: PropTypes.number.isRequired,
			pendingFriendsCount: PropTypes.number.isRequired,
			theme: PropTypes.string,
			onlineTotal: PropTypes.number,
			onlineRoomCurrent: PropTypes.number,
			privacyMode: PropTypes.bool,
		};
	}
	async moveChatPaneClick(e) {
		if (e) e.stopPropagation();
		await moveChatPane();
	}
	toggleChatPaneClick = async (e) => {
		if (e) e.stopPropagation();
		await chat.toggleChatPane();
	};
	setChatLocationClick = async (e, locationStr) => {
		if (e) e.stopPropagation();
		this.setState({ showOverlay1: false });
		await chat.setChatLocation(locationStr);
	};
	toggleOverlay = (v, cb) => {
		v = typeof v === "boolean" ? v : !this.state.showOverlay1;
		this.setState({ showOverlay1: v }, cb);
	};
	onEmojiSelect = async (em) => {
		const v = (await store.getState()).chatInput;
		await storeSet({ chatInput: { $set: `${v} (${em.id})` } });
	};
	info = {
		chatInput: {},
		menulink_home: {},
		//top buttons:
		btnMenuButton: { right: "btnSetPrivacy", down: "btnComChat", Enter: "menulink_home" },
		btnSetPrivacy: { right: "btnSiteSettings", left: "btnMenuButton", down: "btnComChat" },
		btnSiteSettings: { right: "btnWhoIsOnline", left: "btnSetPrivacy", down: "btnComChat" },
		btnWhoIsOnline: { right: "btnRainManual", left: "btnSiteSettings", down: "btnComChat" },
		btnRainManual: { right: "btnChatReposition", left: "btnWhoIsOnline", down: "btnComChat" },
		btnChatReposition: { right: "btnChatClose", left: "btnRainManual", down: "btnComChat" },
		btnChatClose: { left: "btnChatReposition", down: "btnComChat" },

		//com buttons:
		btnComNews: { right: "btnComChat", down: "chatInput", up: "btnMenuButton" },
		btnComChat: { right: "btnComNotifications", left: "btnComNews", down: "chatInput", up: "btnMenuButton" },
		btnComNotifications: { right: "btnComPm", left: "btnComChat", down: "chatInput", up: "btnMenuButton" },
		btnComPm: { right: "btnComFriends", left: "btnComNotifications", down: "chatInput", up: "btnMenuButton" },
		btnComFriends: { right: "btnComRooms", left: "btnComPm", down: "chatInput", up: "btnMenuButton" },
		btnComRooms: { left: "btnComFriends", down: ["btn_room_standard_0", "chatInput"], up: "btnMenuButton", Enter: "btn_room_standard_0" },
		//
		btn_room_standard_0: {},
	};
	renderComButtons = () => {
		return (
			<div className="comButtons">
				<button
					id="btnComNews"
					title={lang.translate("news")}
					onClick={(e) => this.setChatLocationClick(e, "news")}
					className={`diceBtn grey ${this.props.chatLocation === "news" ? "toggled" : ""}`}
					onKeyDown={(e) => focusNext(e, this.info)}
				>
					<div className={`mark ${this.props.newsUnreadMessagesCount > 0 ? "on" : "off"}`}>{this.props.newsUnreadMessagesCount}</div>
					<Icon icon={newsIcon} size={22} />
				</button>
				<button
					id="btnComChat"
					title={lang.translate("chat")}
					onClick={(e) => this.setChatLocationClick(e, "chat")}
					className={`diceBtn grey ${this.props.chatLocation === "chat" ? "toggled" : ""}`}
					onKeyDown={(e) => focusNext(e, this.info)}
				>
					<div className={`mark ${this.props.chatUnreadMessagesCount > 0 ? "on" : "off"}`}>{this.props.chatUnreadMessagesCount}</div>
					<Icon icon={chatIcon} size={22} />
				</button>
				<button
					id="btnComNotifications"
					title={lang.translate("notifications")}
					onClick={(e) => this.setChatLocationClick(e, "notifications")}
					className={`diceBtn grey ${this.props.chatLocation === "notifications" ? "toggled" : ""}`}
					onKeyDown={(e) => focusNext(e, this.info)}
				>
					<div className={`mark ${this.props.notificationsUnreadMessagesCount > 0 ? "on" : "off"}`}>
						{this.props.notificationsUnreadMessagesCount}
					</div>
					<Icon icon={notificationsIcon} size={22} />
				</button>
				<button
					id="btnComPm"
					title={lang.translate("pm")}
					onClick={(e) => this.setChatLocationClick(e, "pm")}
					className={`diceBtn grey ${["pm", "to"].indexOf(this.props.chatLocation) !== -1 ? "toggled" : ""}`}
					onKeyDown={(e) => focusNext(e, this.info)}
				>
					<div className={`mark ${this.props.pmUnreadMessagesCount > 0 ? "on" : "off"}`}>{this.props.pmUnreadMessagesCount}</div>
					<Icon icon={pmIcon} size={22} />
				</button>
				<button
					id="btnComFriends"
					title={lang.translate("friends")}
					onClick={(e) => this.setChatLocationClick(e, "friends")}
					className={`diceBtn grey ${this.props.chatLocation === "friends" ? "toggled" : ""}`}
					onKeyDown={(e) => focusNext(e, this.info)}
				>
					<div className={`mark ${this.props.pendingFriendsCount > 0 ? "on" : "off"}`}>{this.props.pendingFriendsCount}</div>
					<Icon icon={friendsIcon} size={22} />
				</button>
				<button
					id="btnComRooms"
					title={lang.translate("rooms")}
					onClick={(e) => this.setChatLocationClick(e, "rooms")}
					className={`diceBtn grey ${this.props.chatLocation === "rooms" ? "toggled" : ""}`}
					onKeyDown={(e) => focusNext(e, this.info)}
				>
					{/* <Icon icon={roomIcon} size={22} /> */}
					{/* <img
						width={22}
						alt={`${this.props.chatRoomSelected ? this.props.chatRoomSelected.abbrevation : "en"}`}
						title={`${this.props.chatRoomSelected ? `${this.props.chatRoomSelected.room} ${this.props.chatRoomSelected.vip ? "VIP" : ""}` : "en"}`}
						src={`/assets/flags/${this.props.chatRoomSelected ? this.props.chatRoomSelected.abbrevation : "en"}.png`}
						onError={(e) => {
							if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
						}}
					/> */}
					<ImageCached
						width={22}
						storageName="flag"
						url={`/assets/flags/${this.props.chatRoomSelected ? this.props.chatRoomSelected.abbrevation : "en"}.png`}
						alt={`${this.props.chatRoomSelected ? this.props.chatRoomSelected.abbrevation : "en"}`}
						title={`${this.props.chatRoomSelected ? `${this.props.chatRoomSelected.room} ${this.props.chatRoomSelected.vip ? "VIP" : ""}` : "en"}`}
						params={{}}
					/>
				</button>
			</div>
		);
	};
	renderLocation = () => {
		const LocationComponent = this.chatLocations[this.props.chatLocation];
		if (!LocationComponent) return <b>NOT IMPLEMENTED</b>;
		return (
			<LocationComponent
				ref={(el) => withPerms(el, ["NEWS_SEND", "TO_SEND", "CHAT_EDIT_OVERDUE_MESSAGE", "MUTE", "BAN"])}
				toggleOverlay={this.toggleOverlay.bind(this)}
			/>
		);
	};
	renderOverlay = () => {
		return (
			<div
				onClick={(e) => e.stopPropagation()}
				onTouchStart={(e) => e.stopPropagation()}
				className={`chatOverlay1 ${!this.state.showOverlay1 ? "off" : "on"}`}
			>
				<header>
					<button onClick={(e) => [e.stopPropagation(), this.toggleOverlay(false)]} className="btnIcon small btnCloseOverlay">
						<Icon icon={close} />
					</button>
				</header>
				{/* OVERLAY BODY */}
				{this.state.showOverlay1 && <ChatEmoji />}
				<footer>
					<button className="diceBtn grey btnEmoji">
						{/* <Emoji emoji="sunglasses" backgroundImageFn={() => emojiPng} size={24} /> */}
						{chat.getEmoji("sunglasses")}
					</button>
					{/* <button className="diceBtn grey">
						<Icon icon={gifIcon} size={48} />
					</button> */}
					{/* <button className="diceBtn grey">test</button> */}
				</footer>
			</div>
		);
	};
	renderHeadline = () => {
		return (
			<div className="headlineWrapper">
				<h3 className="headline">{lang.translate(this.headlines[this.props.chatLocation])}</h3>
				<div className="onlineRoomCurrentWrapper">
					<div className="circle"></div>
					<div className="onlineRoomCurrent">
						<b>{this.props.onlineRoomCurrent}</b>
					</div>
				</div>
			</div>
		);
	};
	onSettingsClick = () => {
		storeSet({
			modal: {
				$set: { name: "sitesettings", canClose: true, props: { selected: "chat" } },
			},
		});
	};
	render() {
		return (
			<div className={`chatPane ${this.props.chatLeft ? "left" : "right"} ${this.props.chatOpen ? "on" : "off"}`}>
				<div className="content">
					<div className="topButtons">
						{this.props.chatLeft && <MenuButton focusNext={(e) => focusNext(e, this.info)} />}
						<button
							id="btnSetPrivacy"
							className={`btnIcon ${this.props.privacyMode ? "success" : ""} small btnSetPrivacy`}
							onClick={(e) => [e.stopPropagation(), setPrivacy(!this.props.privacyMode)]}
							onKeyDown={(e) => focusNext(e, this.info)}
							title={lang.translate("privacy_mode") + ` ${this.props.privacyMode ? "ON" : "OFF"}`}
						>
							<Icon icon={iconPrivacy} />
						</button>
						<button
							id="btnSiteSettings"
							className="btnIcon small alert btnSiteSettings"
							onClick={(e) => [e.stopPropagation(), this.onSettingsClick()]}
							onKeyDown={(e) => focusNext(e, this.info)}
						>
							<Icon icon={iconSettings} />
						</button>
						<button
							id="btnWhoIsOnline"
							className="btnIcon small warn btnWhoIsOnline"
							onClick={(e) => [e.stopPropagation(), chat.toggleChatPane(true), go("who_is_online")]}
							onKeyDown={(e) => focusNext(e, this.info)}
						>
							<Icon icon={search} />
						</button>
						<button
							id="btnRainManual"
							className="btnIcon small btnRainManual"
							onClick={(e) => [e.stopPropagation(), chat.toggleChatPane(true), go("billing/rain")]}
							onKeyDown={(e) => focusNext(e, this.info)}
						>
							<IconCa name={iconCaName.rain} />
						</button>
						{/* <button
							id="btnVipRequest"
							className="btnIcon small btnVipRequest"
							onClick={(e) => [e.stopPropagation(), chat.toggleChatPane(true), go("vip_request")]}
							onKeyDown={(e) => focusNext(e, this.info)}
						>
							<b style={{ fontSize: "10px" }}>VIP</b>
						</button> */}
						<div className="space"></div>
						<div className="onlineTotalWrapper">
							<div className="circle"></div>
							<div className="onlineTotal">
								<b>{this.props.onlineTotal}</b>
							</div>
						</div>
						<button
							id="btnChatReposition"
							className="btnIcon small btnChatReposition"
							onClick={(e) => [e.stopPropagation(), this.moveChatPaneClick(e)]}
							onKeyDown={(e) => focusNext(e, this.info)}
						>
							<Icon icon={this.props.chatLeft ? caretRight : caretLeft} />
						</button>
						<button
							id="btnChatClose"
							className="btnIcon small btnChatClose"
							onClick={this.toggleChatPaneClick}
							onKeyDown={(e) => focusNext(e, this.info)}
						>
							<Icon icon={close} />
						</button>
					</div>
					{this.renderComButtons()}
					{this.renderHeadline()}
					{this.renderLocation()}
					{this.renderOverlay()}
				</div>
			</div>
		);
	}
}
const a = (store) => {
	let onlineRoomCurrent;
	try {
		onlineRoomCurrent = store.chatRoomSelected
			? store.online.rooms[(store.chatRoomSelected.vip ? 1 : 0) + "-" + store.chatRoomSelected.abbrevation]
			: 0;
	} catch {}
	return {
		chatLeft: store.chat && store.chat.left,
		chatOpen: store.chat && store.chat.open,
		chatRoomSelected: store.chatRoomSelected,
		chatLocation: store.chat ? store.chat.location : "chat",
		//--
		chatUnreadMessagesCount: store.chatUnreadMessagesCount,
		newsUnreadMessagesCount: store.newsUnreadMessagesCount,
		notificationsUnreadMessagesCount: store.notificationsUnreadMessagesCount,
		pmUnreadMessagesCount: store.pmUnreadMessagesCount,
		pendingFriendsCount: store.friends.pendingCount,
		//--
		// onlineTotal: (store.online.ONLINE * 1 || 0) + (store.online.AWAY * 1 || 0) + (store.online.BUSY * 1 || 0) + (store.online.OFFLINE * 1 || 0),
		onlineTotal: Object.keys(store.online.rooms || {}).reduce((t, cur) => t + store.online.rooms[cur], 0),
		onlineRoomCurrent,
		//--
		lang: store.lang.key,
		theme: store.theme.key,
		privacyMode: store.playerConfig?.siteSettings?.custom?.privacy_mode ?? false,
	};
};

export default connect(a, null, null, { forwardRef: true })(CChat);
