import React, { Fragment } from "react";
// eslint-disable-next-line no-unused-vars
import ReactDOM from "react-dom";
//redux:
import { store, storeSet } from "../index";
//classes:
import Axios from "./axios";
import { onResult, getDayTimeFromTimestamp } from "./utills";
import { twitter64 } from "../containers/chat/chatEmoji/data/twitter64";
import lang from "./lang";
import { getPhoto, simpleCan, muteShow } from "./player";
import { delay, cloneJsonMode, minutesPassed, isMobile, showModal, updateOnlineMarks, copy } from "./utills";
import { go } from "./routesLazy";
import { showRollInfo } from "./games/dice";
import { friend } from "./friend";
import { isModAdmin } from "./player";
import { firstBy } from "thenby";
import { sound } from "./sound";
import { getRoomStr } from "./room";
import { GA_EVENTS, gaEvent } from "./ga";
import Toast from "./toast";
import ImageCached from "./cache";
//components:
import Lottie from "../components/lottie/Lottie";

//icons:
import { Icon } from "react-icons-kit";
import { thumbsOUp } from "react-icons-kit/fa/thumbsOUp";
import { close } from "react-icons-kit/fa/close";
import SImpleDropDown from "../components/simpleDropDown/SimpleDropDown";
import { bars } from "react-icons-kit/fa/bars";
import { ic_mode_edit as editIcon } from "react-icons-kit/md/ic_mode_edit";
import { ic_delete_sweep as deleteIcon } from "react-icons-kit/md/ic_delete_sweep";
import { userPlus as addfriendIcon } from "react-icons-kit/fa/userPlus";
import { userTimes as unfriendIcon } from "react-icons-kit/fa/userTimes";
import { ban as banIcon } from "react-icons-kit/fa/ban";
import { ic_volume_off as muteIcon } from "react-icons-kit/md/ic_volume_off";
import { ic_file_upload as sendTipIcon } from "react-icons-kit/md/ic_file_upload";
import { ic_textsms as pmIcon } from "react-icons-kit/md/ic_textsms";
import { ic_notifications_off as ignoreIcon } from "react-icons-kit/md/ic_notifications_off";
// import { ic_notifications_active as unignoreIcon } from "react-icons-kit/md/ic_notifications_active";
// import { ic_inbox as recIcon } from "react-icons-kit/md/ic_inbox";
// import { ic_mail as recIcon } from "react-icons-kit/md/ic_mail";
// import { ic_send as sentIcon } from "react-icons-kit/md/ic_send";
import { ic_chevron_right as iconRight } from "react-icons-kit/md/ic_chevron_right";
import { tag as iconTag } from "react-icons-kit/fa/tag";
import { eye as iconRead } from "react-icons-kit/fa/eye";
import { ic_person_add as iconFriendAdd } from "react-icons-kit/md/ic_person_add";
import { ic_people_outline as friendsIcon } from "react-icons-kit/md/ic_people_outline";
import IconCa, { botCaName, iconCaByAction } from "./iconCa";
import { ic_content_copy as iconCopy } from "react-icons-kit/md/ic_content_copy";
import { emit } from "./socket";

const state = async () => await store.getState();

let moveChatPaneRunning = false;
export const moveChatPane = async () => {
	if (moveChatPaneRunning) return;
	moveChatPaneRunning = true;
	setTimeout(async () => {
		const v = !(await state()).chat.left;
		await storeSet({ chat: { left: { $set: v } } }, "chat");
		moveChatPaneRunning = false;
	}, 10);
};
let instance = null;
class Chat {
	constructor() {
		if (instance) return instance;
		instance = this;
		Chat.inst = instance;
		this.time = new Date();
		this.emojiListRef = null;
		this.messagesListRef = null;
		this.location = null;
		this.dataO = {
			messages: [],
			unreadMessages: [],
			prevMessage: null,
			mD: {},
		};
		this.chat = cloneJsonMode(this.dataO);
		this.news = cloneJsonMode(this.dataO);
		this.notifications = cloneJsonMode(this.dataO);
		this.pm = cloneJsonMode(this.dataO);
		this.to = cloneJsonMode(this.dataO); //individual pms
		//---
		this.CHAT_EDIT_MESSAGE = false;
		this.CHAT_EDIT_OVERDUE_MESSAGE = false;
		this.BAN = false;
		this.MUTE = false;
	}
	markRead = async (prevLocation, location, serverTime) => {
		const t = this.getLastReadTime(prevLocation);
		await Axios.post("/api/chat/read", { prevLocation, t, location, serverTime });
	};
	setChatLocation = async (locationStr) => {
		this.editCancel();
		//mark location as read only if we have unread messages:
		const state = await store.getState();
		this.state = state;
		this.me = state.player.uid;

		const prevReadMode = !!state[`${state.chat.location}ReadMode`];
		//prevlocation to mark read if not in reading mode and chat is open
		const prevLocation = prevReadMode === false && state.chat.open ? state.chat.location : undefined;
		//if (state[`${locationStr}UnreadMessagesCount`])
		if (this.me !== -1) await this.markRead(prevLocation, locationStr, state.serverTime);

		await storeSet({ chat: { location: { $set: locationStr } } }, "chat");
		if (state.chatWith !== null) await storeSet({ chatWith: { $set: null } });
		this.location = ["chat", "news", "notifications", "pm", "to"].indexOf(locationStr) !== -1 ? locationStr : null;
		await this.onReadingModeOff();
		updateOnlineMarks(state);
	};

	toggleChatPane = async (onlyOnMobile = false) => {
		if (Chat.toggleChatPaneRunning && Date.now() - Chat.toggleChatPaneRunning < 10) return;
		Chat.toggleChatPaneRunning = Date.now();
		setTimeout(async () => {
			if (onlyOnMobile && !isMobile()) {
				return;
			} else {
				const v = !(await state()).chat.open;
				await storeSet({ chat: { open: { $set: v } } }, "chat");
				if (v) {
					await this.onReadingModeOff();
					const chatInput = document.getElementById("chatInput");
					if (chatInput) chatInput.focus();
				}
			}
		}, 10);
	};
	closeChatPane = async (onlyOnMobile = false) => {
		if (onlyOnMobile && !isMobile()) return;
		const v = !(await state()).chat.open;
		await storeSet({ chat: { open: { $set: false } } }, "chat");
		if (v) await this.onReadingModeOff();
	};
	send = async (str, location, to) => {
		if (typeof document === "undefined") return;
		const o = { str, location, to };
		if (this.edit) o._id = this.edit._id;
		// const result = await Axios.post("/api/chat/send", o);
		emit("chat_send", o, (result) => {
			onResult(result, "unexpected_error");
		});
		gaEvent(GA_EVENTS.chat, {
			location,
			to: to || undefined,
		});
		// return await onResult(result, "unexpected_error");
	};
	delete = async (_id) => {
		if (typeof document === "undefined") return;
		const result = await Axios.post("/api/chat/delete", { _id });
		return await onResult(result, "unexpected_error");
	};
	chatRoomSetSelected = async (vip, abbrevation, set = true) => {
		const chatRooms = (await store.getState()).chatRooms;
		const chatRoom = { ...chatRooms[vip ? "vip" : "standard"][abbrevation] };
		const room = getRoomStr(chatRoom.vip, chatRoom.abbrevation);
		set && (await storeSet({ chatRoomSelected: { $set: chatRoom }, player: { room: { $set: room } } }));
		return chatRoom;
	};
	chatRoomChange = async (vip, abbrevation) => {
		if (typeof document === "undefined") return;
		const result = await Axios.post("/api/chatRoom/goToRoom", { vip, abbrevation });
		return await onResult(result, "unexpected_error", async () => {
			gaEvent(GA_EVENTS.room_change, {
				vip,
				abbrevation,
			});
			return await this.chatRoomSetSelected(vip, abbrevation);
		});
	};
	getStyle = (x, y) => {
		const cols = 57;
		const rows = 57;
		const multiplyX = 100 / (cols - 1);
		const multiplyY = 100 / (rows - 1);
		if (!Chat.inst.state) Chat.inst.state = store.getState();
		return {
			backgroundPosition: `${multiplyX * x}% ${multiplyY * y}%`,
			backgroundSize: `${100 * cols}% ${100 * rows}%`,
			backgroundImage: Chat.inst.state ? `url("${Chat.inst.state.twitterLink}")` : undefined,
		};
	};
	onEmojiSelect = async (id) => {
		await this.chatInputAppend(`(${id}) `);
	};
	getEmojiData = (id) => {
		return twitter64.emojis[id];
	};
	setEmojiListRef = (el) => {
		this.emojiListRef = el;
	};
	getEmojiListRef = () => {
		return this.emojiListRef;
	};
	setMessagesListRef = (el) => {
		this.messagesListRef = el;
	};
	getMessagesListRef = () => {
		return this.messagesListRef;
	};
	getEmoji = (id, btn = false) => {
		const data = this.getEmojiData(id);
		if (!data) return null;
		if (!data.k) {
			const st = {};
			st.backgroundImage = `url("/assets/gif/${id}.gif")`;
			st.backgroundSize = "contain";
			return btn ? (
				<button key={id} onClick={(e) => [e.stopPropagation(), this.onEmojiSelect(id)]} className="em ff" style={st}></button>
			) : (
				<div className="em" style={st}></div>
			);
		}
		const st = this.getStyle(data.k[0], data.k[1]);
		st.backgroundImage = Chat.inst.state ? `url("${Chat.inst.state.twitterLink}")` : undefined;
		return btn ? (
			<button key={id} onClick={(e) => [e.stopPropagation(), this.onEmojiSelect(id)]} className="em ff" style={st}></button>
		) : (
			<div className="em" style={st}></div>
		);
	};
	getEmojiHeaderButtons = () => {
		const btns = [];
		for (let cat of twitter64.categories) {
			btns.push(
				<button
					key={`btn-${cat.id}`}
					className="diceBtn grey"
					onClick={(e) => [e.stopPropagation(), this.getEmojiListRef().scrollToElementId(`id_${cat.id}`)]}
				>
					{this.getEmoji(cat.em)}
				</button>
			);
		}
		return btns;
	};
	getEmojis = () => {
		const arr = [];
		for (let cat of twitter64.categories) {
			const _emojis = [];
			for (let emoji of cat.emojis) {
				_emojis.push(this.getEmoji(emoji, true));
			}
			arr.push({
				id: cat.id,
				c: (
					<div id={`id_${cat.id}`} className="cat">
						<label>{lang.translate(cat.name)}</label>
						<div key={cat.id} className="emojis">
							{_emojis}
						</div>
					</div>
				),
			});
		}
		return arr;
	};
	onTaggedClick = async (un) => {
		this.chatInputAppend(`@${un} `);
		if (this.location !== "chat") await this.setChatLocation("chat");
		const chatInput = document.getElementById("chatInput");
		if (chatInput) chatInput.select();
	};
	onPmClick = async (senderObj) => {
		await this.setChatLocation("to");
		await storeSet({ chatWith: { $set: senderObj } });
		const result = await Axios.post("/api/chat/getTo", { to: senderObj.uid });
		this.sender = senderObj.uid;
		// eslint-disable-next-line no-unused-vars
		await onResult(result, "unexpected_error", async ({ messages, read }) => {
			await storeSet({
				pmUnreadMessagesCount: {
					$apply: (x) => {
						x = x - read;
						if (x < 0) x = 0;
						return x;
					},
				},
			});
			await this.gotMessages({ to: messages });
		});
	};
	/* receiver read messages */
	// eslint-disable-next-line no-unused-vars
	onPmRead = async ({ sender, tim }) => {
		const nodes = document.getElementsByClassName(`read off read-${sender}`);
		for (let node of nodes) {
			setTimeout(() => {
				node.className = `read on read-${sender}`;
				node.setAttribute("title", lang.translate(`was_read_on`, getDayTimeFromTimestamp(tim, true)));
			});
		}
	};
	chatInputAppend = async (str) => {
		const prevStr = (await store.getState()).chatInput;
		await storeSet({ chatInput: { $set: `${prevStr}${str}` } });
	};
	chatInputSet = async (str) => {
		await storeSet({ chatInput: { $set: str } });
	};
	toggleLikeButtons = (_id, show = true) => {
		this.toggleWhoLiked(_id, "off");
		document.getElementById(`overlay_${_id}`).className = `overlay ${show ? "on" : "off"}`;
	};
	messageLike = async (_id, i) => {
		const result = await Axios.post("/api/chat/like", { _id, i });
		await onResult(result, "unexpected_error");
		this.toggleLikeButtons(_id, false);
	};
	emName(i) {
		switch (i * 1) {
			case 0:
				return "thumbs_up";
			case 1:
				return "growing_heart";
			case 2:
				return "grinning_face_with_smiling_eyes";
			case 3:
				return "hushed_face";
			case 4:
				return "loudly_crying_face";
			case 5:
				return "pouting_face";
			default:
				return "thumbs_up";
		}
	}
	toggleWhoLiked = (_id, cl) => {
		const el = document.getElementById(`${_id}_players`);
		if (!el) return;
		if (cl) {
			el.className = `players ${cl}`;
			return;
		}
		el.className = `players ${el.className.indexOf("on") >= 0 ? "off" : "on"}`;
	};
	renderLiked = (_id, likes) => {
		if (!likes) return null;
		const arr = [];
		const players = [];
		for (let i in likes) {
			if (i === "all" || !Array.isArray(likes[i]) || likes[i].length === 0) continue;
			arr.push(<div key={`${_id}_liked_${i}`}>{this.getEmoji(this.emName(i))}</div>);
			players.push([]);
			for (let uid of likes[i]) {
				const p = likes.all[`${uid}`];
				players[players.length - 1].push(
					<div key={`lk_${uid}`} className="p">
						<img
							alt=""
							src={getPhoto(uid, p.img, 16, 16)}
							onError={(e) => {
								if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
							}}
						/>
						<b>{p.un}</b>
						<div className="f1"></div>
						{this.getEmoji(this.emName(p.i))}
					</div>
				);
			}
		}
		return (
			<>
				<div
					className={`players off`}
					id={`${_id}_players`}
					onMouseLeave={(e) => [e.stopPropagation(), this.toggleWhoLiked(_id, "off")]}
					onClick={(e) => [e.stopPropagation(), this.toggleWhoLiked(_id, "off")]}
				>
					{players}
				</div>
				<button
					className="btnShowPlayers"
					onMouseOver={(e) => [e.stopPropagation(), this.toggleWhoLiked(_id, "on")]}
					// onMouseLeave={(e) => [e.stopPropagation(), this.toggleWhoLiked(_id, "off")]}
					onClick={(e) => [e.stopPropagation(), this.toggleWhoLiked(_id)]}
				>
					{arr}
					<b className="counter">{Object.keys(likes.all).length}</b>
				</button>
			</>
		);
	};
	onMessageBodyClick = ({ m, by }) => {
		if (m.location === "pm") {
			this.onPmClick(by);
		}
	};
	showYoutube = (id) => {
		console.log("showYoutube: ", id);
	};
	renderMessage = (m, mPrev = null) => {
		function renderBody(b) {
			const arr = [];
			let bi = 0;
			for (let chunk of b) {
				bi++;
				switch (chunk.t) {
					case "s":
						chunk = <Fragment key={`chunk_s_${m._id}_${bi}`}>{chunk.v}</Fragment>;
						break;
					case "bold":
						chunk = <strong key={`chunk_strong_${m._id}_${bi}`}>{chunk.v}</strong>;
						break;
					case "red":
						chunk = (
							<b key={`chunk_red_${m._id}_${bi}`} className="red">
								{chunk.v}
							</b>
						);
						break;
					case "green":
						chunk = (
							<b key={`chunk_green_${m._id}_${bi}`} className="green">
								{chunk.v}
							</b>
						);
						break;
					case "gold":
						chunk = (
							<b key={`chunk_gold_${m._id}_${bi}`} className="gold">
								{chunk.v}
							</b>
						);
						break;
					case "cyan":
						chunk = (
							<b key={`chunk_cyan_${m._id}_${bi}`} className="cyan">
								{chunk.v}
							</b>
						);
						break;
					case "small":
						chunk = (
							<b key={`chunk_small_${m._id}_${bi}`} className="small">
								{chunk.v}
							</b>
						);
						break;
					case "lottie": {
						let content = null;
						if (isMobile()) {
							chunk = null;
							break;
						}
						if (chunk.v === "slot_numbers") content = <h3>{lang.translate("finished")}</h3>;
						chunk = (
							<div className="anim">
								<Lottie
									src={`/assets/ae/${chunk.v}.json`}
									renderer="canvas"
									style={{
										height: "60px",
										pointerEvents: "none",
									}}
									contentAfterSeconds={content}
								/>
							</div>
						);
						break;
					}
					case "tr":
						chunk = <Fragment key={`chunk_tr_${m._id}_${bi}`}>{lang.translate(chunk.v)}</Fragment>;
						break;
					case "br":
						chunk = <div key={`chunk_br_${m._id}_${bi}`} className="br"></div>;
						break;
					case "pa":
						try {
							chunk.v = JSON.parse(atob(chunk.v));
							chunk = (
								<button
									key={`chunk_pa_${m._id}_${bi}`}
									data-url={chunk.v.url}
									onClick={(e) => [e.stopPropagation(), showModal("pandoraroll", true, { url: chunk.props["data-url"] })]}
									className={`btnDefault ${chunk.v.win_amount > 0 ? "success" : "alert"} btnBet`}
								>
									{/* <img
										src={`/assets/icons/${chunk.v.currency}@2x.png`}
										alt={chunk.v.currency}
										title={chunk.v.currency}
										onError={(e) => {
											if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
										}}
									/> */}
									<ImageCached
										storageName="coin"
										url={`/assets/icons/${chunk.v.currency}@2x.png`}
										alt={chunk.v.currency}
										title={chunk.v.currency}
										params={{}}
									/>
									<div>
										<label>{chunk.v.game_uuid}</label>
										<b>{chunk.v.win_amount.toFixed(8).substring(0, 9)}</b>
									</div>
								</button>
							);
						} catch {
							chunk = [];
						}
						break;
					case "u": {
						chunk = (
							<div key={`chunk_u_${m._id}_${bi}`} className={`tag${chunk.uid === m.me ? " my" : ""}`} data-un={chunk.un}>
								<button className="btnTag" onClick={(e) => [e.stopPropagation(), chat.toggleChatPane(true), go(`profile/${chunk.props["data-un"]}`)]}>
									<img
										src={getPhoto(chunk.uid, chunk.img, 24, 24)}
										alt=""
										onError={(e) => {
											if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
										}}
									/>
								</button>
								<button className="btnA" onClick={(e) => [e.stopPropagation, Chat.inst.onTaggedClick(chunk.props["data-un"])]}>
									{chunk.un}
								</button>
							</div>
						);
						break;
					}
					case "em":
						chunk = <Fragment key={`chunk_em_${m._id}_${bi}`}> {Chat.inst.getEmoji(chunk.v)}</Fragment>;
						break;
					case "url":
						chunk = (
							<a key={`chunk_url_${m._id}_${bi}`} className="url" href={chunk.url} target="_blank" rel="noopener noreferrer">
								{chunk.url}
							</a>
						);
						break;
					case "b":
						chunk = (
							<button
								key={`chunk_b_${m._id}_${bi}`}
								data-id={chunk.id}
								onClick={(e) => [e.stopPropagation(), showRollInfo(chunk.props["data-id"])]}
								className={`btnDefault ${chunk.win ? "success" : "alert"} btnBet`}
							>
								{/* <img
									src={`/assets/icons/${chunk.short}@2x.png`}
									alt={chunk.short}
									title={chunk.short}
									onError={(e) => {
										if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
									}}
								/> */}
								<ImageCached storageName="coin" url={`/assets/icons/${chunk.short}@2x.png`} alt={chunk.short} title={chunk.short} params={{}} />
								<div>
									<label>{chunk.outcomeStr}</label>
									<b>{chunk.income.toFixed(8).substring(0, 9)}</b>
								</div>
							</button>
						);
						break;
					case "slot":
						chunk = (
							<button
								key={`chunk_slot_${m._id}_${bi}`}
								data-id={chunk.id}
								data-type={chunk.provider === "flappybet" ? "flappybet" : ""}
								onClick={(e) => [
									e.stopPropagation(),
									showModal("slottransaction", true, {
										_id: chunk.props["data-id"],
										type: chunk.props["data-type"],
									}),
								]}
								className={`btnDefault ${chunk.win ? "success" : ""} ${chunk.lose ? "alert" : ""} ${
									!chunk.win && !chunk.lose ? "warn" : ""
								} btnBet btnSlotBet`}
							>
								<ImageCached storageName="coin" url={`/assets/icons/${chunk.short}@2x.png`} alt={chunk.short} title={chunk.short} params={{}} />
								<div>
									<b className="pr">{chunk.provider}</b>
									<b className="tp">{chunk.tp}</b>
									<b className="amt">{chunk.amt}</b>
								</div>
							</button>
						);
						break;
					case "coin":
						chunk = (
							<Fragment key={`chunk_coin_${m._id}_${bi}`}>
								<ImageCached
									key={`chunk_coin_${m._id}_${bi}`}
									width={24}
									height={24}
									storageName="coin"
									url={`/assets/icons/${chunk.v}@2x.png`}
									alt={chunk.v}
									title={chunk.v}
									params={{}}
								/>
							</Fragment>
							// <img
							// 	src={`/assets/icons/${chunk.v}@2x.png`}
							// 	width="24"
							// 	height="24"
							// 	alt={chunk.v}
							// 	title={chunk.v}
							// 	onError={(e) => {
							// 		if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
							// 	}}
							// />
						);
						break;
					case "y":
						chunk = (
							<button
								key={`chunk_y_${m._id}_${bi}`}
								data-id={chunk.id}
								onClick={(e) => [e.stopPropagation(), this.showYoutube(chunk.props["data-id"])]}
								className={`btnDefault btnYTB`}
							>
								<img src={`/assets/icons/youtube.png`} alt="YTB" title="YouTube" />
							</button>
						);
						break;
					case "cfg":
						if (chunk.el === "button" && chunk.url && chunk.title) {
							chunk = (
								<button
									key={`chunk_cfg_${m._id}_${bi}`}
									data-url={chunk?.url ?? ""}
									className="diceBtn yellow"
									style={{ padding: "2px 5px", borderRadius: "5px", flexDirection: "row", gap: "5px", width: "100%", margin: "5px 0" }}
									onClick={(e) => [e.stopPropagation(), go(chunk.props["data-url"])]}
								>
									{iconCaByAction[chunk?.title] ?? null}
									<b>{lang.translate(chunk?.title)}</b>
								</button>
							);
						} else {
							chunk = "";
						}
						break;
					case "JACKPOT":
						chunk = (
							<div key={`chunk_JACKPOT_${m._id}_${bi}`} className="jackpot">
								<b>{`${chunk.amt}`.ToBalanceFixed(4)}</b>
								{/* <img
									src={`/assets/icons/${chunk.short}@2x.png`}
									alt={chunk.short}
									title={chunk.short}
									onError={(e) => {
										if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
									}}
								/> */}
								<ImageCached storageName="coin" url={`/assets/icons/${chunk.short}@2x.png`} alt={chunk.short} title={chunk.short} params={{}} />
							</div>
						);
						break;
					default:
						chunk = "";
						break;
				}
				arr.push(chunk);
			}
			return arr;
		}
		const showDropContent = async (_simpleDropDown, m, mPrev, sent) => {
			const state = await store.getState();
			const me = state.player.uid;
			const isFriend = await friend.isFriend({ uid: m.by.uid, state });
			const isPendingFriend = await friend.isPendingFriend({ uid: m.by.uid, state });
			const isIgnored = await friend.isIgnored({ state, uid: m.by.uid });
			const IAmModAdmin = await isModAdmin({ state, uid: me });
			const _isModAdmin = await isModAdmin({ state, uid: m.by.uid });
			const canEdit = (m.by.uid === m.me || this.CHAT_EDIT_MESSAGE) && (this.CHAT_EDIT_OVERDUE_MESSAGE || minutesPassed(state.serverTime, m.t) <= 3);
			const canAddAsFriend = sent !== true && !isFriend && !isPendingFriend && !isIgnored;
			const canRemoveFromFriends = sent !== true && (isFriend || isPendingFriend) && !isIgnored;
			const canPm = sent !== true && ((IAmModAdmin && !isIgnored) || (await isModAdmin({ state, uid: m.by.uid })) || isFriend);
			const canIgnore = sent !== true && !isIgnored && !isFriend && !_isModAdmin;
			const canTip = sent !== true && !isIgnored; //&& (isFriend || (IAmModAdmin && !isIgnored));
			const canMute = sent !== true && (!isFriend || IAmModAdmin) && this.MUTE;
			const canBan = sent !== true && (!isFriend || IAmModAdmin) && this.BAN;
			// if (!(canEdit || canAddAsFriend || canRemoveFromFriends || canPm || canTip || canMute || canBan)) {
			// 	return <div className="buttons noActions">{lang.translate("no_available_actions")}</div>;
			// }
			return (
				<>
					<div className="buttons">
						<button
							onClick={(e) => [
								e.stopPropagation(),
								_simpleDropDown.toggle(false),
								copy(this.messageToStr(m), Toast.success(lang.translate("copied"))),
							]}
							className="btnDefault"
						>
							<Icon size={18} icon={iconCopy} />
							<b>{lang.translate("copy")}</b>
						</button>
						<button
							disabled={!canEdit}
							onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), this.editMessage(m, mPrev)]}
							className="btnDefault warn"
						>
							<Icon size={18} icon={editIcon} />
							<b>{lang.translate("edit_message")}</b>
						</button>
						<button
							disabled={!canAddAsFriend}
							onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), friend.requestSend({ uid: m.by.uid })]}
							className="btnDefault"
						>
							<Icon size={18} icon={addfriendIcon} />
							<b>{lang.translate("add_to_friends")}</b>
						</button>
						<button
							disabled={!canPm}
							className="btnDefault"
							onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), this.onPmClick(m.by)]}
						>
							<Icon size={18} icon={pmIcon} />
							<b>{lang.translate("send_pm")}</b>
						</button>
						<button
							disabled={!canTip}
							onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), chat.toggleChatPane(true), go(`billing/tip/${m.by.un}`)]}
							className="btnDefault"
						>
							<Icon size={18} icon={sendTipIcon} />
							<b>{lang.translate("send_tip")}</b>
						</button>
						<button
							disabled={!canTip}
							onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), chat.toggleChatPane(true), go(`billing/fsbuy/${m.by.un}`)]}
							className="btnDefault"
						>
							<IconCa name={"slot"} />
							<b>{lang.translate("send_freespins")}</b>
						</button>
						<button
							disabled={!canIgnore}
							className="btnDefault"
							onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), friend.ignore({ uid: m.by.uid, un: m.by.un })]}
						>
							<Icon size={18} icon={ignoreIcon} />
							<b>{lang.translate("ignore")}</b>
						</button>
						<button
							disabled={!canRemoveFromFriends}
							onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), friend.reject({ uid: m.by.uid, un: m.by.un })]}
							className="btnDefault alert"
						>
							<Icon size={18} icon={unfriendIcon} />
							<b>{lang.translate("remove_from_friends")}</b>
						</button>
						<button
							disabled={!canEdit}
							className="btnDefault alert"
							onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), this.delete(m._id)]}
						>
							<Icon size={18} icon={deleteIcon} />
							<b>{lang.translate("delete_message")}</b>
						</button>
						{canMute && (
							<button
								onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), muteShow({ userName: m.by.un })]}
								className="btnDefault alert"
							>
								<Icon size={18} icon={muteIcon} />
								<b>{lang.translate("mute")}</b>
							</button>
						)}
						{canBan && (
							<button
								onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), muteShow({ userName: m.by.un })]}
								className="btnDefault alert"
							>
								<Icon size={18} icon={banIcon} />
								<b>{lang.translate("ban")}</b>
							</button>
						)}
					</div>
				</>
			);
		};
		const bySameUid = mPrev && mPrev.by.uid === m.by.uid && m.by.uid >= 0;
		const imgSize = m.location === "notifications" ? 32 : 48;
		let by = m.by;
		let sideLeft = null;
		let sideRight = null;
		let sent = m.by.uid === m.me;
		let sentCl = sent ? "s" : "r";
		m.icon = null;
		if (["pm"].indexOf(m.location) !== -1) {
			by = sent ? m.to : m.by;
			const unreadCount = m.countGot - m.countGotRead;
			sideRight = (
				<div className="sideRight">
					<div className="unreadCount">
						{unreadCount > 0 && (
							<>
								<b className="unread">{unreadCount}</b>
								<b className="sep">/</b>
							</>
						)}
						<b className="count">{m.countGot}</b>
					</div>
					<Icon icon={iconRight} size={32} />
				</div>
			);
			m.title = `${sent ? lang.translate("you_wrote") : lang.translate(`you_got`)}`;
		} else if (m.location === "notifications") {
			if (m.title === "you_were_tagged") {
				m.icon = <Icon icon={iconTag} size={18} />;
			} else if (m.title === "friend") {
				m.icon = <Icon icon={iconFriendAdd} size={18} />;
			}
		}
		const c = (
			<div
				onClick={(e) => [e.stopPropagation(), this.onMessageBodyClick({ m, by })]}
				className={`m ${bySameUid ? "_same" : "new"} ${sentCl} ${m.location}Location`}
			>
				{sideLeft}
				<div className={`content ${m.by.uid < 0 ? "neonbackground" : ""}`}>
					<div id={`overlay_${m._id}`} className="overlay off">
						<button onClick={(e) => [e.stopPropagation(), Chat.inst.toggleLikeButtons(m._id, false)]} className="btnIcon small btnClose">
							<Icon icon={close} />
						</button>
						{[0, 1, 2, 3, 4, 5].map((i) => {
							return (
								<button key={`${m._id}_em_${i}`} className="btnIcon btnEm" onClick={(e) => [e.stopPropagation(), Chat.inst.messageLike(m._id, i)]}>
									{this.getEmoji(this.emName(i))}
								</button>
							);
						})}
					</div>
					<header className={`${m.by.uid < 0 ? `bot ${m.by.un.toLowerCase()}Bot` : ""}`}>
						<div className="au">
							{["notifications", "pm"].indexOf(m.location) === -1 && (
								<>
									{m.by.uid >= 0 && (
										<SImpleDropDown
											className="actions"
											showArrow={false}
											down={false}
											auto={[0, 80]}
											header={<Icon icon={bars} size={18} />}
											beforeShow={(inst) => showDropContent(inst, m, mPrev, sent)}
											modalMode={true}
										/>
									)}
								</>
							)}
							<div className="un">
								{m.by.uid >= 0 ? (
									<button
										className={`btnDefault ${m.location}Location`}
										onClick={(e) => [e.stopPropagation(), chat.toggleChatPane(true), go(`profile/${by.un}`)]}
									>
										<img
											src={getPhoto(by.uid, by.img, imgSize, imgSize)}
											alt=""
											title={lang.translate("avatar")}
											onError={(e) => {
												if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
											}}
										/>
										<div data-un={m.by.un} data-status={3} className={`onlineMark online_${m.by.un} col_offline`}></div>
									</button>
								) : (
									<b>
										<IconCa name={botCaName[by.un]} />
									</b>
								)}
								<div className="h">
									<div className="hh">
										{friend.isFriendSync(this.state, by.uid) ? (
											<div className="friendsIcon">
												<Icon icon={friendsIcon} />
											</div>
										) : null}
										{by.uid >= 0 ? (
											<button className="btnA" onClick={(e) => [e.stopPropagation(), this.onTaggedClick(by.un)]}>
												<label className="un">{by.un}</label>
											</button>
										) : (
											<label className="un">{by.un.ToWaveText()}</label>
										)}
									</div>
									{["mod", "admin"].includes(by.status) || by.vip ? (
										<div className={`st ${by.status} ${by.vip ? "vip" : ""}`}>{`${by.vip ? "VIP, " : ""}${by.status.Capitalize()}`}</div>
									) : (
										m.by.rank && <div className={`st`}>{m.by.rank?.Capitalize()}</div>
									)}
								</div>
							</div>
						</div>
						<div className="ll">
							<div id={`${m._id}_likeStats`} className="likeStats">
								{this.renderLiked(m._id, m.likes)}
							</div>
							{m.by.level && (
								<div className="rank" title={m.by.rank.Capitalize()}>
									<div className="rankInfo">
										{/* <b>{m.by.rank.Capitalize()}</b> */}
										<b>{m.by.level}</b>
									</div>
									{/* <img
										width={28}
										height={28}
										alt=""
										title={m.by.rank.Capitalize()}
										src={`/assets/levels/28/${m.by.level}.png`}
										onError={(e) => {
											if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
										}}
									/> */}
									<ImageCached
										width={28}
										height={28}
										storageName="ui"
										url={`/assets/levels/28/${m.by.level}.png`}
										title={m.by.rank.Capitalize()}
										params={{}}
									/>
								</div>
							)}
							{/* {sent !== true && ( */}
							<button
								disabled={sent === true}
								onClick={(e) => [e.stopPropagation(), Chat.inst.toggleLikeButtons(m._id, true)]}
								className="btnIcon small btnLike"
							>
								<Icon icon={thumbsOUp} size={18} />
							</button>
							{/* )} */}
						</div>
					</header>
					{m.edit && m.edit.body && <main className="prev">{renderBody(m.edit.body)}</main>}
					<main className={`${m.by.uid < 0 ? `bot ${m.by.un.toLowerCase()}Bot` : ""}`}>{renderBody(m.body)}</main>
					<footer>
						<div className="left">
							{m.icon}
							{m.title && <b className="title">{lang.translate(m.title)}</b>}
							{["notifications", "pm"].indexOf(m.location) === -1 && (
								<>
									{/* {m.by.uid >= 0 && (
										<SImpleDropDown
											className="actions"
											showArrow={false}
											down={false}
											auto={[0, 80]}
											header={<Icon icon={bars} size={18} />}
											beforeShow={(inst) => showDropContent(inst, m, mPrev, sent)}
										/>
									)} */}
									{m.location === "to" && sent === true && (
										<button
											data-by={`read-${m.by.uid}`}
											title={m.readOn > 0 ? lang.translate(`was_read_on`, getDayTimeFromTimestamp(m.readOn, true)) : lang.translate("unread")}
											className={`read ${m.readOn > 0 ? "on" : "off"} read-${m.by.uid}`}
										>
											<Icon icon={iconRead} size={18} />
										</button>
									)}
									{/* {sent !== true && (
										<button onClick={(e) => [e.stopPropagation(), Chat.inst.toggleLikeButtons(m._id, true)]} className="btnIcon small btnLike">
											<Icon icon={thumbsOUp} size={18} />
										</button>
									)}
									<div id={`${m._id}_likeStats`} className="likeStats">
										{this.renderLiked(m._id, m.likes)}
									</div> */}
								</>
							)}
						</div>
						<div className="t">{getDayTimeFromTimestamp(m.t, true)}</div>
					</footer>
					{m.edit && (
						<div className="ed">
							<b>{lang.translate("edited")}</b>
							<div className="tag">
								<button className="btnTag" onClick={(e) => [e.stopPropagation(), chat.toggleChatPane(true), go(`profile/${m.edit.by.un}`)]}>
									<img
										src={getPhoto(m.edit.by.uid, m.edit.by.img, 24, 24)}
										alt=""
										onError={(e) => {
											if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
										}}
									/>
								</button>
								<button className="btnA" onClick={(e) => [e.stopPropagation, Chat.inst.onTaggedClick(m.edit.by.un)]}>
									{m.edit.by.un}
								</button>
							</div>
							<div className="t">{getDayTimeFromTimestamp(m.edit.t, true)}</div>
						</div>
					)}
				</div>
				{sideRight}
			</div>
		);
		return {
			id: m._id,
			m,
			mPrev,
			bySameUid,
			c,
		};
	};
	isReversed = (location) => {
		return [null, "chat", "to"].indexOf(location) === -1;
	};
	messagesRefreshList = async (t = 300, location) => {
		if (typeof document === "undefined") return [];
		const state = store.getState();
		location = location || this.location;
		let data = location === null ? [] : this[location].messages;
		data = Array.isArray(data) ? data : [];
		if (location === "pm") {
			//sort by unread then by time
			data.sort(
				firstBy(function (a, b) {
					return a.m.countGot - a.m.countGotRead - (b.m.countGot - b.m.countGotRead);
				}).thenBy(function (a, b) {
					return a.m.t - b.m.t;
				})
			);
		}

		if (this.isReversed(location)) data = [...data].reverse();
		this.messagesListRef && (await this.messagesListRef.update({ data: !state.chat?.open ? [] : data }));
		await delay(150);
		this.messagesListRef && this.messagesListRef[this.isReversed(location) ? "scrollToTop" : "scrollToEnd"](t);
	};
	reloadChat = async () => {
		let result = await Axios.post("/api/chat/getChatMessages");
		if (!Array.isArray(result)) return;
		this.gotMessages({ chat: result });
	};
	getMessages = () => {
		if (typeof document === "undefined") return [];
		const data = this.location === null ? [] : this[this.location].messages;
		if (!Array.isArray(data)) return [];
		return data;
	};
	onReadingModeOff = async () => {
		const location = this.location;
		if (location !== null && location !== "pm") {
			if (this[location].unreadMessages.length > 0) {
				this[location].messages = this[location].messages.concat(this[location].unreadMessages);
				this[location].unreadMessages.length = 0;
				this.removeOutdatedMessages(location);
			}
			await storeSet({ [`${location}ReadMode`]: { $set: false }, [`${location}UnreadMessagesCount`]: { $set: 0 } });
		}
		this.messagesRefreshList(0, location);
	};
	gotMessages = async (o) => {
		const state = await store.getState();
		this.state = state;
		this.CHAT_EDIT_MESSAGE = await simpleCan(["CHAT_EDIT_MESSAGE"]);
		this.CHAT_EDIT_OVERDUE_MESSAGE = await simpleCan(["CHAT_EDIT_OVERDUE_MESSAGE"]);
		this.MUTE = await simpleCan(["MUTE"]);
		this.BAN = await simpleCan(["BAN"]);
		for (let location in o) {
			const arr = o[location];
			this[location].prevMessage = null;
			this[location].messages.length = 0;
			this[location].unreadMessages.length = 0;
			this[location].mD = {};
			for (let k = 0; k < arr.length; k++) {
				const m = arr[k];
				m.me = state.player.uid;
				if (m.location === "chat" && location === "notifications") {
					m.location = location; //overwrite, as notification location is in ["chat","notificaiton"]
					if (!m.title) m.title = "you_were_tagged";
				} else if (location === "to") {
					m.location = location; //overwrite "pm" to "to"
					//mark all messages in PM as read:
					const _m = cloneJsonMode(m);
					_m._id = _m.members;
					const countGot = this["pm"].mD[_m._id].m.countGot;
					_m.countGot = countGot;
					_m.countGotRead = countGot;
					_m.location = "pm";
					await this.showOriginal(_m);
					const _new = this.renderMessage(_m);
					for (let key in _new) this["pm"].mD[_m._id][key] = _new[key]; //overwrite existing values
				}
				await this.showOriginal(m);
				this[location].mD[m._id] = this.renderMessage(m, this[location].prevMessage);
				this[location].prevMessage = m;
				this[location].messages.push(this[location].mD[m._id]);
			}
			if (this.location === location) {
				if (location !== "pm") await storeSet({ [`${location}ReadMode`]: { $set: false }, [`${location}UnreadMessagesCount`]: { $set: 0 } });
				this.messagesRefreshList(0, location);
			}
		}
	};
	removeOutdatedMessages = (location) => {
		const n = this[location].messages.length - 50;
		for (let i = 0; i < n; i++) {
			delete this[location].mD[this[location].messages.shift().id];
		}
	};
	getLastReadTime = (location) => {
		if (["news", "chat", "notifications"].indexOf(location) === -1) return 0;
		const arr = this[location].messages;
		return arr.length === 0 ? 0 : arr[arr.length - 1].m.t;
	};
	showOriginal = async (m) => {
		if (!m.edit || !m.edit.body) return;
		const SEE_ORIGINAL_MESSAGE = await simpleCan(["SEE_ORIGINAL_MESSAGE"]);
		if (SEE_ORIGINAL_MESSAGE) return;
		if (m.me === m.edit.by.uid) return;
		m.edit.body = null;
	};
	onMessage = async (m) => {
		const updateExisting = (location) => {
			let found = this[location].messages.find((el) => el.id === m._id);
			if (!found) found = this[location].unreadMessages.find((el) => el.id === m._id);
			if (found) {
				for (let key in found) found[key] = null;
				for (let key in this[location].mD[m._id]) found[key] = this[location].mD[m._id][key];
			}
		};
		const state = await store.getState();
		const chatSound = !!state.playerConfig && !!state.playerConfig.siteSettings && !!state.playerConfig.siteSettings.chat.sound;
		this.state = state;
		//return if message is from ignored player:
		if (state.player.uid !== m.by.uid && (await friend.isIgnored({ state, uid: m.by.uid }))) return;
		m.me = state.player.uid;
		const location = m.location;
		const alreadyReadingSenderPm = location === "to" && this.location === "to" && m.members.indexOf(this.sender) >= 0;
		const readingOtherSenderPm = location === "to" && this.location === "to" && m.members.indexOf(this.sender) === -1;
		const update = !!m.edit; //this[location].mD[m._id]; // updating existing message
		chatSound && Array.isArray(m.tags) && m.tags.find((el) => el.uid === state.player.uid) && sound.play("other", "sms");
		chatSound && m.to && m.to.uid === state.player.uid && sound.play("other", "info");
		//RAIN EVENT:
		if (m.by.un.toLowerCase() === "rain") {
			if (["0-mod", "0-adm"].indexOf(state.player.room) !== -1 || state.player.room.startsWith("1-")) return;
			if (state.rainShow === false) {
				chatSound && m.rawData && m.rawData.uids.indexOf(state.player.uid) !== -1 && sound.play("rain", "thunder");
			}
			await storeSet({ rainShow: { $set: true } });
			setTimeout(() => {
				storeSet({ rainShow: { $set: false } });
			}, 3000); //turn off after n seconds
		}
		//---
		if (!readingOtherSenderPm) {
			await this.showOriginal(m);
			if (update && this[location].mD[m._id]) {
				//keep prev message, otherwise onEdit indenting will not work
				this[location].mD[m._id] = this.renderMessage(m, this[location].mD[m._id].mPrev);
			} else {
				this[location].mD[m._id] = this.renderMessage(m, this[location].prevMessage);
				this[location].prevMessage = m;
			}
			//pm cannot have unread mode:
			if (location !== "pm" && (this.location !== location || state[`${location}ReadMode`] || !state.chat.open)) {
				//buffer messages to move to the list later:
				if (!update) {
					this[location].unreadMessages.push(this[location].mD[m._id]);
					await storeSet({
						[`${location}UnreadMessagesCount`]: {
							$apply: (x) => {
								++x;
								return x > 50 ? 50 : x;
							},
						},
					});
				} else updateExisting(location);
			} else {
				if (update) {
					updateExisting(location);
				} else {
					this[location].messages.push(this[location].mD[m._id]);
					this.removeOutdatedMessages(location);
				}
				if (this.location === location) this.messagesRefreshList(0, location);
			}
		}
		if (m.location === "to") {
			if (!alreadyReadingSenderPm && !update) {
				//only increase unread count:
				//pm not holds unread messages array
				await storeSet({
					[`pmUnreadMessagesCount`]: {
						$apply: (x) => {
							++x;
							return x;
						},
					},
				});
			}
			const _m = cloneJsonMode(m);
			_m.location = "pm";
			_m._id = _m.members;
			if (this["pm"].mD[_m._id]) {
				//update existing PM entry:
				//update PM unread/ read
				const received = _m.by.uid !== _m.me;
				let countGot = this["pm"].mD[_m._id].m.countGot + (received && !update ? 1 : 0);
				//countGot = countGot > 100 ? 100 : countGot;
				//pass read info to render:
				_m.countGot = countGot;
				_m.countGotRead = this["pm"].mD[_m._id].m.countGotRead;

				if (alreadyReadingSenderPm) {
					_m.countGotRead = _m.countGot; //mark new messages as read:
					if (received) await Axios.post("/api/chat/readGot", { sender: m.by.uid });
				}
				await this.showOriginal(_m);
				const _new = this.renderMessage(_m);
				for (let key in _new) this["pm"].mD[_m._id][key] = _new[key]; //overwrite existing values
				if (this.location === "pm") this.messagesRefreshList(0, "pm");
			} else {
				//create PM entry:
				_m.countGot = 1;
				_m.countGotRead = 0;
				this.onMessage(_m);
			}
		}
		updateOnlineMarks(state);
	};
	onLike = async ({ _id, likes }) => {
		const el = document.getElementById(`${_id}_likeStats`);
		// if (el) {
		// 	const o = this.renderLiked(_id, likes);
		// 	// ReactDOM.createRoot(el).render(o);
		// 	el.innerHTML = o;
		// }
		el && ReactDOM.render(this.renderLiked(_id, likes), el);
	};
	editCancel = async () => {
		if (this.edit !== null) {
			this.edit = null;
			await this.chatInputSet("");
			const node = document.getElementById("chatInput");
			if (node) node.className = "";
		}
	};
	// eslint-disable-next-line no-unused-vars
	messageToStr = (m) => {
		let str = "";
		for (let el of m.body) {
			switch (el.t) {
				case "tr":
					str = `${str}${lang.translate(el.v)}`;
					break;
				case "s":
					str = `${str}${el.v}`;
					break;
				case "bold":
					str = `${str}<bold>${el.v}</bold>`;
					break;
				case "red":
					str = `${str}<red>${el.v}</red>`;
					break;
				case "green":
					str = `${str}<green>${el.v}</green>`;
					break;
				case "gold":
					str = `${str}<gold>${el.v}</gold>`;
					break;
				case "cyan":
					str = `${str}<cyan>${el.v}</cyan>`;
					break;
				case "small":
					str = `${str}<small>${el.v}</small>`;
					break;
				case "br":
					str = "";
					break;
				case "pa":
					str = `${str}<pa>${btoa(JSON.stringify(el.v))}</pa>`;
					break;
				case "u":
					str = `${str}@${el.un}`;
					break;
				case "b":
					str = `${str}b:${el.id}`;
					break;
				case "em":
					str = `${str}(${el.v})`;
					break;
				case "url":
					str = `${str}${el.url}`;
					break;
				case "y":
					str = `${str}https://www.youtube.com/watch?v=${el.id}`;
					break;
				default:
					break;
			}
		}
		return str;
	};
	// eslint-disable-next-line no-unused-vars
	editMessage = async (m, mPrev) => {
		this.edit = m;
		const str = this.messageToStr(m);
		const node = document.getElementById("chatInput");
		if (node) {
			await this.chatInputSet(str);
			node.className = "editMode";
			node.focus();
			node.click();
		}
	};
}
export const chat = new Chat();
