const _keywords = {
	"en-US": {
		jan: "Jan",
		feb: "Feb",
		mar: "Mar",
		apr: "Apr",
		may: "May",
		jun: "Jun",
		jul: "Jul",
		aug: "Aug",
		sep: "Sep",
		oct: "Oct",
		nov: "Nov",
		dec: "Dec",
		mon: "Mon",
		tue: "Tue",
		wed: "Wed",
		thu: "Thu",
		fri: "Fri",
		sat: "Sat",
		sun: "Sun",
		abbrevation: "Abbrevation",
		accepted_currencies: "Accepted currencies",
		access_code: "Access code",
		access_denied: "Access denied",
		account: "Account",
		action: "Action",
		actions: "Actions",
		action_not_allowed: "Action not allowed",
		activate: "Activate",
		active: "Active",
		active_rollhunts: "Active rollhunts",
		аctivities: "Activities",
		add: "Add",
		add_to_friends: "Add to friends",
		address: "Address",
		address_create_error: "Cannot create address",
		address_created: "Address created",
		address_not_generated_yet: "Address not generated yet",
		administrator: "Administrator",
		administrators: "Administrators",
		affiliate: "Affiliate",
		affiliation: "Affiliation",
		affiliate_casino_loss: "Casino loss from referred users",
		affiliate_casino_revenue: "Casino revenue from referred users",
		aff_claims: "Affiliation claims",
		affiliate_commission_rate: "Current referral comission rate",
		affiliate_current_balance: "Current balance of the referred users",
		affiliate_deposit_count: "Number of deposits",
		affiliate_net_commission: "Net commission",
		affiliate_referral_profit: "Net referral profit",
		affiliate_stats: "Affiliate Stats",
		affiliate_user_count: "Current number of referred users",
		affiliation_management: "Affiliation management",
		after: "After",
		all: "All",
		all_bets: "All bets",
		all_in: "All-in?",
		all_your_progress_will_be_lost: "All your progress will be lost (dep. bonus)",
		allow_faucet_if_has_bank: "Allow faucet if has bank balance",
		allow_faucet_if_has_other_coin_balance: "Allow faucet if has balance on other coin",
		allow_rains: "Allow rains",
		already_approved_by: "Already approved by",
		already_cancelled_by: "Already cancelled by",
		already_verified: "Entry already verified",
		amount: "Amount",
		amount_claimed: "Amount claimed",
		amount_not_number: "Amount is not a number",
		amount_per_user: "Amount per user",
		animals_and_nature: "Animals & Nature",
		approved: "Approved",
		are_you_sure: "Are you sure",
		auto: "Auto",
		auto_cash_out: "Auto cash out",
		auto_roll: "Auto roll",
		avatar: "Avatar",
		away: "Away",
		balance: "Balance",
		balance_changed: "Balance changed",
		balance_zero: "Balance must be zero",
		balance_over_limit_reached: "Balance over limit reached",
		balance_under_limit_reached: "Balance under limit reached",
		ban: "Ban",
		banned: "Banned",
		banner: "Banner",
		banners: "Banners",
		before: "Before",
		slide: "Slide",
		slides: "Slides",
		bank: "Bank",
		bank_zero: "Bank's balance must be zero",
		bank_in: "Transfer to bank",
		bank_out: "Transfer from bank",
		beginning_of_the_hour: "Beginning of the hour",
		beginning_of_the_day: "Beginning of the day",
		beginning_of_the_week: "Beginning of the week",
		beginning_of_the_month: "Beginning of the month",
		bet: "Bet",
		bets: "Bets",
		bet_amount: "Bet amount",
		bet_amount_limit_reached: "Bet amount limit reached",
		bet_info: "Bet info",
		bets_made_with_pair: "Bets made with pair",
		billing: "Billing",
		block: "Block",
		block_hash: "Block hash",
		board: "Board",
		bonus: "Bonus",
		bonus_activated: "Bonus activated",
		bonus_deactivated: "Bonus deactivated",
		bonus_activation_error: "The bonus code is wrong",
		bonus_claim: "Claim bonus",
		bonus_won: "Congrats, you won the bonus!",
		busy: "Busy",
		buy: "Buy",
		by: "By",
		by_clicking: "By clicking button below I agree to the",
		cash_out: "Cash out",
		calculate_in: "Calculate in",
		campaign: "Campaign",
		cancel: "Cancel",
		cancelled: "Cancelled",
		cannot_change_entry: "Cannot change entry",
		cannot_ignore_mod_or_admin: "Cannot ignore Mod or Admin",
		cause_rain: "Cause rain",
		caution: "Caution",
		city: "City",
		clear: "Clear",
		chance: "Chance",
		change_balance: "Change balance",
		change_mail: "Change mail",
		change_client_seed: "Change client seed",
		change_password: "Change password",
		chat: "Chat",
		chatgame: "Chat game",
		chat_game_management: "Chat game management",
		chat_game_statement: "Guess number from 1 to 25 inclusive and win prize! Good Luck!",
		chat_messages: "Chat messages",
		check_input_values: "Check input values",
		check_internet: "Please check your internet connection",
		choose_coin: "Choose coin",
		claim: "Claim",
		clear_2fa: "Clear 2fa",
		client_seed: "Client seed",
		closed: "Closed",
		code: "Code",
		coin: "Coin",
		coin_name: "Coin's name",
		coin_not_selected: "Coin is not selected",
		coins: "Coins",
		coin_management: "Coin management",
		coin_not_active: "Coin not active",
		coin_not_stable: "Coin not stable",
		comment: "Comment",
		commission_rate: "Commission rate",
		con_connection_error: "Connection error",
		con_connection_established: "Connection established",
		con_connection_lost: "Connection lost",
		con_reconnect_attempt: "Reconnect attempt: {0}",
		con_reconnect_failed: "Reconnect failed",
		confirm_new_password: "Confirm new password",
		confirm_you_are_human: "Confirm that you are a human",
		confirmation_link_sent: "Confirmation link sent",
		confirmations_required: "Confirmations required",
		confiscate: "Confiscate",
		confiscated: "Confiscated",
		contact_support: "For more details, contact support",
		contest: "Contest",
		contests: "Contests",
		copied: "Value copied to the clipboard",
		copy: "Copy",
		copy_to_clipboard: "Copy to clipboard",
		country: "Country",
		country_code: "Country code",
		create: "Create",
		create_an_account: "Sign up",
		created_by: "Created by",
		creator_profit: "Creator's profit",
		currency: "Currency",
		current_balance: "Current balance",
		current_data: "Current data",
		current_friends: "Current friends",
		current_rates: "Current rates",
		current_seed_pair: "Current seed pair",
		current_session: "Current",
		custom: "Custom",
		daily_limit: "Daily limit",
		daily_limit_reached: "Daily limit reached",
		daily_total_amount: "Daily total amount",
		daily_count: "Daily count",
		data: "Data",
		data_not_found: "Data not found",
		date_joined: "Date joined",
		day: "Day",
		days: "Days",
		deactivate: "Deactivate",
		decrease_by: "Decrease by",
		delay: "Delay",
		delete: "Delete",
		delete_message: "Delete message",
		delta: "Delta",
		deltas: "Deltas",
		deposit: "Deposit",
		deposit_bonus: "Deposit bonus",
		deposits: "Deposits",
		deposit_confirmation_pending: "You cannot tip, withdraw, send rains or play third party games while deposit confirmation is pending",
		deposit_success: "Deposit success, please wait for 1 confirmation",
		deposit_confirmed: "Deposit success",
		description: "Description",
		dice: "Dice",
		disabled: "Disabled",
		disposable: "One-time",
		divide_amount: "Divide amount",
		do_not_lose_chance_to_activate_our_deposit_bonuses: "Do not lose chance to activate our deposit bonuses",
		double_check_rates: "Please double check if the current exchange rate fits your needs, because exchange is an irreversible action.",
		duration: "Duration",
		edit: "Edit",
		edit_message: "Edit message",
		edited: "Ed. by",
		embed_code: "Embed code (for websites)",
		enabled: "Enabled",
		enter_amount: "Enter amount",
		enter_address: "Enter address",
		enter_tag: "Enter tag",
		enter_delta_amount: "Enter delta amount",
		enter_token: "Enter 2fa token",
		enter_password: "Enter password",
		entry_not_found: "Entry not found",
		entry_add: "Add entry",
		entry_already_exists: "Such entry already exists",
		entry_added: "Entry added",
		entry_remove: "Remove entry",
		entry_removed: "Entry removed",
		entry_update: "Update entry",
		entry_updated: "Entry updated",
		exchange: "Exchange",
		exchange_from: "Exchange from",
		exchange_to: "Exchange to",
		exchange_management: "Exchange management",
		exchanges: "Exchanges",
		exchange_success: "Successfully exchanged amount",
		expired: "Expired",
		error: "Error",
		every: "Every",
		every_losses: "Every losses",
		every_wins: "Every wins",
		fa_if_enabled: "2FA (if enabled)",
		failed: "Failed",
		fairness: "Fairness",
		fairness_check: "Fairness check",
		faq: "FAQ",
		faucet: "Faucet",
		faucet_enable: "Enable faucet",
		faucet_digits_count: "Faucet guessable digits count",
		faucet_disabled: "Faucet is disabled at current time",
		faucet_abuse: "Due to the faucet abuse or faucet farming activities, some limitations applied on your account",
		faucet_claimed: "Faucet claimed successfully",
		faucet_claimed_recently: "You have recently claimed from the faucet and must wait before claiming again.",
		faucet_claims: "Faucet claims",
		faucet_farmer_ignore_admin: "Ignore faucet farming by administrators",
		faucet_farmer_ignore_mod: "Ignore faucet farming by moderators",
		faucet_farmers: "Faucet farmers",
		faucet_farming_msg: "Due to faucet farming claimed amount is lowered",
		faucets_in_hour: "Faucet claims in 1 hour to regard player as a faucet farmer",
		faucet_management: "Faucet management",
		faucet_not_claimed: "Could not claim faucet",
		fbi: "FBI",
		filter: "Filter",
		fp: "fingerprint",
		fingerprint: "Fingerprint",
		fingerprints: "Fingerprints",
		finished: "Finished",
		finish_to_reveal: "Finish game to reveal seed",
		field_blank: "Field cannot be empty",
		first_deposit: "First deposit",
		flags: "Flags",
		food_and_drink: "Food & Drink",
		for: "for",
		forever: "Forever",
		formula: "Formula",
		forum: "Forum",
		forgot_password: "Forgot password",
		free_play: "Free play",
		frequently_used: "Frequently used",
		friend_request_sent: "Friend request sent",
		friend: "Friend",
		friends: "Friends",
		from: "From",
		game: "Game",
		game_added_to_favourites: "Game added to favourites",
		game_removed_from_favourites: "Game removed from favourites",
		game_provider: "Game provider",
		games: "Games",
		game_balance: "Game balance",
		gambling_forbidden: "and gambling isn't forbidden by my local authorities and I'm at least 18 years old.",
		generate_new: "Generate new",
		general_info: "General info",
		geographical_data: "Geographical data",
		gfa_cleared_successfully: "2fa cleared successfully",
		gfa_data_not_found: "No 2fa activation entry was found",
		gfa_management: "Google 2FA management",
		gfa_reset: "2fa reset",
		gfa_set: "2fa set",
		gfa_on: "Google 2FA activated",
		gfa_off: "Google 2FA deactivated",
		granted: "Granted",
		guests: "Guests",
		h: "h",
		hasLower: "Contains lowercase letter",
		hasNumber: "Contains number",
		hasSymbol: "Contains symbol",
		hasUpper: "Contains uppercase letter",
		history: "History",
		hour: "Hour",
		hours: "Hours",
		high_rollers: "High rollers",
		home: "Home",
		hotkeys: "Hotkeys",
		hotkeys_are_currently: "Hotkeys are currently",
		house_edge: "House edge",
		hunt: "Hunt",
		hunt_number: "Hunt number",
		hunt_number_pool: "Hunt number pool",
		id: "Id",
		ignore: "Ignore",
		ignored_players: "Ignored players",
		img: "Image",
		in_a_row: "In a row",
		income: "Income",
		inputs: "Inputs",
		increase_by: "Increase by",
		inc_by: "Inc. by",
		info: "Info",
		infinite: "Infinite",
		insufficient_funds: "Insufficient funds",
		insufficient_privileges: "Insufficient privileges [{0}]",
		invalid_address: "Invalid address",
		invalid_amount: "Invalid amount",
		invalid_arguments: "Invalid arguments",
		invalid_bet_amount: "Invalid bet amount",
		invalid_currency: "Invalid currency",
		invalid_ip_fp: "Username with such IP and fingerprint already exists",
		invalid_login_information: "Invalid login information",
		invalid_mail: "Not a valid email",
		invalid_password: "Invalid password",
		invalid_password_or_2fa: "Invalid password or 2fa",
		invalid_time: "Invalid time",
		invalid_token: "Invalid token",
		invalid_username: "Invalid username",
		invalid_wallet_password: "Invalid wallet password",
		investment_required: "Investment required",
		ip: "Ip",
		ip_fp_change_detected: "IP / Fingerprint change detected, please relogin",
		is_online: "Is online",
		is_offline: "Is offline",
		isp: "ISP",
		jackpot: "Jackpot",
		jackpot_management: "Jackpot management",
		jackpots: "Jackpots",
		join: "Join",
		join_price: "Join price",
		key_expired: "Your key expired",
		lat: "Lat",
		leaderboard: "Leaderboard",
		let_others_know: "Let others know",
		level: "Level",
		live: "Live",
		loading: "Loading",
		lobby: "Lobby",
		login: "Login",
		login_first: "Please, login first",
		logout: "Logout",
		lon: "Lon", //longitude
		lose: "Lose",
		loss: "Loss",
		losses: "Losses",
		luck: "Luck",
		lucky_number: "Lucky number",
		lucky_number_today: "Today's lucky number",
		lucky_players_are: "The lucky players are",
		mail: "Mail",
		mail_verified: "Mail verified",
		mail_confirmed: "Thanks, your mail is now confirmed",
		mail_management: "Mail management",
		mail_set: "Recovery mail set, confirmation has been sent to",
		mail_not_found: "Mail not found",
		mail_not_confirmed: "Mail not confirmed",
		mail_not_set: "Mail not set",
		management: "Management",
		manual: "Manual",
		marked_for_delete: "Marked for deletion",
		max: "Max",
		max_bet: "Max bet",
		max_amount: "Maximum amount",
		menu: "Menu",
		message: "Message",
		message_deleted: "Message deleted",
		min: "Min",
		min_amount: "Minimum amount",
		min_amount_not_matched: "Min amount not matched",
		min_exchange_amount: "Min. exchange amount",
		min_left: "{0} min left",
		min_level_1: "You can not chat until you reach level 2",
		min_level_not_reached: "Min level not reached",
		min_level_required: "Мin level required",
		min6: "min {0} characters",
		minutes: "Minutes",
		minimal_exhcange_amount_is: "Minimal exchange amount is",
		minimal_faucet_amount_is: "Received amount from faucet is",
		minimal_withdraw_amount_is: "Minimal withdrawal amount is",
		minute: "Min",
		moderator: "Мoderator",
		moderator_profit: "Moderator's profit",
		moderators: "Мoderators",
		money: "Money",
		monitor: "Monitor",
		multiply_amount: "Мultiply amount",
		multiplier: "Multiplier",
		mute: "Mute",
		mute_sm: "Mute",
		muted: "Muted",
		my_bets: "My bets",
		my_position: "My position",
		my_recovery_mail: "My recovery mail",
		my_wager: "My wager",
		n_days: "{0} days",
		n_hours: "{0} hours",
		n_minutes: "{0} minutes",
		n_weeks: "{0} weeks",
		n_months: "{0} months",
		name: "Name",
		new: "New",
		news: "News",
		new_message: "New message",
		new_messages: "New messages",
		new_password: "New password",
		new_password_min6: "New password  (min. 6 characters)",
		no: "No",
		no_active_contests: "No active contests found",
		no_content_found: "No content found",
		no_pending_contests: "No pending contests found",
		no_expired_contests: "No expired contests found",
		no_available_actions: "No available actions",
		no_data_found: "No data found",
		no_limit: "no limit",
		no_recurrence: "No recurrence",
		not_allowed_by_system: "Not allowed by the system",
		not_enough_players: "Not enough players",
		not_implemented: "Current function not implemented",
		not_logged_in: "Please login to perform this action",
		not_revealed_yet: "The seed hasn't been revealed yet",
		notes: "Notes",
		notifications: "Notifications",
		number_of_players: "Number of players",
		number_of_roll_limit_reached: "Number of roll limit reached",
		number_of_rolls: "Number of rolls",
		objects: "Objects",
		offline: "Appear offline",
		old_password: "Old password",
		only_with_balance: "Only with balance",
		of_points: "{0} of {1} points",
		of_points_total: "Total {0} of {1} points",
		off: "Off",
		on: "On",
		on_loss: "On loss",
		on_win: "On win",
		online: "Online",
		only: "Only",
		opened: "Opened",
		optional: "Optional",
		or: "Or",
		org: "Org",
		outcome: "Outcome",
		outputs: "Outputs",
		overall_stats: "Overall",
		overall_data: "Overall data",
		overall_limit: "Overall limit",
		pandora: "Pandora",
		page_not_found: "Page not found",
		page_not_found_desc: "The page you are looking for does not exist; it may have been moved, or removed altogether.",
		pages: "Pages",
		packet_lost_retrying: "Packet lost, retrying...",
		parameters: "Parameters",
		participants: "Participants",
		participate: "Participate",
		participate_in: "Participate in",
		password: "Password",
		password_2fa_change_detected: "Password / 2fa change detected",
		password_management: "Password management",
		password_at_least: "Password must be at least 6 characters long",
		passwords_not_match: "passwords do not match",
		password_not_set: "Password not set",
		password_reset: "Password reset",
		password_reset_instruction: "Password reset instruction has been sent to your mail",
		password_reset_successfully: "Password reset successfully",
		password_set: "Password set",
		password_strength: "Password strength",
		password_changed: "Password changed",
		passwords_match: "Passwords match",
		pause: "Pause",
		paused: "Paused",
		payout: "Payout",
		pending: "Pending",
		pending_deposit: "Pending deposit",
		permissions: "Permissions",
		permissions_management: "Permissions management",
		ping: "Ping",
		place: "Place",
		place_bet: "Place bet",
		play: "Play",
		play_now: "Play now",
		player: "Player",
		players: "Players",
		player_has_been_muted: "Player has been muted",
		player_has_been_unblocked: "Player has been unblocked",
		player_not_selected: "Player is not selected",
		please_retry: "Please, try again",
		pm: "PM",
		points_to_next_level: "Points to next level",
		previous_seed_pair: "Previous seed pair",
		priority: "Priority",
		privacy: "Privacy policy",
		prize_pool: "Prize pool",
		probability: "Probability",
		processed: "Processed",
		promotion: "Promotion",
		profile: "Profile",
		profit: "Profit",
		profit_from_jackpot: "Profit from jackpot",
		profit_from_rollhunt: "Profit from rollhunt",
		profit_on_win: "Profit on win",
		provide_password: "Enter password",
		public_chat_rooms: "Public chat rooms",
		qr_error: "Error while generating QR image",
		qr_expired: "QR expired",
		quantity: "Quantity",
		rain: "Rain",
		rain_amount: "Rain amount",
		rain_management: "Rain management",
		rain_received: "You got caught in the rain",
		rain_sent: "You caused the rain",
		rains_sent: "Rains sent",
		rain_out: "Rains sent",
		rains_received: "Rains received",
		rain_in: "Rains received",
		randomize: "Randomize",
		randomise_to_reveal: "Randomise to reveal",
		randomization_error: "Error while changing client seed",
		randomization_failed: "Randomization failed",
		randomization_success: "Client seed successfully changed",
		ratios: "Ratios",
		read_mode: "Read mode",
		read_more: "Read more",
		reason: "Reason",
		recurrence: "Recurrence",
		recent: "Recent",
		reconnect: "Reconnect",
		recover: "Recover",
		recovery_password_sent: "Recovery password sent",
		referral_link: "Referral link",
		ref_by: "Ref. by",
		ref_count: "Referral count",
		refresh: "Refresh",
		reg_date: "Reg. date",
		region: "Region",
		registration: "Sign in/up",
		registration_successful: "registration successful",
		reject: "Reject",
		rejected: "Rejected",
		remove_from_friends: "Remove from friends",
		required_field: "Required field",
		relative_balance_account: "Relative balance account",
		relative_bank_account: "Relative bank account",
		reload: "Reload",
		reload_page: "Site has been updated, please reload page",
		resend_confirmation_link: "Resend confirmation link",
		reset: "Reset",
		reset_password: "Reset password",
		result: "Result",
		to_base: "To base",
		return_to_lobby: "Return to lobby",
		reverse: "Reverse",
		revoked: "Revoked",
		roll: "Roll",
		rolls: "Rolls",
		roll_dice: "Roll dice",
		roll_over: "Over",
		roll_under: "Under",
		roll_hunt: "Roll hunt",
		room: "Room",
		room_create: "Create room",
		room_name: "Room name",
		room_not_active: "Room is not active",
		rooms: "Rooms",
		rooms_management: "Rooms management",
		save: "Save",
		search: "Search",
		search_game: "Search game",
		sec: "Sec",
		security: "Security",
		seconds: "Seconds",
		seconds_remaining: "Seconds remaining",
		select_player: "Select player",
		select_coin: "Select coin",
		send: "Send",
		send_pm: "Send PM",
		send_tip: "Send tip",
		series: "Series",
		server_error: "Server error",
		server_seed: "Server seed",
		server_seed_changed: "Server seed changed",
		server_sha_512: "Server seed sha512 hash",
		server_time: "Server time",
		set: "Set",
		set_bet_amount: "Please set bet amount",
		set_password: "Set password",
		set_password_first: "Set password first",
		set_mail: "Set mail",
		set_recovery_mail: "Set a recovery mail",
		settings: "Settings",
		settings_saved: "Settings saved",
		shortcuts: "Shortcuts",
		show_more: "Show more",
		sign_in: "Sign in",
		simple_balance: "Simple balance",
		site_to_be_shutdown: "Site is going to be updated, all gameplay is stopped",
		skip: "skip",
		slider: "Slider",
		slots: "Slots",
		slow_down: "Too many requests, slow down",
		smileys_and_people: "Smileys & People",
		something_went_wrong: "Something went wrong",
		show_in: "Show in {0}",
		sound: "Sound",
		speed: "Speed",
		split: "Split",
		sport: "Sport",
		stable: "Stable",
		stats: "Statistics",
		statistics: "Statistics",
		starting_point: "St. Point",
		starts_after: "Starts after",
		status: "Status",
		stop: "Stop",
		stop_on_loss: "Triggered stop on loss",
		stop_on_win: "Triggered stop on win",
		stop_on_balance_under: "Triggered stop on [balance amount under] pointed limit",
		stop_on_bet_amount_over: "Triggered stop on [bet amount over] pointed limit",
		stop_on_balance_over: "Triggered stop on [balance amount over] pointed limit",
		stop_on_bet_amount_under: "Triggered stop on [bet amount under] pointed limit",
		stop_on_number_of_rolls: "Triggered stop on [number of rolls]",
		stop_if_over: "Stop if over",
		stop_if_under: "Stop if under",
		stop_if_balance_under: "Stop if balance under",
		stop_if_balance_over: "Stop if balance over",
		stop_if_bet_amount_over: "Stop if bet amount over",
		streak: "Streak",
		submit: "Submit",
		support: "Support",
		symbols: "Symbols",
		system: "System",
		system_management: "System management",
		the_most_popular: "The most fair and trusted crypto gambling portal",
		tags: "Tags",
		take_seat: "Take seat",
		tc: "Terms and Conditions",
		time: "Time",
		time_left: "Тime left",
		time_from: "Time from",
		time_to: "Time to",
		timezone: "Timezone",
		tip: "Tip",
		tips: "Tips",
		tip_amount: "Tip amount",
		tip_received: "Tip received",
		tips_send: "Send tips",
		tip_management: "Tip management",
		tip_not_sent: "Tip not sent",
		tip_sent: "Tip sent",
		tips_sent: "Tips sent",
		tips_receive: "Receive tips",
		tips_received: "Tips received",
		title: "Title",
		to: "To",
		to_withdraw_solve_captcha: "To withdraw solve captcha",
		too_many_requests: "Too many requests",
		token: "Token",
		token_expired: "Your token expired, please relogin",
		toggle_jackpot: "Toggle jackpot",
		toggle_fa_mode: "Toggle 2FA mode",
		toggle_rollhunt: "Toggle rollhunt",
		toggle_slider: "Toggle slider",
		top_250_rain: "Top 250 by chance to be caught by a rain",
		total: "Total",
		total_bets: "Total bets",
		total_losses: "Total losses",
		total_bets_shared: "Total bets shared",
		total_messages: "Total messages",
		total_profit: "Total profit",
		total_wagered: "Total wagered",
		total_wins: "Total wins",
		transaction_fee: "Transaction fee",
		transaction: "Transaction",
		transactions: "Transactions",
		transaction_complete: "Тransaction complete",
		translate: "Translate",
		travel_and_places: "Travel & places",
		turn_on: "Turn on",
		turn_off: "Turn off",
		two_factor_authentication: "Two factor authentication",
		type: "Type",
		type_a_message: "Type a message",
		type_numbers_only: "Type numbers only",
		type_your_mail: "Type your mail",
		unblock_sm: "Unblock",
		unclaimed_profit: "Unclaimed profit",
		unexpected_error: "Unexpected error occured",
		unignore: "Unignore",
		unlimited: "Unlimited",
		unread: "Unread",
		unstable: "Unstable",
		upload_error: "Error while uploaing file",
		upload_success: "File uploaded successfully",
		usd_change_is_shown: "Please note that total balance change in USD is shown with the historical rates",
		user: "User",
		user_name: "User name",
		username_already_taken: "Username already taken",
		username_min6: "Username  (min. 6 characters)",
		username_not_found: "User not found",
		usernames: "Usernames",
		values_are_based_on_ranking: "Values are based on ranking",
		verify: "Verify",
		verified: "Verified",
		verification_failed: "Verification failed",
		view_profile: "View profile",
		vip_chat_rooms: "VIP chat rooms",
		virtual_sport: "Virt.Sport",
		w_requests: "W. requests",
		wager: "Wager",
		wagering_contest: "Wagering contest",
		waiting_time: "Waiting time",
		wallet_not_found: "Wallet not found",
		was_read_on: "Was read on {0}",
		websockets_support: "Your browser does not support websockets",
		welcome: "Welcome",
		who_is_online: "Who is online",
		win: "Win",
		wins: "Wins",
		win_chance: "Win chance",
		winner: "Winner",
		with_mail: "With mail",
		withdraw: "Withdraw",
		withdraw_approved: "With. approved",
		withdraw_rejected: "With. rejected",
		withdraw_min: "Min. withdraw amount",
		withdraw_error: "Withdrawal error, please, contact support",
		withdraw_success: "Successfully withdrew amount",
		withdraws: "Withdraws",
		yes: "Yes",
		you_are_ignored: "Cannot add as a friend, because target player ignored you",
		you_are_not_participating_in_any_campaign: "You are not participating in any campaign",
		you_are_offline: "You are Offline",
		you_have_been_muted_by: "You have been muted by",
		you_must_cancel_running_deposit_bonus_to_proceed_with_the_current_action:
			"You must cancel running deposit bonus to proceed with the current action",
		you_pay: "You pay",
		you_receive: "You receive",
		you_tried_too_many_times: "You tried too many times, please try a bit later",
		your_comment: "Your comment",
		you_got: "You got",
		your_place: "Your place",
		your_recovery_mail: "Your recovery mail",
		you_wagered: "You wagered",
		you_were_tagged: "You were tagged",
		you_wrote: "You wrote",
		your_hunt_number_pool_is_empty: "Your hunt number pool is empty",
		your_withdrawal_will_be_sent: "Your withdrawal will be sent once there are no unconfirmed deposits on your account.",
		zip: "Zip",

		multi_account: "Multi account",
		rude_words: "Rude words",
		cheating: "Cheating",
		faucet_farming: "Faucet farming",
		rain_farming: "Rain farming",
		player_insulting: "Player insulting",
		moderator_insulting: "Moderator insulting",
		ignoring_moderator_warning: "Moderator warnings ignoring",
		asking_for_tips: "Asking for tips",
		flooding_spaming: "Flooding, spaming",
		self_referral: "Self referral",
		other: "Other",

		activities_hunting_level: "Activities hunting level",
		betting_level: "Betting level",
		chat_activity_level: "Chat activity level",
		contest_activity_level: "Contest activity level",
		jackpot_hunting_level: "Jackpot hunting level",
		loyalty_level: "Loyalty level",
		rainmaker_level: "Rainmaker level",
		referrals_betting_level: "Referrals betting level",
		referring_level: "Referring level",
		wagering_level: "Wagering level",
		warn_level: "Warn level",

		activities_hunting_level_d: "Depends on the total number of activities won. Maximum {0} Points ({1} activities).",
		betting_level_d: "Depends on the total bets count. Maximum {0} Points ({1} bets).",
		chat_activity_level_d: "Depends on the total messages count in the chat. Maximum {0} Points ({1} messages).",
		contest_activity_level_d: "Depends on the total number of contests won. Maximum {0} Points ({1} contests).",
		jackpot_hunting_level_d: "Depends on the total number of jackpots won. Maximum {0} Points ({1} jackpots).",
		loyalty_level_d: "Depends on the total days passed since the date of the registration. Maximum {0} Points ({1} days).",
		rainmaker_level_d: "Depends on the total number of rains made. Maximum {0} Points ({1} rains).",
		referrals_betting_level_d: "Depends on the total bets count of referrals. Maximum {0} Points ({1} bets).",
		referring_level_d: "Depends on the total referrals count. Maximum {0} Points ({1} referrals).",
		wagering_level_d: "Depends on the total wagered amount. Maximum {0} Points ({1} USD).",
		warn_level_d: "Depends on the total warns got. Maximum {0} Points ({1} warns)",
	},
	"ru-RU": {
		jan: "Янв",
		feb: "Фев",
		mar: "Мар",
		apr: "Апр",
		may: "Май",
		jun: "Июн",
		jul: "Июл",
		aug: "Авг",
		sep: "Сен",
		oct: "Окт",
		nov: "Ноя",
		dec: "Дек",
		mon: "Пон",
		tue: "Вто",
		wed: "Сре",
		thu: "Чет",
		fri: "Пят",
		sat: "Суб",
		sun: "Вос",
		abbrevation: "Аббревиатура",
		accepted_currencies: "Встроенные валюты",
		access_code: "Код доступа",
		access_denied: "Доступ запрещён",
		account: "Профиль",
		action: "Действие",
		actions: "Действия",
		action_not_allowed: "Действие запрещено",
		active: "Включенный",
		activate: "Включить",
		active_rollhunts: "Активные охоты",
		аctivities: "Мероприятия",
		add: "Добавить",
		add_to_friends: "Добавить в друзья",
		address: "Адрес",
		address_create_error: "Ошибка при создании адреса",
		address_created: "Адрес создался",
		address_not_generated_yet: "Адрес пока не создан",
		administrator: "Администратор",
		administrators: "Администраторы",
		after: "После",
		affiliate: "Рефералы",
		affiliation: "Рефералы",
		affiliate_casino_loss: "Расходы казино от рефералов",
		affiliate_casino_revenue: "Доход казино от рефералов",
		aff_claims: "Доход от рефералов",
		affiliate_commission_rate: "Ставка комиссии",
		affiliate_current_balance: "Текущий баланс рефералов",
		affiliate_deposit_count: "Количество вкладов",
		affiliate_net_commission: "Чистая комиссия",
		affiliate_referral_profit: "Чистая реферальная прибыль",
		affiliate_stats: "Статистика рефералов",
		affiliate_user_count: "Текущее количество рефералов",
		affiliation_management: "Управление рефералами",
		all: "Все",
		all_bets: "Все ставки",
		avatar: "Аватар",
		all_in: "Поставить всё?",
		all_your_progress_will_be_lost: "Весь ваш прогресс будет потерян (деп. бонус)",
		allow_faucet_if_has_bank: "Разрешить кран если есть баланс в банке",
		allow_faucet_if_has_other_coin_balance: "Разрешить кран если есть баланс на другой монете",
		allow_rains: "Разрешить дожди",
		already_approved_by: "Уже подтверждён",
		already_cancelled_by: "Уже отменён",
		already_verified: "Запись уже подтверждена",
		amount: "Сумма",
		amount_claimed: "Сумма запрошена",
		amount_not_number: "Сумма не в формате числа",
		amount_per_user: "Сумма на игрока",
		animals_and_nature: "Животные и природа",
		approved: "Утвержденный",
		are_you_sure: "Вы уверены?",
		auto: "Авто",
		auto_cash_out: "Авто обналичивание",
		auto_roll: "Авто бросок",
		away: "Нет на месте",
		balance: "Баланс",
		balance_changed: "Баланс изменился",
		balance_zero: "Баланс должен быть равен нулю",
		balance_over_limit_reached: "Достигнут лимит верхнего значения баланса",
		balance_under_limit_reached: "Достигнут лимит нижнего значения баланса",
		ban: "Забанить",
		banned: "Под баном",
		bank: "Банк",
		bank_zero: "Баланс в банке должен быть равен нулю",
		bank_in: "Внос в банк",
		bank_out: "Вынос из банка",
		banner: "Баннер",
		banners: "Баннеры",
		slide: "Слайд",
		slides: "Слайды",
		beginning_of_the_hour: "Начало часа",
		beginning_of_the_day: "Начало дня",
		beginning_of_the_week: "Начало недели",
		beginning_of_the_month: "Началоh месяца",
		before: "До",
		bet: "Ставка",
		bets: "Ставки",
		bet_amount: "Размер ставки",
		bet_amount_limit_reached: "Достигнут лимит размера ставки",
		bet_info: "Информация о ставке",
		bets_made_with_pair: "Ставки сделанные парой",
		billing: "Биллинг",
		block: "Заблокировать",
		block_hash: "Хэш блока",
		board: "Настольные",
		bonus: "Бонус",
		bonus_activated: "Бонус активирован",
		bonus_deactivated: "Бонус истёк либо удален",
		bonus_activation_error: "Неверный бонус код",
		bonus_claim: "Запросить бонус",
		bonus_won: "Поздравляем, вы выиграли бонус!",
		busy: "Не беспокоить",
		buy: "Купить",
		by: "Кем",
		by_clicking: "Нажатием кнопки внизу я соглашаюсь с документами",
		cash_out: "Обналичить",
		calculate_in: "Пересчитать в",
		campaign: "Кампания",
		cancel: "Отмена",
		cancelled: "Отмененный",
		cannot_change_entry: "Невозможно поменять запись",
		cannot_ignore_mod_or_admin: "Невозможно игнорировать мода или админа",
		cause_rain: "Вызвать дождь",
		caution: "Внимание",
		chance: "Шанс",
		change_balance: "Смена баланса",
		change_mail: "Смена почты",
		change_client_seed: "Смена сида клиента",
		change_password: "Смена пароля",
		chat: "Чат",
		chatgame: "Чат игра",
		chat_game_management: "Управление чат играми",
		chat_game_statement: "Угадайте число от 1-го до 25 и получите приз! Удачи!",
		chat_messages: "Всего сообщении",
		check_input_values: "Проверьте вводимые значения",
		check_internet: "Пожалуйста, проверьте ваше интернет подключение",
		choose_coin: "Выберите монету",
		city: "Город",
		claim: "Запросить",
		clear: "Очистить",
		clear_2fa: "Сбросить 2fa",
		client_seed: "Сид клиента",
		closed: "Закрыт",
		code: "Код",
		comment: "Комментарий",
		commission_rate: "Ставка комиссии",
		coin: "Монета",
		coin_name: "Название монеты",
		coin_not_active: "Монета не активна",
		coin_not_selected: "Монета не выбрана",
		coin_not_stable: "Монета не стабильна",
		coins: "Монеты",
		coin_management: "Управление монетами",
		con_connection_error: "Ошибка при подключении",
		con_connection_established: "Связь установлена",
		con_connection_lost: "Связь потеряна",
		con_reconnect_attempt: "Попытка подключения: {0}",
		con_reconnect_failed: "Нет подключения",
		confirm_new_password: "Новый пароль заново",
		confirm_you_are_human: "Подтвердите что вы человек",
		confirmation_link_sent: "Ссылка для подтверждения отправлена",
		confirmations_required: "Требует конфирмации",
		confiscate: "Конфисковать",
		confiscated: "Конфискованный",
		contact_support: "Для деталей обратитесь в тех. службу",
		contest: "Конкурс",
		contests: "Конкурсы",
		copied: "Запись перенесена в буфер",
		copy: "Копировать",
		copy_to_clipboard: "Перенести в буфер",
		country: "Страна",
		country_code: "Код страны",
		create: "Создать",
		create_an_account: "Регистрация",
		currency: "Валюта",
		current_balance: "Текущие баланс",
		current_data: "Текущие данные",
		current_friends: "Текущие друзья",
		current_rates: "Текущие курсы",
		current_seed_pair: "Текущая пара сидов",
		current_session: "Текущая",
		custom: "Общие",
		daily_limit: "Дневной лимит",
		data: "Данные",
		data_not_found: "Данные не найдены",
		date_joined: "День регистрации",
		daily_limit_reached: "Вы исчерпали дневной лимит",
		daily_total_amount: "Дневная сумма",
		daily_count: "Дневное количество",
		day: "День",
		days: "Дни",
		deactivate: "Отключить",
		decrease_by: "Уменьшить на",
		delay: "Задержка",
		delete: "Удалить",
		delete_message: "Удалить сообщение",
		delta: "Дельта",
		deltas: "Дельты",
		deposit: "Депозит",
		deposit_bonus: "Депозитный бонус",
		deposit_confirmation_pending:
			"Вы не можете выдавать чаевых, делать вывод, посылать дожди или играть игры от проваидеров когда депозит не потверждён",
		deposit_confirmed: "Депозит потверждён",
		deposits: "Депозиты",
		deposit_success: "Успешный депозит, пожалуйста подождите, нужна 1 конфирмация",
		description: "Описание",
		dice: "Кости",
		disabled: "Выключены",
		disposable: "Одноразовый",
		divide_amount: "Разделить сумму",
		do_not_lose_chance_to_activate_our_deposit_bonuses: "Не упусти шанс чтоб активировать наши депозитные бонусы",
		double_check_rates:
			"Пожалуйста, проверьте, соответствует ли текущий обменный курс вашим потребностям, так как обмен является необратимым действием.",
		duration: "Длительность",
		edit: "Редактировать",
		edit_message: "Редактировать сообщение",
		edited: "Редакт.",
		embed_code: "Встроенный код (для вебсаитов)",
		enabled: "Включены",
		enter_address: "Введите адрес",
		enter_amount: "Введите сумму",
		enter_tag: "Введите таг",
		enter_delta_amount: "Введите дельту",
		enter_password: "Введите пароль",
		enter_token: "Введите 2фа токен",
		entry_not_found: "Запись не найдена",
		entry_add: "Добавить запись",
		entry_already_exists: "Такая запись уже существует",
		entry_added: "Запись добавлена",
		entry_remove: "Удалить запись",
		entry_removed: "Запись удалена",
		entry_update: "Обновить запись",
		entry_updated: "Запись обновлена",
		error: "Ошибка",
		every: "Каждый",
		every_losses: "Каждые проигрыши",
		every_wins: "Каждые выигрыши",
		exchange: "Обмен монет",
		exchange_from: "Обмен от",
		exchange_to: "Обмен в",
		exchange_management: "Управление обменом",
		exchanges: "Обмены монет",
		exchange_success: "Вы успешно произвели обмен монет",
		expired: "Недействительный",
		fa_if_enabled: "2FA (если активирован)",
		failed: "Ошибка",
		fairness: "Честность",
		fairness_check: "Проверить честность",
		faq: "Чаво",
		faucet: "Кран",
		faucet_enable: "Разрешить кран",
		faucet_digits_count: "Количество угадываемых чисел крана",
		faucet_disabled: "В данное время кран отключён",
		faucet_abuse: "Из-за неправильного использования крана, некоторые ограничения были задействованы на вашу учётную запись",
		faucet_claimed: "Кран успешно задействован",
		faucet_claimed_recently: "Вы недавно запросили кран и должны подождать перед следующим запросом",
		faucet_claims: "Доход с крана",
		faucet_farmer_ignore_admin: "Проигнорировать злоупотребление крана администраторами",
		faucet_farmer_ignore_mod: "Проигнорировать злоупотребление крана модераторами",
		faucet_farmers: "Злоупотребители крана",
		faucet_farming_msg: "Из-за неправильного использования крана запрошенная сумма уменьшена",
		faucet_management: "Управление краном",
		faucet_not_claimed: "Не удалось запросить пополнение баланса из крана (баланс должен быть = 0)",
		faucets_in_hour: "Количество запросов на кран в течении часа чтоб посчитать игрока кран-фермером",
		field_blank: "Поле не может быть пустым",
		filter: "Фильтр",
		fingerprint: "Отпечаток",
		fingerprints: "Отпечатки",
		finished: "Завершён",
		finish_to_reveal: "Завершите игру чтоб раскрыть сид",
		first_deposit: "Первый депозит",
		flags: "Флаги",
		food_and_drink: "Еда и напитки",
		for: "для",
		forever: "Навсегда",
		formula: "Формула",
		forum: "Форум",
		fp: "отпечаток",
		forgot_password: "Забыли пароль",
		friend_request_sent: "Запрос на добавление в друзья отправлен",
		friend: "Друг",
		friends: "Друзья",
		free_play: "Демо",
		frequently_used: "Часто используемые",
		from: "От",
		game: "Игра",
		game_added_to_favourites: "Игра добавилась в фаворитные",
		game_removed_from_favourites: "Игра удалена из фаворитных",
		game_provider: "Производитель игр",
		games: "Игры",
		game_balance: "Игровой баланс",
		gambling_forbidden: "и азартные игры не запрещены местными властями и мне не меньше 18 лет.",
		generate_new: "Сгенерировать новый",
		general_info: "Общие данные",
		geographical_data: "Географические данные",
		gfa_cleared_successfully: "Google 2фа успешно сброшен",
		gfa_data_not_found: "Не найдена запись активации 2фа для данного пользователя",
		gfa_management: "Управление Google 2фа",
		gfa_on: "Google 2фа активирован",
		gfa_off: "Google 2фа деактивирован",
		gfa_reset: "Сброс Google 2фа",
		gfa_set: "2фа установлен",
		granted: "Разрешено",
		guests: "Гости",
		h: "чс",
		hasLower: "Содержит маленькие буквы",
		hasNumber: "Содержит числа",
		hasSymbol: "Содержит символы",
		hasUpper: "Содержит большие буквы",
		history: "История",
		hour: "Час",
		hours: "Часы",
		high_rollers: "Высокие ставки",
		home: "Главная",
		hotkeys: "Горячие клавишы",
		hotkeys_are_currently: "Горячие клавишы в данный момент",
		hunt: "Охота",
		hunt_number: "Искомое число",
		hunt_number_pool: "Пул чисел для охоты",
		id: "ид",
		ignore: "Игнорировать",
		ignored_players: "Игнорированные игроки",
		img: "Картинка",
		in_a_row: "Подряд",
		income: "Доход",
		increase_by: "Увеличить на",
		inc_by: "Увел. на",
		info: "Инф.",
		infinite: "Вечно",
		inputs: "Входы",
		insufficient_funds: "Недостаточно средств",
		insufficient_privileges: "Недостаточно прав [{0}]",
		invalid_address: "Неправильный адрес",
		invalid_amount: "Неправильная сумма",
		invalid_arguments: "Неправильные аргументы",
		invalid_bet_amount: "Неправильная ставка",
		invalid_currency: "Неверная валюта",
		invalid_ip_fp: "Пользователь с таким же ип адресом и отпечатком уже существует",
		invalid_login_information: "Неверные параметры входа",
		invalid_mail: "Неправильный почтовой адрес",
		invalid_password: "Неправильный пароль",
		invalid_password_or_2fa: "Неправильный пароль или 2фа",
		invalid_time: "Неправильное время",
		invalid_token: "Неправильный токен",
		invalid_username: "Неправильное имя пользователя",
		invalid_wallet_password: "Неправильный пароль кошелька",
		investment_required: "Необходимое количество вклада",
		ip: "ип",
		ip_fp_change_detected: "Обнаружено изменение ип адреса / отпечатка, пожалуйста, заново войдите в систему",
		is_online: "Онлаин",
		is_offline: "Офлаин",
		jackpot: "Джекпот",
		jackpot_management: "Управление джекпотом",
		jackpots: "Джекпоты",
		join: "Регистрация",
		join_price: "Цена участия",
		key_expired: "Ваш ключ истёк",
		leaderboard: "Таблица лидеров",
		let_others_know: "Дать знать другим",
		level: "Уровень",
		live: "Лайв",
		loading: "Загрузка",
		lobby: "Фойе",
		login: "Вход",
		login_first: "Пожалуйста, сперва пройдите авторизацию",
		logout: "Выйти",
		lose: "Проигрыш",
		loss: "Убыток",
		losses: "Проигрыши",
		luck: "Удача",
		lucky_number: "Удачное число",
		lucky_number_today: "Удачное число дня",
		lucky_players_are: "Успешные игроки",
		mail: "Почта",
		mail_verified: "Почта подтверждена",
		mail_confirmed: "Спасибо, ваша почта подтверждена",
		mail_management: "Управление почтой",
		mail_not_confirmed: "Почта не подтверждена",
		mail_not_set: "Почта не установлена",
		mail_set: "Почтовой адрес установлен, конфирмация отправлена на",
		mail_not_found: "Почта не найдена",
		manual: "Вручную",
		management: "Управление",
		marked_for_delete: "Помечен для удаления",
		max: "Макс",
		max_bet: "Макс ставка",
		max_amount: "Максимальная сумма",
		menu: "Меню",
		message: "Сообщение",
		message_deleted: "Сообщение удалено",
		min: "Мин",
		min_amount: "Минимальная сумма",
		min_amount_not_matched: "Минимальная сумма не соответствует",
		min_exchange_amount: "Минимальная сумма обмена",
		min_left: "Ост. {0} мин",
		min_level_1: "Вы не можете общаться в чате, пока не достигнете 2-го уровня",
		minutes: "Минут",
		minimal_exhcange_amount_is: "Минимальная сумма обмена составляет",
		minimal_faucet_amount_is: "Полученная сумма с крана составляет",
		minimal_withdraw_amount_is: "Минимальная сумма снятия составляет",
		min_level_not_reached: "Мин уровень не достигнут",
		min_level_required: "Требуется мин. уровень",
		min6: "Мин {0} символов",
		minute: "Мин",
		moderator: "Модератор",
		moderator_profit: "Прибыль модератора",
		moderators: "Модераторы",
		money: "Деньги",
		monitor: "Мониторинг",
		multiplier: "Мультипликатор",
		multiply_amount: "Умножить сумму",
		mute: "Заглушить",
		mute_sm: "Заглуш.",
		muted: "Заглушён",
		my_bets: "Мои ставки",
		my_position: "Моё место",
		my_recovery_mail: "Моя почта",
		my_wager: "Мой вагер",
		n_days: "{0} дней",
		n_hours: "{0} часов",
		n_minutes: "{0} минут",
		n_weeks: "{0} недель",
		n_months: "{0} месяцев",
		name: "Имя",
		no: "Нет",
		no_available_actions: "Нет доступных действий",
		no_active_contests: "Нет активных турниров",
		no_content_found: "Нет данных",
		no_pending_contests: "Нет турниров в ожидании",
		no_expired_contests: "Нет истёкших турниров",
		new: "Новый",
		news: "Новости",
		new_message: "новое сообщение",
		new_messages: "новых сообщения",
		new_password: "Новый пароль",
		new_password_min6: "Новый пароль (мин. 6 символов)",
		no_data_found: "Найдено 0 данных",
		no_limit: "Без лимита",
		no_recurrence: "Без повторения",
		not_allowed_by_system: "Не разрешено системой",
		not_enough_players: "Не достаточно игроков",
		not_implemented: "Данная функция не разработана",
		not_logged_in: "Пожалуйста войдите в систему для выполнения данной операции",
		not_revealed_yet: "Сид пока не раскрыт",
		notes: "Заметки",
		notifications: "Уведомления",
		number_of_players: "Количество игроков",
		number_of_roll_limit_reached: "Лимит количества бросков достигнут",
		number_of_rolls: "Кол. бросков",
		objects: "Объекты",
		offline: "Невидимка",
		old_password: "Старый пароль",
		only_with_balance: "Только с балансом",
		optional: "Необязательно",
		of_points: "{0} из {1} очков",
		of_points_total: "Всего {0} из {1} очков",
		off: "Выкл",
		on: "Вкл",
		on_loss: "При проигрыше",
		on_win: "При выигрыше",
		online: "Сейчас активен",
		only: "Только",
		opened: "Открыт",
		or: "Или",
		org: "Орг",
		outcome: "Исход",
		outputs: "Выходы",
		overall_stats: "Вся",
		overall_data: "Исторический данные",
		overall_limit: "Общий лимит",
		pandora: "Пандора",
		page_not_found: "Страница не найдена",
		page_not_found_desc: "К сожалению, страница, которую вы ищете, не существует.",
		pages: "Страницы",
		packet_lost_retrying: "Потеря пакета, пробуем заново...",
		parameters: "Параметры",
		participants: "Участники",
		participate: "Участвовать",
		participate_in: "Участвовать в",
		password: "Пароль",
		password_2fa_change_detected: "Обнаружена смена пароля / 2фа",
		password_reset: "Сброс пароля",
		password_reset_instruction: "На вашу почту была выслана инструкция по сбросу пароля",
		password_management: "Управление паролем",
		password_at_least: "Длина пароля должна быть как минимум 6 символов",
		passwords_not_match: "Несовпадение паролей",
		password_not_set: "Пароль не установлен",
		password_reset_successfully: "Пароль успешно брошен",
		password_set: "Пароль установлен",
		password_strength: "Сложность пароля",
		password_changed: "Пароль поменялся",
		passwords_match: "Пароли совпадают",
		pause: "Приостановить",
		paused: "Приостановленный",
		payout: "Выплата",
		pending: "В ожидании",
		pending_deposit: "В ожидании депозита",
		permissions: "Права",
		permissions_management: "Управление доступом",
		ping: "Пинг",
		place: "Место",
		place_bet: "Сделать ставку",
		play: "Играть",
		play_now: "Играть",
		player: "Игрок",
		players: "Игроки",
		player_has_been_muted: "Вы успешно заглушили игрока",
		player_has_been_unblocked: "Игрок разблокирован",
		player_not_selected: "Игрок не выбран",
		please_retry: "Пожалуйста, повторите попытку",
		pm: "ПС",
		points_to_next_level: "Очков до следующего уровня",
		previous_seed_pair: "Предыдущая пара сидов",
		privacy: "Политика конфиденциальности",
		prize_pool: "Приз",
		priority: "Приоритет",
		probability: "Вероятность",
		processed: "Обработан",
		promotion: "Промо",
		profile: "Профиль",
		profit: "Прибыль",
		profit_from_jackpot: "Прибыль полученная от джекпота",
		profit_from_rollhunt: "Прибыль полученная от охоты бросков",
		profit_on_win: "Пр. при выигрыше",
		provide_password: "Введите пароль",
		public_chat_rooms: "Доступные комнаты",
		qr_error: "Ошибка при создании QR картинки",
		qr_expired: "QR истёк",
		quantity: "Количество",
		rain: "Дождь",
		rain_amount: "Количество дождя",
		rain_management: "Управление дождём",
		rain_received: "Вы попали под дождь",
		rain_sent: "Вы вызвали дождь",
		rains_sent: "Отпр. дожди",
		rain_out: "Отпр. дожди",
		rains_received: "Прин. дожди",
		rain_in: "Прин. дожди",
		randomize: "Случайный отбор",
		randomise_to_reveal: "Сделать случайный отбор для отображения",
		randomization_error: "Ошибка при смене клиентского сида",
		randomization_failed: "Ошибка при случайном отборе",
		randomization_success: "Сид клиента успешно поменялся",
		ratios: "Коэффициенты",
		read_mode: "Режим чтения",
		read_more: "Читать дальше",
		reason: "Причина",
		recurrence: "Повторение",
		recent: "Недавно используемые",
		reconnect: "Переподключиться",
		recover: "Восстановить",
		recovery_password_sent: "Пароль для восстановления отправлен",
		ref_by: "Реф. от:",
		ref_count: "Количество рефералов",
		referral_link: "Реферальная ссылка",
		refresh: "Обновить",
		reg_date: "День регистрации",
		region: "Регион",
		registration: "Регистрация",
		registration_successful: "Успешная регистрация",
		reject: "Отклонить",
		rejected: "Отклоненный",
		remove_from_friends: "Удалить из друзей",
		required_field: "Обязательное поле",
		resend_confirmation_link: "Послать ссылку для активации",
		reset: "Сброс",
		reset_password: "Сброс пароля",
		result: "Результат",
		to_base: "K базе",
		relative_balance_account: "Соответствующий балансный счёт",
		relative_bank_account: "Соответствующий банковский счёт",
		reload: "Обновить",
		reload_page: "Произошло обновление, пожалуйста, обновите страницу",
		return_to_lobby: "Вернуться в лобби",
		reverse: "Реверс",
		revoked: "Запрещено",
		roll: "Бросок",
		rolls: "Броски",
		roll_dice: "Бросить кости",
		roll_hunt: "Охота бросков",
		roll_over: "Выше",
		roll_under: "Ниже",
		room: "Комната",
		room_create: "Создать комнату",
		room_name: "Название комнаты",
		room_not_active: "Комната не активна",
		rooms: "Комнаты",
		rooms_management: "Управление комнатами",
		save: "Сохранить",
		search: "Поиск",
		search_game: "Поиск игр",
		sec: "Сек",
		set_bet_amount: "Пожалуйста поставьте ставку",
		security: "Безопасноть",
		seconds: "Секунды",
		seconds_remaining: "осталось секунд",
		select_player: "Выберите игрока",
		select_coin: "Выберите монету",
		send: "Отправить",
		send_pm: "Отправить ПС",
		send_tip: "Отправить чаевые",
		series: "Серии",
		server_error: "Серверная ошибка",
		server_seed: "Серверный сид",
		server_seed_changed: "Серверный сид поменялся",
		server_sha_512: "Хеш (sha512) серверного сида",
		server_time: "Время сервера",
		set: "Установить",
		set_mail: "Установка почты",
		set_password: "Установка пароля",
		set_password_first: "Сперва установите пароль",
		set_recovery_mail: "Установка почты",
		settings: "Параметры",
		settings_saved: "Параметры сохранены",
		shortcuts: "Меню быстрого вызова",
		show_more: "Показать больше",
		sign_in: "Войти",
		simple_balance: "Простой баланс",
		site_to_be_shutdown: "Сайт будет обновляться, весь игровой процесс остановлен",
		skip: "Пропустить",
		slider: "Ползунок",
		slots: "Слоты",
		slow_down: "Слишком много запросов",
		smileys_and_people: "Смаилы и люди",
		something_went_wrong: "Серверная ошибка",
		show_in: "Показать в {0}",
		sound: "Звук",
		speed: "Скорость",
		sport: "Спорт",
		split: "Деление",
		stable: "Стабильный",
		stats: "Статистика",
		statistics: "Статистика",
		starting_point: "Нач. Точка",
		starts_after: "Начинается через",
		status: "Статус",
		stop: "Стоп",
		stop_on_loss: "Задействован стоп при проигрыше",
		stop_on_win: "Задействован стоп при выигрыше",
		stop_on_bet_amount_over: "Задействован стоп при [ставке больше] указанной величины",
		stop_on_balance_under: "Задействован стоп при [балансе меньше] указанной величины",
		stop_on_balance_over: "Задействован стоп при [балансе больше] указанной величины",
		stop_on_bet_amount_under: "Задействован стоп при [ставке меньше] указанной величины",
		stop_on_number_of_rolls: "Задействован стоп при [количестве ставок]",
		stop_if_over: "Остановить если больше",
		stop_if_under: "Остановить если меньше",
		stop_if_balance_under: "Остановить если баланс меньше",
		stop_if_balance_over: "Остановить если баланс больше",
		stop_if_bet_amount_over: "Остановить если величина ставки больше",
		streak: "Серия",
		submit: "Внести",
		support: "Помощь",
		symbols: "Символы",
		system: "Система",
		system_management: "Системное управление",
		the_most_popular: "Самый справедливый и надёжный крипто игровой портал",
		tags: "Таги",
		tc: "Условия и положения",
		time: "Время",
		time_left: "Оставшееся время",
		time_from: "Время (от)",
		time_to: "Время (до)",
		timezone: "Врем.зона",
		tip: "Чаевые",
		tips: "Чаевые",
		tip_amount: "Количество чаевых",
		tip_management: "Управление чаевыми",
		tips_receive: "Получ. чаевых",
		tip_received: "Вы получили чаевые",
		tip_sent: "Чаевые отправлены",
		tips_send: "Отпр. чаевых",
		tip_not_sent: "Не удалось отправить чаевые",
		tips_sent: "Отпр. чаевые",
		tips_received: "Прин. чаевые",
		title: "Название",
		to: "До",
		to_withdraw_solve_captcha: "Для того чтобы сделать вывод разрешите капчу",
		too_many_requests: "Слишком много запросов",
		toggle_jackpot: "Переключение джекпота",
		toggle_fa_mode: "Переключение 2FA",
		toggle_rollhunt: "Переключение охоты",
		toggle_slider: "Переключение ползунка",
		top_250_rain: "Топ 250 по шансу попадания в дождь",
		token: "Токен",
		token_expired: "Ваш токен истёк, пожалуйста, перелогиньтесь",
		total: "Все",
		total_bets: "Всего ставок",
		total_losses: "Количество проигрышей",
		total_bets_shared: "Количество общих ставок",
		total_messages: "Количество сообщении",
		total_profit: "Вся прибыль",
		total_wagered: "Весь оборот",
		total_wins: "Количество выигрышей",
		transaction_fee: "Kомиссия на перевод",
		translate: "Перевести",
		transaction: "Транзакция",
		transactions: "Транзакции",
		transaction_complete: "Транзакция завершена",
		travel_and_places: "Места и путешествие",
		turn_on: "Включить",
		turn_off: "Выключить",
		two_factor_authentication: "2 факт. авторизация (2FA)",
		type: "Тип",
		type_numbers_only: "Вводите только цифры",
		type_a_message: "Введите сообщение",
		type_your_mail: "Введите ваш почтовый адрес",
		unblock_sm: "Разбл.",
		unclaimed_profit: "Незатребованная прибыль",
		unexpected_error: "Случилась неожидаемая ошибка",
		unignore: "Снять игнор",
		unlimited: "Без лимитный",
		unread: "Не прочитано",
		unstable: "Не стабильный",
		upload_error: "Ошибка при загрузке фаила",
		upload_success: "Фаил успешно загружён",
		usd_change_is_shown:
			"Обратите внимание, что общий баланс в долларах США отображается соответственно с действующим курсом на момент совершения транзакции",
		user: "Пользователь",
		user_name: "Имя пользователя",
		username_already_taken: "Имя пользователя уже изпользуется",
		username_min6: "Имя пользователя (мин. 6 символов)",
		username_not_found: "Имя пользователя не найдено",
		usernames: "Имена пользователей",
		values_are_based_on_ranking: "Значения в зависимости от уровня",
		verify: "Проверить",
		verified: "Подтвержденный",
		verification_failed: "Проверка не удалась",
		view_profile: "Посмотреть профиль",
		vip_chat_rooms: "VIP комнаты",
		virtual_sport: "Вирт. Спорт",
		w_requests: "Запр. на вывод",
		wager: "Оборот",
		wagering_contest: "Конкурс по обороту",
		waiting_time: "Время ожидания",
		wallet_not_found: "Кошелёк не найден",
		was_read_on: "Прочитано {0}",
		websockets_support: "Ваш браузер не поддерживает техонологию 'websockets'",
		welcome: "Добро пожаловать",
		who_is_online: "Кто активен",
		win: "Выигрыш",
		wins: "Выигрыши",
		win_chance: "Вероятность выигрыша",
		winner: "Победитель",
		with_mail: "С почтой",
		withdraw: "Вывод",
		withdraw_approved: "Вывод утверждён",
		withdraw_rejected: "Вывод отклонён",
		withdraw_min: "Мин. сумма вывода",
		withdraw_error: "Ошибка при снятии суммы, пожалуйста обратитесь в суппорт",
		withdraw_success: "Сумма успешно снялась",
		withdraws: "Выводы",
		yes: "Да",
		you_are_ignored: "Невозможно добавить в друзья, так как игрок вас заигнорировал",
		you_are_not_participating_in_any_campaign: "Вы не участвуете в промо акциях",
		you_are_offline: "Вы не в сети",
		you_got: "Вы получили",
		you_have_been_muted_by: "Вас временно заглушил",
		you_must_cancel_running_deposit_bonus_to_proceed_with_the_current_action:
			"Для того чтобы продолжить данное действие, вы должны отменить текущий депозитный бонус",
		you_pay: "Вы платите",
		you_wagered: "Ваш оборот",
		you_were_tagged: "Вы были упомянуты",
		you_wrote: "Вы написали",
		your_place: "Ваше место",
		you_receive: "Вы получаете",
		you_tried_too_many_times: "Вы уже пытались много раз, пожалуйста попытайтесь попозже",
		your_comment: "Ваш комментарий",
		your_hunt_number_pool_is_empty: "Ваш пул для охоты пуст",
		your_recovery_mail: "Ваша почта",
		your_withdrawal_will_be_sent: "Вы получите снятую сумму сразу же, как только на вашем счету не будут не подтверждённых депозитов",
		zip: "Зип",

		multi_account: "Мульти аккаунт",
		rude_words: "Ругательство, мат",
		cheating: "Мошенничество",
		faucet_farming: "Злоупотребление краном",
		rain_farming: "Злоупотребление дождём",
		player_insulting: "Оскорбление игрока",
		moderator_insulting: "Оскорбление модератора",
		ignoring_moderator_warning: "игнорирование предупреждений модератора",
		asking_for_tips: "Требования чаевых в чате",
		flooding_spaming: "Флуд, спам",
		self_referral: "Самореферрал",
		other: "Другое",

		activities_hunting_level: "Уровень других мероприятии",
		betting_level: "Уровень ставок",
		chat_activity_level: "Уровень активности чата",
		contest_activity_level: "Уровень активности конкурса",
		jackpot_hunting_level: "Уровень охоты на джекпот",
		loyalty_level: "Уровень лояльности",
		rainmaker_level: "Уровень дождей",
		referrals_betting_level: "Уровень ставок рефералов",
		referring_level: "Реферальный уровень",
		wagering_level: "Уровень по вагериту",
		warn_level: "Уровень наказаний",

		activities_hunting_level_d: "Зависит от общего количества разных выигранных мероприятии. Максимум {0} очков ({1} мероприятии).",
		betting_level_d: "Зависит от общего количества ставок. Максимум {0} очков ({1} ставок).",
		chat_activity_level_d: "Зависит от общего количества сообщений в чате. Максимум {0} баллов ({1} сообщений).",
		contest_activity_level_d: "Зависит от общего числа выигранных конкурсов. Максимум {0} баллов ({1} конкурсов).",
		jackpot_hunting_level_d: "Зависит от общего количества выигранных джекпотов. Максимум {0} очков ({1} джекпотов).",
		loyalty_level_d: "Зависит от общего количества дней, прошедших с даты регистрации. Максимум {0} баллов ({1} дней).",
		rainmaker_level_d: "Зависит от общего количества проведенных дождей. Максимум {0} баллов ({1} дождей).",
		referrals_betting_level_d: "Зависит от общего количества ставок рефералов. Максимум {0} очков ({1} ставок).",
		referring_level_d: "Зависит от общего количества рефералов. Максимум {0} баллов ({1} рефералов).",
		wagering_level_d: "Зависит от общей суммы ставок. Максимум {0} очков ({1} USD).",
		warn_level_d: "Зависит от общего количества полученных наказаний. Максимум {0} баллов ({1} наказаний )",
	},
	"fr-FR": {
		jan: "Jan",
		feb: "Fév",
		mar: "Mar",
		apr: "Avr",
		may: "Mai",
		jun: "Juin",
		jul: "Jul",
		aug: "Août",
		sep: "Sep",
		oct: "Oct",
		nov: "Nov",
		dec: "Déc",
		mon: "Lun",
		tue: "Mar",
		wed: "Mer",
		thu: "Jeu",
		fri: "Ven",
		sat: "Sam",
		sun: "Dim",
		abbrevation: "Abréviation",
		accepted_currencies: "Monnaies acceptées",
		access_code: "Code d'accès",
		access_denied: "Accès refusé",
		account: "Compte",
		action: "Action",
		actions: "Actions",
		action_not_allowed: "Action non autorisée",
		activate: "Activer",
		active: "Actif",
		active_rollhunts: "Chasseurs de rôles actifs",
		аctivities: "Activités",
		add: "Ajouter",
		add_to_friends: "Ajouter aux amis",
		address: "Adresse",
		address_create_error: "Impossible de créer une adresse",
		address_created: "Adresse créée",
		address_not_generated_yet: "Adresse pas encore créée",
		administrator: "Administrateur",
		administrators: "Administrateurs",
		affiliate: "Affiliation",
		affiliation: "Affiliation",
		affiliate_casino_loss: "Perte de casino des utilisateurs référés",
		affiliate_casino_revenue: "Revenus du casino provenant des utilisateurs référés",
		aff_claims: "Réclamations d'affiliation",
		affiliate_commission_rate: "Taux actuel de commission de parrainage",
		affiliate_current_balance: "Solde actuel des utilisateurs référencés",
		affiliate_deposit_count: "Nombre de dépôts",
		affiliate_net_commission: "Commission nette",
		affiliate_referral_profit: "Bénéfice net du parrainage",
		affiliate_stats: "Statistiques de l'affilié",
		affiliate_user_count: "Nombre actuel d'utilisateurs référés",
		affiliation_management: "Gestion de l'affiliation",
		after: "Après",
		all: "Tous les",
		all_bets: "Tous les paris",
		all_in: "All-in ?",
		all_your_progress_will_be_lost: "Tous vos progrès seront perdus (dep. bonus)",
		allow_faucet_if_has_bank: "Autoriser le robinet si vous avez un solde bancaire",
		allow_faucet_if_has_other_coin_balance: "Autoriser le robinet si vous avez un solde sur une autre pièce",
		allow_rains: "Autoriser les pluies",
		already_approved_by: "Déjà approuvé par",
		already_cancelled_by: "Déjà annulé par",
		already_verified: "Entrée déjà vérifiée",
		amount: "Montant",
		amount_claimed: "Montant demandé",
		amount_not_number: "Le montant n'est pas un nombre",
		amount_per_user: "Montant par utilisateur",
		animals_and_nature: "Animaux et nature",
		approved: "Approuvé",
		are_you_sure: "Êtes-vous sûr",
		auto: "Auto",
		auto_cash_out: "Encaissement automatique",
		auto_roll: "Auto roll",
		avatar: "Avatar",
		away: "Away",
		balance: "Balance",
		balance_changed: "Solde modifié",
		balance_zero: "Le solde doit être nul",
		balance_over_limit_reached: "Solde supérieur à la limite atteinte",
		balance_under_limit_reached: "Solde inférieur à la limite atteinte",
		ban: "Interdiction",
		banned: "Interdit",
		banner: "Bannière",
		banners: "Bannières",
		before: "Avant",
		slide: "Slide",
		slides: "Diapositives",
		bank: "Banque",
		bank_zero: "Le solde de la banque doit être nul",
		bank_in: "Transfert vers la banque",
		bank_out: "Transfert depuis la banque",
		beginning_of_the_hour: "Début de l'heure",
		beginning_of_the_day: "Début de la journée",
		beginning_of_the_week: "Début de la semaine",
		beginning_of_the_month: "Début du mois",
		bet: "Pari",
		bets: "Paris",
		bet_amount: "Montant du pari",
		bet_amount_limit_reached: "Limite du montant du pari atteinte",
		bet_info: "Informations sur les paris",
		bets_made_with_pair: "Pari fait avec la paire",
		billing: "Facturation",
		block: "Bloc",
		block_hash: "Hachage de bloc",
		board: "Conseil d'administration",
		bonus: "Bonus",
		bonus_activated: "Bonus activé",
		bonus_deactivated: "Bonus désactivé",
		bonus_activation_error: "Erreur d'activation du bonus",
		bonus_claim: "Réclamer le bonus",
		bonus_won: "Félicitations, vous avez gagné le bonus !",
		busy: "Occupé",
		buy: "Acheter",
		by: "Par",
		by_clicking: "En cliquant sur le bouton ci-dessous, j'accepte les conditions suivantes",
		cash_out: "Encaisser",
		calculate_in: "Calculer en",
		campaign: "Campagne",
		cancel: "Annuler",
		cancelled: "Annulé",
		cannot_change_entry: "Impossible de modifier l'entrée",
		cannot_ignore_mod_or_admin: "Impossible d'ignorer un mod ou un administrateur",
		cause_rain: "Cause de la pluie",
		caution: "Attention",
		city: "Ville",
		clear: "Clair",
		chance: "Chance",
		change_balance: "Changer la balance",
		change_mail: "Changer le courrier",
		change_client_seed: "Changer la graine du client",
		change_password: "Changer de mot de passe",
		chat: "Chat",
		chatgame: "Jeu de chat",
		chat_game_management: "Gestion du jeu de chat",
		chat_game_statement: "Devinez un nombre de 1 à 100 inclus et gagnez un prix ! Bonne chance !",
		chat_messages: "Messages du chat",
		check_input_values: "Vérifiez les valeurs saisies",
		check_internet: "Vérifiez votre connexion internet",
		choose_coin: "Choisissez une pièce",
		claim: "Réclamer",
		clear_2fa: "Effacer 2fa",
		client_seed: "Graine de client",
		closed: "Fermé",
		code: "Code",
		coin: "Pièce",
		coin_name: "Nom de la pièce",
		coin_not_selected: "La pièce n'est pas sélectionnée",
		coins: "Pièces",
		coin_management: "Gestion des pièces",
		coin_not_active: "Pièce non active",
		coin_not_stable: "Pièce non stable",
		comment: "Commentaire",
		commission_rate: "Taux de commission",
		con_connection_error: "Erreur de connexion",
		con_connection_established: "Connexion établie",
		con_connection_lost: "Connexion perdue",
		con_reconnect_attempt: "Tentative de reconnexion : {0}",
		con_reconnect_failed: "Échec de la reconnexion",
		confirm_new_password: "Confirmez le nouveau mot de passe",
		confirm_you_are_human: "Confirmez que vous êtes un humain",
		confirmation_link_sent: "Lien de confirmation envoyé",
		confirmations_required: "Confirmations requises",
		confiscate: "Confisquer",
		confiscated: "Confisqué",
		contact_support: "Pour plus de détails, contactez le support",
		contest: "Concours",
		contests: "Concours",
		copied: "Valeur copiée dans le presse-papiers",
		copy: "Copier",
		copy_to_clipboard: "Copie dans le presse-papiers",
		country: "Pays",
		country_code: "Code pays",
		create: "Créer",
		create_an_account: "S'inscrire",
		created_by: "Créé par",
		creator_profit: "Bénéfice du créateur",
		currency: "Monnaie",
		current_balance: "Solde actuel",
		current_data: "Données actuelles",
		current_friends: "Amis actuels",
		current_rates: "Taux actuels",
		current_seed_pair: "Paire de semences actuelle",
		current_session: "actuel",
		custom: "Personnalisé",
		daily_limit: "Limite quotidienne",
		daily_limit_reached: "Limite journalière atteinte",
		daily_total_amount: "Montant total quotidien",
		daily_count: "Compte quotidien",
		data: "Données",
		data_not_found: "Données non trouvées",
		date_joined: "Date d'adhésion",
		day: "Jour",
		days: "Jours",
		deactivate: "Désactiver",
		decrease_by: "Diminuer par",
		delay: "Retarder",
		delete: "Supprimer",
		delete_message: "Supprimer le message",
		delta: "Delta",
		deltas: "Deltas",
		deposit: "Dépôt",
		deposit_bonus: "Prime de dépôt",
		deposits: "Dépôts",
		deposit_confirmation_pending:
			"Vous ne pouvez pas verser, retirer, envoyer des pluies ou jouer à des jeux tiers pendant que la confirmation du dépôt est en attente.",
		deposit_success: "Dépôt réussi, veuillez attendre 1 confirmation",
		deposit_confirmed: "Dépôt réussi",
		description: "Description",
		dice: "Dés",
		disabled: "Handicapés",
		disposable: "Jetable",
		divide_amount: "Diviser le montant",
		do_not_lose_chance_to_activate_our_deposit_bonuses: "Ne perdez pas la chance d'activer nos bonus de dépôt",
		double_check_rates: "Veuillez vérifier si le taux de change actuel correspond à vos besoins, car le change est une action irréversible.",
		duration: "Durée",
		edit: "Editer",
		edit_message: "Editer le message",
		edited: "Ed. par",
		embed_code: "Code d'incorporation (pour les sites web)",
		enabled: "Activé",
		enter_amount: "Entrer le montant",
		enter_address: "Entrer l'adresse",
		enter_tag: "Entrer le tag",
		enter_delta_amount: "Entrer le montant du delta",
		enter_token: "Entrer le jeton 2fa",
		enter_password: "Entrer le mot de passe",
		entry_not_found: "Entrée non trouvée",
		entry_add: "Ajouter une entrée",
		entry_already_exists: "Cette entrée existe déjà",
		entry_added: "Entrée ajoutée",
		entry_remove: "Supprimer l'entrée",
		entry_removed: "Entrée supprimée",
		entry_update: "Mise à jour de l'entrée",
		entry_updated: "Entrée mise à jour",
		exchange: "Échanger",
		exchange_from: "Échange de",
		exchange_to: "Exchange vers",
		exchange_management: "Gestion des échanges",
		exchanges: "Échanges",
		exchange_success: "Montant échangé avec succès",
		expired: "Expiré",
		error: "Erreur",
		every: "Chaque",
		every_losses: "Chaque perte",
		every_wins: "Tous les gains",
		fa_if_enabled: "2FA (si activé)",
		failed: "Échec",
		fairness: "Equité",
		fairness_check: "Contrôle de l'équité",
		faq: "FAQ",
		faucet: "Robinet",
		faucet_enable: "Activer le robinet",
		faucet_digits_count: "Nombre de chiffres à deviner dans le robinet",
		faucet_disabled: "Le robinet est désactivé à l'heure actuelle",
		faucet_abuse:
			"En raison de l'utilisation abusive ou de l'exploitation abusive d'un robinet, certaines limitations sont appliquées à votre compte.",
		faucet_claimed: "Le robinet a été réclamé avec succès",
		faucet_claimed_recently: "Vous avez récemment réclamé le robinet et devez attendre avant de le réclamer à nouveau.",
		faucet_claims: "Réclamations de robinets",
		faucet_farmer_ignore_admin: "Ignorer l'exploitation des robinets par les administrateurs",
		faucet_farmer_ignore_mod: "Ignorer le faucet farming par les modérateurs",
		faucet_farmers: "Agriculteurs de robinets",
		faucet_farming_msg: "En raison du faucet farming, le montant réclamé est réduit.",
		faucets_in_hour: 'Réclamations de robinets en 1 heure pour considérer le joueur comme un "faucet farmer".',
		faucet_management: "Gestion des robinets",
		faucet_not_claimed: "Impossible de réclamer un robinet",
		fbi: "FBI",
		filter: "Filtre",
		fp: "empreinte digitale",
		fingerprint: "Empreinte digitale",
		fingerprints: "Empreintes digitales",
		finished: "Terminé\n",
		finish_to_reveal: "Terminer le jeu pour révéler la graine",
		field_blank: "Le champ ne peut pas être vide",
		first_deposit: "Premier dépôt",
		flags: "Drapeaux",
		food_and_drink: "Nourriture et boisson",
		for: "pour",
		forever: "Forever",
		formula: "Formule",
		forum: "Forum",
		forgot_password: "Mot de passe oublié",
		free_play: "Jeu gratuit",
		frequently_used: "Fréquemment utilisé",
		friend_request_sent: "Demande d'ami envoyée",
		friend: "Ami",
		friends: "Amis",
		from: "De",
		game: "Jeu",
		game_added_to_favourites: "Jeu ajouté aux favoris",
		game_removed_from_favourites: "Jeu supprimé des favoris",
		game_provider: "Fournisseur de jeux",
		games: "Jeux",
		game_balance: "Balance des jeux",
		gambling_forbidden: "et les jeux d'argent ne sont pas interdits par mes autorités locales et j'ai au moins 18 ans.",
		generate_new: "Générer un nouveau",
		general_info: "Informations générales",
		geographical_data: "Données géographiques",
		gfa_cleared_successfully: "2fa effacé avec succès",
		gfa_data_not_found: "Aucune entrée d'activation 2fa n'a été trouvée",
		gfa_management: "Gestion de Google 2FA",
		gfa_reset: "2fa reset",
		gfa_set: "2fa set",
		gfa_on: "Google 2FA activé",
		gfa_off: "Google 2FA désactivé",
		granted: "Accordé",
		guests: "Invités",
		h: "h",
		hasLower: "Contient une lettre minuscule",
		hasNumber: "Contient un chiffre",
		hasSymbol: "Contient un symbole",
		hasUpper: "Contient une lettre majuscule",
		history: "Histoire",
		hour: "Heure",
		hours: "Heures",
		high_rollers: "High rollers",
		home: "Accueil",
		hotkeys: "Coups de pouce",
		hotkeys_are_currently: "Les touches de raccourci sont actuellement",
		house_edge: "Avantage de la maison",
		hunt: "Chasse",
		hunt_number: "Numéro de chasse",
		hunt_number_pool: "Pool de numéros de chasse",
		id: "Id",
		ignore: "Ignorer",
		ignored_players: "Joueurs ignorés",
		img: "Image",
		in_a_row: "Dans une rangée",
		income: "Revenu",
		inputs: "Intrants",
		increase_by: "Augmenter par",
		inc_by: "Inc. par",
		info: "Info",
		infinite: "Infini",
		insufficient_funds: "Fonds insuffisants",
		insufficient_privileges: "Insuffisance de privilèges [{0}].",
		invalid_address: "Adresse non valide",
		invalid_amount: "Montant non valide",
		invalid_arguments: "Arguments non valides",
		invalid_bet_amount: "Montant de la mise non valide",
		invalid_currency: "Devise non valide",
		invalid_ip_fp: "Le nom d'utilisateur avec cette IP et empreinte digitale existe déjà",
		invalid_login_information: "Informations de connexion non valides",
		invalid_mail: "Courriel non valide",
		invalid_password: "Mot de passe invalide",
		invalid_password_or_2fa: "Mot de passe ou 2fa non valide",
		invalid_time: "Heure non valide",
		invalid_token: "Jeton non valide",
		invalid_username: "Nom d'utilisateur non valide",
		invalid_wallet_password: "Mot de passe du portefeuille non valide",
		investment_required: "Investissement requis",
		ip: "Ip",
		ip_fp_change_detected: "Changement d'IP / d'empreinte digitale détecté, veuillez vous reconnecter.",
		is_online: "Est en ligne",
		is_offline: "Est hors ligne",
		isp: "ISP",
		jackpot: "Jackpot",
		jackpot_management: "Gestion des jackpots",
		jackpots: "Jackpots",
		join: "Rejoindre",
		join_price: "Prix d'inscription",
		key_expired: "Votre clé a expiré",
		lat: "Lat",
		leaderboard: "Tableau des leaders",
		let_others_know: "Faites savoir aux autres",
		level: "Niveau",
		live: "En direct",
		loading: "Chargement de",
		lobby: "Lobby",
		login: "Connexion",
		login_first: "S'il vous plaît, connectez-vous d'abord",
		logout: "Déconnexion",
		lon: "Lon",
		lose: "Perdre",
		loss: "Perte",
		losses: "Pertes",
		luck: "Chance",
		lucky_number: "Numéro chanceux",
		lucky_number_today: "Le numéro chanceux d'aujourd'hui",
		lucky_players_are: "Les joueurs chanceux sont",
		mail: "Mail",
		mail_verified: "Courrier vérifié",
		mail_confirmed: "Merci, votre courrier est maintenant confirmé",
		mail_management: "Gestion du courrier",
		mail_set: "Récupération du courrier établie, la confirmation a été envoyée à",
		mail_not_found: "Courrier non trouvé",
		mail_not_confirmed: "Courrier non confirmé",
		mail_not_set: "Courrier non établi",
		management: "Gestion",
		manual: "Manuel",
		marked_for_delete: "Marqué pour suppression",
		max: "Max",
		max_bet: "Mise maximale",
		max_amount: "Montant maximum",
		menu: "Menu",
		message: "Message",
		message_deleted: "Message supprimé",
		min: "Min",
		min_amount: "Montant minimum",
		min_amount_not_matched: "Montant minimum non concordant",
		min_exchange_amount: "Montant minimum d'échange",
		min_left: "{0} min restante",
		min_level_1: "Vous ne pouvez pas chatter avant d'avoir atteint le niveau 2",
		min_level_not_reached: "Niveau minimum non atteint",
		min_level_required: "Niveau minimum requis",
		min6: "min {0} caractères",
		minutes: "Minutes",
		minimal_exhcange_amount_is: "Le montant minimal d'échange est de",
		minimal_faucet_amount_is: "Le montant reçu du robinet est de",
		minimal_withdraw_amount_is: "Le montant minimal de retrait est",
		minute: "Min",
		moderator: "Modérateur",
		moderator_profit: "Bénéfice du modérateur",
		moderators: "Modérateurs",
		money: "Argent",
		monitor: "Moniteur",
		multiply_amount: "Multiplier le montant\n",
		multiplier: "Multiplicateur",
		mute: "Muet",
		mute_sm: "Muet",
		muted: "Muet",
		my_bets: "Mes paris",
		my_position: "Ma position",
		my_recovery_mail: "Mon courrier de récupération",
		my_wager: "Mon pari",
		n_days: "{0} jours",
		n_hours: "{0} heures",
		n_minutes: "{0} minutes",
		n_weeks: "{0}semaines",
		n_months: "{0}mois",
		name: "Nom",
		new: "Nouveau",
		news: "Nouvelle",
		new_message: "Nouveau message",
		new_messages: "Nouveaux messages",
		new_password: "Nouveau mot de passe",
		new_password_min6: "Nouveau mot de passe (min. 6 caractères)",
		no: "Non\n",
		no_active_contests: "Aucun concours actif trouvé",
		no_content_found: "Aucun contenu trouvé",
		no_pending_contests: "Aucun concours en attente trouvé",
		no_expired_contests: "Aucun concours expiré trouvé",
		no_available_actions: "Aucune action disponible",
		no_data_found: "Aucune donnée trouvée",
		no_limit: "aucune limite",
		no_recurrence: "Pas de récurrence",
		not_allowed_by_system: "Non autorisé par le système",
		not_enough_players: "Pas assez de joueurs",
		not_implemented: "Fonction actuelle non implémentée",
		not_logged_in: "Veuillez vous connecter pour effectuer cette action",
		not_revealed_yet: "La graine n'a pas encore été révélée",
		notes: "Notes",
		notifications: "Notifications",
		number_of_players: "Nombre de joueurs",
		number_of_roll_limit_reached: "Nombre de rouleaux limite atteint",
		number_of_rolls: "Nombre de lancers",
		objects: "Objets",
		offline: "Apparition hors ligne",
		old_password: "Ancien mot de passe",
		only_with_balance: "Seulement avec le solde",
		of_points: "{0} de {1} points",
		of_points_total: "Total {0} de {1} points",
		off: "Désactivé",
		on: "Active",
		on_loss: "Sur perte",
		on_win: "Sur victoire",
		online: "En ligne",
		only: "Seulement",
		opened: "Ouvert",
		optional: "Facultatif",
		or: "Ou",
		org: "Org",
		outcome: "Résultat",
		outputs: "Sorties",
		overall_stats: "Global",
		overall_data: "Données globales",
		overall_limit: "Limite globale",
		pandora: "Pandora",
		page_not_found: "Page non trouvée",
		page_not_found_desc: "La page que vous recherchez n'existe pas ; elle a peut-être été déplacée ou supprimée.",
		pages: "Pages",
		packet_lost_retrying: "Paquet perdu, nouvelle tentative...",
		parameters: "Paramètres",
		participants: "Participants",
		participate: "Participez à",
		participate_in: "Participer à",
		password: "Mot de passe",
		password_2fa_change_detected: "Changement de mot de passe / 2fa détecté",
		password_management: "Gestion du mot de passe",
		password_at_least: "Le mot de passe doit comporter au moins 6 caractères",
		passwords_not_match: "Les mots de passe ne correspondent pas",
		password_not_set: "Mot de passe non défini",
		password_reset: "Réinitialisation du mot de passe",
		password_reset_instruction: "L'instruction de réinitialisation du mot de passe a été envoyée à votre courrier électronique.",
		password_reset_successfully: "Mot de passe réinitialisé avec succès",
		password_set: "Mot de passe défini",
		password_strength: "La force du mot de passe",
		password_changed: "Mot de passe modifié",
		passwords_match: "Les mots de passe correspondent",
		pause: "Pause",
		paused: "En pause",
		payout: "Paiement",
		pending: "En attente",
		pending_deposit: "Dépôt en attente",
		permissions: "Permissions",
		permissions_management: "Gestion des permissions",
		ping: "Ping",
		place: "Placer",
		place_bet: "Placer un pari",
		play: "Jouer",
		play_now: "Jouer maintenant",
		player: "Joueur",
		players: "Joueurs",
		player_has_been_muted: "Le joueur a été mis en sourdine",
		player_has_been_unblocked: "Le joueur a été débloqué",
		player_not_selected: "Le joueur n'est pas sélectionné",
		please_retry: "S'il vous plaît, essayez à nouveau",
		pm: "MP",
		points_to_next_level: "Points pour le niveau suivant",
		previous_seed_pair: "Paire de têtes de série précédente",
		priority: "Priorité",
		privacy: "Politique de confidentialité",
		prize_pool: "Cagnotte",
		probability: "Probabilité",
		processed: "Traités",
		promotion: "Promotion",
		profile: "Profil",
		profit: "Profit",
		profit_from_jackpot: "Bénéfice du jackpot",
		profit_from_rollhunt: "Bénéfice de la chasse au rouleau",
		profit_on_win: "Profit sur le gain",
		provide_password: "Entrer le mot de passe",
		public_chat_rooms: "Salons de chat publics",
		qr_error: "Erreur lors de la génération de l'image QR",
		qr_expired: "QR expiré",
		quantity: "Quantité",
		rain: "Pluie",
		rain_amount: "Quantité de pluie",
		rain_management: "Gestion de la pluie",
		rain_received: "Vous avez été surpris par la pluie",
		rain_sent: "Vous avez provoqué la pluie",
		rains_sent: "Pluie envoyée",
		rain_out: "Pluies envoyées",
		rains_received: "Pluie reçue",
		rain_in: "Pluie reçue",
		randomize: "Randomiser",
		randomise_to_reveal: "Randomiser pour révéler",
		randomization_error: "Erreur lors du changement de la graine du client",
		randomization_failed: "La ranomisation a échoué",
		randomization_success: "Changement de la graine du client réussi",
		ratios: "Ratios",
		read_mode: "Mode de lecture",
		read_more: "Lire plus",
		reason: "Raison",
		recurrence: "Récurrence",
		recent: "Récent",
		reconnect: "Reconnecter",
		recover: "Récupérer",
		recovery_password_sent: "Mot de passe de récupération envoyé",
		referral_link: "Lien de renvoi",
		ref_by: "Ref. par",
		ref_count: "Nombre d'envois",
		refresh: "Rafraîchir",
		reg_date: "Date d'enregistrement",
		region: "Région",
		registration: "S'inscrire/se lever",
		registration_successful: "Enregistrement réussi",
		reject: "Rejeter",
		rejected: "Rejeté",
		remove_from_friends: "Retirer de la liste des amis",
		required_field: "Champ obligatoire",
		relative_balance_account: "Compte de solde relatif",
		relative_bank_account: "Compte bancaire relatif",
		reload: "Recharger",
		reload_page: "Le site a été mis à jour, veuillez recharger la page",
		resend_confirmation_link: "Renvoyer le lien de confirmation",
		reset: "Réinitialiser",
		reset_password: "Réinitialiser le mot de passe",
		result: "Résultat",
		to_base: "Vers la base",
		return_to_lobby: "Retour au hall",
		reverse: "Retourner à",
		revoked: "Révoqué",
		roll: "Rouleau",
		rolls: "Roulements",
		roll_dice: "Lancer des dés",
		roll_over: "Plus de",
		roll_under: "Sous",
		roll_hunt: "Chasse au rouleau",
		room: "Salle",
		room_create: "Créer une salle",
		room_name: "Nom de la salle",
		room_not_active: "La salle n'est pas active",
		rooms: "Salles",
		rooms_management: "Gestion des salles",
		save: "Sauvegarder",
		search: "Rechercher",
		search_game: "Jeu de recherche",
		sec: "Sec",
		security: "Sécurité",
		seconds: "Seconds",
		seconds_remaining: "Secondes restantes",
		select_player: "Sélectionnez un joueur",
		select_coin: "Sélectionner la pièce",
		send: "Envoyer",
		send_pm: "Envoyer un MP",
		send_tip: "Envoyer un pourboire",
		series: "Série",
		server_error: "Erreur de serveur",
		server_seed: "Graine du serveur",
		server_seed_changed: "La graine du serveur a changé",
		server_sha_512: "Graine du serveur hash sha512",
		server_time: "Heure du serveur",
		set: "Définir",
		set_bet_amount: "Veuillez régler le montant du pari",
		set_password: "Set password",
		set_password_first: "Définir le mot de passe initial",
		set_mail: "Définir le courrier",
		set_recovery_mail: "Définir un courrier de récupération",
		settings: "Paramètres",
		settings_saved: "Paramètres enregistrés",
		shortcuts: "Raccourcis",
		show_more: "Afficher plus",
		sign_in: "Se connecter",
		simple_balance: "Équilibre simple",
		site_to_be_shutdown: "Le site va être mis à jour, tout le jeu est arrêté.",
		skip: "sauter",
		slider: "Glissière",
		slots: "Machines à sous",
		slow_down: "Trop de demandes, ralentissez",
		smileys_and_people: "Smileys et personnes",
		something_went_wrong: "Quelque chose s'est mal passé",
		show_in: "Afficher dans {0}",
		sound: "Son",
		speed: "Vitesse",
		split: "Split",
		sport: "Sport",
		stable: "Stable",
		stats: "Statistiques",
		statistics: "Statistiques",
		starting_point: "Point stable",
		starts_after: "Commence après",
		status: "Statut",
		stop: "Arrêt",
		stop_on_loss: "Stop déclenché sur perte",
		stop_on_win: "Stop déclenché en cas de gain",
		stop_on_balance_under: "Arrêt déclenché si le [montant du solde est inférieur] à la limite fixée.",
		stop_on_bet_amount_over: "Stop déclenché si le [montant de la mise est supérieur] à la limite fixée.",
		stop_on_balance_over: "Stop déclenché sur [montant du solde supérieur] à la limite relevée",
		stop_on_bet_amount_under: "Stop déclenché sur [montant de la mise inférieur] à la limite relevée",
		stop_on_number_of_rolls: "Arrêt déclenché sur [nombre de rouleaux].",
		stop_if_over: "Stop si supérieur",
		stop_if_under: "Stop si inférieur",
		stop_if_balance_under: "Stop si solde inférieur",
		stop_if_balance_over: "Stop si solde supérieur",
		stop_if_bet_amount_over: "Stop si le montant de la mise est supérieur",
		streak: "Streak",
		submit: "Soumettre",
		support: "Support",
		symbols: "Symboles",
		system: "Système",
		system_management: "Gestion du système",
		the_most_popular: "Le portail de jeux en crypto-monnaie le plus équitable et le plus fiable du monde",
		tags: "Tags",
		take_seat: "Prendre place",
		tc: "Termes et conditions",
		time: "Temps",
		time_left: "Temps restant",
		time_from: "Heure de",
		time_to: "Heure de fin",
		timezone: "Fuseau horaire",
		tip: "Conseil",
		tips: "Pourboire",
		tip_amount: "Montant du pourboire",
		tip_received: "Pourboire reçu",
		tips_send: "Envoyer des pourboires",
		tip_management: "Gestion des pourboires",
		tip_not_sent: "Conseil non envoyé\n",
		tip_sent: "Conseil envoyé\n",
		tips_sent: "Conseils envoyés",
		tips_receive: "Recevoir des pourboires",
		tips_received: "Pourboires reçus",
		title: "Titre",
		to: "Vers",
		to_withdraw_solve_captcha: "Pour retirer résoudre le captcha",
		too_many_requests: "Trop de demandes",
		token: "Token",
		token_expired: "Votre jeton a expiré, veuillez vous reconnecter.",
		toggle_jackpot: "Basculer le jackpot",
		toggle_fa_mode: "Basculer le mode 2FA",
		toggle_rollhunt: "Basculer la chasse à l'homme",
		toggle_slider: "Basculer le slider",
		top_250_rain: "Top 250 par chance d'être attrapé par une pluie",
		total: "Total",
		total_bets: "Total des paris",
		total_losses: "Total des pertes",
		total_bets_shared: "Total des paris partagés",
		total_messages: "Total des messages",
		total_profit: "Bénéfices totaux",
		total_wagered: "Total des mises",
		total_wins: "Total des gains",
		transaction_fee: "Frais de transaction",
		transaction: "Transaction",
		transactions: "Transactions",
		transaction_complete: "Transaction terminée\n",
		translate: "Traduire",
		travel_and_places: "Voyages et lieux",
		turn_on: "Activer",
		turn_off: "Désactiver",
		two_factor_authentication: "Authentification à deux facteurs",
		type: "Tapez",
		type_a_message: "Tapez un message",
		type_numbers_only: "Tapez uniquement des chiffres",
		type_your_mail: "Tapez votre courrier",
		unblock_sm: "Débloquer",
		unclaimed_profit: "Bénéfice non réclamé",
		unexpected_error: "Une erreur inattendue s'est produite",
		unignore: "Désactiver",
		unlimited: "Illimité",
		unread: "Non lu",
		unstable: "Instable",
		upload_error: "Erreur lors du téléchargement du fichier",
		upload_success: "Fichier téléchargé avec succès",
		usd_change_is_shown: "Veuillez noter que la variation totale du solde en USD est indiquée avec les taux historiques.",
		user: "Utilisateur",
		user_name: "Nom de l'utilisateur",
		username_already_taken: "Nom d'utilisateur déjà pris",
		username_min6: "Nom d'utilisateur (min. 6 caractères)",
		username_not_found: "Utilisateur non trouvé",
		usernames: "Noms d'utilisateur",
		values_are_based_on_ranking: "Les valeurs sont basées sur le classement",
		verify: "Vérifier",
		verified: "Vérifié",
		verification_failed: "La vérification a échoué",
		view_profile: "Voir le profil",
		vip_chat_rooms: "Salons de chat VIP",
		virtual_sport: "Virt.Sport",
		w_requests: "Demandes de W.",
		wager: "Pari",
		wagering_contest: "Concours de pari",
		waiting_time: "Temps d'attente",
		wallet_not_found: "Portefeuille non trouvé",
		was_read_on: "A été lu sur {0}",
		websockets_support: "Votre navigateur ne supporte pas les websockets",
		welcome: "Bienvenue",
		who_is_online: "Qui est en ligne",
		win: "Gagner",
		wins: "Gagne",
		win_chance: "Chance de gagner",
		winner: "Gagnant",
		with_mail: "Par courrier",
		withdraw: "Retirer",
		withdraw_approved: "Avec. approuvé",
		withdraw_rejected: "Avec rejet",
		withdraw_min: "Montant minimum de retrait",
		withdraw_error: "Erreur de retrait, veuillez contacter l'assistance",
		withdraw_success: "Montant retiré avec succès",
		withdraws: "Retraits",
		yes: "Oui",
		you_are_ignored: "Impossible d'ajouter un ami, car le joueur cible vous a ignoré.",
		you_are_not_participating_in_any_campaign: "Vous ne participez à aucune campagne",
		you_are_offline: "Vous êtes hors ligne",
		you_have_been_muted_by: "Vous avez été mis en sourdine par",
		you_must_cancel_running_deposit_bonus_to_proceed_with_the_current_action:
			"Vous devez annuler le bonus de dépôt en cours pour poursuivre l'action en cours.",
		you_pay: "Vous payez",
		you_receive: "Vous recevez",
		you_tried_too_many_times: "Vous avez essayé trop de fois, veuillez réessayer un peu plus tard.",
		your_comment: "Votre commentaire",
		you_got: "Vous avez reçu",
		your_place: "Votre place",
		your_recovery_mail: "Votre courrier de récupération",
		you_wagered: "Vous avez parié",
		you_were_tagged: "Tu as été tagué",
		you_wrote: "Vous avez écrit",
		your_hunt_number_pool_is_empty: "Votre pool de numéros de chasse est vide",
		your_withdrawal_will_be_sent: "Votre retrait sera envoyé dès qu'il n'y aura pas de dépôts non confirmés sur votre compte.",
		zip: "Zip",
		multi_account: "Compte multiple",
		rude_words: "Mots grossiers",
		cheating: "Tricher",
		faucet_farming: "Agriculture sous la pluie",
		rain_farming: "Insultes de joueurs",
		player_insulting: "Insultes du modérateur",
		moderator_insulting: "Insultes du modérateur",
		ignoring_moderator_warning: "Ignorer les avertissements des modérateurs",
		asking_for_tips: "Demande de conseils",
		flooding_spaming: "Flooding, spaming",
		self_referral: "Auto-renvoi",
		other: "Autres",
		activities_hunting_level: "Niveau de chasse aux activités",
		betting_level: "Niveau de pari",
		chat_activity_level: "Niveau d'activité de chat",
		contest_activity_level: "Niveau d'activité du concours",
		jackpot_hunting_level: "Niveau de chasse au jackpot",
		loyalty_level: "Niveau de loyauté",
		rainmaker_level: 'Niveau "Rainmaker',
		referrals_betting_level: "Niveau de pari de parrainage",
		referring_level: "Niveau de parrainage",
		wagering_level: "Niveau des paris",
		warn_level: "Niveau d'avertissement",
		activities_hunting_level_d: "Dépend du nombre total d'activités gagnées. Maximum {0} Points ({1} activités).",
		betting_level_d: "Dépend du nombre total de paris. Maximum {0} Points ({1} paris).",
		chat_activity_level_d: "Dépend du nombre total de messages dans le chat. Maximum {0} Points ({1} messages).",
		contest_activity_level_d: "Dépend du nombre total de concours gagnés. Maximum de {0} points ({1} concours).",
		jackpot_hunting_level_d: "Dépend du nombre total de jackpots gagnés. Maximum {0} Points ({1} jackpots).",
		loyalty_level_d: "Dépend du nombre total de jours écoulés depuis la date de l'inscription. Maximum {0} Points ({1} jours).",
		rainmaker_level_d: "Dépend du nombre total de pluies effectuées. Maximum {0} Points ({1} pluies).",
		referrals_betting_level_d: "Dépend du nombre total de paris effectués. Maximum {0} Points ({1} paris).\n",
		referring_level_d: "Dépend du nombre total de parrainages. Maximum {0} Points ({1} parrainages).",
		wagering_level_d: "Dépend du montant total des mises. Maximum {0} Points ({1} USD).",
		warn_level_d: "Dépend du total des avertissements obtenus. Maximum {0} Points ({1} avertissements)",
		hide: "Cacher",
		network: "Réseau",
		not_enough_wager: "La mise est insuffisante. Mise supplémentaire requise de {0}$.",
		recommend_set_pass: "Nous vous recommandons fortement de configurer un mot de passe\n",
		recommend_set_mail: "Nous vous recommandons fortement de configurer votre messagerie",
		freespins: "Freespins",
		freespins_repeat: "Freespins répétés",
		freespin_repeat_left: "Les free spins seront crédités {0} fois toutes les 24 heures.",
		freespin_repeat_left2: "(L'activation ne se produira que si les spins gratuits actuels sont entièrement joués)",
		my_freespins: "Mes spins gratuits",
		not_available: "Non disponibles",
		deposits_made: "Compte des dépôts effectués",
		mail_must_be_verified: "Le courrier doit être vérifié",
		gamble_aware: "Jouer conscient",
		soccer: "Football",
		basketball: "Basket-ball",
		tennis: "Tennis",
		icehockey: "Hockey sur glace",
		casino: "Casino",
		"t&c": "T & C",
		community: "Communauté",
	},
	"bn-IN": {
		jan: "जनवरी",
		feb: "फ़रवरी",
		mar: "मार्च",
		apr: "अप्रैल",
		may: "मई",
		jun: "जून",
		jul: "जुलाई",
		aug: "अगस्त",
		sep: "सितम्बर",
		oct: "अक्टूबर",
		nov: "नवम्बर",
		dec: "दिसम्बर",
		mon: "सोम",
		tue: "मंगल",
		wed: "बुध",
		thu: "गुरु",
		fri: "शुक्र",
		sat: "शनि",
		sun: "रवि",
		abbrevation: "संक्षेपाक्षर",
		accepted_currencies: "स्वीकृत मुद्राएँ",
		access_code: "कोड एक्सेस करें",
		access_denied: "एक्सेस अस्वीकृत",
		account: "अकाउंट",
		action: "कार्रवाई",
		actions: "कार्रवाई",
		action_not_allowed: "कार्रवाई की अनुमति नहीं है",
		activate: "सक्रिय\t करें",
		active: "सक्रिय",
		active_rollhunts: "सक्रिय रोलहंट्स",
		аctivities: "गतिविधियाँ",
		add: "जोड़ें\t",
		add_to_friends: "दोस्तों से जुड़ें",
		address: "पता",
		address_create_error: "पता नहीं लिखा जा सका",
		address_created: "पता लिख गया",
		address_not_generated_yet: "पता अभी तक लिखा नहीं गया है",
		administrator: "प्रशासक",
		administrators: "प्रशासक",
		affiliate: "एफिलिएट करें (मिलाएँ)",
		affiliation: "एफिलिएशन",
		affiliate_casino_loss: "संदर्भित (रेफर्ड) यूजर्स से कसीनो हानि",
		affiliate_casino_revenue: "संदर्भित यूजर्स से कसीनो राजस्व",
		aff_claims: "एफिलिएशन दावा",
		affiliate_commission_rate: "वर्तमान रेफरल कमीशन दर",
		affiliate_current_balance: "संदर्भित यूजर्स का वर्तमान बैलेंस",
		affiliate_deposit_count: "डिपॉजिट्स की संख्या",
		affiliate_net_commission: "नेट कमीशन",
		affiliate_referral_profit: "नेट रेफरल लाभ ",
		affiliate_stats: "एफिलिएट आँकड़े",
		affiliate_user_count: "वर्तमान में संदर्भित यूजर्स की संख्या",
		affiliation_management: "एफिलिएशन प्रबंधन",
		after: "बाद में",
		all: "सभी",
		all_bets: "सभी बेट",
		all_in: "सभी में?",
		all_your_progress_will_be_lost: "आपका किया हुआ सारा खो जायेगा (डिपॉजिट बोनस)",
		allow_faucet_if_has_bank: "अगर बैंक में बैलेंस है तो फॉसेट की अनुमति दें",
		allow_faucet_if_has_other_coin_balance: "अगर दूसरी कॉइन में बैलेंस है तो फॉसेट की अनुमति दें",
		allow_rains: "रेन की अनुमति दें",
		already_approved_by: "इनके द्वारा पहले से ही स्वीकृत",
		already_cancelled_by: "इनके द्वारा पहले से ही रद्द",
		already_verified: "प्रविष्टि पहले ही सत्यापित हो चुकी है",
		amount: "राशि",
		amount_claimed: "दावे की राशि",
		amount_not_number: "राशि कोई संख्या नहीं है",
		amount_per_user: "राशि प्रति यूजर ",
		animals_and_nature: "जानवर और प्रकृति",
		approved: "स्वीकृत",
		are_you_sure: "क्या आप सुनिश्चित हैं",
		auto: "ऑटो",
		auto_cash_out: "ऑटो कैश आउट",
		auto_roll: "ऑटो रोल",
		avatar: "अवतार",
		away: "दूर",
		balance: "बैलेंस",
		balance_changed: "बैलेंस बदल गया",
		balance_zero: "बैलेंस शून्य होना चाहिए",
		balance_over_limit_reached: "बैलेंस की अधिकतम सीमा आ गयी",
		balance_under_limit_reached: "बैलेंस की न्यूनतम सीमा आ गयी",
		ban: "प्रतिबंध",
		banned: "प्रतिबंधित",
		banner: "बैनर",
		banners: "बैनर",
		before: "पहले",
		slide: "स्लाइड",
		slides: "स्लाइड्स",
		bank: "बैंक",
		bank_zero: "बैंक बैलेंस शून्य होना चाहिए",
		bank_in: "बैंक में ट्रांसफर",
		bank_out: "बैंक से ट्रांसफर",
		beginning_of_the_hour: "घंटे की शुरुआत",
		beginning_of_the_day: "दिन की शुरुआत",
		beginning_of_the_week: "सप्ताह की शुरुआत",
		beginning_of_the_month: "महीने की शुरुआत\t",
		bet: "बेट",
		bets: "बेट्स",
		bet_amount: "बेट राशि",
		bet_amount_limit_reached: "बेट राशि की सीमा आ गई",
		bet_info: "बेट जानकारी",
		bets_made_with_pair: "जोड़ी के साथ लगाई गई बेट",
		billing: "बिलिंग",
		block: "ब्लॉक",
		block_hash: "ब्लॉक हैश",
		board: "बोर्ड",
		bonus: "बोनस",
		bonus_activated: "बोनस सक्रिय",
		bonus_deactivated: "बोनस निष्क्रिय",
		bonus_activation_error: "बोनस सक्रिय करने में त्रुटि",
		bonus_claim: "बोनस का दावा करें",
		bonus_won: "बधाई हो, आपको बोनस मिला है!",
		busy: "व्यस्त",
		buy: "खरीदना",
		by: "द्वारा",
		by_clicking: "नीचे दिए गए बटन पर क्लिक करते हुए मैं इससे सहमत हूँ",
		cash_out: "नकदी निकालना",
		calculate_in: "गणना करें",
		campaign: "अभियान",
		cancel: "कैंसिल",
		cancelled: "कैंसल हो गया",
		cannot_change_entry: "प्रविष्टि नहीं बदल सकते",
		cannot_ignore_mod_or_admin: "मोड या एडमिन की उपेक्षा नहीं की जा सकती",
		cause_rain: "कॉज रेन",
		caution: "सावधानी",
		city: "शहर",
		clear: "हटायें",
		chance: "अवसर",
		change_balance: "बैलेंस बदलें",
		change_mail: "मेल बदलें",
		change_client_seed: "ग्राहक सीड बदलें",
		change_password: "पासवर्ड बदलें",
		chat: "चैट",
		chatgame: "चैट गेम",
		chat_game_management: "चैट गेम प्रबंधन",
		chat_game_statement: "1 से 100 तक की संख्या सोचें और पुरस्कार जीतें! शुभकामनाएँ! ",
		chat_messages: "चैट मैसेज",
		check_input_values: "इनपुट मानों की जाँच करें",
		check_internet: "कृपया अपने इंटरनेट कनेक्शन की जाँच करें",
		choose_coin: "कॉइन चुनें",
		claim: "दावा\t",
		clear_2fa: "2fa हटायें",
		client_seed: "क्लाइंट सीड",
		closed: "बंद हो गया",
		code: "कोड",
		coin: "कॉइन",
		coin_name: "कॉइन का नाम",
		coin_not_selected: "कॉइन नहीं चुनी है",
		coins: "कॉइन",
		coin_management: "कॉइन प्रबंधन",
		coin_not_active: "कॉइन सक्रिय नहीं है",
		coin_not_stable: "कॉइन स्थिर नहीं है",
		comment: "टिप्पणी",
		commission_rate: "कमीशन दर",
		con_connection_error: "कनेक्शन में त्रुटि",
		con_connection_established: "कनेक्शन स्थापित",
		con_connection_lost: "कनेक्शन टूट गया",
		con_reconnect_attempt: "पुनः कनेक्ट करने का प्रयास: {0} ",
		con_reconnect_failed: "फिर से कनेक्ट करना विफल रहा",
		confirm_new_password: "नए पासवर्ड की पुष्टि करें",
		confirm_you_are_human: "पुष्टि करें कि आप एक इंसान हैं",
		confirmation_link_sent: "पुष्टिकरण लिंक भेज दिया गया है",
		confirmations_required: "पुष्टि की आवश्यकता है",
		confiscate: "जब्त",
		confiscated: "जब्त कर लिया",
		contact_support: "अधिक जानकारी के लिए सहायता से संपर्क करें ",
		contest: "प्रतियोगिता\t",
		contests: "प्रतियोगिताएँ",
		copied: "वैल्यू क्लिपबोर्ड पर कॉपी कर लिया ",
		copy: "कॉपी",
		copy_to_clipboard: "क्लिपबोर्ड पर कॉपी करें\t",
		country: "देश",
		country_code: "देश का कोड",
		create: "बनाएँ",
		create_an_account: "साइन अप करें",
		created_by: "इनके द्वारा बनाई गई",
		creator_profit: "निर्माता का लाभ",
		currency: "मुद्रा",
		current_balance: "वर्तमान बैलेंस",
		current_data: "वर्तमान डेटा",
		current_friends: "वर्तमान दोस्त",
		current_rates: "वर्तमान दरें",
		current_seed_pair: "वर्तमान सीड जोड़ी",
		current_session: "वर्तमान",
		custom: "कस्टम",
		daily_limit: "दैनिक सीमा",
		daily_limit_reached: "दैनिक सीमा पूरी हो गई",
		daily_total_amount: "दैनिक कुल राशि",
		daily_count: "दैनिक गणना",
		data: "डेटा",
		data_not_found: "डेटा नहीं मिला",
		date_joined: "जुड़ने की दिनांक",
		day: "दिन",
		days: "दिन",
		deactivate: "निष्क्रिय करें\t",
		decrease_by: "कम करें",
		delay: "देरी",
		delete: "डिलीट",
		delete_message: "मैसेज डिलीट करें",
		delta: "डेल्टा",
		deltas: "डेल्टाज़",
		deposit: "डिपॉजिट",
		deposit_bonus: "डिपॉजिट बोनस",
		deposits: "डिपॉजिट्स",
		deposit_confirmation_pending:
			"यदि डिपॉजिट की पुष्टि होना अभी बाकी है तो आप टिप नहीं दे सकते, विदड्रा नहीं कर सकते, रेन नहीं भेज सकते या तीसरे पक्ष के खेल नहीं खेल सकते",
		deposit_success: "डिपॉजिट सफल हुआ, कृपया 1 पुष्टिकरण की प्रतीक्षा करें",
		deposit_confirmed: "डिपॉजिट सफल हुआ",
		description: "विवरण",
		dice: "पासा",
		disabled: "डिसएबल हो गया",
		disposable: "डिस्पोजेबल",
		divide_amount: "राशि विभाजित करें",
		do_not_lose_chance_to_activate_our_deposit_bonuses: "हमारे डिपॉजिट बोनस को सक्रिय करने का अवसर हाथ से ना जाने दें",
		double_check_rates:
			"कृपया एक बार दोबारा देख लें कि वर्तमान विनिमय दर आपकी आवश्यकताओं के अनुरूप है या नहीं क्योंकि विनिमय को वापस बदला नहीं जा सकता है।",
		duration: "अवधि",
		edit: "एडिट",
		edit_message: "मैसेज एडिट करें",
		edited: "इनके द्वारा Ed.",
		embed_code: "एम्बेड कोड (वेबसाइट के लिए)",
		enabled: "इनेबल हो गया",
		enter_amount: "राशि लिखें",
		enter_address: "पता लिखें",
		enter_tag: "टैग लिखें",
		enter_delta_amount: "डेल्टा राशि लिखें",
		enter_token: "2fa टोकन लिखें",
		enter_password: "पासवर्ड लिखें",
		entry_not_found: "प्रविष्टि नहीं मिली",
		entry_add: "प्रविष्टि जोड़ें\t",
		entry_already_exists: "ऐसी प्रविष्टि पहले से ही मौजूद है",
		entry_added: "प्रविष्टि जोड़ दी गयी है",
		entry_remove: "प्रविष्टि हटाएँ",
		entry_removed: "प्रविष्टि हटा दी गई है",
		entry_update: "प्रविष्टि अपडेट करें",
		entry_updated: "प्रविष्टि अपडेट कर दी गई है",
		exchange: "विनिमय",
		exchange_from: "से विनिमय",
		exchange_to: "में विनिमय",
		exchange_management: "विनिमय प्रबंधन",
		exchanges: "विनिमय",
		exchange_success: "सफलतापूर्वक विनिमय की गई राशि",
		expired: "समय सीमा समाप्त हो चुकी है",
		error: "त्रुटि",
		every: "प्रत्येक",
		every_losses: "प्रत्येक नुकसान",
		every_wins: "प्रत्येक जीत",
		fa_if_enabled: "2FA (यदि इनेबल हो)",
		failed: "असफल",
		fairness: "निष्पक्षता",
		fairness_check: "निष्पक्षता की जाँच",
		faq: "सामान्य प्रश्न",
		faucet: "फॉसेट",
		faucet_enable: "फॉसेट इनेबल करें",
		faucet_digits_count: "फॉसेट अनुमानित अंकों की गिनती",
		faucet_disabled: "फॉसेट अभी डिसएबल है",
		faucet_abuse: "फॉसेट के दुरुपयोग या फॉसेट फार्मिंग गतिविधियों के कारण आपके अकाउंट पर कुछ प्रतिबन्ध लगा दिए गये हैं",
		faucet_claimed: "फॉसेट का सफलतापूर्वक दावा",
		faucet_claimed_recently: "आपने अभी हाल ही में फॉसेट से दावा किया है और फिर से दावा करने से पहले आपको थोड़ी प्रतीक्षा करनी चाहिए।",
		faucet_claims: "फॉसेट के दावे",
		faucet_farmer_ignore_admin: "प्रबंधकों द्वारा की गयी फॉसेट फार्मिंग पर ध्यान ना दें",
		faucet_farmer_ignore_mod: "मॉडरेटर्स द्वारा की गयी फॉसेट फार्मिंग पर ध्यान ना दें",
		faucet_farmers: "फॉसेट फार्मर्स",
		faucet_farming_msg: "फॉसेट फार्मिंग के कारण दावा की गई राशि कम हो गई है",
		faucets_in_hour: "खिलाड़ी को फॉसेट फार्मर मानने के लिए फॉसेट 1 घंटे में दावा करता है",
		faucet_management: "फॉसेट प्रबंधन",
		faucet_not_claimed: "फॉसेट का दावा नहीं किया जा सका",
		fbi: "FBI",
		filter: "फ़िल्टर",
		fp: "फिंगरप्रिंट",
		fingerprint: "फिंगरप्रिंट",
		fingerprints: "फिंगरप्रिंट्स",
		finished: "समाप्त",
		finish_to_reveal: "सीड देखने के लिए खेल समाप्त करें",
		field_blank: "फ़ील्ड खाली नहीं रखा जा सकता",
		first_deposit: "पहला डिपॉजिट",
		flags: "फ्लैग्स",
		food_and_drink: "खाद्य और पेय",
		for: "के लिये",
		forever: "सदैव",
		formula: "फॉर्मूला",
		forum: "मंच",
		forgot_password: "पासवर्ड भूल गए",
		free_play: "मुफ्त खेलें",
		frequently_used: "अधिकांशतः काम में लिया हुआ",
		friend_request_sent: "फ्रेंड रिक्वेस्ट भेज दी",
		friend: "फ्रेंड",
		friends: "फ्रेंड्स",
		from: "से",
		game: "खेल",
		game_added_to_favourites: "खेल पसंदीदा में जोड़ दिया गया",
		game_removed_from_favourites: "खेल पसंदीदा से हटा दिया गया",
		game_provider: "खेल प्रदाता",
		games: "खेल",
		game_balance: "खेल बैलेंस",
		gambling_forbidden: "और जुआ मेरे स्थानीय अधिकारियों द्वारा प्रतिबंधित नहीं है और मेरी उम्र कम से कम 18 वर्ष है।",
		generate_new: "नया उत्पन्न करें",
		general_info: "सामान्य जानकारी",
		geographical_data: "भौगोलिक डेटा",
		gfa_cleared_successfully: "2fa सफलतापूर्वक हटा दिया",
		gfa_data_not_found: "कोई 2fa सक्रियण प्रविष्टि नहीं मिली",
		gfa_management: "Google 2FA प्रबंधन",
		gfa_reset: "2fa रीसेट",
		gfa_set: "2fa सेट",
		gfa_on: "Google 2FA सक्रिय कर दिया ",
		gfa_off: "Google 2FA निष्क्रिय कर दिया ",
		granted: "प्रदत्त",
		guests: "मेहमान",
		h: "h",
		hasLower: "लोअरकेस अक्षर है",
		hasNumber: "संख्या है",
		hasSymbol: "चिह्न है",
		hasUpper: "अपरकेस अक्षर है",
		history: "इतिहास",
		hour: "घंटा",
		hours: "घंटे",
		high_rollers: "हाई रोलर्स",
		home: "होम",
		hotkeys: "हॉटकी",
		hotkeys_are_currently: "हॉटकी वर्तमान में हैं",
		house_edge: "हाउस एज",
		hunt: "हंट करें",
		hunt_number: "हंट नंबर",
		hunt_number_pool: "हंट नंबर पूल",
		id: "आईडी",
		ignore: "अनदेखा करें",
		ignored_players: "अनदेखा किये गये खिलाड़ी",
		img: "इमेज",
		in_a_row: "एक पंक्ति में",
		income: "आय",
		inputs: "इनपुट",
		increase_by: "इतना बढ़ाएं",
		inc_by: "द्वारा Inc.",
		info: "जानकारी",
		infinite: "अनंत\t",
		insufficient_funds: "अपर्याप्त कोष",
		insufficient_privileges: "अपर्याप्त विशेषाधिकार [{0}] ",
		invalid_address: "अमान्य पता\t",
		invalid_amount: "अमान्य राशि",
		invalid_arguments: "अमान्य तर्क",
		invalid_bet_amount: "अमान्य बेट राशि",
		invalid_currency: "अमान्य मुद्रा ",
		invalid_ip_fp: "ऐसे आईपी और फ़िंगरप्रिंट वाला यूजरनेम पहले से मौजूद है",
		invalid_login_information: "अमान्य लॉगिन जानकारी",
		invalid_mail: "वैध ईमेल नहीं है",
		invalid_password: "अमान्य पासवर्ड",
		invalid_password_or_2fa: "अमान्य पासवर्ड या 2fa ",
		invalid_time: "अमान्य समय",
		invalid_token: "अमान्य टोकन",
		invalid_username: "अमान्य यूजरनेम",
		invalid_wallet_password: "अमान्य वॉलेट पासवर्ड ",
		investment_required: "निवेश की आवश्यकता है",
		ip: "आईपी",
		ip_fp_change_detected: "आईपी / फ़िंगरप्रिंट में बदलाव दिखा है, कृपया पुनः लॉगिन करें",
		is_online: "ऑनलाइन है\t",
		is_offline: "ऑफलाइन है\t",
		isp: "ISP",
		jackpot: "जैकपॉट",
		jackpot_management: "जैकपॉट प्रबंधन",
		jackpots: "जैकपॉट्स",
		join: "जुड़ें",
		join_price: "प्राइस से जुड़ें",
		key_expired: "आपकी की की अवधि समाप्त हो गई है",
		lat: "Lat",
		leaderboard: "लीडरबोर्ड",
		let_others_know: "दूसरों को बताएँ",
		level: "लेवल",
		live: "लाइव",
		loading: "लोड हो रहा है",
		lobby: "लॉबी",
		login: "लॉग इन करें\t",
		login_first: "कृपया पहले लॉग इन करें",
		logout: "लॉग आउट",
		lon: "Lon",
		lose: "हार",
		loss: "नुकसान",
		losses: "नुकसान",
		luck: "भाग्य\t",
		lucky_number: "लकी नंबर",
		lucky_number_today: "आज का लकी नंबर",
		lucky_players_are: "भाग्यशाली खिलाड़ी हैं",
		mail: "मेल",
		mail_verified: "मेल सत्यापित",
		mail_confirmed: " धन्यवाद, अब आपकी मेल कन्फर्म हो गयी है",
		mail_management: "मेल प्रबंधन",
		mail_set: "रिकवरी मेल सेट हो गयी, पुष्टिकरण इनको भेज दिया गया है",
		mail_not_found: "मेल नहीं मिली",
		mail_not_confirmed: "मेल की पुष्टि नहीं हुई",
		mail_not_set: "मेल सेट नहीं है",
		management: "प्रबंधन",
		manual: "नियमावली\t",
		marked_for_delete: "डिलीट करने के लिए चिह्नित ",
		max: "अधिकतम",
		max_bet: "अधिकतम बेट",
		max_amount: "अधिकतम राशि",
		menu: "मेनू",
		message: "मैसेज",
		message_deleted: "मैसेज डिलीट कर दिए",
		min: "न्यूनतम",
		min_amount: "न्यूनतम राशि",
		min_amount_not_matched: "न्यूनतम राशि का मिलान नहीं हुआ",
		min_exchange_amount: "न्यूनतम विनिमय राशि",
		min_left: "{0} मिनट शेष ",
		min_level_1: "लेवल 2 तक पहुँचे बिना आप चैट नहीं कर सकते",
		min_level_not_reached: "न्यूनतम लेवल नहीं आया",
		min_level_required: "आवश्यक न्यूनतम लेवल",
		min6: "न्यूनतम {0} वर्ण",
		minutes: "मिनट\t",
		minimal_exhcange_amount_is: "न्यूनतम विनिमय राशि है ",
		minimal_faucet_amount_is: "फॉसेट से प्राप्त राशि है ",
		minimal_withdraw_amount_is: "न्यूनतम विदड्रावल राशि है ",
		minute: "न्यूनतम",
		moderator: "मॉडरेटर",
		moderator_profit: "मॉडरेटर का लाभ",
		moderators: "मॉडरेटर्स",
		money: "पैसे",
		monitor: "निगरानी करना",
		multiply_amount: "राशि बढ़ाना",
		multiplier: "गुणक",
		mute: "म्यूट",
		mute_sm: "म्यूट",
		muted: "म्यूट कर दिया",
		my_bets: "मेरी बेट्स",
		my_position: "मेरा स्थान",
		my_recovery_mail: "मेरा रिकवरी मेल",
		my_wager: "मेरे वेजर",
		n_days: "{0} दिन ",
		n_hours: "{0} घंटे ",
		n_minutes: "{0} मिनट ",
		n_weeks: "{0} सप्ताह ",
		n_months: "{0} महीने ",
		name: "नाम",
		new: "नया",
		news: "समाचार",
		new_message: "नया मैसेज",
		new_messages: "नए मैसेज",
		new_password: "नया पासवर्ड\t",
		new_password_min6: "नया पासवर्ड (न्यूनतम 6 वर्ण)",
		no: "नहीं",
		no_active_contests: "कोई सक्रिय प्रतियोगिता नहीं मिली",
		no_content_found: "कोई कंटेंट नहीं मिला",
		no_pending_contests: "कोई लंबित प्रतियोगिता नहीं मिलीं",
		no_expired_contests: "कोई एक्सपायर प्रतियोगिता नहीं मिलीं",
		no_available_actions: "कोई उपलब्ध एक्शन नहीं",
		no_data_found: "कोई डेटा नहीं मिला",
		no_limit: "कोई सीमा नहीं",
		no_recurrence: "कोई पुनरावृत्ति नहीं",
		not_allowed_by_system: "सिस्टम द्वारा अनुमति नहीं है",
		not_enough_players: "पर्याप्त खिलाड़ी नहीं हैं",
		not_implemented: "वर्तमान फ़ंक्शन लागू नहीं किया गया है",
		not_logged_in: "इस एक्शन को करने के लिए कृपया लॉग इन करें",
		not_revealed_yet: "सीड अभी तक प्रकट नहीं हुआ है",
		notes: "नोट्स",
		notifications: "सूचनाएँ",
		number_of_players: "खिलाड़ियों की संख्या",
		number_of_roll_limit_reached: "रोल की संख्या की सीमा आ गई है",
		number_of_rolls: "रोल की संख्या",
		objects: "वस्तुएँ",
		offline: "ऑफ़लाइन दिखाई दे रहे हो",
		old_password: "पुराना पासवर्ड",
		only_with_balance: "केवल बैलेंस के साथ",
		of_points: "{1} में से {0} अंक ",
		of_points_total: "{1} में से कुल {0} अंक ",
		off: "बंद",
		on: "चालू",
		on_loss: "नुकसान पर",
		on_win: "जीत पर",
		online: "ऑनलाइन",
		only: "केवल",
		opened: "खुल गया",
		optional: "वैकल्पिक",
		or: "या",
		org: "Org",
		outcome: "परिणाम",
		outputs: "आउटपुट",
		overall_stats: "कुल मिलाकर",
		overall_data: "कुल डेटा",
		overall_limit: "कुल सीमा",
		pandora: "पेंडोरा",
		page_not_found: "पेज नहीं मिला",
		page_not_found_desc:
			"आप जिस पेज की आप तलाश कर रहे हैं वह मौजूद नहीं है; हो सकता है कि इसे कहीं और स्थानांतरित कर दिया गया हो या पूरी तरह से हटा दिया गया हो।",
		pages: "पेज",
		packet_lost_retrying: "पैकेट खो गया, पुनः प्रयास कर रहा है...",
		parameters: "पैरामीटर",
		participants: "प्रतिभागी",
		participate: "भाग लेना",
		participate_in: "इसमें भाग लें",
		password: "पासवर्ड",
		password_2fa_change_detected: "पासवर्ड / 2fa में बदलाव का पता चला है ",
		password_management: "पासवर्ड प्रबंधन",
		password_at_least: "पासवर्ड कम से कम 6 अक्षर लंबा होना चाहिए",
		passwords_not_match: "पासवर्ड मेल नहीं खाते",
		password_not_set: "पासवर्ड सेट नहीं है",
		password_reset: "पासवर्ड रीसेट",
		password_reset_instruction: "पासवर्ड रीसेट निर्देश आपके मेल पर भेज दिया गया है",
		password_reset_successfully: "पासवर्ड सफलतापूर्वक रीसेट कर दिया गया है",
		password_set: "पासवर्ड सेट",
		password_strength: "पासवर्ड मजबूती",
		password_changed: "पासवर्ड बदल दिया",
		passwords_match: "पासवर्ड मेल खाता है",
		pause: "रोकें",
		paused: "रोक दिया",
		payout: "पेआउट",
		pending: "लंबित\t",
		pending_deposit: "लंबित डिपॉजिट",
		permissions: "अनुमतियाँ",
		permissions_management: "अनुमतियाँ प्रबंधन",
		ping: "पिंग",
		place: "लगायें",
		place_bet: "बेट लगायें",
		play: "खेलें",
		play_now: "अब खेलें",
		player: "खिलाड़ी\n",
		players: "ज्यादा खिलाड़ी",
		player_has_been_muted: "खिलाड़ी को म्यूट कर दिया गया है",
		player_has_been_unblocked: "खिलाड़ी को अनब्लॉक कर दिया गया है",
		player_not_selected: "खिलाड़ी का चयन नहीं किया गया है",
		please_retry: "कृपया पुन: प्रयास करें",
		pm: "PM",
		points_to_next_level: "अगले लेवल के लिए अंक",
		previous_seed_pair: "पिछली सीड जोड़ी",
		priority: "वरीयता",
		privacy: "गोपनीयता नीति",
		prize_pool: "प्राइज़ पूल",
		probability: "संभावना",
		processed: "संसाधित",
		promotion: "पदोन्नति",
		profile: "प्रोफ़ाइल",
		profit: "लाभ",
		profit_from_jackpot: "जैकपॉट से लाभ",
		profit_from_rollhunt: "रोलहंट से लाभ",
		profit_on_win: "जीत पर लाभ",
		provide_password: "पासवर्ड लिखें",
		public_chat_rooms: "सार्वजनिक चैट रूम",
		qr_error: "QR इमेज बनाते समय त्रुटि",
		qr_expired: "QR समाप्त हो गया",
		quantity: "मात्रा\t",
		rain: "रेन",
		rain_amount: "रेन राशी",
		rain_management: "रेन प्रबंधन",
		rain_received: "आप रेन में फँस गए",
		rain_sent: "आपने रेन की",
		rains_sent: "रेन भेज दीं",
		rain_out: "रेन भेज दीं",
		rains_received: "रेन प्राप्त हुईं",
		rain_in: "रेन प्राप्त हुईं",
		randomize: "यादृच्छिक",
		randomise_to_reveal: "प्रकट करने के लिए यादृच्छिक करें",
		randomization_error: "ग्राहक सीड बदलते समय त्रुटि",
		randomization_failed: "यादृच्छिक करना विफल रहा",
		randomization_success: "ग्राहक सीड सफलतापूर्वक बदल गया ",
		ratios: "अनुपात",
		read_mode: "पढ़ाई का मोड",
		read_more: "और पढ़ें",
		reason: "कारण",
		recurrence: "पुनरावृत्ति",
		recent: "ताजातरीन",
		reconnect: "फिर से कनेक्ट करें",
		recover: "वापस पाना",
		recovery_password_sent: "रिकवरी पासवर्ड भेजा गया",
		referral_link: "रेफ़रल लिंक",
		ref_by: "द्वारा संदर्भ",
		ref_count: "रेफ़रल गिनती",
		refresh: "रिफ्रेश",
		reg_date: "पंजीकरण दिनांक",
		region: "क्षेत्र",
		registration: "साइन इन/अप करें",
		registration_successful: "पंजीकरण सफल\t",
		reject: "अस्वीकार\t करें",
		rejected: "अस्वीकृत\t",
		remove_from_friends: "फ्रेंड्स की सूचि से हटा दें\t",
		required_field: "आवश्यक फील्ड",
		relative_balance_account: "सापेक्ष बैलेंस अकाउंट",
		relative_bank_account: "सापेक्ष बैंक अकाउंट",
		reload: "पुनः लोड करें",
		reload_page: "साइट को अपडेट कर दिया गया है, कृपया पेज को फिर से लोड करें",
		resend_confirmation_link: "पुष्टिकरण लिंक फिर से भेजें",
		reset: "रीसेट\t करें",
		reset_password: "पासवर्ड रीसेट\t करें",
		result: "परिणाम",
		to_base: "बेस की तरफ",
		return_to_lobby: "लॉबी में लौटें",
		reverse: "उल्टा\t",
		revoked: "निरस्त",
		roll: "रोल",
		rolls: "रोल्स\t",
		roll_dice: "पासा फेंकें",
		roll_over: "ऊपर",
		roll_under: "नीचे",
		roll_hunt: "रोल हंट",
		room: "रूम",
		room_create: "रूम बनायें",
		room_name: "रूम का नाम",
		room_not_active: "रूम सक्रीय नहीं है",
		rooms: "रूम्स",
		rooms_management: "रूम प्रबंधन",
		save: "सेव करें",
		search: "सर्च करें",
		search_game: "खेल सर्च करें",
		sec: "सेकंड",
		security: "सुरक्षा\t",
		seconds: "सेकंड",
		seconds_remaining: "सेकंड शेष",
		select_player: "चुनिंदा खिलाड़ी",
		select_coin: "कॉइन चुनें",
		send: "भेजें",
		send_pm: "PM भेजें",
		send_tip: "टिप भेजें",
		series: "श्रृंखला",
		server_error: "सर्वर त्रुटि",
		server_seed: "सर्वर सीड",
		server_seed_changed: "सर्वर सीड बदल गया है",
		server_sha_512: "सर्वर सीड sha512 हैश",
		server_time: "सर्वर समय",
		set: "निर्धारित करें",
		set_bet_amount: "कृपया बेट राशि निर्धारित करें",
		set_password: "पासवर्ड सेट करें",
		set_password_first: "पहले पासवर्ड सेट करें",
		set_mail: "मेल सेट करें",
		set_recovery_mail: "रिकवरी मेल सेट करें",
		settings: "सेटिंग्स",
		settings_saved: "सेटिंग्स को सेव कर दिया",
		shortcuts: "शॉर्टकट",
		show_more: "और दिखाओ",
		sign_in: "साइन इन करें",
		simple_balance: "केवल बैलेंस",
		site_to_be_shutdown: "साइट को अपडेट किया जा रहा है, सभी गेमप्ले बंद कर दिए गए हैं",
		skip: "छोड़ें",
		slider: "स्लाइडर",
		slots: "स्लॉट्स",
		slow_down: "बहुत सारे अनुरोध, धीमा करें",
		smileys_and_people: "स्माइलीज और लोग",
		something_went_wrong: "कुछ दिक्कत हो गयी",
		show_in: "{0} में दिखायें",
		sound: "आवाज",
		speed: "स्पीड",
		split: "स्प्लिट करे",
		sport: "खेल",
		stable: "स्थिर",
		stats: "आंकड़े",
		statistics: "आंकड़े",
		starting_point: "St. पॉइंट",
		starts_after: "इसके बाद शुरू होता है",
		status: "स्टेट्स",
		stop: "बंद करें",
		stop_on_loss: "नुकसान पर ट्रिगर स्टॉप",
		stop_on_win: "जीत पर ट्रिगर स्टॉप",
		stop_on_balance_under: "निर्धारित सीमा [से कम बैलेंस अमाउंट] पर ट्रिगर स्टॉप",
		stop_on_bet_amount_over: "निर्धारित सीमा [से ज्यादा बेट राशि] पर ट्रिगर स्टॉप",
		stop_on_balance_over: "निर्धारित सीमा [से ज्यादा बैलेंस अमाउंट] पर ट्रिगर स्टॉप",
		stop_on_bet_amount_under: "निर्धारित सीमा [से कम बेट राशि] पर ट्रिगर स्टॉप",
		stop_on_number_of_rolls: "[रोल की संख्या] पर ट्रिगर स्टॉप",
		stop_if_over: "बंद करें यदि ज्यादा हो",
		stop_if_under: "बंद करें यदि कम हो",
		stop_if_balance_under: "बंद करें यदि बैलेंस कम हो",
		stop_if_balance_over: "बंद करें यदि बैलेंस ज्यादा हो",
		stop_if_bet_amount_over: "बंद करें यदि बेट राशि ज्यादा हो",
		streak: "स्ट्रीक",
		submit: "सबमिट करें",
		support: "सहायता",
		symbols: "चिह्न",
		system: "सिस्टम",
		system_management: "सिस्टम प्रबंधन",
		the_most_popular: "सबसे निष्पक्ष और विश्वसनीय क्रिप्टो जुआ पोर्टल",
		tags: "टैग",
		take_seat: "बैठें",
		tc: "नियम और शर्तें",
		time: "समय\t",
		time_left: "शेष समय",
		time_from: "समय से",
		time_to: "समय तक",
		timezone: "टाइमज़ोन",
		tip: "टिप",
		tips: "टिप्स",
		tip_amount: "टिप राशि",
		tip_received: "टिप प्राप्त हुई",
		tips_send: "टिप भेजें",
		tip_management: "टिप प्रबंधन",
		tip_not_sent: "टिप नहीं भेजी गई",
		tip_sent: "टिप भेजी गई",
		tips_sent: "टिप्स भेजी गयीं",
		tips_receive: "टिप्स प्राप्त करें",
		tips_received: "टिप्स प्राप्त हुईं",
		title: "शीर्षक",
		to: "प्रति",
		to_withdraw_solve_captcha: "विदड्रा करने के लिए कैप्चा हल करें",
		too_many_requests: "बहुत सारे अनुरोध",
		token: "टोकन",
		token_expired: "आपका टोकन समाप्त हो गया है, कृपया पुनः लॉग इन करें",
		toggle_jackpot: "जैकपॉट टॉगल करें",
		toggle_fa_mode: "2FA मोड टॉगल करें",
		toggle_rollhunt: "रोलहंट टॉगल करें",
		toggle_slider: "स्लाइडर टॉगल करें",
		top_250_rain: "शीर्ष 250 जो संयोगवश रेन की चपेट में आने वाले हैं",
		total: "कुल",
		total_bets: "कुल बेट्स",
		total_losses: "कुल नुकसान",
		total_bets_shared: "साझा की गयी कुल बेट्स",
		total_messages: "कुल मैसेज",
		total_profit: "कुल लाभ",
		total_wagered: "कुल दांव",
		total_wins: "कुल जीत",
		transaction_fee: "लेन-देन शुल्क",
		transaction: "लेन-देन",
		transactions: "लेन-देन",
		transaction_complete: "लेन-देन पूर्ण",
		translate: "अनुवाद करें",
		travel_and_places: "यात्रा और स्थान",
		turn_on: "चालू करें",
		turn_off: "बंद करें",
		two_factor_authentication: "दो तरीकों से प्रमाणीकरण",
		type: "टाइप\t करें",
		type_a_message: "एक मैसेज टाइप करें",
		type_numbers_only: "केवल नंबर टाइप करें",
		type_your_mail: "अपना मेल टाइप करें",
		unblock_sm: "अनब्लॉक करें",
		unclaimed_profit: "दावा नहीं किया गया लाभ",
		unexpected_error: "अप्रत्याशित त्रुटि हुई",
		unignore: "अनइग्नोर",
		unlimited: "असीमित",
		unread: "अनरीड",
		unstable: "अस्थिर",
		upload_error: "फाइल अपलोड करते समय त्रुटि",
		upload_success: "फाइल सफलतापूर्वक अपलोड हो गई",
		usd_change_is_shown: "कृपया ध्यान दें कि कुल शेष परिवर्तन USD में ऐतिहासिक दरों के साथ दिखाया गया है",
		user: "यूजर",
		user_name: "यूजरनेम",
		username_already_taken: "यह यूजरनेम पहले ही लिया जा चुका है",
		username_min6: "यूजरनेम (न्यूनतम 6 वर्ण)",
		username_not_found: "यूजर नहीं मिला",
		usernames: "यूजरनेम्स",
		values_are_based_on_ranking: "मान रैंकिंग पर आधारित होते हैं ",
		verify: "सत्यापित करें",
		verified: "सत्यापित\t हो गया",
		verification_failed: "सत्यापन असफल\t",
		view_profile: "प्रोफ़ाइल देखें",
		vip_chat_rooms: "VIP चैट रूम",
		virtual_sport: "वर्चुअल खेल",
		w_requests: "W. अनुरोध",
		wager: "वेजर",
		wagering_contest: "सट्टेबाजी प्रतियोगिता",
		waiting_time: "इंतजार का समय\t\n",
		wallet_not_found: "वॉलेट नहीं मिला",
		was_read_on: "{0} पर पढ़ा गया था",
		websockets_support: "आपका ब्राउज़र वेबसोकेट के अनुकूल नहीं है",
		welcome: "स्वागत\t है",
		who_is_online: "कौन ऑनलाइन है\t",
		win: "जीत\t",
		wins: "जीतें",
		win_chance: "जीतने का मौका",
		winner: "विजेता",
		with_mail: "मेल के साथ",
		withdraw: "विदड्रा",
		withdraw_approved: "विदड्रावल स्वीकृत",
		withdraw_rejected: "विदड्रावल खारिज",
		withdraw_min: "न्यूनतम विदड्रावल राशि",
		withdraw_error: "विदड्रावल त्रुटि, कृपया सहायता से संपर्क करें",
		withdraw_success: "सफलतापूर्वक निकाली गई राशि",
		withdraws: "विदड्रावल्स ",
		yes: "हाँ",
		you_are_ignored: "एक फ्रेंड के रूप में नहीं जोड़ा जा सकता क्योंकि सामने वाले खिलाड़ी ने आपको अनदेखा कर दिया है",
		you_are_not_participating_in_any_campaign: "आप किसी अभियान में भाग नहीं ले रहे हैं",
		you_are_offline: "आप ऑफलाइन हैं\t",
		you_have_been_muted_by: "आपको इनके द्वारा म्यूट कर दिया गया है",
		you_must_cancel_running_deposit_bonus_to_proceed_with_the_current_action:
			"वर्तमान कार्य आगे बढ़ाने के लिए आपको चालू डिपॉजिट बोनस को कैंसिल करना होगा",
		you_pay: "आपके द्वारा भुगतान",
		you_receive: "आपके द्वारा प्राप्त",
		you_tried_too_many_times: "आपने कई बार कोशिश कर ली है, कृपया थोड़ी देर बाद कोशिश करें",
		your_comment: "आपकी टिप्पणी\t",
		you_got: "आपको मिल गया",
		your_place: "अपनी जगह\t",
		your_recovery_mail: "आपका रिकवरी मेल",
		you_wagered: "आपने दांव लगाया",
		you_were_tagged: "आपको टैग किया गया था",
		you_wrote: "आपने लिखा था",
		your_hunt_number_pool_is_empty: "आपका हंट नंबर पूल खाली है",
		your_withdrawal_will_be_sent: "आपके अकाउंट में जब कोई पुष्टिरहित डिपॉजिट नहीं बचेंगे तब आपका विदड्रावल भेज दिया जाएगा।",
		zip: "ज़िप",
		multi_account: "मल्टी अकाउंट",
		rude_words: "अशिष्ट शब्द",
		cheating: "बेईमानी करना",
		faucet_farming: "फॉसेट फार्मिंग",
		rain_farming: "रेन फार्मिंग",
		player_insulting: "खिलाड़ी का अपमान",
		moderator_insulting: "मॉडरेटर का अपमान",
		ignoring_moderator_warning: "मॉडरेटर की चेतावनियों को अनदेखा किया जाना",
		asking_for_tips: "टिप्स की माँग",
		flooding_spaming: "फ्लडिंग स्पैमिंग",
		self_referral: "सेल्फ रेफरल",
		other: "अन्य\t",
		activities_hunting_level: "गतिविधियों का हंटिंग लेवल",
		betting_level: "बेटिंग लेवल",
		chat_activity_level: "चैट गतिविधि लेवल",
		contest_activity_level: "प्रतियोगिता गतिविधि लेवल",
		jackpot_hunting_level: "जैकपॉट हंटिंग लेवल",
		loyalty_level: "वफादारी लेवल",
		rainmaker_level: "रेनमेकर लेवल",
		referrals_betting_level: "रेफ़रल सट्टेबाजी का लेवल",
		referring_level: "रेफरिंग लेवल",
		wagering_level: "वेजरिंग स्तर",
		warn_level: "चेतावनी का लेवल",
		activities_hunting_level_d: "जीती गयी गतिविधियों की कुल संख्या पर निर्भर करता है। अधिकतम {0} अंक ({1} गतिविधियाँ)। ",
		betting_level_d: "कुल बेट संख्या पर निर्भर करता है। अधिकतम {0} अंक ({1} बेट्स)। ",
		chat_activity_level_d: "चैट में कुल मैसेज की संख्या पर निर्भर करता है। अधिकतम {0} अंक ({1} मैसेज)। ",
		contest_activity_level_d: "जीती गयी प्रतियोगिताओं की कुल संख्या पर निर्भर करता है। अधिकतम {0} अंक ({1} प्रतियोगितायें)। ",
		jackpot_hunting_level_d: "जीते गए जैकपॉट की कुल संख्या पर निर्भर करता है। अधिकतम {0} अंक ({1} जैकपॉट)। ",
		loyalty_level_d: "पंजीकरण की तारीख के बाद बीत चुके कुल दिनों पर निर्भर करता है। अधिकतम {0} अंक ({1} दिन)। ",
		rainmaker_level_d: "रेन की कुल संख्या पर निर्भर करता है। अधिकतम {0} अंक ({1} रेन)। ",
		referrals_betting_level_d: "रेफ़रल की कुल बेट संख्या पर निर्भर करता है। अधिकतम {0} अंक ({1} बेट्स)। ",
		referring_level_d: "रेफ़रल की कुल संख्या पर निर्भर करता है। अधिकतम {0} अंक ({1} रेफ़रल)। ",
		wagering_level_d: "कुल दांव राशि पर निर्भर करता है। अधिकतम {0} अंक ({1} USD)। ",
		warn_level_d: "मिली कुल चेतावनियों पर निर्भर करता है। अधिकतम {0} अंक ({1} चेतावनियाँ)",
		hide: "छिपाएँ",
		network: "नेटवर्क",
		not_enough_wager: "पर्याप्त दांव नहीं है। अतिरिक्त {0}$ दांव की आवश्यकता है",
		recommend_set_pass: "हम आपको पासवर्ड सेट करने दृढ़तापूर्वक सलाह देते हैं ",
		recommend_set_mail: "हम आपको मेल सेट करने की दृढ़तापूर्वक सलाह देते हैं ",
		freespins: "फ्रीस्पिन",
		freespins_repeat: "फ्रीस्पिन दोहराना",
		freespin_repeat_left: "फ़्रीस्पिन को बार-बार हर 24 घंटे में {0} बार क्रेडिट किया जाएगा",
		freespin_repeat_left2: "(सक्रिय किया जाना तभी हो सकेगा जब वर्तमान फ्रीस्पिन पूरी तरह से खेल लिए जाते हैं)",
		my_freespins: "मेरे फ्रीस्पिन",
		not_available: "उपलब्ध नहीं है",
		deposits_made: "किये गये डिपॉजिट्स की संख्या",
		mail_must_be_verified: "मेल सत्यापित होनी चाहिए",
		gamble_aware: "जुआ जागरूक",
		soccer: "सॉकर",
		basketball: "बास्केटबॉल",
		tennis: "टेनिस",
		icehockey: "आइस हॉकी\t",
		casino: "कसीनो",
		"t&c": "नियम और शर्तें",
		community: "समुदाय",
	},
	"de-DE": {
		jan: "Jan",
		feb: "Feb",
		mar: "Mär",
		apr: "Apr",
		may: "Mai",
		jun: "Jun",
		jul: "Jul",
		aug: "Aug",
		sep: "Sep",
		oct: "Okt",
		nov: "Nov",
		dec: "Dez",
		mon: "Mo",
		tue: "Di.",
		wed: "Mi.",
		thu: "Do.",
		fri: "Fr.",
		sat: "Sa.",
		sun: "So.",
		abbrevation: "Abkürzung",
		accepted_currencies: "Akzeptierte Währungen",
		access_code: "Zugangscode",
		access_denied: "Zugriff abgelehnt",
		account: "Konto",
		action: "Aktion",
		actions: "Aktionen",
		action_not_allowed: "Aktion nicht erlaubt",
		activate: "Aktivieren",
		active: "Aktiv",
		active_rollhunts: "Aktive Rollhunts",
		аctivities: "Aktivitäten",
		add: "Hinzufügen",
		add_to_friends: "Freunden hinzufügen",
		address: "Adresse",
		address_create_error: "Adresse kann nicht erstellt werden",
		address_created: "Adresse erstellt",
		address_not_generated_yet: "Adresse noch nicht generiert",
		administrator: "Administrator",
		administrators: "Administrators",
		affiliate: "Affiliate",
		affiliation: "Zugehörigkeit",
		affiliate_casino_loss: "Casino-Verlust von geworbenen Benutzern",
		affiliate_casino_revenue: "Casino-Einnahmen von geworbenen Benutzern",
		aff_claims: "Zugehörigkeitsansprüche",
		affiliate_commission_rate: "Aktuelle Empfehlungsprovisionsrate",
		affiliate_current_balance: "Aktuelles Guthaben der geworbenen Nutzer",
		affiliate_deposit_count: "Anzahl der Einzahlungen",
		affiliate_net_commission: "Nettoprovision",
		affiliate_referral_profit: "Nettoempfehlungsgewinn",
		affiliate_stats: "Affiliate-Statistiken",
		affiliate_user_count: "Aktuelle Anzahl der geworbenen Nutzer",
		affiliation_management: "Zugehörigkeitsverwaltung",
		after: "Nach",
		all: "Alle",
		all_bets: "Alle Wetten",
		all_in: "All-in",
		all_your_progress_will_be_lost: "Ihr gesamter Fortschritt geht verloren (abhängiger Bonus)",
		allow_faucet_if_has_bank: "Wasserhahn (Faucet) zulassen  wenn Bankguthaben vorhanden ist",
		allow_faucet_if_has_other_coin_balance: "Wasserhahn (Faucet) zulassen  wenn Guthaben auf anderer Münze vorhanden ist",
		allow_rains: "Erlaube Regen",
		already_approved_by: "Bereits genehmigt von",
		already_cancelled_by: "Bereits storniert von",
		already_verified: "Eintrag bereits verifiziert",
		amount: "Menge",
		amount_claimed: "Geforderter Betrag",
		amount_not_number: "Betrag ist keine Zahl",
		amount_per_user: "Betrag pro Nutzer",
		animals_and_nature: "Tiere & Natur",
		approved: "Genehmigt",
		are_you_sure: "Bist du dir sicher",
		auto: "Automatisch",
		auto_cash_out: "Automatische Auszahlung",
		auto_roll: "Automatische Rolle",
		avatar: "Benutzerbild (Avatar also works)",
		away: "Weg",
		balance: "Guthaben",
		balance_changed: "Guthaben geändert",
		balance_zero: "Guthaben muss Null sein",
		balance_over_limit_reached: "Guthaben über dem Limit erreicht",
		balance_under_limit_reached: "Guthaben unter Limit erreicht",
		ban: "Verbot",
		banned: "Verboten",
		banner: "Banner",
		banners: "Banner",
		before: "Vor",
		slide: "Dia",
		slides: "Dias",
		bank: "Bank",
		bank_zero: "Der Kontostand der Bank muss null sein",
		bank_in: "Überweisung an Bank",
		bank_out: "Überweisung von der Bank",
		beginning_of_the_hour: "Beginn der Stunde",
		beginning_of_the_day: "Beginn des Tages",
		beginning_of_the_week: "Anfang der Woche ",
		beginning_of_the_month: "Anfang des Monats",
		bet: "Wette",
		bets: "Wetten",
		bet_amount: "Einsatzbetrag",
		bet_amount_limit_reached: "Betragslimit erreicht",
		bet_info: "Wettinfo",
		bets_made_with_pair: "Mit Paar abgeschlossene Wetten",
		billing: "Abrechnung",
		block: "Block",
		block_hash: "Hash blockieren",
		board: "Tafel",
		bonus: "Bonus",
		bonus_activated: "Bonus aktiviert",
		bonus_deactivated: "Bonus deaktiviert",
		bonus_activation_error: "Bonusaktivierungsfehler",
		bonus_claim: "Bonus einfordern",
		bonus_won: "Herzlichen Glückwunsch  Sie haben den Bonus gewonnen!",
		busy: "beschäftigt",
		buy: "Kaufen",
		by: "Durch",
		by_clicking: "Indem ich auf die Taste unten klicke  stimme ich dem zu.",
		cash_out: "Auszahlen",
		calculate_in: "Berechnen Sie in",
		campaign: "Kampagne",
		cancel: "Abrechnen",
		cancelled: "Abgesagt",
		cannot_change_entry: "Eintrag kann nicht geändert werden",
		cannot_ignore_mod_or_admin: "Mod oder Admin kann nicht ignoriert werden",
		cause_rain: "Regen machen",
		caution: "Vorsicht",
		city: "Stadt",
		clear: "Frei",
		chance: "Chance",
		change_balance: "Kontostand ändern",
		change_mail: "Mail ändern",
		change_client_seed: "Client-Seed ändern",
		change_password: "Passwort ändern",
		chat: "Plaudern",
		chatgame: "Chat-Spiel",
		chat_game_management: "Chatspielverwaltung",
		chat_game_statement: "Rate eine Zahl von 1 bis einschließlich 100 und gewinne den Preis! Viel Glück!",
		chat_messages: "Chatnachrichten",
		check_input_values: "Eingabewerte prüfen",
		check_internet: "Bitte überprüfe deine Internetverbindung",
		choose_coin: "Münze wählen",
		claim: "Fordern",
		clear_2fa: "Lösche 2fa",
		client_seed: "Client-Seed",
		closed: "Abgeschlossen",
		code: "Code",
		coin: "Münze",
		coin_name: "Name der Münze",
		coin_not_selected: "Münze ist nicht ausgewählt",
		coins: "Münzen",
		coin_management: "Coin-Management",
		coin_not_active: "Münze nicht aktiv",
		coin_not_stable: "Münze nicht stabil",
		comment: "Kommentar",
		commission_rate: "Provisionssatz",
		con_connection_error: "Verbindungsfehler",
		con_connection_established: "Verbindung hergestellt",
		con_connection_lost: "Verbindung unterbrochen",
		con_reconnect_attempt: "Wiederverbindungsversuch: {0}",
		con_reconnect_failed: "Neuverbindung fehlgeschlagen",
		confirm_new_password: "Bestätige neues Passwort",
		confirm_you_are_human: "Bestätige  dass du ein Mensch bist",
		confirmation_link_sent: "Bestätigungslink gesendet",
		confirmations_required: "Bestätigungen erforderlich",
		confiscate: "Beschlagnahmen",
		confiscated: "Beschlagnahmt",
		contact_support: "Für weitere Details wenden Sie sich an den Support",
		contest: "Wettbewerb",
		contests: "Wettbewerbe",
		copied: "Wert in die Zwischenablage kopiert",
		copy: "Kopieren",
		copy_to_clipboard: "In die Zwischenablage kopieren",
		country: "Land",
		country_code: "Ländercode",
		create: "Schaffen",
		create_an_account: "Anmelden",
		created_by: "Erstellt von",
		creator_profit: "Gewinn des Erstellers",
		currency: "Währung",
		current_balance: "Aktueller Saldo",
		current_data: "Aktuelle Daten",
		current_friends: "Aktuelle Freunde",
		current_rates: "Aktuelle Preise",
		current_seed_pair: "Aktuelles Seed-Paar",
		current_session: "Aktuell",
		custom: "Brauch",
		daily_limit: "Tägliches Limit",
		daily_limit_reached: "Tageslimit erreicht",
		daily_total_amount: "Täglicher Gesamtbetrag",
		daily_count: "Tageszählung",
		data: "Daten",
		data_not_found: "Daten nicht gefunden",
		date_joined: "Beitrittsdatum",
		day: "Tag",
		days: "Tage",
		deactivate: "Deaktivieren",
		decrease_by: "Verringern um",
		delay: "Verzögerung",
		delete: "Löschen",
		delete_message: "Nachricht löschen",
		delta: "Delta",
		deltas: "Delta",
		deposit: "Kaution",
		deposit_bonus: "Einzahlungsbonus",
		deposits: "Einzahlungen",
		deposit_confirmation_pending: "Sie können kein Trinkgeld geben  abheben  Regen senden oder Spiele von Drittanbietern spielen  während die",
		deposit_success: "Einzahlungsbestätigung aussteht.",
		deposit_confirmed: "Einzahlung erfolgreich  bitte warten Sie auf 1 Bestätigung ",
		description: "Beschreibung\t",
		dice: "Würfel",
		disabled: "Deaktiviert",
		disposable: "Verfügbar",
		divide_amount: "Betrag teilen",
		do_not_lose_chance_to_activate_our_deposit_bonuses: "Verpassen Sie nicht die Chance  unsere Einzahlungsboni zu aktivieren",
		double_check_rates:
			"Bitte überprüfen Sie noch einmal  ob der aktuelle Wechselkurs Ihren Bedürfnissen entspricht  da der Umtausch eine unumkehrbare Aktion ist.",
		duration: "Dauer",
		edit: "Bearbeiten",
		edit_message: "Nachricht bearbeiten",
		edited: "Hrsg. von",
		embed_code: "Code einbetten (für Websites)",
		enabled: "Ermöglicht",
		enter_amount: "Menge eingeben",
		enter_address: "Adresse eingeben",
		enter_tag: "Tag eingeben",
		enter_delta_amount: "Geben Sie den Delta-Betrag ein",
		enter_token: "2fa-Token eingeben",
		enter_password: "Passwort eingeben",
		entry_not_found: "Eintrag nicht gefunden",
		entry_add: "Eintrag hinzufügen",
		entry_already_exists: "Dieser Eintrag existiert bereits",
		entry_added: "Eintrag hinzugefügt",
		entry_remove: "Eintrag entfernen",
		entry_removed: "Eintrag entfernt",
		entry_update: "Eintrag aktualisieren",
		entry_updated: "Eintrag aktualisiert",
		exchange: "Austausch",
		exchange_from: "Umtausch von",
		exchange_to: "Wechsel nach",
		exchange_management: "Wechselmanagement",
		exchanges: "Austausch",
		exchange_success: "Betrag erfolgreich umgetauscht",
		expired: "Abgelaufen",
		error: "Fehler",
		every: "Jeder",
		every_losses: "Alle Verluste",
		every_wins: "Jeder gewinnt",
		fa_if_enabled: "2FA (falls aktiviert)",
		failed: "Gescheitert",
		fairness: "Gerechtigkeit",
		fairness_check: "Fairness-Check",
		faq: "FAQ",
		faucet: "Wasserhahn",
		faucet_enable: "Facucet aktivieren",
		faucet_digits_count: "Faucet erratene Ziffern zählen",
		faucet_disabled: "Faucet ist zurzeit deaktiviert",
		faucet_abuse: "Aufgrund des Faucet-Missbrauchs oder der Faucet-Farming-Aktivitäten gelten einige Einschränkungen für Ihr Konto",
		faucet_claimed: "Faucet erfolgreich beansprucht  ",
		faucet_claimed_recently:
			"Sie haben vor Kurzem vom Faucet Anspruch erhoben und müssen warten  bevor Sie einen weiteren Anspruch geltend machen können.",
		faucet_claims: "Faucet-Behauptungen",
		faucet_farmer_ignore_admin: "Faucet-Farming durch Administratoren ignorieren",
		faucet_farmer_ignore_mod: "Faucet-Farming durch Moderatoren ignorieren",
		faucet_farmers: "Armaturenbauern",
		faucet_farming_msg: "Aufgrund von Faucet Farming wird der geltend gemachte Betrag gesenkt",
		faucets_in_hour: "Faucet behauptet in 1 Stunde  den Spieler als Faucet-Farmer zu betrachten",
		faucet_management: "Faucet management",
		faucet_not_claimed: "Faucet konnte nicht beansprucht werden",
		fbi: "FBI",
		filter: "Filtern",
		fp: "Fingerabdruck",
		fingerprint: "Fingerabdruck",
		fingerprints: "Fingerabdrücke",
		finished: "Fertig",
		finish_to_reveal: "Spiel beenden  um Samen zu enthüllen",
		field_blank: "Feld darf nicht leer sein",
		first_deposit: "Erste Einzahlung",
		flags: "Flaggen",
		food_and_drink: "Essen & Trinken",
		for: "zum",
		forever: "Bis in alle Ewigkeit",
		formula: "Formel",
		forum: "Forum",
		forgot_password: "Passwort vergessen",
		free_play: "Freies Spiel",
		frequently_used: "Häufig verwendet",
		friend_request_sent: "Freundschaftsanfrage gesendet",
		friend: "Freund",
		friends: "Freunde",
		from: "Aus",
		game: "Spiel",
		game_added_to_favourites: " Spiel zu Favoriten hinzugefügt",
		game_removed_from_favourites: "Spiel aus Favoriten entfernt",
		game_provider: "Spielanbieter",
		games: "Spiele",
		game_balance: "Spielbalance",
		gambling_forbidden: "und Glücksspiel ist von meinen örtlichen Behörden nicht verboten und ich bin mindestens 18 Jahre alt.",
		generate_new: "Neu generieren",
		general_info: "Allgemeine Information",
		geographical_data: "Geografische Daten",
		gfa_cleared_successfully: "2fa erfolgreich gelöscht",
		gfa_data_not_found: "Es wurde kein 2fa-Aktivierungseintrag gefunden",
		gfa_management: "Google 2FA-Verwaltung",
		gfa_reset: "2fa zurücksetzen",
		gfa_set: "2fa-Satz",
		gfa_on: "Google 2FA aktiviert",
		gfa_off: "Google 2FA deaktiviert",
		granted: "Gewährt",
		guests: "Gäste",
		h: "h",
		hasLower: "Enthält Kleinbuchstaben",
		hasNumber: "Enthält Nummer",
		hasSymbol: "Enthält Symbol",
		hasUpper: "Enthält Großbuchstaben",
		history: "Geschichte",
		hour: "Stunde",
		hours: "Std (Studenden)",
		high_rollers: "High Roller",
		home: "Heim",
		hotkeys: "Schnelltasten",
		hotkeys_are_currently: "Hotkeys sind derzeit",
		house_edge: "Hausvorteil",
		hunt: "Jagd",
		hunt_number: "Jagdnummer",
		hunt_number_pool: "agdnummer Pool",
		id: "Ausweis",
		ignore: "Ignorieren",
		ignored_players: "Ignorierte Spieler",
		img: "Bild",
		in_a_row: "In einer Reihe",
		income: "Einkommen",
		inputs: "Eingänge",
		increase_by: "Erhöhen um",
		inc_by: "Einschl. von",
		info: "Die Info",
		infinite: "Unendlich",
		insufficient_funds: "Unzureichende Mittel",
		insufficient_privileges: "Unzureichende Berechtigungen [{0}]",
		invalid_address: "Ungültige Adresse",
		invalid_amount: "Ungültige Menge",
		invalid_arguments: "Ungültige Argumente",
		invalid_bet_amount: "Ungültiger Einsatzbetrag",
		invalid_currency: "Ungültige Währung",
		invalid_ip_fp: "Benutzername mit solcher IP und Fingerabdruck existiert bereits",
		invalid_login_information: "Falsche login Daten",
		invalid_mail: "Keine gültige E-Mail",
		invalid_password: "Ungültiges Passwort",
		invalid_password_or_2fa: "Ungültiges Passwort oder 2fa",
		invalid_time: "Ungültige Uhrzeit",
		invalid_token: "Ungültiges Token",
		invalid_username: "Ungültiger Benutzername",
		invalid_wallet_password: "Ungültiges Wallet-Passwort",
		investment_required: "Investitionen erforderlich",
		ip: "Ip",
		ip_fp_change_detected: "Änderung der IP-Adresse/Fingerabdruck erkannt  bitte erneut anmelden",
		is_online: "Ist online",
		is_offline: "Ist offline",
		isp: "ISP",
		jackpot: "Jackpot",
		jackpot_management: "Jackpot-Verwaltung",
		jackpots: "Jackpots",
		join: "Verbinden",
		join_price: "Joint-Preis",
		key_expired: "Ihr Schlüssel ist abgelaufen",
		lat: "Lat",
		leaderboard: "Bestenliste",
		let_others_know: "Lass es andere wissen",
		level: "Eben",
		live: "Live",
		loading: "Wird geladen",
		lobby: "Empfangshalle",
		login: "Anmeldung",
		login_first: "Bitte loggen Sie sich zuerst ein",
		logout: "Ausloggen",
		lon: "Lon",
		lose: "Verlieren",
		loss: "Verlust",
		losses: "Verluste",
		luck: "Glück",
		lucky_number: "Glückszahl",
		lucky_number_today: "Die Glückszahl von heute",
		lucky_players_are: "Die glücklichen Spieler sind",
		mail: "Mail",
		mail_verified: "E-Mail bestätigt",
		mail_confirmed: "Danke  Ihre E-Mail ist jetzt bestätigt.",
		mail_management: "Mail-Verwaltung",
		mail_set: "Wiederherstellungs-E-Mail-Set  Bestätigung wurde gesendet an",
		mail_not_found: "E-Mail nicht gefunden",
		mail_not_confirmed: "E-Mail nicht bestätigt",
		mail_not_set: "E-Mail nicht eingerichtet",
		management: "Management",
		manual: "Handbuch",
		marked_for_delete: "Zum Löschen markiert",
		max: "Max",
		max_bet: "Maximalgebot",
		max_amount: "Maximum amount",
		menu: "Menu",
		message: "Message",
		message_deleted: "Message deleted",
		min: "Min",
		min_amount: "Minimum amount",
		min_amount_not_matched: "Min amount not matched",
		min_exchange_amount: "Min. exchange amount",
		min_left: "{0} min left",
		min_level_1: "You can not chat until you reach level 2",
		min_level_not_reached: "Min level not reached",
		min_level_required: "Min level required\t \n",
		min6: "min {0} characters",
		minutes: "Minutes",
		minimal_exhcange_amount_is: "Minimal exchange amount is",
		minimal_faucet_amount_is: "Received amount from faucet is",
		minimal_withdraw_amount_is: "Minimal withdrawal amount is",
		minute: "Mindest",
		moderator: "Moderator",
		moderator_profit: "Gewinn des Moderators",
		moderators: "Moderatoren",
		money: "Geld",
		monitor: "Überwachen",
		multiply_amount: "Betrag multiplizieren",
		multiplier: "Multiplikator",
		mute: "Stumm",
		mute_sm: "Stumm",
		muted: "Auf stumm geschaltet",
		my_bets: "Meine Wetten",
		my_position: "Meine Position",
		my_recovery_mail: "Meine Wiederherstellungs-Mail",
		my_wager: "Meine Wette",
		n_days: "{0} Tage",
		n_hours: "{0} Stunden",
		n_minutes: "{0} Minuten",
		n_weeks: "{0} Wochen",
		n_months: "{0} Monate",
		name: "Name",
		new: "Neu",
		news: "Nachrichten",
		new_message: "Neue Nachricht",
		new_messages: "Neue Nachrichten",
		new_password: "Neues Passwort",
		new_password_min6: "Neues Passwort (min. 6 Zeichen)",
		no: "Nein",
		no_active_contests: "Keine aktiven Wettbewerbe gefunden",
		no_content_found: "Kein Inhalt gefunden",
		no_pending_contests: "Keine ausstehenden Wettbewerbe gefunden",
		no_expired_contests: "Keine abgelaufenen Wettbewerbe gefunden",
		no_available_actions: "Keine verfügbaren Aktionen",
		no_data_found: "Keine Daten gefunden",
		no_limit: "keine Begrenzung",
		no_recurrence: "Keine Wiederholung",
		not_allowed_by_system: "Vom System nicht zugelassen",
		not_enough_players: "Zu wenig Spieler",
		not_implemented: "Aktuelle Funktion nicht implementiert",
		not_logged_in: "Bitte melden Sie sich an  um diese Aktion auszuführen",
		not_revealed_yet: "Die Saat wurde noch nicht enthüllt",
		notes: "Anmerkungen",
		notifications: "Benachrichtigungen",
		number_of_players: "Anzahl der Spieler",
		number_of_roll_limit_reached: "Anzahl der Rolllimits erreicht",
		number_of_rolls: "Anzahl Rollen",
		objects: "Objekte",
		offline: "Offline erscheinen",
		old_password: "Altes Passwort",
		only_with_balance: "Nur mit Gleichgewicht",
		of_points: "{0} von {1} Punkten",
		of_points_total: "Gesamt {0} von {1} Punkten",
		off: "Aus",
		on: "An",
		on_loss: "Auf Verlust",
		on_win: "Auf Sieg",
		online: "Online",
		only: "Nur",
		opened: "Geöffnet ",
		optional: "Optional",
		or: "Oder",
		org: "Org",
		outcome: "Ergebnis",
		outputs: "Ausgänge",
		overall_stats: "Gesamt",
		overall_data: "Gesamtdaten",
		overall_limit: "Gesamtlimit",
		pandora: "Pandora",
		page_not_found: "Seite nicht gefunden",
		page_not_found_desc: "Die Seite  nach der Sie suchen  existiert nicht; sie wurde möglicherweise verschoben oder ganz entfernt.",
		pages: "Seiten",
		packet_lost_retrying: "Paket verloren  neuer Versuch...",
		parameters: "Parameter",
		participants: "Teilnehmer",
		participate: "Sich beteiligen",
		participate_in: "Teilnehmen an",
		password: "Passwort",
		password_2fa_change_detected: "Passwort-/2fa-Änderung festgestellt",
		password_management: "Passwortverwaltung",
		password_at_least: "Das Passwort muss mindestens 6 Zeichen lang sein",
		passwords_not_match: "Passwörter stimmen nicht überein",
		password_not_set: "Passwort nicht gesetzt",
		password_reset: "Passwort zurücksetzen",
		password_reset_instruction: "Die Anweisung zum Zurücksetzen des Passworts wurde an Ihre E-Mail gesendet.",
		password_reset_successfully: "Passwort erfolgreich zurückgesetzt",
		password_set: "Passwort festgelegt",
		password_strength: "Passwortstärke",
		password_changed: "Passwort geändert",
		passwords_match: "Passwörter stimmen überein",
		pause: "Pause",
		paused: "Pausiert",
		payout: "Auszahlung",
		pending: "Ausstehend",
		pending_deposit: "Einzahlung ausstehend  ",
		permissions: "Berechtigungen ",
		permissions_management: "Berechtigungsverwaltung  ",
		ping: "Ping ",
		place: "Ort",
		place_bet: "Wetteinsatz festlegen\t ",
		play: "Spielen\t",
		play_now: "Jetzt spielen\t ",
		player: "Spieler\t",
		players: "Spieler ",
		player_has_been_muted: "Spieler wurde stummgeschaltet  ",
		player_has_been_unblocked: "Spieler wurde entsperrt  ",
		player_not_selected: "Spieler ist nicht ausgewählt ",
		please_retry: "Bitte versuche es erneut\t",
		pm: "PM",
		points_to_next_level: "  Punkte zum nächsten Level ",
		previous_seed_pair: "Vorheriges Seed-Paar ",
		priority: "Priorität",
		privacy: "Datenschutz-Bestimmungen\t",
		prize_pool: "Preis Pool\t ",
		probability: "Wahrscheinlichkeit ",
		processed: "Verarbeitet\t ",
		promotion: "Förderung",
		profile: "Profil",
		profit: "Gewinn",
		profit_from_jackpot: "Gewinne vom Jackpot",
		profit_from_rollhunt: "Profitieren Sie von Rollhunt",
		profit_on_win: "Profit on win",
		provide_password: "Passwort eingeben",
		public_chat_rooms: "Öffentliche Chatrooms",
		qr_error: "Fehler beim Generieren des QR-Bildes",
		qr_expired: "QR abgelaufen",
		quantity: "Menge",
		rain: "Regen",
		rain_amount: "Regenmenge",
		rain_management: "Regenmanagement",
		rain_received: "Du bist vom Regen erwischt worden",
		rain_sent: "Du hast den Regen verursacht",
		rains_sent: "Regen geschickt",
		rain_out: "Regen geschickt",
		rains_received: "Regen empfangen",
		rain_in: "Regen empfangen",
		randomize: "Randomisieren",
		randomise_to_reveal: "Zum Aufdecken zufällig auswählen",
		randomization_error: "Fehler beim Ändern des Client-Seeds",
		randomization_failed: "Randomisierung fehlgeschlagen",
		randomization_success: "Client-Seed erfolgreich geändert",
		ratios: "Verhältnisse",
		read_mode: "Lesemodus",
		read_more: "Weiterlesen",
		reason: "Grund",
		recurrence: "Wiederauftreten",
		recent: "Jüngste",
		reconnect: "Wiederverbinden",
		recover: "Genesen",
		recovery_password_sent: "Wiederherstellungspasswort gesendet",
		referral_link: "Empfehlungslink",
		ref_by: "Ref. von",
		ref_count: "Empfehlungszähler",
		refresh: "Aktualisierung",
		reg_date: "Registrierungsdatum",
		region: "Region",
		registration: "Anmelden/anmelden",
		registration_successful: "Registrierung erfolgreich",
		reject: "Ablehnen",
		rejected: "Abgelehnt",
		remove_from_friends: "Von Freunden entfernen",
		required_field: "Pflichtfeld",
		relative_balance_account: "Relatives Saldokonto",
		relative_bank_account: "Verwandtes Bankkonto",
		reload: "Neu laden",
		reload_page: "Website wurde aktualisiert  bitte Seite neu laden",
		resend_confirmation_link: "Bestätigungslink erneut senden",
		reset: "Zurücksetzen",
		reset_password: "Passwort zurücksetzen",
		result: "Ergebnis",
		to_base: "Zur Basis\t",
		return_to_lobby: "Zurück zur Lobby",
		reverse: "Umkehren",
		revoked: "Widerrufen",
		roll: "Rolle",
		rolls: "Rollen",
		roll_dice: "Würfeln",
		roll_over: "Über",
		roll_under: "Unter",
		roll_hunt: "Rollenjagd",
		room: "Zimmer",
		room_create: "Platz schaffen\t",
		room_name: "Raumname",
		room_not_active: "Raum ist nicht aktiv",
		rooms: "Räume",
		rooms_management: "Zimmerverwaltung",
		save: "Speichern",
		search: "Suche",
		search_game: "Suchspiel",
		sec: " Sekunde",
		security: "Sicherheit",
		seconds: "Sekunden",
		seconds_remaining: "Verbleibende Sekunden",
		select_player: "Spieler auswählen",
		select_coin: "Münze auswählen",
		send: "Senden",
		send_pm: "PN senden",
		send_tip: "Tipp senden",
		series: "Serie",
		server_error: "Serverfehler",
		server_seed: "Server-Seed",
		server_seed_changed: "Server-Seed geändert",
		server_sha_512: "Sha512-Hash des Server-Seeds",
		server_time: "Serverzeit",
		set: "Satz",
		set_bet_amount: "Bitte setzen Sie den Einsatzbetrag ein",
		set_password: "Passwort festlegen",
		set_password_first: "Zuerst Passwort festlegen",
		set_mail: "Mail einstellen",
		set_recovery_mail: "Legen Sie eine Wiederherstellungs-E-Mail fest",
		settings: "Einstellungen",
		settings_saved: "Einstellungen gespeichert",
		shortcuts: "Abkürzungen",
		show_more: "mehr zeigen",
		sign_in: "Einloggen",
		simple_balance: "Einfache Bilanz",
		site_to_be_shutdown: "Die Seite wird aktualisiert  das gesamte Gameplay wird gestoppt",
		skip: "überspringen",
		slider: "Schieberegler",
		slots: "Schlüssel",
		slow_down: "Zu viele Anfragen  verlangsamen",
		smileys_and_people: "Smileys & Leute",
		something_went_wrong: "Etwas ist schief gelaufen",
		show_in: "In {0} anzeigen",
		sound: "Klang",
		speed: "Geschwindigkeit",
		split: "Teilt",
		sport: "Sport",
		stable: "Stabil",
		stats: "Statistiken",
		statistics: "Statistiken",
		starting_point: "St. Point",
		starts_after: "Beginnt nach",
		status: "Status",
		stop: "Stop",
		stop_on_loss: "Ausgelöster Stop bei Verlust",
		stop_on_win: "Ausgelöster Stopp bei Sieg",
		stop_on_balance_under: "Ausgelöster Stopp bei [Saldobetrag unter] Pointed Limit",
		stop_on_bet_amount_over: "Ausgelöster Stopp bei [Einsatzbetrag über] Pointed Limit",
		stop_on_balance_over: "Ausgelöster Stopp bei [Saldobetrag über] Pointed Limit",
		stop_on_bet_amount_under: "Ausgelöster Stopp bei [Wettbetrag unter] Pointed Limit",
		stop_on_number_of_rolls: "Ausgelöster Stopp bei [Anzahl der Rollen]",
		stop_if_over: "Hör auf  wenn vorbei",
		stop_if_under: "Stopp wenn unter",
		stop_if_balance_under: "Stopp  wenn Kontostand unter",
		stop_if_balance_over: "Stopp  wenn das Gleichgewicht vorbei ist",
		stop_if_bet_amount_over: "Aufhören  wenn der Einsatzbetrag über ist",
		streak: "Strähne",
		submit: "Einreichen",
		support: "Die Unterstützung",
		symbols: "Symbole",
		system: "System",
		system_management: "Systemmanagement",
		the_most_popular: "Das fairste und vertrauenswürdigste Krypto-Glücksspielportal",
		tags: "Stichworte",
		take_seat: "Nimm Platz",
		tc: "Geschäftsbedingungen",
		time: "Zeit",
		time_left: "Übrige Zeit",
		time_from: "Uhrzeit von",
		time_to: "Zeit zum",
		timezone: "Zeitzone",
		tip: "Tipp",
		tips: "Tipps",
		tip_amount: "Trinkgeldbetrag",
		tip_received: "Tipp erhalten",
		tips_send: "Tipps senden",
		tip_management: "Trinkgeldverwaltung",
		tip_not_sent: "Tipp nicht gesendet",
		tip_sent: "Tipp gesendet",
		tips_sent: "Tipps gesendet",
		tips_receive: "Tipps erhalten",
		tips_received: "Tipps erhalten",
		title: "Titel",
		to: "Zu",
		to_withdraw_solve_captcha: "Um das Captcha zurückzuziehen",
		too_many_requests: "Zu viele Anfragen",
		token: "Token",
		token_expired: "Ihr Token ist abgelaufen  bitte melden Sie sich erneut an.",
		toggle_jackpot: "Jackpot umschalten",
		toggle_fa_mode: "2FA-Modus umschalten",
		toggle_rollhunt: "Rollhunt umschalten",
		toggle_slider: "Schieberegler umschalten",
		top_250_rain: "Top 250 zufällig von einem Regen erwischt",
		total: "Gesamt",
		total_bets: "Gesamtwetten",
		total_losses: "Totalverluste",
		total_bets_shared: "Gesamtwetten geteilt",
		total_messages: "Nachrichten insgesamt",
		total_profit: "Gesamtgewinn",
		total_wagered: "Gesamteinsatz",
		total_wins: "Gesamtgewinne",
		transaction_fee: "Transaktionsgebühr",
		transaction: "Transaktion",
		transactions: "Transaktionen",
		transaction_complete: "Transaktion abgeschlossen",
		translate: "Übersetzen",
		travel_and_places: "Reisen & Orte",
		turn_on: "Einschalten",
		turn_off: "Schalte aus",
		two_factor_authentication: "Zwei-Faktor-Authentifizierung",
		type: "Typ",
		type_a_message: "Geben Sie eine Nachricht ein",
		type_numbers_only: "Nur Zahlen eingeben",
		type_your_mail: "Geben Sie Ihre E-Mail ein",
		unblock_sm: "Blockierung aufheben",
		unclaimed_profit: "Nicht beanspruchter Gewinn",
		unexpected_error: "Unerwarteter Fehler aufgetreten",
		unignore: "Ignorieren",
		unlimited: "Unbegrenzt",
		unread: "Ungelesen",
		unstable: "Instabil",
		upload_error: "Fehler beim Hochladen der Datei",
		upload_success: "Datei erfolgreich hochgeladen",
		usd_change_is_shown: "Bitte beachten Sie  dass die Veränderung des Gesamtsaldos in USD mit den historischen Kursen angezeigt wird.",
		user: "Benutzer",
		user_name: "Nutzername",
		username_already_taken: "Benutzername bereits vergeben",
		username_min6: "Benutzername (min. 6 Zeichen)",
		username_not_found: "Benutzer nicht gefunden",
		usernames: "Benutzernamen",
		values_are_based_on_ranking: "Werte basieren auf Ranking",
		verify: "Verifizieren",
		verified: "Bestätigt",
		verification_failed: "Überprüfung fehlgeschlagen",
		view_profile: "Profil anzeigen",
		vip_chat_rooms: "VIP-Chatrooms",
		virtual_sport: "Virt.Sport",
		w_requests: "W. fordert",
		wager: "Wette",
		wagering_contest: "Wettwettbewerb",
		waiting_time: "Wartezeit",
		wallet_not_found: "Wallet nicht gefunden",
		was_read_on: "Wurde am {0} gelesen",
		websockets_support: "Ihr Browser unterstützt keine Websockets",
		welcome: "Herzlich willkommen",
		who_is_online: "Wer ist Online",
		win: "Gewinnen",
		wins: "Gewinnt",
		win_chance: "Gewinnchance",
		winner: "Gewinner",
		with_mail: "Mit Post",
		withdraw: "Abheben",
		withdraw_approved: "Mit. genehmigt",
		withdraw_rejected: "Mit. abgelehnt",
		withdraw_min: "Min. Auszahlungsbetrag",
		withdraw_error: "Abhebungsfehler  bitte wenden Sie sich an den Support",
		withdraw_success: "Betrag erfolgreich abgehoben",
		withdraws: "Zieht sich zurück",
		yes: "Ja",
		you_are_ignored: "Kann nicht als Freund hinzugefügt werden  weil der Zielspieler Sie ignoriert hat",
		you_are_not_participating_in_any_campaign: "Sie nehmen an keiner Kampagne teil",
		you_are_offline: "Sie sind offline",
		you_have_been_muted_by: "Sie wurden stummgeschaltet von",
		you_must_cancel_running_deposit_bonus_to_proceed_with_the_current_action:
			"Sie müssen den laufenden Einzahlungsbonus stornieren  um mit der aktuellen Aktion fortzufahren",
		you_pay: "Sie bezahlen",
		you_receive: "Du erhältst",
		you_tried_too_many_times: "Du hast es zu oft versucht  bitte versuche es ein bisschen später",
		your_comment: "Dein Kommentar",
		you_got: "Du hast",
		your_place: "Dein Platz",
		your_recovery_mail: "Ihre E-Mail zur Wiederherstellung",
		you_wagered: "Du hast gewettet",
		you_were_tagged: "Du wurdest getaggt",
		you_wrote: "Sie schrieben",
		your_hunt_number_pool_is_empty: "Ihr Jagdnummernpool ist leer",
		your_withdrawal_will_be_sent: "Ihre Auszahlung wird gesendet  sobald keine unbestätigten Einzahlungen auf Ihrem Konto vorhanden sind",
		zip: "Zip",
		multi_account: "Mehrere Konten",
		rude_words: "Beleidigende Worte",
		cheating: "Schummeln",
		faucet_farming: "Faucet Farming",
		rain_farming: "Regenfarmen",
		player_insulting: "Spieler beleidigend",
		moderator_insulting: "Moderator beleidigt",
		ignoring_moderator_warning: "Moderator-Warnungen ignorieren",
		asking_for_tips: "Bitte um Tipps",
		flooding_spaming: "Überschwemmungen  Spam",
		self_referral: "Selbstempfehlung",
		other: "Sonstiges",
		activities_hunting_level: "Aktivitätsjagdstufe",
		betting_level: "Wettniveau",
		chat_activity_level: "Chat-Aktivitätslevel",
		contest_activity_level: "Wettbewerbsaktivitätslevel",
		jackpot_hunting_level: "Jackpot-Jagdlevel",
		loyalty_level: "Treuestufe",
		rainmaker_level: "Regenmacher-Level",
		referrals_betting_level: "Empfehlungs-Wettlevel",
		referring_level: "Verweisende Ebene",
		wagering_level: "Einsatzniveau",
		warn_level: "Warnstufe",
		activities_hunting_level_d: "Hängt von der Gesamtzahl der gewonnenen Aktivitäten ab. Maximal {0} Punkte ({1} Aktivitäten)",
		betting_level_d: "Hängt von der Gesamtzahl der Wetten ab. Maximal {0} Punkte ({1} Wetten)",
		chat_activity_level_d: "Hängt von der Gesamtzahl der Nachrichten im Chat ab. Maximal {0} Punkte ({1} Nachrichten)",
		contest_activity_level_d: "Hängt von der Gesamtzahl der gewonnenen Wettbewerbe ab. Maximal {0} Punkte ({1} Wettbewerbe).",
		jackpot_hunting_level_d: "Hängt von der Gesamtzahl der gewonnenen Jackpots ab. Maximal {0} Punkte ({1} Jackpots).",
		loyalty_level_d: "Hängt von der Gesamtzahl der Tage ab  die seit dem Datum der Registrierung vergangen sind. Maximal {0} Punkte ({1} Tage).",
		rainmaker_level_d: "Hängt von der Gesamtzahl der durchgeführten Regenfälle ab. Maximal {0} Punkte ({1} Regenfälle).",
		referrals_betting_level_d: "Hängt von der Gesamtzahl der geworbenen Wetten ab. Maximal {0} Punkte ({1} Wetten).",
		referring_level_d: "Hängt von der Gesamtzahl der Empfehlungen ab. Maximal {0} Punkte ({1} Empfehlungen).",
		wagering_level_d: "Hängt vom Gesamteinsatzbetrag ab. Maximal {0} Punkte ({1} USD).",
		warn_level_d: "Hängt von der Gesamtzahl der erhaltenen Warnungen ab. Maximal {0} Punkte ({1} Warnungen)",
		hide: "Ausblenden\t ",
		network: "Netzwerk ",
		not_enough_wager: "  Nicht genug Wetteinsatz. Zusätzlicher {0}$-Wetteinsatz erforderlich. ",
		recommend_set_pass: "Wir empfehlen Ihnen dringend  ein Passwort einzurichten.",
		recommend_set_mail: "Wir empfehlen Ihnen dringend  E-Mail einzurichten.",
		freespins: "  Freispiele  ",
		freespins_repeat: "Freespins wiederholen sich  ",
		freespin_repeat_left: "Freespins werden wiederholt alle 24 Stunden {0} Mal gutgeschrieben.",
		freespin_repeat_left2: "(Die Aktivierung erfolgt nur  wenn die aktuellen Freispiele vollständig gespielt werden)  ",
		my_freespins: "  Meine Freispiele  ",
		not_available: "Nicht verfügbar\t ",
		deposits_made: "Zählung der getätigten Einzahlungen  ",
		mail_must_be_verified: "  E-Mail muss verifiziert werden  ",
		gamble_aware: "Glücksspielbewusst",
		soccer: "Fußball",
		basketball: "Basketball",
		tennis: "Tennis",
		icehockey: "Eishockey",
		casino: "Kasino",
		"t&c": "AGB",
		community: "Gemeinschaft",
	},
	"es-ES": {
		jan: "Ene",
		feb: "Feb",
		mar: "Mar",
		apr: "Abr",
		may: "May",
		jun: "Jun",
		jul: "Jul",
		aug: "Ago",
		sep: "Sep",
		oct: "Oct",
		nov: "Nov",
		dec: "Dic",
		mon: "Lun",
		tue: "Mar",
		wed: "Mie",
		thu: "Jue",
		fri: "Vie",
		sat: "Sab",
		sun: "Dom",
		abbrevation: "Abreviación",
		accepted_currencies: "Monedas aceptadas",
		access_code: "Código de acceso",
		access_denied: "Acceso denegado",
		account: "Cuenta",
		action: "Acción",
		actions: "Acciones",
		action_not_allowed: "Acción no permitida",
		activate: "Activar",
		active: "Activo",
		active_rollhunts: "Activa la caza de rollos",
		аctivities: "Actividades",
		add: "Añadir",
		add_to_friends: "Añadir a los amigos",
		address: "Dirección",
		address_create_error: "No se puede crear una dirección",
		address_created: "Dirección creada",
		address_not_generated_yet: "Dirección no generada aún",
		administrator: "Administrador",
		administrators: "Administradores",
		affiliate: "Afiliado",
		affiliation: "Afiliación",
		affiliate_casino_loss: "Pérdidas del casino por usuarios referidos",
		affiliate_casino_revenue: "Ingresos del casino por usuarios referidos",
		aff_claims: "Reclamaciones de afiliación",
		affiliate_commission_rate: "Tasa de comisión de referencia actual",
		affiliate_current_balance: "Saldo actual de los usuarios referidos",
		affiliate_deposit_count: "Número de depósitos",
		affiliate_net_commission: "Comisión neta",
		affiliate_referral_profit: "Beneficio neto de los referidos",
		affiliate_stats: "Estadísticas del afiliado",
		affiliate_user_count: "Número actual de usuarios referidos",
		affiliation_management: "Gestión de la afiliación",
		after: "Después de",
		all: "Todos los",
		all_bets: "Todas las apuestas",
		all_in: "¿Todas las apuestas?",
		all_your_progress_will_be_lost: "Todo su progreso se perderá (dep. bonificación)",
		allow_faucet_if_has_bank: "Permitir faucet si tiene saldo en la banca",
		allow_faucet_if_has_other_coin_balance: "Permitir faucet si tiene saldo en otra moneda",
		allow_rains: "Permitir lluvias",
		already_approved_by: "Ya aprobado por",
		already_cancelled_by: "Ya cancelado por",
		already_verified: "Entrada ya verificada",
		amount: "Cantidad",
		amount_claimed: "Importe reclamado",
		amount_not_number: "El importe no es un número",
		amount_per_user: "Importe por usuario",
		animals_and_nature: "Animales y naturaleza",
		approved: "Aprobado",
		are_you_sure: "¿Está seguro?",
		auto: "Auto",
		auto_cash_out: "Auto cash out",
		auto_roll: "Auto roll",
		avatar: "Avatar",
		away: "Fuera",
		balance: "Saldo",
		balance_changed: "Saldo modificado",
		balance_zero: "El saldo debe ser cero",
		balance_over_limit_reached: "Saldo por encima del límite alcanzado",
		balance_under_limit_reached: "Saldo por debajo del límite alcanzado",
		ban: "Prohibición",
		banned: "Prohibido",
		banner: "Banner",
		banners: "Banners",
		before: "Antes de",
		slide: "Diapositiva",
		slides: "Diapositivas",
		bank: "Banco",
		bank_zero: "El saldo del banco debe ser cero",
		bank_in: "Transferencia al banco",
		bank_out: "Transferencia desde el banco",
		beginning_of_the_hour: "Comienzo de la hora",
		beginning_of_the_day: "Comienzo del día",
		beginning_of_the_week: "Comienzo de la semana",
		beginning_of_the_month: "Comienzo del mes",
		bet: "Apuesta",
		bets: "Apuestas",
		bet_amount: "Importe de la apuesta",
		bet_amount_limit_reached: "Límite de apuesta alcanzado",
		bet_info: "Información de la apuesta",
		bets_made_with_pair: "Apuestas realizadas con par",
		billing: "Facturación",
		block: "Bloque",
		block_hash: "Hash del bloque",
		board: "Tablero\n",
		bonus: "Bonus",
		bonus_activated: "Bonificación activada",
		bonus_deactivated: "Bono desactivado",
		bonus_activation_error: "Error de activación de la bonificación",
		bonus_claim: "Reclamar la bonificación",
		bonus_won: "Felicidades, ¡has ganado la bonificación!",
		busy: "Ocupado",
		buy: "Comprar",
		by: "Por",
		by_clicking: "Al hacer clic en el botón de abajo estoy de acuerdo con el",
		cash_out: "Cobro en efectivo",
		calculate_in: "Calcular en",
		campaign: "Campaña",
		cancel: "Cancelar",
		cancelled: "Cancelado",
		cannot_change_entry: "No se puede modificar la entrada",
		cannot_ignore_mod_or_admin: "No se puede ignorar a Mod o Admin",
		cause_rain: "Causa de la lluvia",
		caution: "Precaución",
		city: "Ciudad",
		clear: "Despejar",
		chance: "Oportunidad",
		change_balance: "Cambiar el saldo",
		change_mail: "Cambiar el correo",
		change_client_seed: "Cambiar la semilla del cliente",
		change_password: "Cambiar contraseña\n",
		chat: "Chat",
		chatgame: "Juego de chat",
		chat_game_management: "Gestión de juegos de chat",
		chat_game_statement: "¡Adivina el número del 1 al 100 inclusive y gana un premio! ¡Buena suerte!",
		chat_messages: "Mensajes de chat",
		check_input_values: "Compruebe los valores de entrada",
		check_internet: "Compruebe su conexión a Internet",
		choose_coin: "Elija la moneda",
		claim: "Reclamar",
		clear_2fa: "Borrar 2fa",
		client_seed: "Semilla de cliente",
		closed: "Cerrado",
		code: "Código",
		coin: "Moneda",
		coin_name: "Nombre de la moneda",
		coin_not_selected: "La moneda no está seleccionada",
		coins: "Monedas",
		coin_management: "Gestión de monedas",
		coin_not_active: "Moneda no activa",
		coin_not_stable: "Moneda no estable",
		comment: "Comentario",
		commission_rate: "Tasa de comisión",
		con_connection_error: "Error de conexión",
		con_connection_established: "Conexión establecida",
		con_connection_lost: "Conexión perdida",
		con_reconnect_attempt: "Intento de reconexión: {0}",
		con_reconnect_failed: "Fallo en la reconexión",
		confirm_new_password: "Confirme la nueva contraseña",
		confirm_you_are_human: "Confirme que es un humano",
		confirmation_link_sent: "Enlace de confirmación enviado",
		confirmations_required: "Confirmaciones requeridas",
		confiscate: "Confiscar",
		confiscated: "Confiscado",
		contact_support: "Para más detalles, póngase en contacto con el servicio de asistencia",
		contest: "Concurso",
		contests: "Concursos",
		copied: "Valor copiado en el portapapeles",
		copy: "Copiar",
		copy_to_clipboard: "Copiar al portapapeles",
		country: "País",
		country_code: "Código del país",
		create: "Crear",
		create_an_account: "Inscríbete",
		created_by: "Creado por",
		creator_profit: "Beneficio del creador",
		currency: "Moneda",
		current_balance: "Saldo actual",
		current_data: "Datos actuales",
		current_friends: "Amigos actuales",
		current_rates: "Tasas actuales",
		current_seed_pair: "Pares de semillas actuales",
		current_session: "Actual",
		custom: "Personalizado",
		daily_limit: "Límite diario",
		daily_limit_reached: "Límite diario alcanzado",
		daily_total_amount: "Cantidad total diaria",
		daily_count: "Recuento diario",
		data: "Datos",
		data_not_found: "Datos no encontrados",
		date_joined: "Fecha de ingreso",
		day: "Día",
		days: "Días",
		deactivate: "Desactivar",
		decrease_by: "Disminuir por",
		delay: "Retraso",
		delete: "Borrar",
		delete_message: "Borrar mensaje",
		delta: "Delta",
		deltas: "Deltas",
		deposit: "Depósito",
		deposit_bonus: "Bonificación de depósito",
		deposits: "Depósitos",
		deposit_confirmation_pending:
			"No puede dar propinas, retirar, enviar lluvias o jugar a juegos de terceros mientras la confirmación del depósito esté pendiente",
		deposit_success: "Depósito realizado con éxito, por favor espere 1 confirmación",
		deposit_confirmed: "Depósito realizado con éxito",
		description: "Descripción",
		dice: "Dados",
		disabled: "Desactivado",
		disposable: "Desechable",
		divide_amount: "Divide la cantidad",
		do_not_lose_chance_to_activate_our_deposit_bonuses: "No pierda la oportunidad de activar nuestros bonos de depósito",
		double_check_rates: "Por favor, compruebe si el tipo de cambio actual se ajusta a sus necesidades, ya que el cambio es una acción irreversible.",
		duration: "Duración",
		edit: "Editar",
		edit_message: "Editar mensaje",
		edited: "Editado por",
		embed_code: "Código de inserción (para sitios web)",
		enabled: "Habilitado",
		enter_amount: "Introducir importe",
		enter_address: "Introduzca la dirección",
		enter_tag: "Introduzca la etiqueta",
		enter_delta_amount: "Introducir importe delta",
		enter_token: "Introducir token 2fa\n",
		enter_password: "Introduzca la contraseña",
		entry_not_found: "Entrada no encontrada\n",
		entry_add: "Añadir entrada",
		entry_already_exists: "Esta entrada ya existe",
		entry_added: "Entrada añadida",
		entry_remove: "Eliminar entrada",
		entry_removed: "Entrada eliminada",
		entry_update: "Actualizar entrada",
		entry_updated: "Entrada actualizada",
		exchange: "Intercambio",
		exchange_from: "Intercambio de",
		exchange_to: "Intercambio a",
		exchange_management: "Gestión de intercambios",
		exchanges: "Intercambios",
		exchange_success: "Importe intercambiado con éxito",
		expired: "Caducado",
		error: "Error",
		every: "Todo",
		every_losses: "Todas las pérdidas",
		every_wins: "Cada ganancia",
		fa_if_enabled: "2FA (si está activado)",
		failed: "Fallido",
		fairness: "Equidad",
		fairness_check: "Comprobación de equidad",
		faq: "FAQ",
		faucet: "Grifo",
		faucet_enable: "Activar el grifo",
		faucet_digits_count: "Recuento de dígitos adivinables del grifo",
		faucet_disabled: "El grifo está desactivado en este momento",
		faucet_abuse: "Debido al abuso del grifo o a las actividades de cultivo del grifo, se aplican algunas limitaciones a su cuenta",
		faucet_claimed: "Grifo reclamado con éxito",
		faucet_claimed_recently: "Ha reclamado recientemente el grifo y debe esperar antes de volver a reclamarlo.",
		faucet_claims: "Reclamaciones de faucet",
		faucet_farmer_ignore_admin: "Ignorar el cultivo de grifos por parte de los administradores",
		faucet_farmer_ignore_mod: "Ignorar el cultivo de grifos por parte de los moderadores",
		faucet_farmers: "Agricultores de grifos",
		faucet_farming_msg: "Debido a la agricultura de grifo se reduce la cantidad reclamada",
		faucets_in_hour: "Las reclamaciones de grifo en 1 hora para considerar al jugador como un agricultor de grifo",
		faucet_management: "Gestión de grifos",
		faucet_not_claimed: "No se puede reclamar el grifo",
		fbi: "FBI",
		filter: "Filtrar",
		fp: "huella dactilar",
		fingerprint: "Huella dactilar",
		fingerprints: "Huellas dactilares",
		finished: "Terminado",
		finish_to_reveal: "Terminar el juego para revelar la semilla",
		field_blank: "El campo no puede estar vacío",
		first_deposit: "Primer depósito",
		flags: "Banderas",
		food_and_drink: "Comida y bebida",
		for: "para",
		forever: "Para siempre",
		formula: "Fórmula",
		forum: "Foro",
		forgot_password: "Olvidé mi contraseña",
		free_play: "Juego gratis",
		frequently_used: "Usado frecuentemente",
		friend_request_sent: "Solicitud de amistad enviada",
		friend: "Amigo",
		friends: "Amigos",
		from: "Desde",
		game: "Juego",
		game_added_to_favourites: "Juego añadido a los favoritos",
		game_removed_from_favourites: "Juego eliminado de los favoritos",
		game_provider: "Proveedor de juegos",
		games: "Juegos",
		game_balance: "Balance del juego",
		gambling_forbidden: "y el juego no está prohibido por las autoridades locales y tengo al menos 18 años.",
		generate_new: "Generar nuevo",
		general_info: "Información general",
		geographical_data: "Datos geográficos",
		gfa_cleared_successfully: "2fa borrado con éxito",
		gfa_data_not_found: "No se ha encontrado ninguna entrada de activación 2fa",
		gfa_management: "Gestión de Google 2FA",
		gfa_reset: "Restablecimiento de 2fa",
		gfa_set: "2fa configurado",
		gfa_on: "Google 2FA activado",
		gfa_off: "Google 2FA desactivado",
		granted: "Concedido",
		guests: "Invitados",
		h: "h",
		hasLower: "Contiene una letra minúscula",
		hasNumber: "Contiene un número",
		hasSymbol: "Contiene un símbolo",
		hasUpper: "Contiene letra mayúscula",
		history: "Historia",
		hour: "Hora",
		hours: "Horas",
		high_rollers: "Grandes jugadores",
		home: "Inicio",
		hotkeys: "Hotkeys",
		hotkeys_are_currently: "Las teclas de acceso rápido son actualmente",
		house_edge: "Borde de la casa",
		hunt: "Caza",
		hunt_number: "Número de caza",
		hunt_number_pool: "Grupo de números de caza",
		id: "Id",
		ignore: "Ignorar",
		ignored_players: "Jugadores ignorados",
		img: "Imagen",
		in_a_row: "En una fila",
		income: "Ingresos",
		inputs: "Entradas",
		increase_by: "Aumento por",
		inc_by: "Inc. por",
		info: "Información",
		infinite: "Infinito",
		insufficient_funds: "Fondos insuficientes",
		insufficient_privileges: "Privilegios insuficientes [{0}]",
		invalid_address: "Dirección no válida",
		invalid_amount: "Importe no válido",
		invalid_arguments: "Argumentos no válidos",
		invalid_bet_amount: "Importe de la apuesta no válido",
		invalid_currency: "Moneda no válida",
		invalid_ip_fp: "El nombre de usuario con esa IP y huella digital ya existe",
		invalid_login_information: "Información de inicio de sesión no válida",
		invalid_mail: "Correo electrónico no válido",
		invalid_password: "Contraseña inválida",
		invalid_password_or_2fa: "Contraseña o 2fa no válidos",
		invalid_time: "Hora inválida",
		invalid_token: "Token inválido",
		invalid_username: "Nombre de usuario no válido",
		invalid_wallet_password: "Contraseña del monedero no válida",
		investment_required: "Inversión requerida",
		ip: "Ip",
		ip_fp_change_detected: "Se ha detectado un cambio de IP / huella digital, por favor, vuelva a iniciar sesión",
		is_online: "Está en línea",
		is_offline: "Está desconectado",
		isp: "ISP",
		jackpot: "Jackpot",
		jackpot_management: "Gestión del bote",
		jackpots: "Jackpots",
		join: "Únase a",
		join_price: "Precio de inscripción",
		key_expired: "Su clave ha caducado",
		lat: "Lat",
		leaderboard: "Tabla de clasificación",
		let_others_know: "Haz que los demás lo sepan",
		level: "Nivel",
		live: "En vivo",
		loading: "Cargando",
		lobby: "Lobby",
		login: "Iniciar sesión",
		login_first: "Por favor, inicie sesión primero",
		logout: "Cierre de sesión",
		lon: "Lon",
		lose: "Perder",
		loss: "Pérdida",
		losses: "Pérdidas",
		luck: "Suerte",
		lucky_number: "Número de la suerte",
		lucky_number_today: "El número de la suerte de hoy",
		lucky_players_are: "Los jugadores afortunados son",
		mail: "Correo",
		mail_verified: "Correo verificado",
		mail_confirmed: "Gracias, su correo está confirmado",
		mail_management: "Gestión del correo",
		mail_set: "Correo de recuperación establecido, la confirmación ha sido enviada a",
		mail_not_found: "Correo no encontrado",
		mail_not_confirmed: "Correo no confirmado",
		mail_not_set: "Correo no configurado",
		management: "Gestión",
		manual: "Manual",
		marked_for_delete: "Marcado para ser borrado",
		max: "Máximo",
		max_bet: "Apuesta máxima",
		max_amount: "Importe máximo",
		menu: "Menú",
		message: "Mensaje",
		message_deleted: "Mensaje eliminado",
		min: "Mínimo",
		min_amount: "Importe mínimo",
		min_amount_not_matched: "Importe mínimo no coincidente",
		min_exchange_amount: "Importe mínimo de intercambio",
		min_left: "{0} min. restante",
		min_level_1: "No se puede chatear hasta alcanzar el nivel 2",
		min_level_not_reached: "Nivel mínimo no alcanzado",
		min_level_required: "Mínimo nivel requerido\n",
		min6: "min {0} caracteres",
		minutes: "Minutos",
		minimal_exhcange_amount_is: "La cantidad mínima de intercambio es",
		minimal_faucet_amount_is: "La cantidad recibida del grifo es",
		minimal_withdraw_amount_is: "El importe mínimo de retirada es",
		minute: "Mínimo",
		moderator: "Moderador",
		moderator_profit: "Beneficio del moderador",
		moderators: "Moderadores",
		money: "Dinero",
		monitor: "Monitor",
		multiply_amount: "Importe de la multiplicación",
		multiplier: "Multiplicador",
		mute: "Silenciar",
		mute_sm: "Silenciar",
		muted: "Silenciado",
		my_bets: "Mis apuestas",
		my_position: "Mi posición",
		my_recovery_mail: "Mi correo de recuperación",
		my_wager: "Mi apuesta",
		n_days: "{0} días",
		n_hours: "{0} horas",
		n_minutes: "{0} minutos",
		n_weeks: "{0} semanas",
		n_months: "{0} meses",
		name: "Nombre",
		new: "Nuevo",
		news: "Noticias",
		new_message: "Nuevo mensaje",
		new_messages: "Nuevos mensajes",
		new_password: "Nueva contraseña",
		new_password_min6: "Nueva contraseña (mín. 6 caracteres)",
		no: "No",
		no_active_contests: "No se han encontrado concursos activos",
		no_content_found: "No se han encontrado contenidos",
		no_pending_contests: "No se han encontrado concursos pendientes",
		no_expired_contests: "No se han encontrado concursos caducados",
		no_available_actions: "No hay acciones disponibles",
		no_data_found: "No se han encontrado datos",
		no_limit: "No hay límite",
		no_recurrence: "No hay recurrencia",
		not_allowed_by_system: "No permitido por el sistema",
		not_enough_players: "No hay suficientes jugadores",
		not_implemented: "Función actual no implementada",
		not_logged_in: "Por favor, inicie sesión para realizar esta acción",
		not_revealed_yet: "La semilla aún no ha sido revelada",
		notes: "Notas",
		notifications: "Notificaciones",
		number_of_players: "Número de jugadores",
		number_of_roll_limit_reached: "Número de tiradas alcanzadas",
		number_of_rolls: "Número de tiradas",
		objects: "Objetos",
		offline: "Aparecer fuera de línea",
		old_password: "Contraseña antigua",
		only_with_balance: "Sólo con saldo",
		of_points: "{0} de {1} puntos",
		of_points_total: "Total {0} de {1} puntos",
		off: "Apagado",
		on: "En",
		on_loss: "Sobre pérdida",
		on_win: "Sobre victoria",
		online: "En línea",
		only: "Sólo",
		opened: "Abierto",
		optional: "Opcional",
		or: "O",
		org: "Org",
		outcome: "Resultado",
		outputs: "Resultados",
		overall_stats: "En general",
		overall_data: "Datos globales",
		overall_limit: "Límite global",
		pandora: "Pandora",
		page_not_found: "Página no encontrada",
		page_not_found_desc: "La página que buscas no existe; puede haber sido movida o eliminada por completo.",
		pages: "Páginas",
		packet_lost_retrying: "Paquete perdido, reintentando...",
		parameters: "Parámetros",
		participants: "Participantes",
		participate: "Participar",
		participate_in: "Participar en",
		password: "Contraseña",
		password_2fa_change_detected: "Se detecta el cambio de contraseña / 2fa",
		password_management: "Gestión de contraseñas",
		password_at_least: "La contraseña debe tener al menos 6 caracteres",
		passwords_not_match: "las contraseñas no coinciden",
		password_not_set: "Contraseña no establecida",
		password_reset: "Restablecimiento de la contraseña",
		password_reset_instruction: "La instrucción de restablecimiento de contraseña ha sido enviada a su correo",
		password_reset_successfully: "La contraseña se ha restablecido con éxito",
		password_set: "Contraseña establecida",
		password_strength: "Fuerza de la contraseña",
		password_changed: "Contraseña cambiada",
		passwords_match: "Las contraseñas coinciden",
		pause: "Pausa",
		paused: "Pausa",
		payout: "Pago",
		pending: "Pendiente",
		pending_deposit: "Depósito pendiente",
		permissions: "Permisos",
		permissions_management: "Gestión de permisos",
		ping: "Ping",
		place: "Colocar",
		place_bet: "Colocar apuesta",
		play: "Jugar",
		play_now: "Jugar ahora",
		player: "Jugador",
		players: "Jugadores",
		player_has_been_muted: "El jugador ha sido silenciado",
		player_has_been_unblocked: "El jugador ha sido desbloqueado",
		player_not_selected: "El jugador no está seleccionado",
		please_retry: "Por favor, inténtalo de nuevo",
		pm: "PM",
		points_to_next_level: "Puntos para el siguiente nivel",
		previous_seed_pair: "Pareja de semillas anterior",
		priority: "Prioridad",
		privacy: "Política de privacidad",
		prize_pool: "Bolsa de premios",
		probability: "Probabilidad",
		processed: "Procesado",
		promotion: "Promoción",
		profile: "Perfil",
		profit: "Beneficio",
		profit_from_jackpot: "Beneficio del bote",
		profit_from_rollhunt: "Beneficio del rollhunt",
		profit_on_win: "Beneficio de la ganancia",
		provide_password: "Introduzca la contraseña",
		public_chat_rooms: "Salas de chat públicas",
		qr_error: "Error al generar la imagen del QR",
		qr_expired: "QR caducado",
		quantity: "Cantidad",
		rain: "Lluvia",
		rain_amount: "Cantidad de lluvia",
		rain_management: "Gestión de la lluvia",
		rain_received: "Te ha pillado la lluvia",
		rain_sent: "Has provocado la lluvia",
		rains_sent: "Lluvia enviada",
		rain_out: "Lluvias enviadas",
		rains_received: "Lluvia recibida",
		rain_in: "Lluvia recibida",
		randomize: "Aleatorizar",
		randomise_to_reveal: "Aleatorizar para revelar",
		randomization_error: "Error al cambiar la semilla del cliente",
		randomization_failed: "Error de aleatorización",
		randomization_success: "Semilla de cliente cambiada con éxito",
		ratios: "Ratios",
		read_mode: "Modo de lectura",
		read_more: "Leer más",
		reason: "Razón",
		recurrence: "Recurrencia",
		recent: "Reciente",
		reconnect: "Reconectar",
		recover: "Recuperar",
		recovery_password_sent: "Contraseña de recuperación enviada",
		referral_link: "Enlace de referencia",
		ref_by: "Ref. por",
		ref_count: "Recuento de referencias",
		refresh: "Actualizar",
		reg_date: "Fecha reg.",
		region: "Región",
		registration: "Registro/Inicio de sesión",
		registration_successful: "registro exitoso",
		reject: "Rechazar",
		rejected: "Rechazado",
		remove_from_friends: "Eliminar de los amigos",
		required_field: "Campo obligatorio",
		relative_balance_account: "Cuenta de saldo relativa",
		relative_bank_account: "Cuenta bancaria relativa",
		reload: "Recargar",
		reload_page: "El sitio ha sido actualizado, por favor recargue la página",
		resend_confirmation_link: "Reenviar enlace de confirmación",
		reset: "Restablecer",
		reset_password: "Restablecer contraseña",
		result: "Resultado",
		to_base: "A la base",
		return_to_lobby: "Volver al vestíbulo",
		reverse: "Invertir",
		revoked: "Revocar",
		roll: "Rollo",
		rolls: "Rollos\n",
		roll_dice: "Tirar dados",
		roll_over: "Por encima de",
		roll_under: "Bajo",
		roll_hunt: "Tirada de caza",
		room: "Habitación",
		room_create: "Crear sala",
		room_name: "Nombre de la sala",
		room_not_active: "La sala no está activa",
		rooms: "Habitaciones",
		rooms_management: "Gestión de habitaciones",
		save: "Guardar",
		search: "Buscar en",
		search_game: "Buscar juego",
		sec: "Sec",
		security: "Seguridad",
		seconds: "Segundos",
		seconds_remaining: "Segundos restantes",
		select_player: "Seleccionar jugador",
		select_coin: "Seleccionar moneda",
		send: "Enviar",
		send_pm: "Enviar PM",
		send_tip: "Enviar consejo",
		series: "Serie",
		server_error: "Error del servidor",
		server_seed: "Semilla del servidor",
		server_seed_changed: "La semilla del servidor ha cambiado",
		server_sha_512: "Semilla del servidor hash sha512",
		server_time: "Hora del servidor",
		set: "Establecer",
		set_bet_amount: "Por favor, establezca la cantidad de la apuesta",
		set_password: "Establecer contraseña",
		set_password_first: "Establezca la contraseña primero",
		set_mail: "Establecer correo",
		set_recovery_mail: "Establecer un correo de recuperación",
		settings: "Ajustes",
		settings_saved: "Ajustes guardados",
		shortcuts: "Accesos directos",
		show_more: "Mostrar más",
		sign_in: "Iniciar sesión",
		simple_balance: "Equilibrio simple",
		site_to_be_shutdown: "El sitio se va a actualizar, todo el juego se detiene",
		skip: "omitir",
		slider: "Deslizador",
		slots: "Ranuras",
		slow_down: "Demasiadas peticiones, ralentizar",
		smileys_and_people: "Smileys y gente",
		something_went_wrong: "Algo salió mal",
		show_in: "Mostrar en {0}",
		sound: "Sonido",
		speed: "Velocidad",
		split: "Dividir",
		sport: "Deporte",
		stable: "Estable",
		stats: "Estadísticas",
		statistics: "Estadísticas",
		starting_point: "Punto St.",
		starts_after: "Comienza después de",
		status: "Estado",
		stop: "Parada",
		stop_on_loss: "Stop activado en caso de pérdida",
		stop_on_win: "Stop activado en caso de ganancia",
		stop_on_balance_under: "Parada activada en caso de [importe de la apuesta por debajo del] límite señalado",
		stop_on_bet_amount_over: "Stop activado en [importe de la apuesta por encima del] límite señalado",
		stop_on_balance_over: "Stop disparado en [importe del saldo por encima del] límite señalado",
		stop_on_bet_amount_under: "Parada activada en [importe de la apuesta por debajo] del límite señalado",
		stop_on_number_of_rolls: "Parada activada en [número de tiradas]",
		stop_if_over: "Parar si se sobrepasa",
		stop_if_under: "Stop si es inferior",
		stop_if_balance_under: "Stop si el saldo es inferior",
		stop_if_balance_over: "Stop si el saldo es superior",
		stop_if_bet_amount_over: "Stop si el importe de la apuesta es superior",
		streak: "Racha",
		submit: "Enviar",
		support: "Soporte",
		symbols: "Símbolos",
		system: "Sistema",
		system_management: "Gestión del sistema",
		the_most_popular: "El portal de criptomonedas más justo y fiable",
		tags: "Etiquetas",
		take_seat: "Tomar asiento",
		tc: "Términos y condiciones",
		time: "Tiempo",
		time_left: "Tiempo restante",
		time_from: "Tiempo desde",
		time_to: "Hora de llegada",
		timezone: "Zona horaria",
		tip: "Consejo\n",
		tips: "Propinas",
		tip_amount: "Cantidad de propina",
		tip_received: "Propina recibida",
		tips_send: "Enviar propinas",
		tip_management: "Gestión de propinas",
		tip_not_sent: "Propina no enviada",
		tip_sent: "Propina enviada",
		tips_sent: "Propinas enviadas",
		tips_receive: "Recibir propinas",
		tips_received: "Propinas recibidas",
		title: "Título",
		to: "A",
		to_withdraw_solve_captcha: "Retirar resolver captcha",
		too_many_requests: "Demasiadas solicitudes",
		token: "Token",
		token_expired: "Su token ha caducado, por favor, vuelva a iniciar sesión",
		toggle_jackpot: "Activar el bote",
		toggle_fa_mode: "Activar el modo 2FA",
		toggle_rollhunt: "Conmutar el rollhunt",
		toggle_slider: "Activar el control deslizante",
		top_250_rain: "Top 250 por probabilidad de ser atrapado por una lluvia",
		total: "Total",
		total_bets: "Total de apuestas",
		total_losses: "Total de pérdidas",
		total_bets_shared: "Total de apuestas compartidas",
		total_messages: "Total de mensajes",
		total_profit: "Total de ganancias",
		total_wagered: "Total apostado",
		total_wins: "Total de ganancias",
		transaction_fee: "Tarifa de la transacción",
		transaction: "Transacción",
		transactions: "Transacciones",
		transaction_complete: "Transacción completa",
		translate: "Traducir",
		travel_and_places: "Viajes y lugares",
		turn_on: "Activar",
		turn_off: "Desactivar",
		two_factor_authentication: "Autenticación de dos factores",
		type: "Escribe",
		type_a_message: "Escriba un mensaje",
		type_numbers_only: "Escriba sólo números",
		type_your_mail: "Escriba su correo",
		unblock_sm: "Desbloquear",
		unclaimed_profit: "Beneficio no reclamado",
		unexpected_error: "Se ha producido un error inesperado",
		unignore: "Descartar",
		unlimited: "Ilimitado",
		unread: "No leído",
		unstable: "Inestable",
		upload_error: "Error al subir el archivo",
		upload_success: "Archivo cargado con éxito",
		usd_change_is_shown: "Tenga en cuenta que el cambio de saldo total en USD se muestra con las tasas históricas",
		user: "Usuario",
		user_name: "Nombre de usuario",
		username_already_taken: "Nombre de usuario ya tomado",
		username_min6: "Nombre de usuario (mín. 6 caracteres)",
		username_not_found: "Usuario no encontrado",
		usernames: "Nombres de usuario",
		values_are_based_on_ranking: "Los valores se basan en la clasificación",
		verify: "Verificar",
		verified: "Verificado",
		verification_failed: "Verificación fallida",
		view_profile: "Ver perfil",
		vip_chat_rooms: "Salas de chat VIP",
		virtual_sport: "Virt.Sport",
		w_requests: "Solicitudes de W.",
		wager: "Apuesta",
		wagering_contest: "Concurso de apuestas",
		waiting_time: "Tiempo de espera",
		wallet_not_found: "Cartera no encontrada",
		was_read_on: "Fue leído en {0}",
		websockets_support: "Su navegador no soporta websockets",
		welcome: "Bienvenido",
		who_is_online: "Quién está en línea",
		win: "Triunfo",
		wins: "Triunfos",
		win_chance: "Oportunidad de ganar",
		winner: "Ganador",
		with_mail: "Con correo",
		withdraw: "Retirar",
		withdraw_approved: "Con. aprobado",
		withdraw_rejected: "Con. rechazado",
		withdraw_min: "Importe mínimo de retirada",
		withdraw_error: "Error de retirada, por favor, póngase en contacto con el servicio de atención al cliente",
		withdraw_success: "Importe retirado con éxito",
		withdraws: "Retirada",
		yes: "Sí",
		you_are_ignored: "No se puede añadir como amigo, porque el jugador objetivo te ha ignorado",
		you_are_not_participating_in_any_campaign: "No estás participando en ninguna campaña",
		you_are_offline: "Estás desconectado",
		you_have_been_muted_by: "Has sido silenciado por",
		you_must_cancel_running_deposit_bonus_to_proceed_with_the_current_action:
			"Debes cancelar el bono de depósito en curso para continuar con la acción actual",
		you_pay: "Usted paga",
		you_receive: "Usted recibe",
		you_tried_too_many_times: "Has intentado demasiadas veces, por favor, inténtalo más tarde",
		your_comment: "Tu comentario",
		you_got: "Has recibido",
		your_place: "Tu plaza",
		your_recovery_mail: "Tu correo de recuperación",
		you_wagered: "Has apostado",
		you_were_tagged: "Te han etiquetado",
		you_wrote: "Has escrito",
		your_hunt_number_pool_is_empty: "Su reserva de números de caza está vacía",
		your_withdrawal_will_be_sent: "Su retiro será enviado una vez que no haya depósitos no confirmados en su cuenta.",
		zip: "Código postal",
		multi_account: "Cuenta múltiple",
		rude_words: "Palabras groseras",
		cheating: "Tramposo",
		faucet_farming: "Cultivo de grifo",
		rain_farming: "Cultivar la lluvia",
		player_insulting: "Insultos a jugadores",
		moderator_insulting: "Insultos de los moderadores",
		ignoring_moderator_warning: "Ignorar las advertencias de los moderadores",
		asking_for_tips: "Pedir consejos",
		flooding_spaming: "Flooding, spaming",
		self_referral: "Auto-referencia",
		other: "Otros",
		activities_hunting_level: "Nivel de caza de actividades",
		betting_level: "Nivel de apuestas",
		chat_activity_level: "Nivel de actividad de chat",
		contest_activity_level: "Nivel de actividad en concursos",
		jackpot_hunting_level: "Nivel de caza del bote",
		loyalty_level: "Nivel de lealtad",
		rainmaker_level: "Nivel de Rainmaker",
		referrals_betting_level: "Nivel de apuestas de referidos",
		referring_level: "Nivel de referidos",
		wagering_level: "Nivel de apuestas",
		warn_level: "Nivel de advertencia",
		activities_hunting_level_d: "Depende del número total de actividades ganadas. Máximo {0} puntos ({1} actividades).",
		betting_level_d: "Depende del número total de apuestas. Máximo {0} Puntos ({1} apuestas).",
		chat_activity_level_d: "Depende del recuento total de mensajes en el chat. Máximo {0} Puntos ({1} mensajes).",
		contest_activity_level_d: "Depende del número total de concursos ganados. Máximo {0} Puntos ({1} concursos).",
		jackpot_hunting_level_d: "Depende del número total de botes ganados. Máximo {0} puntos ({1} botes).",
		loyalty_level_d: "Depende del total de días transcurridos desde la fecha de la inscripción. Máximo {0} Puntos ({1} días).",
		rainmaker_level_d: "Depende del número total de lluvias realizadas. Máximo {0} Puntos ({1} lluvias).",
		referrals_betting_level_d: "Depende del número total de apuestas realizadas. Máximo {0} Puntos ({1} apuestas).",
		referring_level_d: "Depende del recuento total de referidos. Máximo {0} Puntos ({1} referidos).",
		wagering_level_d: "Depende del total de apuestas. Máximo {0} Puntos ({1} USD).",
		warn_level_d: "Depende del total de avisos conseguidos. Máximo {0} Puntos ({1} avisos)",
		hide: "Ocultar",
		network: "Red",
		not_enough_wager: "Apuesta insuficiente. Se requiere una apuesta adicional de {0}$.",
		recommend_set_pass: "Le recomendamos encarecidamente que configure la contraseña",
		recommend_set_mail: "Le recomendamos encarecidamente que configure el correo",
		freespins: "Giros gratis",
		freespins_repeat: "Repetición de tiradas gratuitas",
		freespin_repeat_left: "Las tiradas gratuitas se acreditarán repetidamente {0} veces cada 24 horas",
		freespin_repeat_left2: "(La activación sólo se producirá si las tiradas gratuitas actuales se juegan por completo)",
		my_freespins: "Mis tiradas gratis",
		not_available: "No disponible",
		deposits_made: "Recuento de los depósitos realizados",
		mail_must_be_verified: "El correo debe ser verificado",
		gamble_aware: "Apuesta consciente",
		soccer: "Fútbol",
		basketball: "Baloncesto",
		tennis: "Tenis",
		icehockey: "Hockey sobre hielo",
		casino: "Casino",
		"t&c": "T & C",
		community: "Comunidad",
	},
	"id-ID": {
		jan: "Jan",
		feb: "Feb",
		mar: "Mar",
		apr: "Apr",
		may: "Mei",
		jun: "Jun",
		jul: "Jul",
		aug: "Agu",
		sep: "Sep",
		oct: "Okt",
		nov: "Nov",
		dec: "Des",
		mon: "Sen ",
		tue: "Sel ",
		wed: "Rab ",
		thu: "Kam ",
		fri: "Jum ",
		sat: "Sab ",
		sun: "Min",
		abbrevation: "Singkatan ",
		accepted_currencies: "Mata uang yang diterima ",
		access_code: "Kode akses ",
		access_denied: "Akses ditolak ",
		account: "Akun",
		action: "Tindakan ",
		actions: "Tindakan ",
		action_not_allowed: "Tindakan tidak diperbolehkan ",
		activate: "Aktivasi ",
		active: "Aktif",
		active_rollhunts: "Perburuan aktif ",
		аctivities: "Aktivitas ",
		add: "Tambah ",
		add_to_friends: "Tambahkan ke teman ",
		address: "Alamat",
		address_create_error: "Tidak dapat membuat alamat ",
		address_created: "Alamat dibuat",
		address_not_generated_yet: "Alamat belum dibuat",
		administrator: "Administrator ",
		administrators: "Administrator ",
		affiliate: "Afiliasi ",
		affiliation: "Afiliasi ",
		affiliate_casino_loss: "Kerugian kasino dari pengguna yang dirujuk",
		affiliate_casino_revenue: "Keuntungan kasino dari pengguna yang dirujuk ",
		aff_claims: "Klaim afiliasi",
		affiliate_commission_rate: "Tingkat komisi rujukan saat ini",
		affiliate_current_balance: "Saldo dari pengguna yang dirujuk saat ini ",
		affiliate_deposit_count: "Jumlah setoran",
		affiliate_net_commission: "Komisi bersih",
		affiliate_referral_profit: "Keuntungan rujukan bersih",
		affiliate_stats: "Statistik afiliasi",
		affiliate_user_count: "Jumah pengguna yang dirujuk saat ini ",
		affiliation_management: "Manajemen afiliasi",
		after: "Setelah ",
		all: "Semua ",
		all_bets: "Semua taruhan ",
		all_in: "Semua masuk? ,\n",
		all_your_progress_will_be_lost: "Semua progres Anda akan hilang (dep. bonus) ,\n",
		allow_faucet_if_has_bank: "Biarkan faucet jika memiliki saldo bank",
		allow_faucet_if_has_other_coin_balance: "Izinkan faucet jika memiliki saldo pada koin lain",
		allow_rains: "Izinkan rain ",
		already_approved_by: "Sudah disetujui oleh",
		already_cancelled_by: "Sudah dibatalkan oleh",
		already_verified: "Entri sudah diverifikasi ",
		amount: "Jumlah",
		amount_claimed: "Jumlah yang diklaim",
		amount_not_number: "Jumlah bukan dalam bentuk angka",
		amount_per_user: "Jumlah per pengguna",
		animals_and_nature: "Hewan & Alam ",
		approved: "Disetujui",
		are_you_sure: "Apakah anda yakin ",
		auto: "Auto",
		auto_cash_out: "Penarikan otomatis ",
		auto_roll: "Gulungan otomatis",
		avatar: "Avatar ",
		away: "Jauh ",
		balance: "Saldo",
		balance_changed: "Saldo berubah ",
		balance_zero: "Saldo harus nol",
		balance_over_limit_reached: "Saldo melebihi batas tercapai ",
		balance_under_limit_reached: "Saldo di bawah batas tercapai ",
		ban: "Dilarang ",
		banned: "Dilarang",
		banner: "Spanduk ",
		banners: "Spanduk ",
		before: "Sebelum ",
		slide: "Slide ",
		slides: "Slide ",
		bank: "Bank ",
		bank_zero: "Saldo bank harus nol ",
		bank_in: "Transfer ke bank ",
		bank_out: "Transfer dari bank ",
		beginning_of_the_hour: "Awal jam",
		beginning_of_the_day: "Awal hari ",
		beginning_of_the_week: "Awal minggu ",
		beginning_of_the_month: "Awal bulan ",
		bet: "Taruhan ",
		bets: "Taruhan",
		bet_amount: "Jumlah taruhan ",
		bet_amount_limit_reached: "Batas jumlah taruhan tercapai ",
		bet_info: "Informasi taruhan ",
		bets_made_with_pair: "Taruhan dibuat dengan pasangan",
		billing: "Penagihan",
		block: "Blok",
		block_hash: "Block hash ",
		board: "Papan ",
		bonus: "Bonus ",
		bonus_activated: "Bonus diaktifkan",
		bonus_deactivated: "Bonus dinonaktifkan",
		bonus_activation_error: "Kesalahan aktivasi bonus",
		bonus_claim: "Klaim bonus",
		bonus_won: "Selamat, anda memenangkan bonus!",
		busy: "Sibuk ",
		buy: "Beli ",
		by: "Oleh ",
		by_clicking: "Dengan mengklik tombol di bawah ini, saya setuju dengan",
		cash_out: "Kas keluar",
		calculate_in: "Menghitung dalam ",
		campaign: "Kampanye ",
		cancel: "Membatalkan ",
		cancelled: "Dibatalkan",
		cannot_change_entry: "Tidak dapat mengubah entri",
		cannot_ignore_mod_or_admin: "Tidak dapat mengabaikan Mod atau Admin ",
		cause_rain: "Membuat hujan ",
		caution: "Peringatan ",
		city: "Kota ",
		clear: "Jelas ",
		chance: "Kesempatan ",
		change_balance: "Ubah saldo ",
		change_mail: "Ubah email ",
		change_client_seed: "Ubah seed klien ",
		change_password: "Ubah password ",
		chat: "Obrolan ",
		chatgame: "Obrolan permainan ",
		chat_game_management: "Chat manajemen game ",
		chat_game_statement: "Tebak angka dari 1 hingga 100 inklusif dan menangkan hadiahnya! Semoga Sukses!",
		chat_messages: "Pesan obrolan",
		check_input_values: "Periksa nilai masukan ",
		check_internet: "Mohon cek koneksi internet anda , ",
		choose_coin: "Pilih koin ",
		claim: "Klaim ",
		clear_2fa: "Kosongkan 2fa ",
		client_seed: "Seed klien",
		closed: "Tutup",
		code: "Kode ",
		coin: "Koin ",
		coin_name: "Nama koin ",
		coin_not_selected: "Koin tidak dipilih ",
		coins: "Koin",
		coin_management: "Manajemen koin ",
		coin_not_active: "Koin tidak aktif",
		coin_not_stable: "Koin tidak stabil",
		comment: "Komentar",
		commission_rate: "Tingkat komisi",
		con_connection_error: "Koneksi bermasalah",
		con_connection_established: "Koneksi dibuat",
		con_connection_lost: "Koneksi hilang",
		con_reconnect_attempt: "Upaya menyambung kembali: {0}",
		con_reconnect_failed: "Upaya menyambung kembali gagal",
		confirm_new_password: "Konfirmasi kata sandi baru",
		confirm_you_are_human: "Konfirmasikan bahwa Anda adalah manusia",
		confirmation_link_sent: "Tautan konfirmasi terkirim",
		confirmations_required: "Konfirmasi diperlukan",
		confiscate: "Sita",
		confiscated: "Disita",
		contact_support: "Untuk lebih jelasnya, hubungi dukungan",
		contest: "Kontes ",
		contests: "Kontes",
		copied: "Nilai disalin ke Clipboard",
		copy: "Salin",
		copy_to_clipboard: "Menyalin ke clipboard",
		country: "Negara",
		country_code: "Kode negara",
		create: "Membuat\t",
		create_an_account: "Daftar",
		created_by: "Dibuat oleh",
		creator_profit: "Keuntungan kreator",
		currency: "Mata uang",
		current_balance: "Saldo saat ini",
		current_data: "Data sekarang",
		current_friends: "Teman saat ini ",
		current_rates: "Tarif saat ini",
		current_seed_pair: "Pasangan benih saat ini ",
		current_session: "Saat ini",
		custom: "Kustom ",
		daily_limit: "Batas harian",
		daily_limit_reached: "Batas harian tercapai ",
		daily_total_amount: "Jumlah total harian ",
		daily_count: "Hitungan harian",
		data: "Data ",
		data_not_found: "Data tidak ditemukan ",
		date_joined: "Tanggal bergabung",
		day: "Hari",
		days: "Hari ",
		deactivate: "Deaktivasi",
		decrease_by: "Berkurang sebanyak",
		delay: "Tunda ",
		delete: "Hapus ",
		delete_message: "Hapus pesan ",
		delta: "Delta ",
		deltas: "Delta ",
		deposit: "Setoran ",
		deposit_bonus: "Bonus setoran ",
		deposits: "Setoran ",
		deposit_confirmation_pending:
			"Anda tidak dapat memberi tip, menarik, mengirim hujan, atau memainkan game pihak ketiga saat konfirmasi setoran tertunda ",
		deposit_success: "Setoran berhasil, mohon tunggu 1 konfirmasi",
		deposit_confirmed: "Setoran berhasil",
		description: "Deskripsi ",
		dice: "Dadu ",
		disabled: "Dinonaktifkan ",
		disposable: "Sekali pakai",
		divide_amount: "Bagi jumlah",
		do_not_lose_chance_to_activate_our_deposit_bonuses: "Jangan kehilangan kesempatan untuk mengaktifkan bonus deposit kami",
		double_check_rates:
			"Silakan periksa kembali apakah nilai tukar saat ini sesuai dengan kebutuhan Anda, karena pertukaran ini adalah tindakan yang tidak dapat diubah. ",
		duration: "Durasi ",
		edit: "Edit ",
		edit_message: "Edit pesan ",
		edited: "Ed. oleh",
		embed_code: "Sematkan kode (untuk situs web)",
		enabled: "Diaktifkan ",
		enter_amount: "Masukan jumlah",
		enter_address: "Masukkan alamat",
		enter_tag: "Masukkan tag ",
		enter_delta_amount: "Masukkan jumlah delta",
		enter_token: "Masukkan token 2fa",
		enter_password: "Masukkan kata kunci",
		entry_not_found: "Entri tidak ditemukan",
		entry_add: "Tambahkan entri",
		entry_already_exists: "Entri tersebut sudah ada",
		entry_added: "Entri ditambahkan",
		entry_remove: "Hapus entri ",
		entry_removed: "Entri dihapus",
		entry_update: "Perbarui entri",
		entry_updated: "Entri diperbarui",
		exchange: "Pertukaran",
		exchange_from: "Tukar dari",
		exchange_to: "Tukarkan ke",
		exchange_management: "Manajemen pertukaran ",
		exchanges: "Pertukaran ",
		exchange_success: "Berhasil menukar jumlah",
		expired: "Kedaluwarsa ",
		error: "Kesalahan ",
		every: "Setiap",
		every_losses: "Setiap kerugian ",
		every_wins: "Setiap kemenangan ",
		fa_if_enabled: "2fA (apabila diaktifkan) ",
		failed: "Gagal ",
		fairness: "Keadilan ",
		fairness_check: "Pemeriksaan keadilan ",
		faq: "Tanya Jawab ",
		faucet: "Keran ",
		faucet_enable: "Aktifkan keran ",
		faucet_digits_count: "Hitungan angka keran yang bisa ditebak",
		faucet_disabled: "Keran dinonaktifkan saat ini ",
		faucet_abuse: "Karena penyalahgunaan Keran atau aktivitas pertanian Keran, beberapa batasan diterapkan pada akun Anda ",
		faucet_claimed: "Keran berhasil diklaim",
		faucet_claimed_recently: "Anda baru saja mengklaim dari Keran dan harus menunggu sebelum mengklaim lagi.",
		faucet_claims: "Klaim keran",
		faucet_farmer_ignore_admin: "Abaikan pertanian keran oleh administrator",
		faucet_farmer_ignore_mod: "Abaikan pertanian faucet oleh moderator",
		faucet_farmers: "Petani Keran",
		faucet_farming_msg: "Karena faucet farming diklaim jumlahnya telah dikurangi",
		faucets_in_hour: "Keran mengklaim dalam 1 jam untuk menganggap pemain sebagai petani faucet",
		faucet_management: "Manajemen keran",
		faucet_not_claimed: "Tidak dapat mengklaim faucet",
		fbi: "FBI ",
		filter: "Filter",
		fp: "Sidik jari ",
		fingerprint: "Sidik jari",
		fingerprints: "Sidik jari ",
		finished: "Selesai ",
		finish_to_reveal: "Selesaikan game untuk menampakkan benih ",
		field_blank: "Bidang tidak boleh kosong",
		first_deposit: "Setoran pertama",
		flags: "Bendera",
		food_and_drink: "Makanan & Minuman",
		for: "untuk",
		forever: "Selamanya",
		formula: "Formula ",
		forum: "Forum ",
		forgot_password: "Lupa kata sandi ",
		free_play: "Bermain gratis ",
		frequently_used: "Sering digunakan",
		friend_request_sent: "Permintaan teman dikirim",
		friend: "Teman",
		friends: "Teman-teman",
		from: "Dari",
		game: "Permainan",
		game_added_to_favourites: "Permainan ditambahkan ke daftar favorit",
		game_removed_from_favourites: "Game dihapus dari daftar favorit",
		game_provider: "Penyedia permainan",
		games: "Permainan",
		game_balance: "Keseimbangan permainan",
		gambling_forbidden: "dan perjudian tidak dilarang oleh otoritas lokal saya dan saya berusia minimal 18 tahun. ",
		generate_new: "Hasilkan yang baru ",
		general_info: "Informasi Umum",
		geographical_data: "Data Geografis",
		gfa_cleared_successfully: "2fa berhasil dibersihkan",
		gfa_data_not_found: "Entri aktivasi 2fa tidak ditemukan ",
		gfa_management: "Pengelolaan Google 2FA",
		gfa_reset: "2fa reset",
		gfa_set: "set 2fa ",
		gfa_on: "Google 2FA diaktifkan",
		gfa_off: "Google 2FA dinonaktifkan",
		granted: "Diberikan ",
		guests: "Tamu ",
		h: "h ",
		hasLower: "Berisi huruf kecil",
		hasNumber: "Berisi nomor",
		hasSymbol: "Berisi simbol",
		hasUpper: "Berisi huruf besar",
		history: "Sejarah",
		hour: "Jam",
		hours: "Jam",
		high_rollers: "High rollers ",
		home: "Rumah ",
		hotkeys: "Hotkeys ",
		hotkeys_are_currently: "Hotkeys sedang",
		house_edge: "House edge ",
		hunt: "Berburu",
		hunt_number: "Nomor berburu",
		hunt_number_pool: "Kolam nomor berburu ",
		id: "Id ",
		ignore: "Hiraukan ",
		ignored_players: "Hiraukan pemain ",
		img: "Gambar",
		in_a_row: "Berbaris",
		income: "Penghasilan",
		inputs: "Masukan",
		increase_by: "Meningkatkan sebanyak",
		inc_by: "Termasuk oleh",
		info: "Info ",
		infinite: "Tak terbatas",
		insufficient_funds: "Dana tidak mencukupi",
		insufficient_privileges: "Hak istimewa tidak mencukupi [{0}]",
		invalid_address: "Alamat tidak valid",
		invalid_amount: "Jumlah tidak valid ",
		invalid_arguments: "Argumen tidak valid",
		invalid_bet_amount: "Jumlah taruhan tidak valid",
		invalid_currency: "Mata uang tidak valid",
		invalid_ip_fp: "Username dengan IP dan sidik jari tersebut sudah ada ",
		invalid_login_information: "Informasi masuk tidak valid ",
		invalid_mail: "Bukan email yang valid ",
		invalid_password: "Kata sandi salah",
		invalid_password_or_2fa: "Kata sandi atau 2fa salah ",
		invalid_time: "Waktu tidak valid ",
		invalid_token: "Token tidak valid",
		invalid_username: "Nama pengguna tidak valid",
		invalid_wallet_password: "Kata sandi dompet tidak valid",
		investment_required: "Investasi diperlukan",
		ip: "IP",
		ip_fp_change_detected: "Pergantian IP / Sidik jari dideteksi, mohon masuk kembali ",
		is_online: "sedang online ",
		is_offline: "sedang ofline ",
		isp: "ISP ",
		jackpot: "Jackpot ",
		jackpot_management: "Manajemen Jackpot ",
		jackpots: "Jackpot ",
		join: "Bergabung ",
		join_price: "Harga Bergabung ",
		key_expired: "Kunci anda kedaluwarsa ",
		lat: "Lat ",
		leaderboard: "Papan peringkat",
		let_others_know: "Biarkan yang lain tahu",
		level: "Tingkat",
		live: "Hidup",
		loading: "Memuat",
		lobby: "Lobi",
		login: "Gabung",
		login_first: "Silahkan masuk terlebih dahulu",
		logout: "Keluar",
		lon: "Lon ",
		lose: "Kalah",
		loss: "Kerugian",
		losses: "Kerugian",
		luck: "Keberuntungan",
		lucky_number: "Nomor keberuntungan",
		lucky_number_today: "Angka keberuntungan hari ini",
		lucky_players_are: "Pemain yang beruntung adalah",
		mail: "Email",
		mail_verified: "Email terverifikasi",
		mail_confirmed: "Terima kasih, email Anda telah terverifikasi ",
		mail_management: "Manajemen email",
		mail_set: "Email pemulihan ditetapkan, konfirmasi telah dikirim ke",
		mail_not_found: "Surat tidak ditemukan",
		mail_not_confirmed: "Surat tidak dikonfirmasi",
		mail_not_set: "Email tidak disetel",
		management: "Manajemen",
		manual: "Pedoman",
		marked_for_delete: "Ditandai untuk dihapus",
		max: "Maks",
		max_bet: "Taruhan maksimal",
		max_amount: "Jumlah maksimum",
		menu: "Menu",
		message: "Pesan",
		message_deleted: "Pesan dihapus",
		min: "Min ",
		min_amount: "Jumlah minimal",
		min_amount_not_matched: "Jumlah minimum tidak cocok ",
		min_exchange_amount: "Min. nilai tukar ",
		min_left: "{0} menit tersisa",
		min_level_1: "Kamu tidak bisa mengobrol sampai kamu mencapai level 2 ",
		min_level_not_reached: "Level minimum tidak tercapai ",
		min_level_required: "Level minimum dibutuhkan ",
		min6: "Minimal {0} karakter ",
		minutes: "Menit ",
		minimal_exhcange_amount_is: "Jumlah penukaran minimum adalah ",
		minimal_faucet_amount_is: "Jumlah yang diterima dari faucet adalah ",
		minimal_withdraw_amount_is: "Jumlah penarikan minimum adalah",
		minute: "Min ",
		moderator: "Moderator",
		moderator_profit: "Profit Moderator ",
		moderators: "Moderator ",
		money: "Uang ",
		monitor: "Monitor ",
		multiply_amount: "Kalikan jumlah",
		multiplier: "Pengganda",
		mute: "Bisu",
		mute_sm: "Bisu",
		muted: "Dibungkam",
		my_bets: "Taruhan saya",
		my_position: "Posisi saya",
		my_recovery_mail: "Email pemulihan saya",
		my_wager: "Taruhan saya",
		n_days: "{0} hari",
		n_hours: "{0} jam",
		n_minutes: "{0} menit",
		n_weeks: "{0} minggu",
		n_months: "{0} bulan ",
		name: "Nama ",
		new: "Baru ",
		news: "Berita ",
		new_message: "Pesan baru ",
		new_messages: "Pesan baru",
		new_password: "Kata sandi baru",
		new_password_min6: "Kata sandi baru (min. 6 karakter)",
		no: "Tidak",
		no_active_contests: "Tidak ada kontes aktif yang ditemukan",
		no_content_found: "Tidak ada konten yang ditemukan",
		no_pending_contests: "Tidak ada kontes yang tertunda ditemukan",
		no_expired_contests: "Kontes kedaluwarsa tidak ditemukan",
		no_available_actions: "Tidak ada tindakan yang tersedia",
		no_data_found: "Tidak ada data ditemukan",
		no_limit: "tidak ada batas",
		no_recurrence: "Tidak ada pengulangan",
		not_allowed_by_system: "Tidak diizinkan oleh sistem ",
		not_enough_players: "Jumlah pemain tidak cukup ",
		not_implemented: "Fungsi saat ini tidak diterapkan",
		not_logged_in: "Silahkan masuk untuk melakukan tindakan ini",
		not_revealed_yet: "Benihnya belum terungkap",
		notes: "Catatan",
		notifications: "Pemberitahuan ",
		number_of_players: "Jumlah pemain",
		number_of_roll_limit_reached: "Jumlah batas roll tercapai",
		number_of_rolls: "Jumlah roll",
		objects: "Objek",
		offline: "Tampil offline",
		old_password: "Password lama",
		only_with_balance: "Hanya dengan saldo",
		of_points: "{0} dari {1} poin",
		of_points_total: "Total {0} dari {1} poin",
		off: "Mati ",
		on: "Menyala",
		on_loss: "Pada kerugian",
		on_win: "Saat menang",
		online: "Online",
		only: "Hanya",
		opened: "Dibuka",
		optional: "Opsional",
		or: "Atau",
		org: "Organisasi ",
		outcome: "Hasil",
		outputs: "Keluaran",
		overall_stats: "Keseluruhan",
		overall_data: "Data keseluruhan",
		overall_limit: "Batas keseluruhan",
		pandora: "Pandora",
		page_not_found: "Halaman tidak ditemukan",
		page_not_found_desc: "Halaman yang Anda cari tidak ada; mungkin telah dipindahkan, atau dihapus sama sekali.",
		pages: "Halaman",
		packet_lost_retrying: "Paket hilang , mencoba kembali?",
		parameters: "Parameter",
		participants: "Partisipan",
		participate: "Partisipasi",
		participate_in: "Partisipasi dalam",
		password: "Kata Sandi",
		password_2fa_change_detected: "Perubahan kata sandi / 2fa terdeteksi",
		password_management: "Manajemen kata sandi",
		password_at_least: "Kata sandi harus minimal 6 karakter",
		passwords_not_match: "Kata sandi tidak cocok",
		password_not_set: "Kata sandi tidak ditetapkan",
		password_reset: "Tetapkan ulang kata sandi",
		password_reset_instruction: "Petunjuk penetapan ulang kata sandi telah dikirim ke email Anda",
		password_reset_successfully: "Penetapan ulang sandi berhasil",
		password_set: "Pengaturan kata sandi",
		password_strength: "Kekuatan kata sandi",
		password_changed: "Kata sandi diubah",
		passwords_match: "Password cocok",
		pause: "Jeda",
		paused: "Dijeda",
		payout: "Pembayaran",
		pending: "Tertunda",
		pending_deposit: "Setoran tertunda",
		permissions: "Izin",
		permissions_management: "Manajemen izin",
		ping: "Ping",
		place: "Tempat",
		place_bet: "Tempatkan taruhan",
		play: "Main ",
		play_now: "Main sekarang",
		player: "Pemain",
		players: "Pemain",
		player_has_been_muted: "Pemain telah dibungkam",
		player_has_been_unblocked: "Pemain telah diblokir",
		player_not_selected: "Pemain tidak dipilih",
		please_retry: "Tolong, coba lagi",
		pm: "PM",
		points_to_next_level: "Poin ke level berikutnya",
		previous_seed_pair: "Pasangan benih sebelumnya",
		priority: "Prioritas",
		privacy: "Kebijakan privasi",
		prize_pool: "Kolam Hadiah",
		probability: "Kemungkinan",
		processed: "Diproses",
		promotion: "Promosi",
		profile: "Profil",
		profit: "Laba",
		profit_from_jackpot: "Keuntungan dari jackpot ",
		profit_from_rollhunt: "Keuntungan dari rollhunt ",
		profit_on_win: "Keuntungan dari kemenangan",
		provide_password: "Masukkan kata kunci",
		public_chat_rooms: "Ruang obrolan publik ",
		qr_error: "Kesalahan saat membuat gambar QR",
		qr_expired: "QR kedaluwarsa ",
		quantity: "Kuantitas",
		rain: "Rain",
		rain_amount: "Jumlah rain",
		rain_management: "Manajemen rain",
		rain_received: "Anda terjebak dalam hujan",
		rain_sent: "Anda menyebabkan hujan",
		rains_sent: "Rain yang dikirimkan",
		rain_out: "Rain yang dikirimkan",
		rains_received: "Hujan yang diterima",
		rain_in: "Hujan yang diterima ",
		randomize: "Mengacak",
		randomise_to_reveal: "Mengacak untuk menampakkan ",
		randomization_error: "Kesalahan saat mengganti benih klien",
		randomization_failed: "Pengacakan gagal",
		randomization_success: "Benih klien berhasil diganti",
		ratios: "Rasio",
		read_mode: "Mode membaca",
		read_more: "Baca selengkapnya",
		reason: "Alasan ",
		recurrence: "Pengulangan",
		recent: "Terkini",
		reconnect: "Hubungkan kembali",
		recover: "Pulihkan",
		recovery_password_sent: "Kirim kata sandi pemulihan",
		referral_link: "Tautan rujukan",
		ref_by: "Ref. oleh ",
		ref_count: "Jumlah referensi ",
		refresh: "Perbarui ",
		reg_date: "Tanggal registrasi ",
		region: "Wilayan ",
		registration: "Masuk / Buat akun ",
		registration_successful: "Registrasi berhasil",
		reject: "Tolak",
		rejected: "Ditolak",
		remove_from_friends: "Hapus dari daftar teman",
		required_field: "Kolom yang harus diisi",
		relative_balance_account: "Akun saldo relatif",
		relative_bank_account: "Rekening bank relatif",
		reload: "Muat ulang",
		reload_page: "Situs telah diperbarui, silakan muat ulang halaman",
		resend_confirmation_link: "Kirim ulang tautan konfirmasi",
		reset: "Mengatur ulang",
		reset_password: "Setel ulang kata sandi",
		result: "Hasil",
		to_base: "Untuk dasar",
		return_to_lobby: "Kembali ke lobi ",
		reverse: "Membalik",
		revoked: "Dicabut",
		roll: "Gulung",
		rolls: "Gulungan",
		roll_dice: "Melempar dadu",
		roll_over: "Lebih",
		roll_under: "Dibawah",
		roll_hunt: "Perburuan gulung ",
		room: "Ruang ",
		room_create: "Buat ruang ",
		room_name: "Nama ruang ",
		room_not_active: "Ruang tidak aktif ",
		rooms: "Ruang ",
		rooms_management: "Manajemen ruangan",
		save: "Simpan",
		search: "Cari ",
		search_game: "Cari permainan ",
		sec: "Detik ",
		security: "Keamanan ",
		seconds: "Detik ",
		seconds_remaining: "Beberapa detik tersisa",
		select_player: "Pilih pemain",
		select_coin: "Pilih koin",
		send: "Kirim",
		send_pm: "Kirim PM",
		send_tip: "Kirim tip",
		series: "Seri",
		server_error: "Server error",
		server_seed: "Benih server",
		server_seed_changed: "Benih server berubah",
		server_sha_512: "Benih server sha512 hash",
		server_time: "Waktu server",
		set: "Mengatur",
		set_bet_amount: "Silakan tentukan jumlah taruhan",
		set_password: "Setel kata sandi ",
		set_password_first: "Tetapkan kata sandi terlebih dahulu",
		set_mail: "Setel email",
		set_recovery_mail: "Setel email pemulihan",
		settings: "Pengaturan",
		settings_saved: "Pengaturan disimpan",
		shortcuts: "Jalan pintas",
		show_more: "Tampilkan lebih banyak",
		sign_in: "Masuk",
		simple_balance: "Saldo sederhana",
		site_to_be_shutdown: "Situs akan diperbarui, semua gameplay dihentikan",
		skip: "lewati",
		slider: "Slider",
		slots: "Slot",
		slow_down: "Terlalu banyak permintaan, pelan-pelan",
		smileys_and_people: "Emoji Senyum & Orang",
		something_went_wrong: "Terdapat kesalahan",
		show_in: "Tampilkan di {0}",
		sound: "Suara",
		speed: "Kecepatan",
		split: "Membelah",
		sport: "Olahraga",
		stable: "Stabil ",
		stats: "Statistik",
		statistics: "Statistik ",
		starting_point: "Titik awal ",
		starts_after: "Mulai setelah",
		status: "Status",
		stop: "Berhenti",
		stop_on_loss: "Memicu berhenti saat rugi ",
		stop_on_win: "Memicu berhenti saat menang",
		stop_on_balance_under: "Memicu berhenti pada [jumlah saldo di bawah] batas yang ditentukan",
		stop_on_bet_amount_over: "Memicu berhenti pada [jumlah taruhan di atas] batas yang ditentukan",
		stop_on_balance_over: "Memicu berhenti pada [jumlah saldo di atas] batas yang ditentukan ",
		stop_on_bet_amount_under: "Memicu berhenti pada [jumlah taruhan di bawah] batas yang ditentukan",
		stop_on_number_of_rolls: "Memicu berhenti pada [jumlah gulungan]",
		stop_if_over: "Berhenti apabila melebihi",
		stop_if_under: "Berhenti apabila dibawah ",
		stop_if_balance_under: "Berhenti apabila saldo dibawah",
		stop_if_balance_over: "Berhenti apabila saldo melebihi",
		stop_if_bet_amount_over: "Berhenti apabila jumlah taruhan melebihi",
		streak: "Rentetan",
		submit: "Kirim",
		support: "Mendukung",
		symbols: "Simbol",
		system: "Sistem",
		system_management: "Manajemen sistem",
		the_most_popular: "Portal perjudian crypto paling adil dan tepercaya",
		tags: "Tag",
		take_seat: "Silahkan duduk ",
		tc: "Syarat dan Ketentuan",
		time: "Waktu",
		time_left: "Waktu tersisa",
		time_from: "Waktu dari",
		time_to: "Waktunya untuk",
		timezone: "Zona waktu",
		tip: "Tips ",
		tips: "Tips ",
		tip_amount: "Jumlah tips",
		tip_received: "Tips diterima",
		tips_send: "Kirim tips",
		tip_management: "Manajemen tips",
		tip_not_sent: "Tips tidak terkirim ",
		tip_sent: "Tips terkirim",
		tips_sent: "Tips terkirim",
		tips_receive: "Terima tips ",
		tips_received: "Tips diterima",
		title: "Judul",
		to: "Kepada",
		to_withdraw_solve_captcha: "Untuk menarik, selesaikan captcha",
		too_many_requests: "Terlalu banyak permintaan",
		token: "Token ",
		token_expired: "Token Anda sudah kadaluarsa, silakan login kembali",
		toggle_jackpot: "Beralih jackpot",
		toggle_fa_mode: "Beralih mode 2FA",
		toggle_rollhunt: "Alihkan rollhunt",
		toggle_slider: "Alihkan penggeser",
		top_250_rain: "250 teratas secara kebetulan ditangkap oleh hujan",
		total: "Total ",
		total_bets: "Total taruhan ",
		total_losses: "Total kerugian ",
		total_bets_shared: "Total taruhan yang dibagikan ",
		total_messages: "Total pesan",
		total_profit: "Total keuntungan ",
		total_wagered: "Total yang dipertaruhkan",
		total_wins: "Total kemenangan",
		transaction_fee: "Biaya transaksi ",
		transaction: "Transaksi ",
		transactions: "Transaksi ",
		transaction_complete: "Transaksi selesai ",
		translate: "Terjemahkan ",
		travel_and_places: "Perjalanan & Tempat ",
		turn_on: "Aktifkan",
		turn_off: "Matikan",
		two_factor_authentication: "Proses otentikasi dua faktor",
		type: "Jenis",
		type_a_message: "Ketik pesan",
		type_numbers_only: "Ketik angka saja",
		type_your_mail: "Ketik email Anda",
		unblock_sm: "Buka blokir",
		unclaimed_profit: "Keuntungan yang tidak diklaim",
		unexpected_error: "Terjadi kesalahan tak terduga",
		unignore: "Abaikan",
		unlimited: "Tidak terbatas",
		unread: "Belum dibaca",
		unstable: "Tidak stabil",
		upload_error: "Kesalahan saat mengunggah file",
		upload_success: "Berkas berhasil diunggah",
		usd_change_is_shown: "Harap diperhatikan bahwa perubahan saldo total dalam USD ditampilkan dengan kurs historis ",
		user: "Pengguna ",
		user_name: "Nama belakang",
		username_already_taken: "Nama pengguna sudah diambil ",
		username_min6: "Nama pengguna (min. 6 karakter) ",
		username_not_found: "Pengguna tidak ditemukan",
		usernames: "Nama pengguna ",
		values_are_based_on_ranking: "Nilai didasarkan pada peringkat",
		verify: "Verifikasi ",
		verified: "Diverifikasi",
		verification_failed: "Verifikasi gagal",
		view_profile: "Tampilkan profil",
		vip_chat_rooms: "Ruang obrolan VIP ",
		virtual_sport: "Olahraga Virtual ",
		w_requests: "w. requests",
		wager: "Taruhan",
		wagering_contest: "Kontes Taruhan ",
		waiting_time: "Waktu menunggu",
		wallet_not_found: "Dompet tidak ditemukan ",
		was_read_on: "Dibaca pada {0}",
		websockets_support: "Browser Anda tidak mendukung soket web ",
		welcome: "Selamat datang",
		who_is_online: "Yang sedang online",
		win: "Menang ",
		wins: "Menang ",
		win_chance: "Memenangkan kesempatan ",
		winner: "Pemenang",
		with_mail: "Dengan surat",
		withdraw: "Menarik",
		withdraw_approved: "Penarikan disetujui ",
		withdraw_rejected: "Penarikan ditolak",
		withdraw_min: "Jumlah penarikan minimal ",
		withdraw_error: "Kesalahan penarikan, tolong, hubungi dukungan",
		withdraw_success: "Berhasil menarik jumlah",
		withdraws: "Penarikan",
		yes: "Ya ",
		you_are_ignored: "Tidak dapat menambahkan sebagai teman, karena pemain target mengabaikan Anda",
		you_are_not_participating_in_any_campaign: "Anda tidak berpartisipasi dalam kampanye apa pun",
		you_are_offline: "Anda Offline ",
		you_have_been_muted_by: "Kamu telah dibungkam oleh ",
		you_must_cancel_running_deposit_bonus_to_proceed_with_the_current_action:
			"Anda harus membatalkan bonus setoran yang sedang berjalan untuk melanjutkan tindakan saat ini",
		you_pay: "Anda membayar ",
		you_receive: "Anda menerima ",
		you_tried_too_many_times: "Anda terlalu sering mencoba, mohon coba lagi nanti ",
		your_comment: "Komentar Anda",
		you_got: "Anda punya",
		your_place: "Tempat Anda ",
		your_recovery_mail: "Surat pemulihan Anda ",
		you_wagered: "Anda bertaruh ",
		you_were_tagged: "Anda sudah ditandai ",
		you_wrote: "Anda menulis",
		your_hunt_number_pool_is_empty: "Satuan nomor perburuan anda kosong ",
		your_withdrawal_will_be_sent: "Your withdrawal will be sent once there are no unconfirmed deposits on your account.",
		zip: "Zip ",
		multi_account: "Banyak akun",
		rude_words: "Kata kata kasar",
		cheating: "Curang",
		faucet_farming: "Menabung faucet ",
		rain_farming: "Menabung hujan ",
		player_insulting: "Pemain mengina ",
		moderator_insulting: "Moderator menghina ",
		ignoring_moderator_warning: "Peringatan moderator diabaikan",
		asking_for_tips: "Meminta tips",
		flooding_spaming: "Membanjiri target, mengirim spam",
		self_referral: "Referensi mandiri",
		other: "Lainnya",
		activities_hunting_level: "Tingkat aktivitas berburu",
		betting_level: "Tingkat taruhan",
		chat_activity_level: "Tingkat aktivitas obrolan ",
		contest_activity_level: "Tingkat aktivitas kontes ",
		jackpot_hunting_level: "Tingkat berburu jackpot",
		loyalty_level: "Tingkat kesetiaan ",
		rainmaker_level: "Tingkat pembuat hujan ",
		referrals_betting_level: "Tingkat taruhan rujukan ",
		referring_level: "Tingkat memberi rujukan ",
		wagering_level: "Tingkat taruhan ",
		warn_level: "Peringatkan tingkat",
		activities_hunting_level_d: "Tergantung pada jumlah total aktivitas yang dimenangkan. Maksimum {0} Poin ({1} aktivitas).",
		betting_level_d: "Tergantung pada jumlah total taruhan. Maksimum {0} Poin ({1} taruhan). ",
		chat_activity_level_d: "Tergantung pada jumlah total pesan dalam obrolan. Maksimum {0} Poin ({1} pesan). ",
		contest_activity_level_d: "Tergantung pada jumlah total kontes yang dimenangkan. Maksimum {0} Poin ({1} kontes).",
		jackpot_hunting_level_d: "Tergantung pada jumlah total jackpot yang dimenangkan. Maksimum {0} Poin ({1} jackpot).",
		loyalty_level_d: "Tergantung pada total hari yang berlalu sejak tanggal pendaftaran. Maksimum {0} Poin ({1} hari). ",
		rainmaker_level_d: "Tergantung pada jumlah total hujan yang terjadi. Maksimum {0} Poin ({1} hujan).",
		referrals_betting_level_d: "Tergantung pada jumlah total taruhan dari referensi. Maksimum {0} Poin ({1} taruhan).",
		referring_level_d: "Tergantung pada jumlah total rujukan. Maksimum {0} Poin ({1} rujukan).",
		wagering_level_d: "Tergantung pada jumlah total yang dipertaruhkan. Maksimum {0} Poin ({1} USD).",
		warn_level_d: "Tergantung dari total warning yang didapat. Maksimal {0} Points ({1} warns)",
		hide: "Bersembunyi",
		network: "Jaringan",
		not_enough_wager: "Taruhan tidak cukup. Diperlukan tambahan {0}$ taruhan",
		recommend_set_pass: "Kami sangat menyarankan Anda untuk mengatur kata sandi",
		recommend_set_mail: "Kami sangat menyarankan Anda untuk menyiapkan email",
		freespins: "Putaran gratis",
		freespins_repeat: "Ulang putaran gratis",
		freespin_repeat_left: "Putaran gratis akan dikreditkan sebanyak {0} kali setiap 24 jam",
		freespin_repeat_left2: "(Aktivasi hanya akan terjadi jika putaran bebas saat ini dimainkan sepenuhnya)",
		my_freespins: "Putaran gratis saya",
		not_available: "Tidak tersedia",
		deposits_made: "Jumlah setoran yang dilakukan",
		mail_must_be_verified: "Surat harus diverifikasi",
		gamble_aware: "Sadar perjudian",
		soccer: "Sepak bola",
		basketball: "Bola Basket",
		tennis: "Tenis",
		icehockey: "Hoki es",
		casino: "Kasino",
		"t&c": "S&K",
		community: "Komunitas",
	},
	"pt-PT": {
		jan: "Jan",
		feb: "Fev",
		mar: "Mar",
		apr: "Abr",
		may: "Mai",
		jun: "Jun",
		jul: "Jul",
		aug: "Ago",
		sep: "Set",
		oct: "Out",
		nov: "Nov",
		dec: "Dez",
		mon: "Seg",
		tue: "Ter",
		wed: "Qua",
		thu: "Qui",
		fri: "Sex",
		sat: "Sab",
		sun: "Dom",
		abbrevation: "Abreviatura",
		accepted_currencies: "Moedas aceites",
		access_code: "Código de acesso",
		access_denied: "Acesso negado\t",
		account: "Conta",
		action: "Ação",
		actions: "Ações",
		action_not_allowed: "Ação não permitida",
		activate: "Ativar",
		active: "Ativo",
		active_rollhunts: "Caças ao rolo ativas",
		аctivities: "Atividades",
		add: "Adicionar\t",
		add_to_friends: "Adicionar aos amigos",
		address: "Endereço",
		address_create_error: "Não é possível criar endereço",
		address_created: "Endereço criado",
		address_not_generated_yet: "Endereço ainda não gerado",
		administrator: "Administrador",
		administrators: "Administradores",
		affiliate: "Afiliado",
		affiliation: "Afiliação",
		affiliate_casino_loss: "Perda do casino dos utilizadores referidos",
		affiliate_casino_revenue: "Receita de casino dos utilizadores referidos",
		aff_claims: "Solicitações de afiliação",
		affiliate_commission_rate: "Taxa de comissão de referência atual",
		affiliate_current_balance: "Saldo atual dos utilizadores referidos",
		affiliate_deposit_count: "Número de depósitos",
		affiliate_net_commission: "Comissão líquida",
		affiliate_referral_profit: "Lucro líquido de referência",
		affiliate_stats: "Estatísticas de afiliados",
		affiliate_user_count: "Número atual de utilizadores referidos",
		affiliation_management: "Gestão de afiliação",
		after: "Depois",
		all: "Tudo",
		all_bets: "Todas as apostas",
		all_in: "Apostar tudo?",
		all_your_progress_will_be_lost: "Todo o seu progresso será perdido (bónus dep.) ",
		allow_faucet_if_has_bank: "Permitir torneira se tiver saldo bancário",
		allow_faucet_if_has_other_coin_balance: "Permitir torneira se tiver saldo em outra moeda",
		allow_rains: "Permitir chuvas",
		already_approved_by: "Já aprovado por",
		already_cancelled_by: "Já cancelado por",
		already_verified: "Entrada já verificada",
		amount: "Quantia",
		amount_claimed: "Quantia reivindicada",
		amount_not_number: "Quantia não é um número",
		amount_per_user: "Valor por utilizador",
		animals_and_nature: "Animais e Natureza",
		approved: "Aprovado",
		are_you_sure: "Tem a certeza\t",
		auto: "Auto",
		auto_cash_out: "Retirada automática",
		auto_roll: "Rolagem automática",
		avatar: "Avatar",
		away: "Ausente",
		balance: "Saldo",
		balance_changed: "Saldo alterado",
		balance_zero: "O saldo deve ser zero",
		balance_over_limit_reached: "Saldo acima do limite ",
		balance_under_limit_reached: "Saldo abaixo do limite ",
		ban: "Exclusão",
		banned: "Excluído",
		banner: "Faixa",
		banners: "Faixas",
		before: "Antes ",
		slide: "Slide\t",
		slides: "Slides",
		bank: "Banco",
		bank_zero: "Saldo do banco deve ser zero",
		bank_in: "Transferência para banco",
		bank_out: "Transferência do banco",
		beginning_of_the_hour: "Início da hora",
		beginning_of_the_day: "Início do dia",
		beginning_of_the_week: "Início da semana",
		beginning_of_the_month: "Início do mês\t",
		bet: "Aposta",
		bets: "Apostas",
		bet_amount: "Valor da aposta",
		bet_amount_limit_reached: "Limite do valor da aposta atingido",
		bet_info: "Informações da aposta",
		bets_made_with_pair: "Apostas feitas com par",
		billing: "Cobrança",
		block: "Bloqueio",
		block_hash: "Bloquear hash",
		board: "Quadro\t",
		bonus: "Bónus ",
		bonus_activated: "Bónus ativado",
		bonus_deactivated: "Bónus desativado",
		bonus_activation_error: "Erro de ativação do bónus",
		bonus_claim: "Solicitar bónus",
		bonus_won: "Parabéns, ganhou o bónus!",
		busy: "Ocupado\t",
		buy: "Comprar\t",
		by: "Por",
		by_clicking: "Ao clicar no botão abaixo eu concordo com o ",
		cash_out: "Levantar",
		calculate_in: "Calcular em",
		campaign: "Campanha",
		cancel: "Cancelar",
		cancelled: "Cancelado",
		cannot_change_entry: "Não é possível alterar a entrada",
		cannot_ignore_mod_or_admin: "Não é possível ignorar Mod ou Admin",
		cause_rain: "Por causa da chuva",
		caution: "Cuidado\t",
		city: "Cidade\t",
		clear: "Limpar",
		chance: "Oportunidade",
		change_balance: "Alterar saldo",
		change_mail: "Alterar e-mail",
		change_client_seed: "Alterar semente do cliente",
		change_password: "Alterar a senha",
		chat: "Chat",
		chatgame: "Jogo do Chat",
		chat_game_management: "Gestão de jogos do chat",
		chat_game_statement: "Adivinhe o número de 1 a 100 inclusive e ganhe o prémio! Boa Sorte!",
		chat_messages: "Mensagens do Chat",
		check_input_values: "Verificar valores de entrada",
		check_internet: "Por favor, verifique a sua conexão à internet",
		choose_coin: "Escolha a moeda",
		claim: "Reclamar",
		clear_2fa: "Limpar 2fa",
		client_seed: "Semente do cliente",
		closed: "Fechado",
		code: "Código",
		coin: "Moeda",
		coin_name: "Nome da moeda",
		coin_not_selected: "A moeda não está selecionada",
		coins: "Moedas",
		coin_management: "Gestão de moedas",
		coin_not_active: "Moeda não ativa",
		coin_not_stable: "Moeda não estável",
		comment: "Comentário",
		commission_rate: "Taxa de comissão",
		con_connection_error: "Erro de conexão",
		con_connection_established: "Conexão estabelecida",
		con_connection_lost: "Conexão perdida\t",
		con_reconnect_attempt: "Tentativa de reconexão: {0} ",
		con_reconnect_failed: "Falha na reconexão",
		confirm_new_password: "Confirme a nova palavra-passe",
		confirm_you_are_human: "Confirme que é um humano",
		confirmation_link_sent: "Link de confirmação enviado",
		confirmations_required: "Confirmações necessárias",
		confiscate: "Confiscar",
		confiscated: "Confiscado",
		contact_support: "Para mais detalhes, entre em contacto com o suporte",
		contest: "Concurso",
		contests: "Concursos",
		copied: "Valor copiado para a área de transferência",
		copy: "Copiar",
		copy_to_clipboard: "Copiar para área de transferência\t",
		country: "País\t",
		country_code: "Código do país",
		create: "Criar",
		create_an_account: "Inscrever-se",
		created_by: "Criado por",
		creator_profit: "Lucro do Criador",
		currency: "Moeda",
		current_balance: "Saldo atual",
		current_data: "Dados atuais",
		current_friends: "Amigos atuais",
		current_rates: "Taxas atuais",
		current_seed_pair: "Par de sementes atual",
		current_session: "Atual",
		custom: "Personalizado",
		daily_limit: "Limite diário",
		daily_limit_reached: "Limite diário atingido",
		daily_total_amount: "Valor total diário",
		daily_count: "Contagem diária",
		data: "Dados\t",
		data_not_found: "Dados não encontrados\t",
		date_joined: "Data de adesão",
		day: "Dia",
		days: "Dias\t",
		deactivate: "Desativar",
		decrease_by: "Diminuir em",
		delay: "Atraso",
		delete: "Apagar",
		delete_message: "Apagar mensagem\t",
		delta: "Delta",
		deltas: "Deltas",
		deposit: "Depósito",
		deposit_bonus: "Bónus de depósito",
		deposits: "Depósitos",
		deposit_confirmation_pending:
			"Não pode dar gorjeta, levantar, enviar chuvas ou jogar jogos de terceiros enquanto a confirmação do depósito estiver pendente",
		deposit_success: "Depósito feito com sucesso, aguarde pela confirmação",
		deposit_confirmed: "Depósito feito com sucesso",
		description: "Descrição\t",
		dice: "Dado",
		disabled: "Desativado",
		disposable: "Descartável",
		divide_amount: "Dividir quantia",
		do_not_lose_chance_to_activate_our_deposit_bonuses: "Não perca a oportunidade de ativar os nossos bónus de depósito",
		double_check_rates: "Por favor, verifique se a taxa de câmbio atual atende às suas necessidades, porque a troca é uma ação irreversível.",
		duration: "Duração\t",
		edit: "Editar",
		edit_message: "Editar mensagem",
		edited: "Ed. por",
		embed_code: "Código de incorporação (para sites)",
		enabled: "Ativado",
		enter_amount: "Introduza o valor",
		enter_address: "Introduza o endereço\t",
		enter_tag: "Introduza o tag",
		enter_delta_amount: "Introduza o valor delta",
		enter_token: "Introduza o token 2fa",
		enter_password: "Introduza a senha\t",
		entry_not_found: "Entrada não encontrada",
		entry_add: "Adicionar entrada\t",
		entry_already_exists: "Essa entrada já existe",
		entry_added: "Entrada adicionada",
		entry_remove: "Remover entrada",
		entry_removed: "Entrada removida",
		entry_update: "Atualizar entrada",
		entry_updated: "Entrada atualizada",
		exchange: "Câmbio\t",
		exchange_from: "Trocar de",
		exchange_to: "Trocar para",
		exchange_management: "Gestão de câmbio",
		exchanges: "Trocas",
		exchange_success: "Valor trocado com sucesso",
		expired: "Expirado",
		error: "Erro",
		every: "Tudo\t",
		every_losses: "Todas as perdas",
		every_wins: "Todos os ganhos",
		fa_if_enabled: "2FA (se ativado) ",
		failed: "Falhou",
		fairness: "Imparcialidade",
		fairness_check: "Verificação de imparcialidade",
		faq: "PERGUNTAS FREQUENTES\t",
		faucet: "Torneira\t",
		faucet_enable: "Ativar torneira",
		faucet_digits_count: "Contagem de dígitos da torneira",
		faucet_disabled: "A torneira está desativada neste momento ",
		faucet_abuse: "Devido ao abuso de torneiras ou atividades de cultivo de torneiras, foram aplicadas algumas limitações à sua conta",
		faucet_claimed: "Torneira reivindicada com sucesso",
		faucet_claimed_recently: "Você solicitou  recentemente da torneira e deve esperar antes de uma nova solicitação.",
		faucet_claims: "Reivindicações da torneira",
		faucet_farmer_ignore_admin: "Ignorar a criação de torneira por administradores",
		faucet_farmer_ignore_mod: "Ignorar a criação de torneiras pelos moderadores",
		faucet_farmers: "Criadores de torneiras",
		faucet_farming_msg: "Devido à criação de torneira, o valor reivindicado é reduzido",
		faucets_in_hour: "Torneira solicitada em 1 hora para considerar o jogador como um criador de torneiras",
		faucet_management: "Gestão de torneiras",
		faucet_not_claimed: "Não foi possível solicitar a torneira",
		fbi: "FBI",
		filter: "Filtro",
		fp: "impressão digital",
		fingerprint: "Impressão digital",
		fingerprints: "Impressões digitais",
		finished: "Terminado",
		finish_to_reveal: "Termine o jogo para revelar a semente",
		field_blank: "O campo não pode estar vazio",
		first_deposit: "Primeiro depósito",
		flags: "Bandeiras",
		food_and_drink: "Comida e bebida",
		for: "por",
		forever: "Para sempre",
		formula: "Fórmula",
		forum: "Fórum",
		forgot_password: "Esqueceu a palavra-passe",
		free_play: "Jogue grátis",
		frequently_used: "Usado frequentemente\t",
		friend_request_sent: "Solicitação de amizade enviada",
		friend: "Amigo",
		friends: "Amigos",
		from: "De",
		game: "Jogo\t",
		game_added_to_favourites: "Jogo adicionado aos favoritos",
		game_removed_from_favourites: "Jogo removido dos favoritos",
		game_provider: "Provedor de jogos",
		games: "Jogos",
		game_balance: "Saldo do jogo",
		gambling_forbidden: "e o jogar não é proibido pelas minhas autoridades locais e eu tenho pelo menos 18 anos.\n",
		generate_new: "Gerar novo",
		general_info: "Informações gerais\t",
		geographical_data: "Dados geográficos",
		gfa_cleared_successfully: "2fa limpo com sucesso",
		gfa_data_not_found: "Nenhuma entrada de ativação 2fa foi encontrada",
		gfa_management: "Gestão 2FA do Google",
		gfa_reset: "Redefinir 2fa ",
		gfa_set: "Definir 2fa",
		gfa_on: "Google 2FA ativado",
		gfa_off: "Google 2FA desativado",
		granted: "Garantido",
		guests: "Convidados",
		h: "h",
		hasLower: "Contém letra minúscula",
		hasNumber: "Contém número",
		hasSymbol: "Contém símbolo ",
		hasUpper: "Contém letra maiúscula",
		history: "História",
		hour: "Hora",
		hours: "Horas",
		high_rollers: "Grandes apostadores",
		home: "Casa",
		hotkeys: "Teclas de atalho",
		hotkeys_are_currently: "As teclas de atalho estão atualmente ",
		house_edge: "Borda da casa",
		hunt: "Caça",
		hunt_number: "Caça ao Número",
		hunt_number_pool: "Partilha de caça ao Número",
		id: "Identidade",
		ignore: "Ignorar",
		ignored_players: "Jogadores ignorados",
		img: "Imagem",
		in_a_row: "Em sequência\t",
		income: "Rendimento",
		inputs: "Entradas",
		increase_by: "Aumentar em",
		inc_by: "Inc. por",
		info: "Informações",
		infinite: "Infinito",
		insufficient_funds: "Fundos insuficientes",
		insufficient_privileges: "Privilégios insuficientes [{0}]",
		invalid_address: "Endereço inválido",
		invalid_amount: "Montante inválido",
		invalid_arguments: "Argumentos inválidos",
		invalid_bet_amount: "Valor de aposta inválido",
		invalid_currency: "Moeda inválida",
		invalid_ip_fp: "O nome de utilizador com tal IP e impressão digital já existe ",
		invalid_login_information: "Informações de login inválidas",
		invalid_mail: "E-mail inválido",
		invalid_password: "Senha inválida",
		invalid_password_or_2fa: "Senha inválida ou 2fa",
		invalid_time: "Hora inválida",
		invalid_token: "Token inválido",
		invalid_username: "Nome de utilizador Inválido",
		invalid_wallet_password: "Palavra-passe da carteira inválida",
		investment_required: "Investimento necessário",
		ip: "Ip",
		ip_fp_change_detected: "Alteração de IP/impressão digital detectada, faça login novamente",
		is_online: "Está online",
		is_offline: "Está offline",
		isp: "FAI",
		jackpot: "Jackpot",
		jackpot_management: "Gestão de jackpots",
		jackpots: "Jackpots",
		join: "Aderir",
		join_price: "Preço de adesão",
		key_expired: "A sua palavra-passe expirou",
		lat: "Lat",
		leaderboard: "Entre os melhores",
		let_others_know: "Deixe os outros saberem",
		level: "Nível",
		live: "Ao vivo",
		loading: "A carregar",
		lobby: "Entrada",
		login: "Login",
		login_first: "Por favor, faça login primeiro",
		logout: "Sair",
		lon: "Lon",
		lose: "Perder",
		loss: "Perda",
		losses: "Perdas",
		luck: "Sorte",
		lucky_number: "Número da sorte",
		lucky_number_today: "Número da sorte de hoje",
		lucky_players_are: "Os jogadores felizardos são",
		mail: "E-mail",
		mail_verified: "E-mail verificado",
		mail_confirmed: "Obrigado, seu e-mail está confirmado",
		mail_management: "Gestão de e-mail",
		mail_set: "E-mail de recuperação definido, a confirmação foi enviada para ",
		mail_not_found: "E-mail não encontrado",
		mail_not_confirmed: "E-mail não confirmado",
		mail_not_set: "E-mail não definido",
		management: "Gestão",
		manual: "Manual",
		marked_for_delete: "Marcado para exclusão",
		max: "Máximo",
		max_bet: "Aposta máxima",
		max_amount: "Quantia máxima",
		menu: "Menu",
		message: "Mensagem",
		message_deleted: "Mensagem apagada",
		min: "Mínimo",
		min_amount: "Quantia mínima",
		min_amount_not_matched: "Valor mínimo não correspondido",
		min_exchange_amount: "Valor mínimo de troca",
		min_left: "{0} minutos restantes",
		min_level_1: "Não pode utilizar o chat até atingir o nível 2",
		min_level_not_reached: "Nível mínimo não alcançado",
		min_level_required: "min nível necessário",
		min6: "min {0} caracteres",
		minutes: "Minutos",
		minimal_exhcange_amount_is: "O valor mínimo de troca é ",
		minimal_faucet_amount_is: "O valor recebido da torneira é ",
		minimal_withdraw_amount_is: "O valor mínimo de levantamento é ",
		minute: "Mínimo",
		moderator: "moderador",
		moderator_profit: "Lucro do moderador",
		moderators: "Moderadores",
		money: "Dinheiro\t",
		monitor: "Monitor",
		multiply_amount: "Multiplique a quantidade ",
		multiplier: "Multiplicador",
		mute: "Mudo",
		mute_sm: "Silenciar",
		muted: "Silenciado",
		my_bets: "As minhas apostas",
		my_position: "A minha posição",
		my_recovery_mail: "O meu e-mail de recuperação",
		my_wager: "A minha aposta",
		n_days: "{0} dias",
		n_hours: "{0} horas ",
		n_minutes: "{0} minutos ",
		n_weeks: "{0} semanas ",
		n_months: "{0} meses ",
		name: "Nome",
		new: "Novo",
		news: "Notícia",
		new_message: "Nova mensagem",
		new_messages: "Novas mensagens\t",
		new_password: "Nova palavra-passe",
		new_password_min6: "Nova palavra-passe (mín. 6 caracteres)",
		no: "Não\t",
		no_active_contests: "Nenhum concurso ativo encontrado",
		no_content_found: "Nenhum conteúdo encontrado",
		no_pending_contests: "Nenhum concurso pendente encontrado",
		no_expired_contests: "Nenhum concurso expirado encontrado",
		no_available_actions: "Nenhuma ação disponível",
		no_data_found: "Nenhum dado encontrado",
		no_limit: "sem limite",
		no_recurrence: "Sem recorrência",
		not_allowed_by_system: "Não é permitido pelo sistema",
		not_enough_players: "Não há jogadores suficientes",
		not_implemented: "Função atual não implementada",
		not_logged_in: "Por favor efetue o login para realizar esta ação",
		not_revealed_yet: "A semente ainda não foi revelada",
		notes: "Notas",
		notifications: "Notificações",
		number_of_players: "Número de jogadores",
		number_of_roll_limit_reached: "Número de limite de lançamento atingido",
		number_of_rolls: "Número de lançamentos",
		objects: "Objetos",
		offline: "Aparecer offline",
		old_password: "Palavra-passe Antiga",
		only_with_balance: "Só com saldo",
		of_points: "{0} de {1} pontos ",
		of_points_total: "Total {0} de {1} pontos ",
		off: "Desligado",
		on: "Ligado",
		on_loss: "Em prejuízo",
		on_win: "Em ganho",
		online: "Online",
		only: "Apenas\t",
		opened: "Aberto",
		optional: "Opcional",
		or: "Ou",
		org: "Org",
		outcome: "Resultado",
		outputs: "Saídas",
		overall_stats: "Geral",
		overall_data: "Dados gerais",
		overall_limit: "Limite geral",
		pandora: "Pandora",
		page_not_found: "Página não encontrada\t",
		page_not_found_desc: "A página que está à procura não existe; pode ter sido movida ou removida completamente.",
		pages: "Páginas\t",
		packet_lost_retrying: "Pacote perdido, a tentar novamente...",
		parameters: "Parâmetros",
		participants: "Participantes",
		participate: "Participar",
		participate_in: "Participar em\t",
		password: "Palavra-passe",
		password_2fa_change_detected: "Alteração de palavra-passe/2fa detectada",
		password_management: "Gestão de palavras-passe",
		password_at_least: "A palavra-passe deve ter pelo menos 6 caracteres",
		passwords_not_match: "As palavras-passe não coincidem\t",
		password_not_set: "Palavra-passe não definida",
		password_reset: "Redefinir palavra-passe\t",
		password_reset_instruction: "Instrução de redefinição de palavra-passe foi enviada para seu e-mail",
		password_reset_successfully: "Palavra-passe redefinida com sucesso",
		password_set: "Defenição de palavra-passe",
		password_strength: "Força da palavra-passe",
		password_changed: "Palavra-passe alterada",
		passwords_match: "Palavras-passe correspondem",
		pause: "Pausa",
		paused: "Pausado",
		payout: "Pagamento",
		pending: "Pendente",
		pending_deposit: "Depósito pendente",
		permissions: "Permissões",
		permissions_management: "Gestão de permissões",
		ping: "Ping",
		place: "Fazer",
		place_bet: "Fazer uma aposta",
		play: "Jogar",
		play_now: "Jogar agora",
		player: "Jogador",
		players: "Jogadores",
		player_has_been_muted: "O jogador foi silenciado",
		player_has_been_unblocked: "O jogador foi desbloqueado",
		player_not_selected: "O jogador não está selecionado",
		please_retry: "Por favor, tente novamente",
		pm: "MP",
		points_to_next_level: "Pontos para o próximo nível",
		previous_seed_pair: "Par de sementes anterior",
		priority: "Prioridade",
		privacy: "Política de Privacidade",
		prize_pool: "Piscina de prémios",
		probability: "Probabilidade",
		processed: "Processado",
		promotion: "Promoção",
		profile: "Perfil",
		profit: "Lucro",
		profit_from_jackpot: "Lucro com o jackpot",
		profit_from_rollhunt: "Lucro com a caça aos rolos",
		profit_on_win: "Lucro na vitória",
		provide_password: "Introduza a senha",
		public_chat_rooms: "Salas de chat públicas",
		qr_error: "Erro ao gerar imagem QR",
		qr_expired: "QR expirado",
		quantity: "Quantidade",
		rain: "Chuva",
		rain_amount: "Quantidade de chuva",
		rain_management: "Gestão da chuva",
		rain_received: "Foi apanhado pela chuva",
		rain_sent: "Você provocou a chuva",
		rains_sent: "Chuvas enviadas",
		rain_out: "Chuvas enviadas",
		rains_received: "Chuvas recebidas",
		rain_in: "Chuvas recebidas",
		randomize: "Randomizar",
		randomise_to_reveal: "Randomize para revelar",
		randomization_error: "Erro ao alterar a semente do cliente",
		randomization_failed: "Falha na randomização",
		randomization_success: "Semente do cliente alterada com sucesso ",
		ratios: "Rácios",
		read_mode: "Modo de leitura",
		read_more: "Leia mais",
		reason: "Razão",
		recurrence: "Recorrência",
		recent: "Recente",
		reconnect: "Reconectar",
		recover: "Recuperar",
		recovery_password_sent: "Palavra-passe de recuperação enviada",
		referral_link: "Link de referência",
		ref_by: "Ref. por",
		ref_count: "Contagem de referência",
		refresh: "Atualizar",
		reg_date: "Data reg.",
		region: "Região",
		registration: "Entrar/Cadastre-se",
		registration_successful: "registo bem sucedido",
		reject: "Rejeitar",
		rejected: "Rejeitado",
		remove_from_friends: "Remover dos amigos",
		required_field: "Campo obrigatório",
		relative_balance_account: "Conta de saldo relativo",
		relative_bank_account: "Conta bancária relativa",
		reload: "Recarregar",
		reload_page: "O site foi atualizado, por favor recarregue a página",
		resend_confirmation_link: "Reenviar link de confirmação",
		reset: "Redefinir",
		reset_password: "Redefinir senha",
		result: "Resultado",
		to_base: "Basear",
		return_to_lobby: "Voltar à entrada",
		reverse: "Reverter",
		revoked: "Revogado",
		roll: "Rolo",
		rolls: "Rolos",
		roll_dice: "Atirar dados",
		roll_over: "Sobre",
		roll_under: "Debaixo",
		roll_hunt: "Caça ao rolo",
		room: "Sal",
		room_create: "Criar sala",
		room_name: "Nome da sala",
		room_not_active: "A sala não está ativa",
		rooms: "Salas",
		rooms_management: "Gestão de salas",
		save: "Guardar",
		search: "Procurar\t",
		search_game: "Procurar jogo",
		sec: "Seg",
		security: "Segurança",
		seconds: "Segundos",
		seconds_remaining: "Segundos restantes",
		select_player: "Selecionar jogador",
		select_coin: "Selecionar moeda",
		send: "Enviar",
		send_pm: "Enviar MP",
		send_tip: "Enviar dica",
		series: "Series",
		server_error: "Erro de servidor",
		server_seed: "Semente do servidor",
		server_seed_changed: "Semente do servidor alterada",
		server_sha_512: "Semente do servidor sha512 hash",
		server_time: "Horário do servidor\t",
		set: "Definir",
		set_bet_amount: "Por favor, defina o valor da aposta",
		set_password: "Configurar palavra-passe",
		set_password_first: "Defina a palavra-passe primeiro",
		set_mail: "Definir e-mail",
		set_recovery_mail: "Definir um e-mail de recuperação",
		settings: "Definições",
		settings_saved: "Configurações salvas",
		shortcuts: "Atalhos",
		show_more: "Mostrar mais",
		sign_in: "Entrar",
		simple_balance: "Saldo simples",
		site_to_be_shutdown: "O site vai ser atualizado, todos os jogos serão suspensos",
		skip: "saltar",
		slider: "Slider",
		slots: "Slots",
		slow_down: "Muitos pedidos, abrande",
		smileys_and_people: "Sorrisos e Pessoas",
		something_went_wrong: "Algo correu mal",
		show_in: "Mostrar em {0}",
		sound: "Som",
		speed: "Velocidade",
		split: "Dividir",
		sport: "Desporto",
		stable: "Estável",
		stats: "Estatísticas",
		statistics: "Estatísticas",
		starting_point: "Ponto de Começo",
		starts_after: "Começa depois",
		status: "Estado",
		stop: "Parar",
		stop_on_loss: "Paragem acionada pela perda",
		stop_on_win: "Paragem acionada pela vitória",
		stop_on_balance_under: "Paragem acionada pelo limite indicado [valor do saldo abaixo]",
		stop_on_bet_amount_over: "Paragem acionada pelo limite indicado [valor da aposta acima]",
		stop_on_balance_over: "Paragem acionada pelo limite indicado [valor do saldo acima]",
		stop_on_bet_amount_under: "Paragem acionada pelo [valor da aposta abaixo] do limite apontado",
		stop_on_number_of_rolls: "Paragem acionada pelo [número de rolos]",
		stop_if_over: "Parar se tiver acabado",
		stop_if_under: "Parar se estiver abaixo",
		stop_if_balance_under: "Parar se o saldo estiver em ",
		stop_if_balance_over: "Parar se o saldo acabar",
		stop_if_bet_amount_over: "Parar se o valor da aposta for superior",
		streak: "À risca",
		submit: "Submeter",
		support: "Apoio",
		symbols: "Símbolos",
		system: "Sistema",
		system_management: "Gestão do sistema",
		the_most_popular: "O portal de jogo de criptomoedas mais justo e confiável",
		tags: "Tag",
		take_seat: "Sente-se",
		tc: "Termos e Condições",
		time: "Tempo",
		time_left: "?empo restante",
		time_from: "Hora de",
		time_to: "Hora até",
		timezone: "Fuso horário",
		tip: "Gorjeta",
		tips: "Gorjetas",
		tip_amount: "Valor da gorjeta",
		tip_received: "Gorjeta recebida",
		tips_send: "Enviar gorjetas",
		tip_management: "Gestão de gorjetas",
		tip_not_sent: "Gorjeta não enviada",
		tip_sent: "Gorjeta enviada",
		tips_sent: "Gorjetas enviadas",
		tips_receive: "Receber gorjetas",
		tips_received: "Gorjetas recebidas",
		title: "Título",
		to: "Para\t",
		to_withdraw_solve_captcha: "Para levantar resolva ocaptcha",
		too_many_requests: "Demasiados pedidos",
		token: "Token",
		token_expired: "O seu token expirou, faça login novamente",
		toggle_jackpot: "Alternar jackpot",
		toggle_fa_mode: "Alternar modo 2FA",
		toggle_rollhunt: "Alternar caça ao rolo",
		toggle_slider: "Alternar slider",
		top_250_rain: "Top 250 no caso de ser apanhado pela chuva",
		total: "Total",
		total_bets: "Apostas totais",
		total_losses: "Perdas totais",
		total_bets_shared: "Total de apostas partilhadas",
		total_messages: "Total de mensagens",
		total_profit: "Lucro total",
		total_wagered: "Total apostado",
		total_wins: "Ganhos totais",
		transaction_fee: "Taxa de transação",
		transaction: "Transação",
		transactions: "Transações",
		transaction_complete: "? ransação concluída ",
		translate: "Traduzir",
		travel_and_places: "Viagens e lugares",
		turn_on: "Ligar",
		turn_off: "Desligar",
		two_factor_authentication: "Autenticação de dois fatores",
		type: "Digite",
		type_a_message: "Digite uma mensagem",
		type_numbers_only: "Digite apenas números",
		type_your_mail: "Digite o seu e-mail",
		unblock_sm: "Desbloquear",
		unclaimed_profit: "Lucro não declarado",
		unexpected_error: "Ocorreu um erro inesperado",
		unignore: "Não Ignorar",
		unlimited: "Ilimitado",
		unread: "Não lida",
		unstable: "Instável",
		upload_error: "Erro ao fazer upload do ficheiro",
		upload_success: "Ficheiro enviado com sucesso",
		usd_change_is_shown: "Observe que a variação total do saldo em USD é mostrada com as taxas históricas",
		user: "Utilizador\t",
		user_name: "Nome de utilizador\t",
		username_already_taken: "Nome de uutilizador já utilizado",
		username_min6: "Nome deutilizador (mín. 6 caracteres)",
		username_not_found: "Utilizador não encontrado",
		usernames: "Nomes de utilizador",
		values_are_based_on_ranking: "Os valores são baseados na classificação",
		verify: "Verificar",
		verified: "Verificado",
		verification_failed: "Falha na verificação\t",
		view_profile: "Ver perfil\t",
		vip_chat_rooms: "Salas de chat VIP",
		virtual_sport: "Supor. Virtual",
		w_requests: "Pedidos de Levantamento",
		wager: "Aposta",
		wagering_contest: "Concurso de apostas",
		waiting_time: "Tempo de espera",
		wallet_not_found: "Carteira não encontrada",
		was_read_on: "Foi lido em {0} ",
		websockets_support: "O seu browser não suporta websockets",
		welcome: "Bem-vindo",
		who_is_online: "Quem está online",
		win: "Ganhar",
		wins: "Ganhos",
		win_chance: "Probabilidade de ganhar",
		winner: "Vencedor\t",
		with_mail: "Com correio",
		withdraw: "Levantar",
		withdraw_approved: "Levantamento aprovado ",
		withdraw_rejected: "Levantamento rejeitado",
		withdraw_min: "Valor mínimo de levantamento",
		withdraw_error: "Erro no levantamento, por favor, entre em contacto com o apoio",
		withdraw_success: "Valor retirado com sucesso",
		withdraws: "Levantamentos",
		yes: "Sim",
		you_are_ignored: "Não é possível adicionar como amigo, porque o jogador o ignorou ",
		you_are_not_participating_in_any_campaign: "Você não está a participar em nenhuma campanha",
		you_are_offline: "Você está offline",
		you_have_been_muted_by: "Você foi silenciado por",
		you_must_cancel_running_deposit_bonus_to_proceed_with_the_current_action:
			"Você deve cancelar o bónus de depósito em execução para prosseguir com a ação atual",
		you_pay: "Você paga",
		you_receive: "Você recebe",
		you_tried_too_many_times: "Você tentou muitas vezes, por favor, tente um pouco mais tarde",
		your_comment: "O seu comentário",
		you_got: "Você tem\t",
		your_place: "O seu lugar",
		your_recovery_mail: "O seu e-mail de recuperação",
		you_wagered: "Você apostou",
		you_were_tagged: "Você foi marcado",
		you_wrote: "Você escreveu\t",
		your_hunt_number_pool_is_empty: "O seu conjunto de números de busca está vazio",
		your_withdrawal_will_be_sent: "OP seu levantamento será enviado assim que não houver depósitos não confirmados na sua conta.",
		zip: "Fecho eclair",
		multi_account: "Múltiplas contas",
		rude_words: "Palavras rudes\t",
		cheating: "Batota",
		faucet_farming: "Criação de torneiras",
		rain_farming: "Criação de chuva",
		player_insulting: "Jogador insultuoso",
		moderator_insulting: "Moderador insultuoso",
		ignoring_moderator_warning: "Avisos do moderador ignorados",
		asking_for_tips: "Pedir gorjetas",
		flooding_spaming: "Inundações, spam",
		self_referral: "Auto-referência",
		other: "Outro",
		activities_hunting_level: "Nível de caça de atividades",
		betting_level: "Nível de apostas",
		chat_activity_level: "Nível de atividade de chat",
		contest_activity_level: "Nível de atividade do concurso",
		jackpot_hunting_level: "Nível de caça ao jackpot",
		loyalty_level: "Nível de fidelidade",
		rainmaker_level: "Nível de criador de chuva",
		referrals_betting_level: "Nível de apostas de referência",
		referring_level: "Nível de referência",
		wagering_level: "Nível de aposta",
		warn_level: "Nível de aviso",
		activities_hunting_level_d: "Depende do número total de atividades ganhas. Máximo de {0} pontos ({1} atividades). ",
		betting_level_d: "Depende da contagem total de apostas. Máximo de {0} pontos ({1} apostas). ",
		chat_activity_level_d: "Depende da contagem total de mensagens no chat. Máximo de {0} pontos ({1} mensagens). ",
		contest_activity_level_d: "Depende do número total de concursos ganhos. Máximo de {0} pontos ({1} concursos). ",
		jackpot_hunting_level_d: "Depende do número total de jackpots ganhos. Máximo de {0} pontos ({1} jackpots). ",
		loyalty_level_d: "Depende do total de dias passados ??desde a data do registo. Máximo de {0} pontos ({1} dias). ",
		rainmaker_level_d: "Depende do número total de chuvas criadas. Máximo de {0} pontos ({1} chuvas). ",
		referrals_betting_level_d: "Depende da contagem total de apostas das referências. Máximo de {0} pontos ({1} apostas). ",
		referring_level_d: "Depende da contagem total de referências. Máximo de {0} pontos ({1} referências). ",
		wagering_level_d: "Depende do valor total apostado. Máximo de {0} pontos ({1} USD). ",
		warn_level_d: "Depende do total de avisos recebidos. Máximo de {0} pontos ({1} avisos) ",
		hide: "Esconder",
		network: "Rede",
		not_enough_wager: "Aposta insuficiente. Requer {0}$ aposta adicional ",
		recommend_set_pass: "Recomendamos fortemente que configure a palavra-passe ",
		recommend_set_mail: "Recomendamos fortemente que configure o e-mail ",
		freespins: "Rodadas Grátis",
		freespins_repeat: "Repetir Rodadas Grátis",
		freespin_repeat_left: "As rodadas grátis serão creditadas repetidamente {0} vezes a cada 24 horas ",
		freespin_repeat_left2: "(A ativação ocorrerá apenas se as rodadas grátis atuais forem utilizadas na totalidade) ",
		my_freespins: "As minhas rodadas grátis",
		not_available: "Não disponível",
		deposits_made: "Contagem dos depósitos feitos",
		mail_must_be_verified: "E-mail deve ser verificado",
		gamble_aware: "Aposta Consciente",
		soccer: "Futebol",
		basketball: "Basquetebol",
		tennis: "Ténis",
		icehockey: "Hockey no gelo",
		casino: "Casino",
		"t&c": "T&C",
		community: "Comunidade",
	},
	"tr-TR": {
		jan: "Ocak",
		feb: "Şubat",
		mar: "Mart",
		apr: "Nisan",
		may: "Mayıs",
		jun: "Haziran",
		jul: "Temmuz",
		aug: "Ağustos",
		sep: "Eylül",
		oct: "Ekim",
		nov: "Kasım",
		dec: "Aralık",
		mon: "Pazartesi",
		tue: "Salı",
		wed: "Çarşamba",
		thu: "Perşembe",
		fri: "Cuma",
		sat: "Cumartesi",
		sun: "Pazar",
		abbrevation: "Kısaltma",
		accepted_currencies: "Kabul görülen para birimleri",
		access_code: "Erişim kodu",
		access_denied: "Reddilen erişim",
		account: "Hesap",
		action: "Eylem",
		actions: "Hareketler",
		action_not_allowed: "İşleme izin verilmiyor",
		activate: "Etkinleştir",
		active: "Aktif",
		active_rollhunts: "Aktif rollhunt'lar",
		аctivities: "Faaliyetler",
		add: "Eklemek",
		add_to_friends: "Arkadaş eklemek",
		address: "Adres",
		address_create_error: "Adres oluşturulamadı",
		address_created: "Adres oluşturuldu",
		address_not_generated_yet: "Adres henüz oluşturulmadı",
		administrator: "Yönetici",
		administrators: "Yöneticiler",
		affiliate: "Bağlı kuruluş",
		affiliation: "Üyelik",
		affiliate_casino_loss: "Yönlendirilen kullanıcılardan kumarhane kaybı",
		affiliate_casino_revenue: "Yönlendirilen kullanıcılardan kumarhane geliri",
		aff_claims: "İlişki iddiaları",
		affiliate_commission_rate: "Mevcut yönlendirme komisyon oranı",
		affiliate_current_balance: "Yönlendirilen kullanıcıların mevcut bakiyesi",
		affiliate_deposit_count: "Mevduat sayısı",
		affiliate_net_commission: "Net komisyon",
		affiliate_referral_profit: "Net tavsiye karı",
		affiliate_stats: "Ortaklık İstatistikleri",
		affiliate_user_count: "Yönlendirilen kullanıcıların mevcut sayısı",
		affiliation_management: "Üyelik yönetimi",
		after: "Sonra",
		all: "Hepsi",
		all_bets: "Tüm bahisler",
		all_in: "Hepsi içinde",
		all_your_progress_will_be_lost: "Tüm ilerlemeniz kaybolacak (dep. bonus)",
		allow_faucet_if_has_bank: "Banka bakiyesi varsa musluğa izin ver",
		allow_faucet_if_has_other_coin_balance: "Diğer madeni para üzerinde bakiye varsa musluğa izin ver",
		allow_rains: "Yağmurlara izin ver",
		already_approved_by: "Tarafından zaten onaylandı",
		already_cancelled_by: "Tarafından zaten iptal edildi",
		already_verified: "Giriş zaten doğrulandı",
		amount: "Miktar",
		amount_claimed: "İstenilen para",
		amount_not_number: "Tutar bir sayı değil",
		amount_per_user: "Kullanıcı başına ücret",
		animals_and_nature: "Hayvanlar & Doğa",
		approved: "Onaylı",
		are_you_sure: "Emin misin",
		auto: "Otomatik",
		auto_cash_out: "Otomatik para çekme",
		auto_roll: "Otomatik rulo",
		avatar: "Avatar",
		away: "Uzak",
		balance: "Denge",
		balance_changed: "Bakiye değişti",
		balance_zero: "Bakiye sıfır olmalı",
		balance_over_limit_reached: "Bakiye limit aşıldı",
		balance_under_limit_reached: "Bakiye limitin altında kaldı",
		ban: "Yasak",
		banned: "Yasaklandı",
		banner: "Afiş",
		banners: "Afişler",
		before: "Önce",
		slide: "Dilim",
		slides: "Dilimler",
		bank: "Banka ",
		bank_zero: "Bankanın bakiyesi sıfır olmalıdır",
		bank_in: "Bankaya transfer",
		bank_out: "Bankadan transfer",
		beginning_of_the_hour: "Saat başı",
		beginning_of_the_day: "Günün başlangıcı",
		beginning_of_the_week: "Haftanın başlangıcı",
		beginning_of_the_month: "Ayın başlangıcı",
		bet: "Bahis",
		bets: "Bahisler",
		bet_amount: "Bahis miktarı",
		bet_amount_limit_reached: "Bahis miktarı sınırına ulaşıldı",
		bet_info: "Bahis bilgisi",
		bets_made_with_pair: "Çift ile yapılan bahisler",
		billing: "Faturalandırma",
		block: "Engellemek",
		block_hash: "Engeli şifrelemek",
		board: "Tahta",
		bonus: "Bonus",
		bonus_activated: "Bonus aktive edildi",
		bonus_deactivated: "Bonus devre dışı bırakıldı",
		bonus_activation_error: "Bonus etkinleştirme hatası",
		bonus_claim: "Bonus talep et",
		bonus_won: "Tebrikler, bonus kazandınız!",
		busy: "Meşgul",
		buy: "Satın almak",
		by: "Tarafından",
		by_clicking: "Aşağıdaki butona tıklayarak kabul ediyorum",
		cash_out: "Para bitti",
		calculate_in: "Hesapla",
		campaign: "Kampanya",
		cancel: "İptal etmek",
		cancelled: "İptal edildi",
		cannot_change_entry: "Giriş değiştirilemiyor",
		cannot_ignore_mod_or_admin: "Mod veya Yönetici göz ardı edilemez\nÜlke\nÜlke kodu\nOluşturmak\nÜye olmak\nTarafından oluşuruldu",
		cause_rain: "Yağmura sebep",
		caution: "Uyarı",
		city: "Şehir",
		clear: "Temiz",
		chance: "Şans",
		change_balance: "Dengeleri değiştirmek",
		change_mail: "Maili değiştirmek",
		change_client_seed: "İstemci tohumunu değiştir",
		change_password: "Şifreyi değiştir",
		chat: "Sohbet",
		chatgame: "Sohbet oyunu",
		chat_game_management: "Sohbet oyunu yönetimi",
		chat_game_statement: "1'den 100'e kadar olan sayıyı tahmin edin ve ödülü kazanın! İyi şanslar!",
		chat_messages: "Sohbet mesajları",
		check_input_values: "Giriş değerlerini kontrol edin",
		check_internet: "Lütfen internet bağlantınızı kontrol edin",
		choose_coin: "Madeni para / coin seçin",
		claim: "İddia",
		clear_2fa: "2fa'yı temizle",
		client_seed: "İstemci tohum",
		closed: "Kapalı",
		code: "Kod",
		coin: "Madeni para / coin",
		coin_name: "Madeni para adı / Coin adı",
		coin_not_selected: "Madeni para seçilmedi / Coin seçilmedi",
		coins: "Madeni paralar / Coinler",
		coin_management: "Madeni para yönetimi / Coin yönetimi",
		coin_not_active: "Madeni para aktif değil / Coin aktif değil",
		coin_not_stable: "Madeni para sabit değil / Coin sabit değil",
		comment: "Yorum",
		commission_rate: "Komisyon oranı",
		con_connection_error: "Connection error        ",
		con_connection_established: "Bağlantı kuruldu",
		con_connection_lost: "Bağlantı koptu",
		con_reconnect_attempt: "Yeniden bağlanma girişimi: {0}",
		con_reconnect_failed: "Yeniden bağlanılamadı",
		confirm_new_password: "Yeni şifreyi onayla",
		confirm_you_are_human: "İnsan olduğunuzu onaylayın",
		confirmation_link_sent: "Onay bağlantısı gönderildi",
		confirmations_required: "Onaylar gerekli",
		confiscate: "El koymak",
		confiscated: "El konuldu",
		contact_support: "Daha fazla ayrıntı için desteğe başvurun",
		contest: "Yarışma",
		contests: "Yarışmalar",
		copied: "Değer panoya kopyalandı",
		copy: "Kopyala",
		copy_to_clipboard: "Panoya kopyala",
		country: "Ülke",
		country_code: "Ülke kodu",
		create: "Oluşturmak",
		create_an_account: "Üye olmak",
		created_by: "Tarafından oluşuruldu",
		creator_profit: "Yaratıcının yararı",
		currency: "Para birimi",
		current_balance: "Mevcut bakiye",
		current_data: "Güncel veri",
		current_friends: "Mevcut arkadaşlar",
		current_rates: "Mevcut oranlar",
		current_seed_pair: "Mevcut tohum çifti",
		current_session: "Mevcut",
		custom: "Gelenek",
		daily_limit: "Günlük sınır",
		daily_limit_reached: "Günlük sınıra ulaşıldı",
		daily_total_amount: "Günlük toplam miktar",
		daily_count: "Günlük sayım",
		data: "Veri",
		data_not_found: "Veri bulunamadı",
		date_joined: "Katılma tarihi",
		day: "Gün",
		days: "Günler",
		deactivate: "Devre dışı bırakmak",
		decrease_by: "Azaltmak",
		delay: "Gecikme",
		delete: "Silmek",
		delete_message: "Mesajı sil",
		delta: "Delta",
		deltas: "Deltalar",
		deposit: "Depozito",
		deposit_bonus: "Para yatırma bonusu",
		deposits: "Mevduat   ",
		deposit_confirmation_pending:
			"Para yatırma onayı beklemedeyken bahşiş veremez, çekemez, yağmur gönderemez veya üçüncü taraf oyunları oynayamazsınız.",
		deposit_success: "Para yatırma işlemi başarılı, lütfen 1 onay için bekleyin",
		deposit_confirmed: "Para yatırma başarısı",
		description: "Tanım",
		dice: "Zar",
		disabled: "Engelli",
		disposable: "Tek kullanımlık",
		divide_amount: "Miktarı böl",
		do_not_lose_chance_to_activate_our_deposit_bonuses: "Para yatırma bonuslarımızı etkinleştirme şansınızı kaybetmeyin",
		double_check_rates:
			"Lütfen mevcut döviz kurunun ihtiyaçlarınızı karşılayıp karşılamadığını iki kez kontrol edin, çünkü değişim geri alınamaz bir işlemdir.",
		duration: "Süre",
		edit: "Düzenlemek\n",
		edit_message: "Mesajı düzenle",
		edited: "Tarafından düzenlendi",
		embed_code: "Yerleştirme kodu (web siteleri için)",
		enabled: "Etkinleştirilmiş",
		enter_amount: "Miktarı girin",
		enter_address: "Adresi girin",
		enter_tag: "Etiketi girin",
		enter_delta_amount: "Delta miktarını girin",
		enter_token: "2fa belirteci girin",
		enter_password: "Şifre girin",
		entry_not_found: "Giriş bulunamadı",
		entry_add: "Giriş ekle",
		entry_already_exists: "Böyle bir giriş zaten var",
		entry_added: "Giriş eklendi",
		entry_remove: "Girişi kaldır",
		entry_removed: "Giriş kaldırıldı",
		entry_update: "Girişi güncelle",
		entry_updated: "Giriş güncellendi",
		exchange: "Değiş tokuş",
		exchange_from: "Değişim",
		exchange_to: "İle değiştir",
		exchange_management: "Değişim yönetimi",
		exchanges: "Takaslar",
		exchange_success: "Tutar başarıyla değiştirildi",
		expired: "Süresi doldu",
		error: "Hata",
		every: "Her",
		every_losses: "Her kayıp",
		every_wins: "Her galibiyet",
		fa_if_enabled: "2FA (etkinleştirilmişse)",
		failed: "Arızalı",
		fairness: "Adalet",
		fairness_check: "Adalet kontrolü",
		faq: "Sıkça Sorulan Sorular(SSS)",
		faucet: "Musluk",
		faucet_enable: "Musluğu etkinleştir",
		faucet_digits_count: "Musluk tahmin edilebilir basamak sayısı",
		faucet_disabled: "Musluk şu anda devre dışı",
		faucet_abuse: "Musluk istismarı veya musluk çiftçiliği faaliyetleri nedeniyle hesabınızda bazı kısıtlamalar uygulandı.",
		faucet_claimed: "Musluk başarıyla talep edildi",
		faucet_claimed_recently: "Yakın zamanda musluktan talepte bulundunuz ve tekrar talep etmeden önce beklemeniz gerekiyor.",
		faucet_claims: "Musluk iddiaları",
		faucet_farmer_ignore_admin: "Yöneticiler tarafından musluk çiftçiliğini görmezden gelin",
		faucet_farmer_ignore_mod: "Moderatörler tarafından musluk çiftçiliğini görmezden gelin",
		faucet_farmers: "Musluk çiftçileri",
		faucet_farming_msg: "Musluk çiftçiliği nedeniyle talep edilen miktar düşürüldü",
		faucets_in_hour: "Musluk, oyuncuyu bir musluk çiftçisi olarak görmek için 1 saat içinde iddia ediyor",
		faucet_management: "Musluk yönetimi",
		faucet_not_claimed: "Musluk talep edilemedi",
		fbi: "Federal Soruşturma Bürosu",
		filter: "Filtre",
		fp: "Parmak izi",
		fingerprint: "Parmak izi",
		fingerprints: "Parmak izleri",
		finished: "Bitmiş",
		finish_to_reveal: "Tohumu ortaya çıkarmak için oyunu bitir",
		field_blank: "Alan boş olamaz",
		first_deposit: "İlk para yatırma",
		flags: "Bayraklar",
		food_and_drink: "Yiyecek & İçecek",
		for: "için",
		forever: "Sonsuza dek",
		formula: "Formül",
		forum: "Forum",
		forgot_password: "Parolanızı mı unuttunuz",
		free_play: "Ücretsiz oyun",
		frequently_used: "Sıklıkla kullanılan",
		friend_request_sent: "Arkadaşlık isteği gönderildi",
		friend: "Arkadaş",
		friends: "Arkadaşlar",
		from: "İtibaren",
		game: "Oyun",
		game_added_to_favourites: "Favorilere eklenen oyun",
		game_removed_from_favourites: "Oyun favorilerden kaldırıldı",
		game_provider: "Oyun sağlayıcı",
		games: "Oyunlar",
		game_balance: "Oyun dengesi",
		gambling_forbidden: "ve kumar yerel yetkililerim tarafından yasak değil ve en az 18 yaşındayım.",
		generate_new: "Yeni oluştur",
		general_info: "Genel Bilgi",
		geographical_data: "Coğrafi veriler",
		gfa_cleared_successfully: "2fa başarıyla temizlendi",
		gfa_data_not_found: "2fa aktivasyon girişi bulunamadı",
		gfa_management: "Google 2FA yönetimi",
		gfa_reset: "2fa sıfırlama",
		gfa_set: "2fa seti",
		gfa_on: "Google 2FA etkinleştirildi",
		gfa_off: "Google 2FA devre dışı bırakıldı",
		granted: "İmtiyazlı",
		guests: "Misafirler",
		h: "h",
		hasLower: "Küçük harf içerir",
		hasNumber: "Sayı içerir",
		hasSymbol: "Sembol içerir",
		hasUpper: "Büyük harf içerir",
		history: "Tarih",
		hour: "Saat\nGiriş yapmak\nLütfen önce giriş yapınız",
		hours: "Saat",
		high_rollers: "Yüksek makaralar",
		home: "Ev",
		hotkeys: "Kısayol Tuşları",
		hotkeys_are_currently: "Kısayol tuşları şu anda",
		house_edge: "Ev kenarı",
		hunt: "Avlamak",
		hunt_number: "Av numarası",
		hunt_number_pool: "Av numarası havuzu",
		id: "Id",
		ignore: "Aldırmamak",
		ignored_players: "Yok sayılan oyuncular",
		img: "Resim",
		in_a_row: "Üst üste",
		income: "Gelir",
		inputs: "Girişler",
		increase_by: "Şuna göre artır:",
		inc_by: "Şuna göre artır:",
		info: "Bilgi",
		infinite: "Sonsuza dek",
		insufficient_funds: "Yetersiz bakiye",
		insufficient_privileges: "Yetersiz ayrıcalık [{0}]",
		invalid_address: "Geçersiz adres",
		invalid_amount: "Geçersiz miktar",
		invalid_arguments: "Geçersiz argümanlar",
		invalid_bet_amount: "Geçersiz bahis miktarı",
		invalid_currency: "Geçersiz para birimi",
		invalid_ip_fp: "Böyle bir IP ve parmak izine sahip kullanıcı adı zaten var",
		invalid_login_information: "Geçersiz giriş bilgisi",
		invalid_mail: "Geçerli bir e-posta değil",
		invalid_password: "Geçersiz şifre",
		invalid_password_or_2fa: "Geçersiz şifre veya 2fa",
		invalid_time: "Geçersiz zaman",
		invalid_token: "Geçersiz belirteç",
		invalid_username: "Geçersiz kullanıcı adı",
		invalid_wallet_password: "Geçersiz cüzdan şifresi",
		investment_required: "Yatırım gerekli",
		ip: "Ip",
		ip_fp_change_detected: "IP / Parmak İzi değişikliği algılandı, lütfen yeniden giriş yapın",
		is_online: "Çevrimiçi",
		is_offline: "Çevrimdışı",
		isp: "İnternet servis sağlayıcı",
		jackpot: "İkramiye",
		jackpot_management: "İkramiye yönetimi",
		jackpots: "Büyük ikramiye",
		join: "Katılmak",
		join_price: "Katılma fiyatı",
		key_expired: "Anahtarınızın süresi doldu",
		lat: "Enlem",
		leaderboard: "Liderler Sıralaması",
		let_others_know: "Başkalarının bilmesine izin ver",
		level: "Seviye",
		live: "Canlı",
		loading: "Yükleniyor",
		lobby: "Lobi",
		login: "Giriş yapmak",
		login_first: "Lütfen önce giriş yapınız",
		logout: "Çıkış Yap",
		lon: "Boylam",
		lose: "Kaybetmek",
		loss: "Kayıp",
		losses: "Kayıplar",
		luck: "Şans",
		lucky_number: "Şanslı sayı",
		lucky_number_today: "Bugünün şanslı numarası",
		lucky_players_are: "Şanslı oyuncular",
		mail: "Posta / Mail",
		mail_verified: "Mail doğrulandı",
		mail_confirmed: "Teşekkürler, mailiniz şimdi onaylandı",
		mail_management: "Mail yönetimi",
		mail_set: "Kurtarma postası ayarlandı, onay şu adrese gönderildi:",
		mail_not_found: "Mail bulunamadı",
		mail_not_confirmed: "Mail onaylanmadı",
		mail_not_set: "Mail ayarlanmadı",
		management: "Yönetmek ",
		manual: "Manuel",
		marked_for_delete: "Silinmek üzere işaretlendi",
		max: "Maksimum",
		max_bet: "Maksimum bahis",
		max_amount: "En yüksek miktar",
		menu: "Menü",
		message: "İleti",
		message_deleted: "Mesaj silindi",
		min: "Minimum / en az",
		min_amount: "Minimum miktar",
		min_amount_not_matched: "Minimum miktar eşleşmedi",
		min_exchange_amount: "Minimum döviz miktarı",
		min_left: "{0} dakika kaldı",
		min_level_1: "2. seviyeye ulaşana kadar sohbet edemezsiniz",
		min_level_not_reached: "Minimum seviyeye ulaşılmadı",
		min_level_required: "Minimum gerekli seviyede",
		min6: "En az {0} karakter",
		minutes: "Dakika",
		minimal_exhcange_amount_is: "Minimum değişim miktarı",
		minimal_faucet_amount_is: "Musluktan alınan miktar",
		minimal_withdraw_amount_is: "Minimum para çekme miktarı",
		minute: "Minimum",
		moderator: "Moderatör",
		moderator_profit: "Moderatör kazancı",
		moderators: "Moderatörler ",
		money: "Para",
		monitor: "Monitör",
		multiply_amount: "Miktarı çarpın",
		multiplier: "Çarpan",
		mute: "Sessize almak",
		mute_sm: "Sessize almak",
		muted: "Sessiz",
		my_bets: "Bahislerim",
		my_position: "Pozisyonum",
		my_recovery_mail: "Kurtarma postam",
		my_wager: "Bahsim",
		n_days: "{0} gün",
		n_hours: "{0} saat",
		n_minutes: "{0} dakika   ",
		n_weeks: "{0} hafta",
		n_months: "{0} ay",
		name: "İsim",
		new: "Yeni",
		news: "Haberler",
		new_message: "Yeni mesaj",
		new_messages: "Yeni mesajlar",
		new_password: "Yeni şifre",
		new_password_min6: "Yeni şifre (en az 6 karakter)",
		no: "Numara",
		no_active_contests: "Aktif yarışma bulunamadı",
		no_content_found: "İçerik bulunamadı",
		no_pending_contests: "Bekleyen yarışma bulunamadı",
		no_expired_contests: "Süresi dolmuş yarışma bulunamadı",
		no_available_actions: "Kullanılabilir işlem yok",
		no_data_found: "Veri bulunamadı",
		no_limit: "Limit yok",
		no_recurrence: "Tekrarlama yok",
		not_allowed_by_system: "Sistem tarafından izin verilmiyor",
		not_enough_players: "Yeterli oyuncu yok",
		not_implemented: "Geçerli işlev uygulanmadı",
		not_logged_in: "Bu eylemi gerçekleştirmek için lütfen giriş yapın",
		not_revealed_yet: "Tohum henüz açıklanmadı",
		notes: "Notlar",
		notifications: "Bildirimler",
		number_of_players: "Number of players        ",
		number_of_roll_limit_reached: "Rulo sınırına ulaşıldı",
		number_of_rolls: "Rulo sayısı",
		objects: "Nesneler",
		offline: "Çevrimdışı görünür",
		old_password: "Eski Şifre",
		only_with_balance: "Sadece denge ile",
		of_points: "{0} / {1} puan",
		of_points_total: "Toplam {0} / {1} puan",
		off: "Kapalı",
		on: "Açık",
		on_loss: "Kaybetmek",
		on_win: "Kazanmak",
		online: "Çevrimiçi",
		only: "Sadece denge ile",
		opened: "Açıldı",
		optional: "İsteğe bağlı",
		or: "Veya",
		org: "Kuruluş",
		outcome: "Sonuç",
		outputs: "Çıktılar",
		overall_stats: "Etraflı",
		overall_data: "Genel veriler",
		overall_limit: "Genel sınır",
		pandora: "Pandora",
		page_not_found: "Sayfa bulunamadı",
		page_not_found_desc: "Aradığınız sayfa mevcut değil; taşınmış veya tamamen kaldırılmış olabilir.",
		pages: "Sayfalar",
		packet_lost_retrying: "Paket kayboldu, yeniden deneniyor...",
		parameters: "Parametreler",
		participants: "Katılımcılar",
		participate: "Katılmak",
		participate_in: "Katılmak",
		password: "Şifre",
		password_2fa_change_detected: "Şifre / 2fa değişikliği algılandı",
		password_management: "Şifre yönetimi",
		password_at_least: "Şifre en az 6 karakter uzunluğunda olmalı",
		passwords_not_match: "Parolalar uyuşmuyor",
		password_not_set: "Şifre ayarlanmadı",
		password_reset: "Parola sıfırlama",
		password_reset_instruction: "Parola sıfırlama talimatı mailinize gönderildi",
		password_reset_successfully: "Şifre başarıyla sıfırlandı",
		password_set: "Şifre seti",
		password_strength: "Şifre güvenlik seviyesi",
		password_changed: "Şifre değiştirildi",
		passwords_match: "Şifre uyuşması",
		pause: "Duraklat",
		paused: "Duraklatıldı",
		payout: "Ödeme",
		pending: "Askıda olması",
		pending_deposit: "Bekleyen mevduat",
		permissions: "İzinler",
		permissions_management: "İzin yönetimi",
		ping: "Gecikme",
		place: "Yer",
		place_bet: "Bahis yeri",
		play: "Oyna",
		play_now: "Şimdi oyna",
		player: "Oyuncu",
		players: "Oyuncular",
		player_has_been_muted: "Oyuncunun sesi kapatıldı",
		player_has_been_unblocked: "Oyuncunun engellemesi kaldırıldı",
		player_not_selected: "Oyuncu seçilmedi",
		please_retry: "Lütfen tekrar deneyin",
		pm: "Öğleden sonra",
		points_to_next_level: "Bir sonraki seviyeye puan",
		previous_seed_pair: "Önceki tohum çifti",
		priority: "Öncelik",
		privacy: "Gizlilik Politikası",
		prize_pool: "Ödül havuzu",
		probability: "Olasılık",
		processed: "İşlenmiş",
		promotion: "Terfi",
		profile: "Profil",
		profit: "Kâr",
		profit_from_jackpot: "Büyük ikramiyeden kâr",
		profit_from_rollhunt: "Rollhunt'tan kazanç",
		profit_on_win: "Kazanma kârı",
		provide_password: "Şifre girin",
		public_chat_rooms: "Genel sohbet odaları",
		qr_error: "QR görüntüsü oluşturulurken hata oluştu",
		qr_expired: "QR'nin süresi doldu",
		quantity: "Miktarı çarpın",
		rain: "Yağmur",
		rain_amount: "Yağmur miktarı",
		rain_management: "Yağmur yönetimi",
		rain_received: "Yağmura yakalandın",
		rain_sent: "Yağmura sebep oldun",
		rains_sent: "Yağmurlar gönderildi",
		rain_out: "Yağmurlar gönderildi",
		rains_received: "Alınan yağmurlar",
		rain_in: "Alınan yağmurlar",
		randomize: "Rastgele",
		randomise_to_reveal: "Ortaya çıkarmak için rastgele",
		randomization_error: "İstemci çekirdeği değiştirilirken hata oluştu",
		randomization_failed: "Randomizasyon başarısız oldu",
		randomization_success: "İstemci çekirdeği başarıyla değiştirildi",
		ratios: "Oranlar",
		read_mode: "Okuma Modu",
		read_more: "Devamını oku",
		reason: "Sebep",
		recurrence: "Yineleme",
		recent: "Son",
		reconnect: "Yeniden bağlan",
		recover: "İyileşmek",
		recovery_password_sent: "Kurtarma şifresi gönderildi",
		referral_link: "Yönlendirme bağlantısı",
		ref_by: "Referans ile",
		ref_count: "Sevk sayısı",
		refresh: "Yenile",
		reg_date: "Kayıt tarihi",
		region: "Bölge",
		registration: "Oturum aç/kaydol",
		registration_successful: "Kayıt başarılı",
		reject: "Reddetmek",
		rejected: "Reddedildi",
		remove_from_friends: "Arkadaşlardan kaldır",
		required_field: "Gerekli alan",
		relative_balance_account: "Bağıl bakiye hesabı",
		relative_bank_account: "Göreceli banka hesabı",
		reload: "Tekrar yükle",
		reload_page: "Site güncellendi, lütfen sayfayı yeniden yükleyin",
		resend_confirmation_link: "Onay bağlantısını tekrar gönder",
		reset: "Sıfırla",
		reset_password: "Şifreyi sıfırla",
		result: "Sonuç",
		to_base: "Tabanına",
		return_to_lobby: "Lobiye dön",
		reverse: "Ters",
		revoked: "İptal edildi",
		roll: "Rulo",
		rolls: "Rulolar",
		roll_dice: "Zar atmak",
		roll_over: "Üzerinde",
		roll_under: "Altında",
		roll_hunt: "Rulo avı",
		room: "Oda",
		room_create: "Oda oluşturmak",
		room_name: "Oda ismi",
		room_not_active: "Oda aktif değil",
		rooms: "Odalar",
		rooms_management: "Oda yönetimi",
		save: "Kaydetmek",
		search: "Aramak",
		search_game: "Oyun ara",
		sec: "Saniye(sn)",
		security: "Güvenlik",
		seconds: "Saniye",
		seconds_remaining: "Kalan saniye",
		select_player: "Oyuncu seç",
		select_coin: "Madeni para seçin",
		send: "Gönder",
		send_pm: "PM gönder",
		send_tip: "Bahşiş gönder",
		series: "Diziler",
		server_error: "Server hatası",
		server_seed: "Sunucu başlangıcı",
		server_seed_changed: "Sunucu çekirdeği değiştirildi",
		server_sha_512: "Sunucu çekirdeği sha512 karma",
		server_time: "Sunucu zamanı",
		set: "Ayarlamak",
		set_bet_amount: "Lütfen bahis miktarını ayarlayın",
		set_password: "Şifreyi belirle",
		set_password_first: "Önce şifreyi ayarla",
		set_mail: "Mail ayarla",
		set_recovery_mail: "Bir kurtarma postası ayarlayın",
		settings: "Ayarlar",
		settings_saved: "Ayarlar kaydedildi",
		shortcuts: "Kısayollar",
		show_more: "Daha fazla göster",
		sign_in: "Kayıt olmak",
		simple_balance: "Basit denge",
		site_to_be_shutdown: "Site güncellenecek, tüm oyun durduruldu",
		skip: "Atlamak",
		slider: "Kaydırıcı",
		slots: "Yuvalar",
		slow_down: "Çok fazla istek, yavaşla",
		smileys_and_people: "Suratlar ve İnsanlar",
		something_went_wrong: "Bir şeyler yanlış gitti",
		show_in: "{0} içinde göster",
		sound: "Ses",
		speed: "Hız",
		split: "Bölmek",
		sport: "Spor",
		stable: "Kararlı",
		stats: "İstatistik",
		statistics: "İstatistik",
		starting_point: "Aziz Nokta",
		starts_after: "Sonra başlar",
		status: "Durum",
		stop: "Dur",
		stop_on_loss: "Kaybedildiğinde tetiklenen durdurma",
		stop_on_win: "Kazanıldığında tetiklenen durdurma",
		stop_on_balance_under: "[Bakiye tutarının altında] belirtilen limitte tetiklenen durdurma",
		stop_on_bet_amount_over: "[Bahis tutarının üzerinde] belirtilen limitte tetiklenen durdurma",
		stop_on_balance_over: "[Bakiye tutarının üzerinde] belirtilen limitte tetiklenen durdurma",
		stop_on_bet_amount_under: "[Bahis tutarının altında] belirtilen limitte tetiklenen durdurma",
		stop_on_number_of_rolls: "[Rulo sayısı] üzerinde tetiklenen durdurma",
		stop_if_over: "Bittiyse dur",
		stop_if_under: "Altındaysa dur",
		stop_if_balance_under: "Denge altındaysa dur",
		stop_if_balance_over: "Denge bozulursa dur",
		stop_if_bet_amount_over: "Bahis miktarı aşılırsa dur",
		streak: "Meç",
		submit: "Göndermek",
		support: "Destek",
		symbols: "Semboller",
		system: "Sistem",
		system_management: "Sistem Yönetimi",
		the_most_popular: "En adil ve güvenilir kripto kumar portalı",
		tags: "Etiketler",
		take_seat: "Oturun",
		tc: "Şartlar ve koşullar",
		time: "Zaman",
		time_left: "Kalan zaman",
		time_from: "Zaman",
		time_to: "Zaman",
		timezone: "Saat dilimi",
		tip: "Bahşiş",
		tips: "Bahşişler",
		tip_amount: "Bahşiş miktarı",
		tip_received: "Bahşiş alındı",
		tips_send: "Bahşiş gönder",
		tip_management: "Bahşiş yönetimi",
		tip_not_sent: "Bahşiş gönderilmedi",
		tip_sent: "Bahşiş gönderildi",
		tips_sent: "Bahşiş gönderildi",
		tips_receive: "Bahşişleri al",
		tips_received: "Alınan bahşişler",
		title: "Başlık",
		to: "İle",
		to_withdraw_solve_captcha: "Captcha'yı çözmek için",
		too_many_requests: "Çok fazla istek",
		token: "Madeni para / coin / jeton",
		token_expired: "Coininizin süresi doldu, lütfen yeniden giriş yapın",
		toggle_jackpot: "Bingoyu aç/kapat",
		toggle_fa_mode: "2FA modunu değiştir",
		toggle_rollhunt: "Rollhunt'u aç/kapat",
		toggle_slider: "Kaydırıcıyı aç/kapat",
		top_250_rain: "Yağmura yakalanmak şans eseri ilk 250",
		total: "Toplam",
		total_bets: "Toplam bahis",
		total_losses: "Toplam kayıplar",
		total_bets_shared: "Paylaşılan toplam bahisler",
		total_messages: "Toplam mesaj",
		total_profit: "Toplam kâr",
		total_wagered: "Toplam bahis",
		total_wins: "Toplam kazanç",
		transaction_fee: "İşlem ücreti",
		transaction: "İşlem",
		transactions: "İşlemler",
		transaction_complete: "İşlem tamamlandı",
		translate: "Çevirmek",
		travel_and_places: "Seyahat ve yerler",
		turn_on: "Açmak",
		turn_off: "Kapatmak",
		two_factor_authentication: "İki Adımlı Doğrulama",
		type: "Tip",
		type_a_message: "Bir mesaj yazın",
		type_numbers_only: "Yalnızca sayıları yazın",
		type_your_mail: "Postanızı yazın",
		unblock_sm: "Engeli kaldırmak",
		unclaimed_profit: "Talep edilmemiş kar",
		unexpected_error: "Beklenmeyen bir hata oluştu",
		unignore: "Yoksay",
		unlimited: "Sınırsız",
		unread: "Okunmamış",
		unstable: "Dengesiz",
		upload_error: "Dosya yüklenirken hata oluştu",
		upload_success: "Dosya başarıyla yüklendi",
		usd_change_is_shown: "USD cinsinden toplam bakiye değişiminin geçmiş oranlarla gösterildiğini lütfen unutmayın.",
		user: "Kullanıcı",
		user_name: "Kullanıcı adı",
		username_already_taken: "Kullanıcı adı önceden alınmış",
		username_min6: "Kullanıcı adı (en az 6 karakter)",
		username_not_found: "Kullanıcı bulunamadı",
		usernames: "Kullanıcı adları",
		values_are_based_on_ranking: "Değerler sıralamaya dayalıdır",
		verify: "Doğrulayın",
		verified: "Doğrulandı",
		verification_failed: "Doğrulama başarısız oldu",
		view_profile: "Profili Görüntüle",
		vip_chat_rooms: "VIP sohbet odaları",
		virtual_sport: "Sanal Spor",
		w_requests: "W. istekleri",
		wager: "Bahis",
		wagering_contest: "Bahis yarışması",
		waiting_time: "Bekleme süresi",
		wallet_not_found: "Cüzdan bulunamadı",
		was_read_on: "{0} tarihinde okundu",
		websockets_support: "Tarayıcınız web yuvalarını desteklemiyor",
		welcome: "Hoş geldiniz",
		who_is_online: "Kim çevrimiçi",
		win: "Kazanmak",
		wins: "Galibiyet",
		win_chance: "Kazanma şansı",
		winner: "Kazanan",
		with_mail: "Mail ile",
		withdraw: "Geri çekilmek",
		withdraw_approved: "İle birlikte onaylı",
		withdraw_rejected: "İle birlikte reddedilmiş",
		withdraw_min: "Minimum para çekme miktarı",
		withdraw_error: "Para çekme hatası, lütfen desteğe başvurun",
		withdraw_success: "Tutar başarıyla çekildi",
		withdraws: "Para çekme",
		yes: "Evet",
		you_are_ignored: "Arkadaş olarak eklenemiyor çünkü hedef oyuncu sizi görmezden geldi",
		you_are_not_participating_in_any_campaign: "Herhangi bir kampanyaya katılmıyorsunuz",
		you_are_offline: "Çevrimdışısınız",
		you_have_been_muted_by: "Tarafından sessize alındınız",
		you_must_cancel_running_deposit_bonus_to_proceed_with_the_current_action:
			"Mevcut işleme devam etmek için devam eden para yatırma bonusunu iptal etmelisiniz",
		you_pay: "Öde",
		you_receive: "Aldığınız",
		you_tried_too_many_times: "Çok fazla denedin, lütfen biraz sonra dene",
		your_comment: "Yorumun",
		you_got: "Sende var",
		your_place: "Yeriniz",
		your_recovery_mail: "Kurtarma postanız",
		you_wagered: "Bahse girdin",
		you_were_tagged: "Etiketlendin",
		you_wrote: "Yazdın",
		your_hunt_number_pool_is_empty: "Av sayı havuzunuz boş",
		your_withdrawal_will_be_sent: "Hesabınızda onaylanmamış para yatırma işlemi olmadığında para çekme işleminiz gönderilecektir.",
		zip: "Posta kodu",
		multi_account: "Çoklu hesap",
		rude_words: "Kaba kelimeler",
		cheating: "Hile",
		faucet_farming: "Musluk çiftçiliği",
		rain_farming: "Yağmur tarımı",
		player_insulting: "Oyuncu hakareti",
		moderator_insulting: "Moderatör hakaret",
		ignoring_moderator_warning: "Moderatör uyarıları yok sayılıyor",
		asking_for_tips: "İpuçları istemek",
		flooding_spaming: "Su baskını, spam gönderme",
		self_referral: "Kendi kendine yönlendirme",
		other: "Başka",
		activities_hunting_level: "Faaliyetler avlanma seviyesi",
		betting_level: "Bahis seviyesi",
		chat_activity_level: "Sohbet etkinliği düzeyi",
		contest_activity_level: "Yarışma aktivite seviyesi",
		jackpot_hunting_level: "İkramiye avlanma seviyesi",
		loyalty_level: "Sadakat seviyesi",
		rainmaker_level: "Yağmur yapıcı seviyesi",
		referrals_betting_level: "Tavsiye bahis seviyesi",
		referring_level: "Referans seviyesi",
		wagering_level: "Bahis seviyesi",
		warn_level: "Uyarı seviyesi",
		activities_hunting_level_d: "Kazanılan toplam aktivite sayısına bağlıdır. Maksimum {0} Puan ({1} aktivite).",
		betting_level_d: "Toplam bahis sayısına bağlıdır. Maksimum {0} Puan ({1} bahis).",
		chat_activity_level_d: "Sohbetteki toplam mesaj sayısına bağlıdır. Maksimum {0} Puan ({1} mesaj).",
		contest_activity_level_d: "Kazanılan toplam yarışma sayısına bağlıdır. Maksimum {0} Puan ({1} yarışma).",
		jackpot_hunting_level_d: "Kazanılan toplam jackpot sayısına bağlıdır. Maksimum {0} Puan ({1} ikramiye).",
		loyalty_level_d: "Kayıt tarihinden itibaren geçen toplam gün sayısına bağlıdır. Maksimum {0} Puan ({1} gün).",
		rainmaker_level_d: "Yapılan toplam yağış sayısına bağlıdır. Maksimum {0} Puan ({1} yağmur).",
		referrals_betting_level_d: "Referansların toplam bahis sayısına bağlıdır. Maksimum {0} Puan ({1} bahis).",
		referring_level_d: "Toplam referans sayısına bağlıdır. Maksimum {0} Puan ({1} tavsiye).",
		wagering_level_d: "Toplam bahis miktarına bağlıdır. Maksimum {0} Puan ({1} USD).",
		warn_level_d: "Alınan toplam uyarıya bağlıdır. Maksimum {0} Puan ({1} uyarır)",
		hide: "Saklamak",
		network: "Ağ",
		not_enough_wager: "Yeterli bahis yok. Gerekli ek {0}$ bahis",
		recommend_set_pass: "Şifre ayarlamanızı şiddetle tavsiye ederiz.",
		recommend_set_mail: "Posta kurmanızı şiddetle tavsiye ederiz.",
		freespins: "Ücretsiz Döndürme tekrarı",
		freespins_repeat: "Ücretsiz Döndürme tekrarı",
		freespin_repeat_left: "Freespin'ler her 24 saatte bir {0} defa tekrar tekrar kredilendirilir",
		freespin_repeat_left2: "(Etkinleştirme yalnızca mevcut ücretsiz döndürmeler tamamen oynandığında gerçekleşir)",
		my_freespins: "Bedava dönüşlerim",
		not_available: "Müsait değil",
		deposits_made: "Yapılan mevduat sayısı",
		mail_must_be_verified: "Posta doğrulanmalıdır",
		gamble_aware: "Kumar farkında",
		soccer: "Futbol",
		basketball: "Basketbol",
		tennis: "Tenis",
		icehockey: "Buz Hokeyi",
		casino: "Kumarhane",
		"t&c": "T & C",
		community: "Toplum",
	},
};
Object.assign(_keywords["en-US"], {
	hide: "Hide",
	network: "Network",
	not_enough_wager: "Not enough wager. Required additional {0}$ wager",
	recommend_set_pass: "We strongly recommend you to set up password",
	recommend_set_mail: "We strongly recommend you to set up mail",
	freespins: "Freespins",
	freespins_repeat: "Freespins repeat",
	freespin_repeat_left: "Freespins will repeatedly be credited {0} times every 24 hours",
	freespin_repeat_left2: "(The activation will only occur if the current free spins are fully played)",
	my_freespins: "My free spins",
	not_available: "Not available",
	deposits_made: "Count of the deposits made",
	mail_must_be_verified: "Mail must be verified",
	gamble_aware: "Gamble aware",
	soccer: "Soccer",
	basketball: "Basketball",
	tennis: "Tennis",
	icehockey: "Icehockey",
	casino: "Casino",
	"t&c": "T & C",
	community: "Community",
});
Object.assign(_keywords["ru-RU"], {
	hide: "Спрятать",
	network: "Сеть",
	not_enough_wager: "Не достаточно оборота. Требуется дополнительно {0}$ оборота",
	recommend_set_pass: "Мы настоятельно рекомендуем вам установить пароль",
	recommend_set_mail: "Мы настоятельно рекомендуем вам установить почту",
	freespins: "Фриспины",
	freespins_repeat: "Повторить Фриспины",
	freespin_repeat_left: "Фриспины будут повторно начисляться {0} раз каждые 24 часа",
	freespin_repeat_left2: "(Начисление будет успешным если текущие спины полностью сыгранны)",
	my_freespins: "Мои фриспины",
	not_available: "Не доступный",
	deposits_made: "Количество сделанных депозитов",
	mail_must_be_verified: "Почта должна быть подтверждена",
	gamble_aware: "Риски азартных игр",
	soccer: "Футбол",
	basketball: "Баскетбол",
	tennis: "Теннис",
	icehockey: "Хоккей",
	casino: "Казино",
	"t&c": "Условия использования",
	community: "Сообщество",
});
//2
Object.assign(_keywords["pt-PT"], {
	spin: "Girar",
	fortune_wheel: "Roda da Fortuna",
	crash: "Queda",
	discord: "Discord",
	telegram: "Telegram",
	twitter: "Twitter",
	instagram: "Instagram",
	facebook: "Facebook",
	daily_stats: "Estatísticas diárias",
	wheel_social_terms:
		"Para ser elegível para a retirada de lucro da roda, o usuário deve ter compartilhado nosso link do site em uma rede social (twitter, facebook, vk.com etc) nas últimas 24 horas, caso não tenha compartilhado, temos o direito de pausar ou confiscar a retirada, ao retirar, o usuário deve inserir o link no campo de comentários da retirada;",
	multi_accounts: "Multi contas",
	max_balance: "O saldo máximo permitido (saldo + banco) deve ser inferior a 3 USD",
	balance_must_be_less_than_1: "O saldo máximo permitido (saldo + banco) deve ser inferior a 1 USD",
	adjusted: "Ajustado",
	verify_or_wait:
		"Por favor, defina uma senha e verifique seu e-mail para desfrutar do serviço de depósito instantâneo ou espere a confirmação completa da transação",
	bonus_already_used: "O bônus atual já foi usado",
	new_games: "Novos jogos",
	hot_games: "Jogos populares",
	trending_games: "Jogos em tendência",
	freespin_tier: "Nível de giros grátis",
	total_players: "Total de jogadores",
	last_claimed_on: "Última reivindicação em: {0}",
	switch_aff_pro:
		"Afiliado PRO, é um sistema de afiliados dedicado a agências profissionais ou influenciadores de jogos de azar. Para ser elegível para o afiliado pro, seus afiliados precisam ter pelo menos 200 000 USDt equivalente em apostas. Para mais perguntas, por favor, entre em contato com o suporte",
	switch_aff_basic: "Para mudar para a afiliação BÁSICA, entre em contato com o suporte",
	total_claimed: "Total reivindicado",
	carry_forward: "Carregar para frente",
	campaigns: "Campanhas",
	campaign_name: "Nome da campanha",
	link: "Link",
	date_created: "Data de criação",
	registered_count: "Contagem de registrados",
	aff_pro_only_on_1_day: "Reivindicações só podem ser feitas no primeiro dia do mês",
	aff_min_10_days: "Reivindicações só podem ser feitas após 10 dias da última reivindicação",
	playertiers: "Níveis dos jogadores",
	prize: "Prêmio",
	repeat_after_days: "Repetir após dias",
	award_at: "Premiar em",
	our_choice: "Nossa escolha",
	official_mirror1: "Espelho: {0}",
	crypto: "Cripto",
	fiat: "Depósito em dinheiro",
	cash: "Dinheiro",
	member_limit_reached: "Limite de membros atingido",
	battle_already_running: "Esta batalha já está em andamento",
	country_restricted: "Seu país está restrito para este provedor de jogos",
	battle: "Batalha",
	battles: "Batalhas",
	leave: "Sair",
	scores: "Pontuações",
	other_battles: "Outras batalhas",
	my_favourite_games: "Meus jogos favoritos",
	create_new_battle: "Criar nova batalha",
	battle_created: "Batalha criada",
	new_battle: "Nova batalha",
	no_battle: "Atualmente nenhuma batalha está em andamento, mas você pode criar uma e convidar outros",
	battle_spins: "Giros da batalha",
	battle_not_found: "Batalha não encontrada ou expirada",
	invite_battlers: "Convidar lutadores",
	spin_value: "Valor do giro",
	view: "Ver",
	fsbuy: "Comprar giros grátis",
	freespins_acquired: "Giros grátis adquiridos",
	freespinIn: "Giros grátis recebidos",
	freespinOut: "Giros grátis enviados",
	freespins_received: "Giros grátis recebidos",
	send_freespins: "Enviar giros grátis",
	privacy_mode: "Modo incognito",
	you_have_unspent_freespins: "Você tem giros grátis não utilizados para o jogo selecionado",
	already_playing_other_battle: "Você já está jogando o mesmo jogo em outra batalha",
	on_privacy_off: "Atenção! Ao desativar o 'Modo Incognito' suas apostas passadas e resultados de concursos se tornarão visíveis",
	on_privacy_on:
		"Você está ativando o modo incognito, suas apostas, posições em concursos, participação em batalhas se tornarão invisíveis até você desativar o modo incognito",
	my_points: "Meus pontos",
	big_wins: "Grandes vitórias",
	rakeback: "Rakeback",
	cannot_perform: "Não é possível realizar a ação, por favor, cumpra todos os requisitos",
	expires_after: "Expira após",
	gross: "Bruto",
	wheel_disclaimer: "Giros grátis são creditados apenas quando o Valor Líquido Reivindicado é maior que 1 USD",
	add_balance: "Adicionar saldo",
	promo_code: "Código promocional",
	loyalty: "Fidelidade",
	fullname: "Nome completo",
	next: "Próximo",
	bonus_expired: "O código do bônus está errado ou já expirou",
	detected_multiaccount: "Multi conta detectada",
	cashback: "Cashback",
	create_order: "Criar pedido",
	changelly: "Comprar cripto",
	changelly_sell: "Vender cripto",
	flappybet: "FlappyBet",
	share: "Compartilhar",
	campaign_expired: "Campanha expirada",
	campaign_already_activated: "Você já usou o código da campanha atual",
	responsible_gaming: "Jogo responsável",
});
//2
Object.assign(_keywords["en-US"], {
	spin: "Spin",
	fortune_wheel: "Fortune wheel",
	crash: "Crash",
	discord: "Discord",
	telegram: "Telegram",
	twitter: "Twitter",
	instagram: "Instagram",
	facebook: "Facebook",
	daily_stats: "Daily stats",
	wheel_social_terms:
		"In order to be eligible for wheel profit withdrawal, the user should have shared our site link in social netwok (twitter, facebook, vk.com etc) within past 24 hours, if not shared we have right to pause or confiscate the withdrawal, while withdrawing the user must insert the link in the withdraw's comment field;",
	multi_accounts: "Multi accounts",
	max_balance: "Max allowed balance (balance + bank) must be less than 2 USD",
	balance_must_be_less_than_1: "Max allowed balance (balance + bank) must be less than 1 USD",
	adjusted: "Adjusted",
	verify_or_wait: "Please set password and verify your email to enjoy the instant deposit service or wait for the transaction to be fully confirmed",
	bonus_already_used: "Current bonus is already used",
	new_games: "New games",
	hot_games: "Hot games",
	trending_games: "Trending games",
	freespin_tier: "Freespin tier",
	total_players: "Total players",
	last_claimed_on: "Last claimed on: {0}",
	switch_aff_pro:
		"Affiliation PRO, is dedicated affiliate system for professional agencies or gambling influencers in order to be eligible for affiliate pro your affiliates need to have at least 200 000 USDt equivalent wager, for further questions please contact support",
	switch_aff_basic: "To switch to the affiliation BASIC, please contact support",
	total_claimed: "Total claimed",
	carry_forward: "Carry forward",
	campaigns: "Campaigns",
	campaign_name: "Campaign name",
	link: "Link",
	date_created: "Date created",
	registered_count: "Registered count",
	aff_pro_only_on_1_day: "Claims can be done only on the first day of the month",
	aff_min_10_days: "Claims can only be done after 10 days from last claim",
	playertiers: "Players' tiers",
	prize: "Prize",
	repeat_after_days: "Repeat after days",
	award_at: "Award at",
	our_choice: "Our choice",
	official_mirror1: "Мirror: {0}",
	crypto: "Crypto",
	fiat: "Fiat",
	cash: "Cash",
	member_limit_reached: "Member limit reached",
	battle_already_running: "This battle is already running",
	country_restricted: "Your country is restricted for this game provider",
	battle: "Battle",
	battles: "Battles",
	leave: "Leave",
	scores: "Scores",
	other_battles: "Other battles",
	my_favourite_games: "My Favourite Games",
	create_new_battle: "Create new battle",
	battle_created: "Battle created",
	new_battle: "New battle",
	no_battle: "Currently no battle is running, but you can create one and invite others",
	battle_spins: "Battle spins",
	battle_not_found: "Battle not found or expired",
	invite_battlers: "Invite battlers",
	spin_value: "Spin value",
	view: "View",
	fsbuy: "Buy freespins",
	freespins_acquired: "Freespins acquired",
	freespinIn: "Freespins received",
	freespinOut: "Freespins sent",
	freespins_received: "Freespins received",
	send_freespins: "Send freespins",
	privacy_mode: "Incognito mode",
	you_have_unspent_freespins: "You have unspent freespins for the selected game",
	already_playing_other_battle: "You already playing same game in other battle",
	on_privacy_off: "Attention! By turning the 'Incognito Mode' off your past bets and contests' results will become visible",
	on_privacy_on:
		"You are activating incognito mode, your bets, contest positions, battle participation will become invisible untill you disable incognito mode",
	my_points: "My points",
	big_wins: "Big wins",
	rakeback: "Rakeback",
	cannot_perform: "Cannot perform action, please fulfill all requirements",
	expires_after: "Expires after",
	gross: "Gross",
	wheel_disclaimer: "Freespins are only credited when the Net Claimed Amount is more then 1 USD",
	add_balance: "Add balance",
	promo_code: "Promo code",
	loyalty: "Loyalty",
	fullname: "Fullname",
	next: "Next",
	bonus_expired: "The bonus code is wrong or has already been expired",
	detected_multiaccount: "Detected multiaccount",
	cashback: "Cashback",
	create_order: "Create order",
	changelly: "Buy crypto",
	changelly_sell: "Sell crypto",
	flappybet: "FlappyBet",
	share: "Share",
	campaign_expired: "Campaign expired",
	campaign_already_activated: "You already used current campaign code",
	responsible_gaming: "Responsible gaming",
	destination_tag: "Destination tag",
	wager_required: "Minimum wager required",
	vip_request: "VIP Request form",
	request_sent: "Request sent successfully",
	birthday: "Birth day",
	not_contains_username: "Does not contain username"
});
Object.assign(_keywords["ru-RU"], {
	spin: "Вращать",
	fortune_wheel: "Колесо удачи",
	crash: "Краш",
	discord: "Дискорд",
	telegram: "Телеграм",
	twitter: "Твиттер",
	instagram: "Инстаграм",
	facebook: "Феисбук",
	daily_stats: "Дневная статистика",
	wheel_social_terms:
		"Чтобы иметь право на вывод прибыли от колеса, пользователь должен опубликовать ссылку нашего сайта в социальной сети (twitter, facebook, vk.com и т. д.) в течение последних 24 часов, в противном случае мы имеем право приостановить или конфисковать вывод, при выводе пользователь должен вставить ссылку в поле комментария вывода",
	multi_accounts: "Мультик аккаунты",
	max_balance: "Максимальный допустимый баланс (баланс + банк) должен быть меньше 3$",
	balance_must_be_less_than_1: "Максимальный допустимый баланс (баланс + банк) должен быть меньше 1$",
	adjusted: "Корректировка",
	verify_or_wait:
		"Пожалуйста, установите пароль и подтвердите свою почту, чтобы воспользоваться услугой мгновенного депозита, или дождитесь полного подтверждения транзакции",
	bonus_already_used: "Данный бонус уже использован",
	new_games: "Новые игры",
	hot_games: "Горячие игры",
	trending_games: "Трендовые игры",
	freespin_tier: "Уровень фриспинов",
	total_players: "Всего игроков",
	last_claimed_on: "Последний раз был запрошен в: {0}",
	switch_aff_pro:
		"Реферал (Про) — это специализированная партнерская система для профессиональных агентств или для инфлюенсеров в азартных играх, чтобы иметь право на участие в партнерской программе Pro, ваши партнеры должны иметь эквивалентный оборот не менее 200 000 долларов США. Для получения дополнительных вопросов обратитесь в службу поддержки",
	switch_aff_basic: "Чтобы перейти на реферальную систему (Бaзовая), пожалуйста, свяжитесь с поддержкой",
	total_claimed: "Всего запрошено",
	carry_forward: "Carry forward",
	campaigns: "Кампании",
	campaign_name: "Название кампании",
	link: "Ссылка",
	date_created: "Дата создания",
	registered_count: "Количество зарег.",
	aff_pro_only_on_1_day: "Запрос может только быть сделан первого чилса месяца",
	aff_min_10_days: "Запрос может быть сделан только после 10 дней от последнего запроса",
	playertiers: "Тиры игроков",
	prize: "Приз",
	repeat_after_days: "Повторить через дней",
	award_at: "Вознаградить в",
	our_choice: "Наш выбор",
	official_mirror1: "Зеркало: {0}",
	crypto: "Крипта",
	fiat: "Оплата картой",
	cash: "Деньги",
	member_limit_reached: "Достигнут лимит участников",
	battle_already_running: "Битва уже началась",
	country_restricted: "Данный проваидер игр запрещён в вашей стране",
	battle: "Битва",
	battles: "Битвы",
	leave: "Выйти",
	scores: "Очки",
	other_battles: "Другие битвы",
	my_favourite_games: "Мои фаворитные игры",
	create_new_battle: "Создать новую битву",
	battle_created: "Вы создали битву",
	no_battle: "В данный момент битвы не ведутся но вы можете создать свою битву и пригласить других",
	battle_spins: "Спины битв",
	new_battle: "Новая битва",
	battle_not_found: "Битва не найдена, либо истекла",
	invite_battlers: "Пригласить бойцов",
	spin_value: "Стоимость броска",
	view: "Посмотреть",
	fsbuy: "Купить фриспины",
	freespins_acquired: "Вы приобрели фриспины",
	freespinIn: "Полученные фриспины",
	freespinOut: "Отправленные фриспины",
	freespins_received: "Вы получили фриспины",
	send_freespins: "Отправить фриспины",
	privacy_mode: "Приватный режим",
	you_have_unspent_freespins: "В данной игре у вас уже имеются неиспользованные фриспины",
	already_playing_other_battle: "Вы уже играете данную игру в другой битве",
	on_privacy_off: "Внимание! При отключении приватного режима ваши прежние скрытые ставки и очки конкурсов станут доступными",
	on_privacy_on:
		"Вы включаете скрытый режим, ваши ставки, места в конкурсах, участие в битвах станут скрытыми до тех пор пока вы не отключите данный режим",
	my_points: "Мои очки",
	big_wins: "Крупные выигрыши",
	rakeback: "Рейкбек",
	cannot_perform: "Невозможно выполнить действие, пожалуйста выполните все требуемые условия",
	expires_after: "Истекает после",
	gross: "Валовой",
	wheel_disclaimer: "фриспины зачисляются только в том случае, когда чистая запрошенная сумма превышает 1$",
	add_balance: "Пополнить баланс",
	promo_code: "Промо код",
	loyalty: "Лояльность",
	fullname: "Полное имя",
	next: "Далее",
	bonus_expired: "Бонус код неверный либо истек",
	detected_multiaccount: "Обнаружен мультиаккаунт",
	cashback: "Кешбек",
	create_order: "Заказать",
	changelly: "Купить крипту",
	changelly_sell: "Продать крипту",
	share: "Поделиться",
	campaign_expired: "Данная кампания больше не действительна",
	campaign_already_activated: "Вы уже использовали данный код",
	responsible_gaming: "Ответственная игра",
	wager_required: "Требуется минимальный вагер",
	vip_request: "Заявка на Вип статус",
	request_sent: "Заявка успешно отправлена",
	birthday: "День рождения",
	not_contains_username: "Не содержит имя пользователя"
});

const fullKeysArr = Object.keys(_keywords);
const fullKeysO = {};
const keys = [];
for (let el of fullKeysArr) {
	const key = el.split("-")[0];
	fullKeysO[key] = el;
	keys.push(key);
}

export const keywords = _keywords;
export const FULL_KEYS = fullKeysO; //{en: "en-US", ru: "ru-RU"}
export const KEYS = keys; //["en","ru"]
